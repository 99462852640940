export default {
  path: '/dsp/system',
  title: '系统设置',
  header: 'dsp',
  children: [
  // {
  // 		path: '/dsp/system/performance-setting',
  // 		title:'绩效设置'
  // },
  {
    path: '/dsp/system/system',
    title: '系统设置'
  }]
};