export default {
  path: '/dsp/upload-manage',
  title: '上传管理',
  header: 'dsp',
  children: [{
    path: '/dsp/upload',
    title: '上传管理',
    children: [{
      path: '/dsp/upload/details',
      title: '上传报告',
      hide: true
    }]
  }]
};