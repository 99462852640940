var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "fk-state-icon"
  }, [_vm.readOnly ? _c("div", {
    staticClass: "read-only"
  }, [_vm.state === "enabled" ? _c("fk-tooltip", {
    attrs: {
      content: "运行中"
    }
  }, [_c("span", {
    staticClass: "el-import-icon-qiyong"
  })]) : _vm._e(), _vm.state === "paused" ? _c("fk-tooltip", {
    attrs: {
      content: "已暂停"
    }
  }, [_c("span", {
    staticClass: "el-import-icon-tingyong"
  })]) : _vm._e(), _vm.state === "archived" ? _c("fk-tooltip", {
    staticStyle: {
      cursor: "default"
    },
    attrs: {
      content: "已归档"
    }
  }, [_c("span", {
    staticClass: "el-import-icon-guidang"
  })]) : _vm._e()], 1) : [_vm.loading ? _c("i", {
    staticClass: "el-icon-loading",
    staticStyle: {
      "font-size": "14px"
    }
  }) : _vm.state !== "archived" ? _c("el-dropdown", {
    on: {
      command: _vm.handleStateChange
    }
  }, [_c("span", {
    staticClass: "el-dropdown-link dropdown-icon"
  }, [_vm.state === "enabled" ? _c("i", {
    staticClass: "el-import-icon-qiyong"
  }) : _vm._e(), _vm.state === "paused" ? _c("i", {
    staticClass: "el-import-icon-tingyong"
  }) : _vm._e()]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    class: {
      "is-cur": _vm.state === "enabled"
    },
    attrs: {
      command: "enabled"
    }
  }, [_vm._v("启用")]), !_vm.hidePaused ? _c("el-dropdown-item", {
    class: {
      "is-cur": _vm.state === "paused"
    },
    attrs: {
      command: "paused"
    }
  }, [_vm._v("暂停")]) : _vm._e(), !_vm.hideArchived ? _c("el-dropdown-item", {
    attrs: {
      disabled: _vm.disArchived,
      command: "archived"
    }
  }, [_vm._v("归档")]) : _vm._e()], 1)], 1) : _c("fk-tooltip", {
    attrs: {
      content: "已归档"
    }
  }, [_c("span", {
    staticClass: "el-import-icon-guidang",
    staticStyle: {
      cursor: "default"
    }
  })])]], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };