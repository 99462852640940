import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import request from '@/plugins/request';
var origin = window.location.origin;
var dsp2BaseURL = origin + '/dspapi';
export default {
  dsp2Get: function dsp2Get(url, data, config) {
    return request.get(dsp2BaseURL + url, _objectSpread(_objectSpread({}, config), {}, {
      requestExternalInterface: true,
      params: data
    }));
  },
  dsp2Post: function dsp2Post(url, data, config) {
    return request.post(dsp2BaseURL + url, data, _objectSpread(_objectSpread({}, config), {}, {
      requestExternalInterface: true
    }));
  },
  dsp2Put: function dsp2Put(url, data, config) {
    return request.put(dsp2BaseURL + url, data, _objectSpread(_objectSpread({}, config), {}, {
      requestExternalInterface: true
    }));
  }
};