/* 装饰器 */

/**
 * 防抖
 * @param {number} [delay=200] 延迟
 */
export var debounce = function debounce() {
  var delay = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 200;
  return function (target, name, desc) {
    var fn = desc.value;
    var timeout = null;
    desc.value = function () {
      for (var _len = arguments.length, rest = new Array(_len), _key = 0; _key < _len; _key++) {
        rest[_key] = arguments[_key];
      }
      var context = this;
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        fn.call.apply(fn, [context].concat(rest));
      }, delay);
    };
  };
};