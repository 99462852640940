// 导入自有语言包
import zhCN from "./locale/zh-CN";
import enUS from "./locale/en-US";

// 导入 iView 语言包
import zhCNiView from 'view-design/dist/locale/zh-CN';
import enUSiView from 'view-design/dist/locale/en-US';

// 导入element语言包
import enLocale from 'element-ui/lib/locale/lang/en';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';

// 合并语言包
export default {
  'zh-CN': Object.assign(zhCN, zhCNiView, zhLocale),
  'en-US': Object.assign(enUS, enUSiView, enLocale)
};