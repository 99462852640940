import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.number.constructor";
import { date2EnWithYear, time2En, convertUTF8ToCST, convertUTF8ToEST } from "../../assets/js/utils/date";
export default {
  name: 'fk-pagination',
  props: {
    page: {
      type: Number,
      default: 1
    },
    size: {
      type: Number,
      default: 50
    },
    total: {
      type: Number,
      default: 0
    },
    sizes: {
      type: Array,
      default: function _default() {
        return [10, 50, 100];
      }
    },
    showTime: {
      type: String,
      default: 'false'
    },
    showItemIndex: {
      type: Boolean,
      default: false
    },
    showDragMsg: {
      type: String,
      default: 'true'
    }
  },
  data: function data() {
    return {
      layout: 'total, prev, pager, next, sizes, jumper'
    };
  },
  created: function created() {
    if (this.showItemIndex) {
      this.layout = this.layout.replace('total, ', '');
    }
  },
  computed: {
    start: function start() {
      return (this.page - 1) * this.size + 1;
    },
    end: function end() {
      var end = this.page * this.size;
      return end > this.total ? this.total : end;
    }
  },
  filters: {
    showTimeHandle: function showTimeHandle(value) {
      if (value) {
        var CSTValue = convertUTF8ToCST(value);
        var CSTArr = CSTValue.split(' ');
        var CSTDate = date2EnWithYear(CSTArr[0]);
        var CSTTime = time2En(CSTArr[1]);
        var ESTValue = convertUTF8ToEST(value);
        var ESTArr = ESTValue.split(' ');
        var ESTDate = date2EnWithYear(ESTArr[0]);
        var ESTTime = time2En(ESTArr[1]);
        return " Last update ".concat(ESTDate, " ").concat(ESTTime, " EST & ").concat(CSTDate, " ").concat(CSTTime, " CST");
      }
    }
  },
  methods: {
    pageChange: function pageChange(page) {
      this.$emit('update:page', page);
      this.$emit('page-change', page);
    },
    pageSizeChange: function pageSizeChange(size) {
      // size改变，重置当前页
      this.$emit('update:page', 1);
      this.$emit('update:size', size);
      this.$emit('size-change', size);
    }
  }
};