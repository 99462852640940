export default {
  path: '/dsp/advertise-manage',
  title: '广告管理',
  header: 'dsp',
  children: [{
    path: '/dsp/media-plan-manage',
    title: '广告投放'
  }, {
    path: '/dsp/orders',
    title: 'Orders管理'
  }]
};