import "core-js/modules/es6.function.name";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("router-link", {
    attrs: {
      to: _vm.to,
      target: _vm.target
    }
  }, [_vm._v(_vm._s(_vm.name))]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };