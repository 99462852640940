var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "router-alive"
  }, [_c("transition", _vm._b({
    attrs: {
      appear: ""
    },
    on: {
      "after-enter": _vm.onTrans,
      "after-leave": _vm.onTrans
    }
  }, "transition", _vm.pageTrans, false), [_c("keep-alive", {
    attrs: {
      max: _vm.max
    }
  }, [_vm.alive && !_vm.onRefresh && !_vm.hideRouter ? _c("router-view", _vm._g({
    key: _vm.key,
    ref: "page",
    class: _vm.pageClass,
    on: {
      "page-loaded": _vm.onPageLoaded
    }
  }, _vm.hooks)) : _vm._e()], 1)], 1), _c("transition", _vm._b({
    attrs: {
      appear: ""
    },
    on: {
      "after-enter": _vm.onTrans,
      "after-leave": _vm.onTrans
    }
  }, "transition", _vm.pageTrans, false), [!_vm.alive && !_vm.onRefresh && !_vm.hideRouter ? _c("router-view", {
    key: _vm.key,
    ref: "page",
    class: _vm.pageClass
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };