export default {
  path: '/dsp/question-manage/result',
  name: 'questionManageResult',
  meta: {
    auth: true,
    title: '查看问卷'
  },
  component: function component() {
    return import( /*webpackChunkName:'dsp-account-manage'*/'@/pages/dsp/office-manage/question-manage/detail/result');
  }
};