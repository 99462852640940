export default {
  path: '/dsp/apply-manager',
  title: '审批管理',
  header: 'dsp',
  children: [{
    path: '/dsp/budget-apply',
    title: '方案预算审核'
  }, {
    path: '/dsp/balance-account',
    title: '账单平帐审批'
  }]
};