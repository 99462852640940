import request from '@/plugins/request';
import QS from "qs";
/**
 * 发起登陆
 */
export function doLogin(path, data) {
  return request({
    url: path,
    method: 'post',
    data: data
  });
}

//微信登录校验
export var wxLogin = function wxLogin(data) {
  return request.post("/system/callback/wx", data);
};

//获取用户信息
export var getUserInfo = function getUserInfo() {
  return request.get("/system/userInfo");
};

//退出登录
export var AccountLogout = function AccountLogout() {
  return request.put("/system/logout");
};

//密码修改
export var PasswordReset = function PasswordReset(data) {
  return request.put("/system/reset", data, {
    transformRequest: function transformRequest(v) {
      return QS.stringify(v);
    }
  });
};

//获取 所有 系统用户，用于绑定AM ＢＤ的微信　或者流转之类的功能
export var getAllSystemUser = function getAllSystemUser() {
  return request.get("/system/allUser");
};

// 获取系统当前登录用户　/system/userInfo
export var getCurrentLoginUser = function getCurrentLoginUser() {
  return request.get("/system/userInfo");
};