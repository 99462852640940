/****** business ******/

import { getSiteList as _getSiteList } from '@api/indicator/ad';
export default {
  namespaced: true,
  state: {
    //站点列表
    siteList: [],
    // 投放方案是否发生改变  用于提示是否需要生成新版本
    versionChange: false
  },
  mutations: {
    // 投放方案已发生修改,提示是否需要生成新版本
    changeVersion: function changeVersion(state) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      state.versionChange = data;
    }
  },
  actions: {
    //获取站点列表
    getSiteList: function getSiteList(_ref) {
      var state = _ref.state;
      return new Promise(function (resolve, reject) {
        _getSiteList().then(function (res) {
          var flag = res.flag,
            message = res.message,
            data = res.data;
          if (flag === 0) {
            state.siteList = data || [];
          }
          resolve(res);
        }).catch(function (e) {
          return reject(e);
        });
      });
    }
  }
};