import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.regexp.split";
import _toConsumableArray from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/es6.array.find";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/web.dom.iterable";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import VXETable from 'vxe-table';
import vuedraggable from 'vuedraggable';
import VXETableConfig from "../../config/vxeTableConfig";
import { getScrollbarWidth } from '@/util/util';
var Grid = VXETable.Grid;

// init配置
VXETableConfig(VXETable);
var scrollbarWidth = getScrollbarWidth();

// 循环渲染默认render方法
function deepChild(obj) {
  obj.children.forEach(function (item) {
    if (!item.columnsKey) item.columnsKey = getCustomKey(item);
    if (!item.cellRender) {
      item.cellRender = {
        name: 'VxeTableDefaultCellRender',
        props: item
      };
    }
    if (!item.key && item.field) item.key = item.field;
    if (item.children) deepChild(item);
  });
}

/**
 * 获取自定义字段的key
 */
function getCustomKey(row) {
  return row.params && row.params.customKey ? row.params.customKey : row.field;
}
// 表格属性
var tableProps = Object.assign({}, Grid.props, {
  autoResize: {
    type: Boolean,
    default: true
  },
  showOverflow: {
    type: Boolean,
    default: true
  },
  highlightHoverRow: {
    type: Boolean,
    default: true
  },
  rowKey: {
    type: Boolean,
    default: true
  },
  height: {
    default: '100%'
  },
  scrollY: {
    default: function _default() {
      return {
        gt: 20
      };
    }
  },
  resizable: {
    default: false
  },
  border: {
    type: [Boolean, String],
    default: 'default'
  }
});
// 非表格属性
var otherProps = {
  // 分页config
  paginationConfig: {
    type: Object,
    default: function _default() {
      return {
        enabled: true,
        showDragMsg: true
      };
    }
  },
  // 若要记住上次分页则传入key
  savePageSizeName: {
    type: String,
    default: ''
  },
  // 分页开始
  tablePage: {
    type: Number,
    default: 1
  },
  // 分页大小
  tableSize: {
    type: Number,
    default: 20
  },
  // 列表总数
  tableTotal: {
    type: Number,
    default: 0
  },
  // table的loading
  loading: {
    type: Boolean,
    default: false
  },
  // 自定义列弹窗宽度
  dialogWidth: {
    type: String,
    default: '480px'
  },
  // 自定义列存放key
  moduleName: {
    type: String,
    default: undefined
  },
  // 表格单行高度
  tdHeight: {
    type: [Number, String],
    default: 60
  },
  // 列表
  columns: {
    type: Array
  },
  // 拖拽
  tableDrag: {
    type: Boolean,
    default: true
  },
  // 固定表头
  tableFixed: {
    type: Boolean,
    default: false
  }
};
export default {
  name: 'fk-vxe-table',
  props: _objectSpread(_objectSpread({}, tableProps), otherProps),
  components: {
    vuedraggable: vuedraggable
  },
  data: function data() {
    return {
      updateTableHeightNum: 0,
      // 自定义字段
      saveCustomLoading: false,
      columnVisible: false,
      customColumns: [],
      columnsListCopy: [],
      isMultistage: false,
      isHideAllMultiple: true
    };
  },
  created: function created() {
    var PROPS = this.$props;
    if (PROPS.savePageSizeName) {
      var localPageSize = localStorage.getItem(PROPS.savePageSizeName);
      localPageSize = Number(localPageSize);
      if (localPageSize && !isNaN(localPageSize)) {
        this.$emit('update:tableSize', localPageSize);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      var PROPS = _this.$props;
      var columns = PROPS.columns;
      columns.forEach(function (item) {
        if (!item.columnsKey) item.columnsKey = getCustomKey(item);
        item.visible = item.visible === false || item.hideColumn ? false : true; // 仅当传入值为false时才会为false, hideColumn用于外部控制显示隐藏，visible用于自定义字段控制显示与隐藏
        if (item.children) {
          item.children.forEach(function (children) {
            children.visible = children.visible === false || item.hideColumn ? false : true;
          });
        }
        if (!item.cellRender) {
          item.cellRender = {
            name: 'VxeTableDefaultCellRender',
            props: item
          };
        }
        if (!item.key && item.field) item.key = item.field;
        item.children && deepChild(item);
      });
      _this.customColumns = columns;
      _this.getCustomColumn();
    });
  },
  watch: _defineProperty({}, '$props.columns', function $propsColumns(v) {
    var hideArr = v.filter(function (k) {
      return k.hideColumn !== undefined;
    });
    if (hideArr.length) {
      this.customColumns.forEach(function (i) {
        var info = hideArr.find(function (z) {
          return z.field === i.field;
        });
        if (info) {
          i.hideColumn = info.hideColumn;
          i.visible = !info.hideColumn;
          if (i.children) {
            i.children.forEach(function (children) {
              children.hideColumn = info.hideColumn;
              children.visible = !info.hideColumn;
            });
          }
        }
      });
      this.tableRefresh();
    }
  }),
  computed: {
    tableHeight: function tableHeight() {
      this.updateTableHeightNum;
      var myTable = this.$refs.myTable;
      var childrenLen = 0;
      var length = this.data.length;
      if (myTable && length && this.$props.treeConfig) {
        var treeExpandArr = myTable.getTreeExpandRecords();
        childrenLen = treeExpandArr.map(function (i) {
          return i.children;
        }).flat().length;
      }
      length += childrenLen;
      var tableNode = this.getRef();
      var headerEl = tableNode ? tableNode.$el.querySelector('.vxe-table--header-wrapper') : null;
      var tdHeight = Number(this.tdHeight) || 64;
      var curHeight = length ? tdHeight * length : 48;
      var headerHeight = headerEl && headerEl.offsetHeight ? headerEl.offsetHeight : 45;
      return curHeight + scrollbarWidth + headerHeight;
    }
  },
  render: function render(h) {
    var _this2 = this;
    var PROPS = this.$props;
    var SELF = this;
    var paginationConfig = PROPS.paginationConfig;
    var vxeTableProps = {};
    // 传入vxe的props去除非表格属性
    for (var key in PROPS) {
      if (!otherProps.key) {
        vxeTableProps[key] = PROPS[key];
      }
    }
    // 设置公共的展开样式
    var expandConfig = vxeTableProps['expandConfig'] || {};
    vxeTableProps['expandConfig'] = _objectSpread({
      iconClose: 'el-import-icon-moren2x',
      iconOpen: 'el-import-icon-huijiantou2x2',
      iconLoaded: 'el-icon-loading'
    }, expandConfig);
    var LISTENERS = this.$listeners;
    // 设置div高度
    var styleObj = {
      position: 'relative'
    };
    // 仅有在开启虚拟滚动时需要计算高度，计算高度后，若使用expand展开功能则无法进行详细计算
    if (PROPS.scrollY && PROPS.scrollY.enabled !== false) {
      // 计算table高度
      var defaultCalcHeight = 100;
      if (paginationConfig.enabled) defaultCalcHeight += 40;
      styleObj.maxHeight = SELF.tableHeight + 'px';
      styleObj.height = "calc(100vh - ".concat(defaultCalcHeight, "px)");
    }
    var renderArr = [
    // 默认的slot
    this.$slots.default,
    // 表格
    h('div', {
      directives: [{
        name: 'loading',
        value: this.loading
      }],
      class: {
        'fk-common-full-screen': PROPS.height === '100%'
      }
    }, [h('vxe-grid', {
      props: _objectSpread(_objectSpread({}, vxeTableProps), {}, {
        columns: this.customColumns
      }),
      ref: 'myTable',
      class: {
        'MultistageSingleStyle': this.isMultistage && this.isHideAllMultiple
      },
      on: _objectSpread(_objectSpread({}, LISTENERS), {}, {
        'toggle-tree-expand': function toggleTreeExpand(_ref) {
          var expanded = _ref.expanded,
            row = _ref.row;
          if (!expanded) {
            // setTreeExpand会拿去所有展开数据，不管父节点是否展开，因此为了方便计算高度，关闭时隐藏所有子节点
            if (row.level === 1) SELF.$refs.myTable.setTreeExpand(row.children, false);
            SELF.updateTableHeightNum++;
          }
          SELF.$emit('toggle-tree-expand');
        }
      }),
      directives: [{
        name: 'fixed-vxe-table-head',
        modifiers: {
          drag: this.tableDrag,
          fixed: this.tableFixed
        }
      }]
    })])];
    if (paginationConfig.enabled) {
      var tablePage = PROPS.tablePage,
        tableSize = PROPS.tableSize,
        tableTotal = PROPS.tableTotal,
        savePageSizeName = PROPS.savePageSizeName;
      renderArr.push(h('fk-pagination', {
        props: {
          page: tablePage,
          size: tableSize,
          total: tableTotal,
          showItemIndex: paginationConfig.showItemIndex,
          showTime: paginationConfig.showTime === false ? 'false' : paginationConfig.showTime,
          showDragMsg: paginationConfig.showDragMsg === false ? 'false' : 'true'
        },
        style: {
          padding: '10px 0 0'
        },
        on: {
          'page-change': function pageChange() {
            SELF.getRef().clearScroll();
            SELF.$emit('getList');
          },
          'size-change': function sizeChange() {
            SELF.getRef().clearScroll();
            SELF.$emit('getList');
          },
          'update:page': function updatePage(page) {
            SELF.$emit('update:tablePage', page);
          },
          'update:size': function updateSize(size) {
            if (savePageSizeName) localStorage.setItem(savePageSizeName, size);
            SELF.$emit('update:tableSize', size);
          }
        }
      }));
    }
    if (this.moduleName) {
      renderArr.push(h('fk-dialog', {
        props: {
          title: '数据列显示',
          visible: SELF.columnVisible,
          width: PROPS.dialogWidth
        },
        on: {
          close: function close() {
            SELF.closeCustomDialog();
          }
        }
      }, [h(vuedraggable, {
        "class": "wrapper",
        "attrs": {
          "sort": false
        },
        "model": {
          value: SELF.columnsListCopy,
          callback: function callback($$v) {
            _this2.$set(SELF, "columnsListCopy", $$v);
          }
        }
      }, [h("transition-group", [SELF.columnsListCopy.map(function (column, index) {
        if (column.params && column.params.isCustomColumn && column.params.customTitle) {
          return h("vxe-checkbox", {
            "class": "fk-custom-column-list",
            "key": column.columnsKey,
            "model": {
              value: column.visible,
              callback: function callback($$v) {
                _this2.$set(column, "visible", $$v);
              }
            }
          }, [column.params.customTitle]);
        } else if (column.params && column.params.isCustomColumn) {
          return h("vxe-checkbox", {
            "class": "fk-custom-column-list",
            "key": column.columnsKey,
            "model": {
              value: column.visible,
              callback: function callback($$v) {
                _this2.$set(column, "visible", $$v);
              }
            }
          }, [column.title]);
        }
      })])]), h("div", {
        "slot": "footer"
      }, [h("el-button", {
        "attrs": {
          "size": "small"
        },
        "on": {
          "click": SELF.closeCustomDialog
        }
      }, ["\u53D6\u6D88"]), h("el-button", {
        "attrs": {
          "size": "small",
          "type": "primary",
          "loading": SELF.saveCustomLoading
        },
        "on": {
          "click": SELF.setCustomColumn
        }
      }, ["\u4FDD\u5B58"])])]));
    }
    return h('div', {}, renderArr);
  },
  methods: {
    /**
     * 获取当前表格refs对象
     */
    getRef: function getRef() {
      return this.$refs.myTable;
    },
    /**
     * 关闭自定义字段弹窗
     */
    closeCustomDialog: function closeCustomDialog() {
      this.columnVisible = false;
    },
    /**
     * 打开自定义字段弹窗
     */
    showCustomDialog: function showCustomDialog() {
      var _this3 = this;
      this.columnVisible = true;
      this.columnsListCopy = this.customColumns.filter(function (item) {
        return item.params && item.params.isCustomColumn && !item.hideColumn;
      }).map(function (i) {
        return _objectSpread({}, i);
      });
      this.customColumns.forEach(function (item) {
        if (item.children) {
          var _this3$columnsListCop;
          (_this3$columnsListCop = _this3.columnsListCopy).push.apply(_this3$columnsListCop, _toConsumableArray(item.children.filter(function (item) {
            return item.params && item.params.isCustomColumn && !item.hideColumn;
          }).map(function (i) {
            return _objectSpread({}, i);
          })));
        }
      });
    },
    /**
     * 获取自定义字段
     */
    getCustomColumn: function getCustomColumn() {
      var _this4 = this;
      if (this.moduleName) {
        this.javaGet('/api/UserPageListFields/GetUserListFieldsByModule', {
          'input.moduleName': this.moduleName
        }).then(function (res) {
          var resData = res.data;
          if (!resData || !resData.result) return false;
          var resResult = resData.result;
          if (!resResult || !resResult[0]) return false;
          var list = resResult.split(',');
          _this4.reloadCustomColumn(list);
        }).catch(function (error) {});
      }
    },
    setCustomColumn: function setCustomColumn() {
      var _this5 = this;
      var list = this.columnsListCopy;
      var fieldsArr = list.filter(function (k) {
        return k.visible;
      }).map(function (i) {
        return i.columnsKey;
      });
      var fieldsStr = fieldsArr.join(',');
      this.saveCustomLoading = true;
      var name = this.moduleName;
      this.javaPost('/api/UserPageListFields/AddUserListFields?input.moduleName=' + name + '&input.fields=' + fieldsStr).then(function (response) {
        _this5.$message.success(_this5.$t('saveMsg'));
        _this5.reloadCustomColumn(fieldsArr);
        _this5.closeCustomDialog();
        _this5.saveCustomLoading = false;
      }).catch(function (error) {
        _this5.saveCustomLoading = false;
        _this5.$message.error(_this5.$t('errorMsg'));
      });
    },
    reloadCustomColumn: function reloadCustomColumn(list) {
      var _this6 = this;
      // let customColumns = this.customColumns.filter(item => item.params && item.params.isCustomColumn) // 自定义字段
      // let customColumnsFix = this.customColumns.filter(item => !(item.params && item.params.isCustomColumn)) // 非自定义字段
      // customColumns.forEach(item => { // 展示隐藏
      //   item.visible = list.includes(item.columnsKey)
      // })
      // todo 由于要保存排序顺序，因此要全部传入自定义字段， 例如：[key:{visible: true}]，同时保证新加入字段可以展示
      // customColumns = customColumns.sort(function(a, b){ // 排序
      //   if (list.indexOf(a.columnsKey) === -1 || list.indexOf(b.columnsKey) === -1) return 0
      //   return list.indexOf(a.columnsKey) - list.indexOf(b.columnsKey)
      // });
      // this.customColumns = customColumnsFix.concat(customColumns)
      // *************************以上为排序功能，若排序 自定义字段必须连续，否则将会导致混乱；有需求再具体探讨***************************
      this.isHideAllMultiple = true;
      var customColumnsHaveChildren = -1;
      var customColumnsHaveChildrenLast = -1;
      this.customColumns.forEach(function (item, index) {
        // 展示隐藏
        if (item.params && item.params.isCustomColumn && !item.hideColumn) item.visible = list.includes(item.columnsKey);
        if (item.children) {
          item.headerClassName = 'cell-border-left';
          customColumnsHaveChildren = index;
          _this6.isMultistage = true;
          var firstChildren = -1;
          var lastChildren = -1;
          item.children.forEach(function (i, childrenIndex) {
            if (i.params && i.params.isCustomColumn && !i.hideColumn) i.visible = list.includes(i.columnsKey);
            if (i.visible) {
              _this6.isHideAllMultiple = false;
            }
          });
          // 找到第一个显示的二级表头和最后一个，加上左边边
          for (var i = 0; i < item.children.length; i++) {
            if (item.children[i].visible && firstChildren === -1) {
              firstChildren = i;
            }
            if (item.children.visible) {
              lastChildren = i;
            }
          }
          if (firstChildren !== -1) {
            item.children[firstChildren].headerClassName = 'cell-border-left';
          }
        }
      });
      if (customColumnsHaveChildren !== -1) {
        if (this.customColumns[customColumnsHaveChildren].headerClassName) {
          this.customColumns[customColumnsHaveChildren].headerClassName = 'cell-border-left cell-border-right';
        } else {
          this.customColumns[customColumnsHaveChildren].headerClassName = 'cell-border-right';
        }
        this.customColumns[customColumnsHaveChildren].children.forEach(function (item, index) {
          if (item.visible) {
            customColumnsHaveChildrenLast = index;
          }
        });
        if (customColumnsHaveChildrenLast !== -1) {
          if (this.customColumns[customColumnsHaveChildren].children[customColumnsHaveChildrenLast].headerClassName) {
            this.customColumns[customColumnsHaveChildren].children[customColumnsHaveChildrenLast].headerClassName = 'cell-border-left cell-border-right';
          } else {
            this.customColumns[customColumnsHaveChildren].children[customColumnsHaveChildrenLast].headerClassName = 'cell-border-right';
          }
        }
      }
      this.tableRefresh();
    },
    tableRefresh: function tableRefresh() {
      var _this7 = this;
      if (this.$refs.myTable) {
        this.$refs.myTable.reloadColumn(this.customColumns);
        this.$refs.myTable.reloadData(this.$props.data);
        this.$nextTick(function () {
          _this7.$refs.myTable.recalculate(true);
        });
      }
    }
  }
};