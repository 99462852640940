import "core-js/modules/es6.string.fixed";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/web.dom.iterable";
import _toConsumableArray from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import { throttle } from "../../assets/js/utils";

// 滚动元素class
var scrollElementClass = 'content-wraper';

/**
 * 获取父级滚动元素
 */
function getScrollElement() {
  return new Promise(function (resolve) {
    var interval = setInterval(function () {
      var div = document.querySelector(".".concat(scrollElementClass));
      if (!div) return false;
      clearInterval(interval);
      resolve(div);
    }, 100);
  });
}

/**
 * 获取当前元素到目标元素的top值
 */
function getCurrentDistance(el, target) {
  var offsetTop = el.offsetTop,
    offsetParent = el.offsetParent;
  if (!offsetParent || offsetParent === target) return offsetTop;
  return getCurrentDistance(offsetParent, target) + offsetTop;
}

/**
 * 创建需要固定表头的table
 */
function createFixedHead(el) {
  // 获取父级滚动元素
  getScrollElement().then(function (div) {
    // 初始化
    var head = el.querySelector('.vxe-table--header-wrapper');
    var body = el.querySelector('.vxe-table--body-wrapper');
    head.style.position = 'absolute';
    head.style.left = '0';
    head.style.top = '0';
    head.style.zIndex = '99';
    head.style.borderTop = "1px solid #EAEDF2", body.style.marginTop = "".concat(head.offsetHeight, "px");
    // 绑定需要控制top值的元素
    var fixedHead = el.querySelectorAll('.vxe-table--fixed-wrapper .vxe-table--header-wrapper');
    // 表格头部固定栏阴影
    var boxShadowl = el.querySelector('.vxe-table--fixed-wrapper');
    var boxShadowr = el.querySelector('.vxe-table--fixed-right-wrapper');
    if (boxShadowl && boxShadowr) {
      boxShadowl.style.zIndex = '100';
      boxShadowr.style.zIndex = '100';
    }
    fixedHead = [head].concat(_toConsumableArray(fixedHead));
    fixedHead.forEach(function (i) {
      return i.style.zIndex = '99';
    });
    el._fixed_head_scroll_list = fixedHead;
    el._fixed_head_scroll = function (e) {
      var target = e.target;
      var distance = getCurrentDistance(el, target);
      var scrollDistance = target.scrollTop;
      // el-table顶部有边框时
      // let top = scrollDistance - distance - 1
      var top = scrollDistance - distance;
      if (top < 0) top = 0;
      el._fixed_head_scroll_list.forEach(function (i) {
        return i.style.top = "".concat(top, "px");
      });
    };
    div.addEventListener('scroll', el._fixed_head_scroll);
  });
  // 释放资源
  return function () {
    getScrollElement().then(function (div) {
      div.onscroll = undefined;
      div.removeEventListener('scroll', el._fixed_head_scroll);
      el._fixed_head_scroll = undefined;
    });
  };
}

/**
 * 创建需要拖拽移动的table
 */
function createDragMove(el) {
  var div = el.querySelector('.vxe-table--main-wrapper .vxe-table--body-wrapper');
  var vxeTableEl = el;
  if (el.className.includes('vxe-grid')) {
    vxeTableEl = el.querySelector('.vxe-table');
  }
  div.onmousedown = function (e) {
    var fixedDiv = el.querySelector('.vxe-table--fixed-wrapper .vxe-table--body-wrapper');
    if (!fixedDiv && (vxeTableEl.className.includes('is--fixed-left') || vxeTableEl.className.includes('is--fixed-right'))) {
      div.style.userSelect = 'auto';
    } else {
      el._fixed_head_start = e.clientX;
      div.style.userSelect = 'none';
      div.onmousemove = throttle(function (e) {
        if (!el._fixed_head_start) return false;
        var pageX = e.clientX;
        var left = div.scrollLeft + el._fixed_head_start - pageX;
        div.scrollTo(left, div.scrollTop);
        el._fixed_head_start = pageX;
      }, 60);
    }
  };
  div.onmouseup = function () {
    div.onmousemove = undefined;
    el._fixed_head_start = undefined;
  };
  div.onmouseleave = function () {
    div.onmousemove = undefined;
    el._fixed_head_start = undefined;
  };
  // 释放资源
  return function () {
    div.onmousedown = undefined;
    div.onmouseup = undefined;
    div.onmouseleave = undefined;
  };
}
function getHeaderHeight(el) {
  var head = el.querySelector('.vxe-table--header-wrapper');
  var body = el.querySelector('.vxe-table--body-wrapper');
  if (head.offsetHeight === 0) {
    setTimeout(function () {
      getHeaderHeight(el);
    }, 500);
    return false;
  }
  el._fixed_head_scroll_list.forEach(function (i) {
    return i.style.top = "0px";
  });
  body.style.marginTop = "".concat(head.offsetHeight, "px");
}
export default {
  bind: function bind(el, _bind) {
    var _bind$modifiers = _bind.modifiers,
      fixed = _bind$modifiers.fixed,
      drag = _bind$modifiers.drag;
    // 固定表头
    if (fixed) el._fixed_head_fixed = createFixedHead(el);
    // 拖拽移动
    if (drag) el._fixed_head_drag = createDragMove(el);
  },
  update: function update(el, bind) {
    var fixed = bind.modifiers.fixed;
    if (!fixed) return;
    getScrollElement().then(function (div) {
      getHeaderHeight(el);
      // 滚动一下 将头部放在正确位置
      div.scrollTop = div.scrollTop - 1;
    });
  },
  unbind: function unbind(el) {
    // 固定表头
    if (el._fixed_head_fixed) el._fixed_head_fixed();
    // 拖拽移动
    if (el._fixed_head_drag) el._fixed_head_drag();
  }
};