export default {
  get: function get(key) {
    var value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : undefined;
  },
  set: function set(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  },
  remove: function remove(key) {
    sessionStorage.removeItem(key);
  },
  localGet: function localGet(key) {
    var value = localStorage.getItem(key);
    try {
      value = JSON.parse(value);
    } catch (e) {
      value = value || undefined;
    }
    return value;
  },
  localSet: function localSet(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },
  localRemove: function localRemove(key) {
    localStorage.removeItem(key);
  }
};