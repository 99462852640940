export default {
  path: '/dsp/system/exchangeRate',
  title: '汇率管理',
  header: 'dsp',
  children: [{
    path: '/dsp/system/exchangeRate-manage',
    title: '汇率管理'
  }, {
    path: '/dsp/financial/exchange-loss-manage',
    title: '汇损管理'
  }]
};