import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.array.find";
import { emptyObj } from "../util";

// 浏览器窗口关闭或者刷新
var beforeunload = function beforeunload($tabs, tabId, beforePageLeave) {
  return function (e) {
    if (!$tabs && $tabs._isDestroyed) return;
    var tab = $tabs.items.find(function (item) {
      return item.id === tabId;
    });
    var msg = beforePageLeave(tab, 'unload');
    if (msg && typeof msg === 'string') {
      e.preventDefault();
      e.returnValue = msg;

      // 非当前页签则切换
      if ($tabs.activeTabId !== tabId) {
        $tabs.open(tab.to, false, false);
      }
      return msg;
    }
  };
};

// 路由页面混入
export default {
  watch: {
    // 监听 routerTab 字段，更新页签信息
    routeTab: {
      handler: function handler(val) {
        if (!val) return;
        var tab = typeof val === 'string' ? {
          title: val
        } : val;
        var _ref = this.$tabs || emptyObj,
          activeTab = _ref.activeTab;
        if (tab && activeTab) {
          for (var key in tab) {
            if (!['id', 'to'].includes(key)) {
              this.$set(activeTab, key, tab[key]);
            }
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  // 创建前记录缓存
  mounted: function mounted() {
    var $tabs = this.$tabs;
    var _ref2 = this.$vnode && this.$vnode.componentOptions.Ctor.options || emptyObj,
      beforePageLeave = _ref2.beforePageLeave;

    // 页面离开确认
    if ($tabs && beforePageLeave) {
      window.addEventListener('beforeunload', this._beforeunload = beforeunload($tabs, $tabs.activeTabId, beforePageLeave.bind(this)));
    }
  },
  destroyed: function destroyed() {
    if (this._beforeunload) {
      window.removeEventListener('beforeunload', this._beforeunload);
    }
  }
};