var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "router-tab__contextmenu",
    class: {
      "has-icon": _vm.hasIcon
    },
    style: {
      left: "".concat(_vm.data.left, "px"),
      top: "".concat(_vm.data.top, "px")
    }
  }, _vm._l(_vm.menuList, function (item) {
    return _c("tab-contextmenu-item", {
      key: item.id,
      attrs: {
        data: item
      }
    });
  }), 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };