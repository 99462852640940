export default {
  name: 'dsp-legend-list',
  props: {
    align: {
      type: String,
      default: 'center'
    },
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      colors: ['rgba(51,153,255,0.6)', 'rgba(255,72,134,0.6)', 'rgba(40, 187, 135, 0.6)', 'rgba(253, 188, 0, 0.6)', 'rgba(0, 38, 142, 0.5)', 'rgba(208, 2, 27, 0.5)', 'rgba(0, 207, 204, 0.6)', 'rgba(245, 161, 107, 0.6)', 'rgba(153, 153, 153, 0.6)', 'rgba(202, 120, 235, 0.6)']
    };
  }
};