import "core-js/modules/es6.number.is-nan";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.set";
import "core-js/modules/es6.number.parse-float";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.to-string";
import { getObjectType } from "./index";
import { formatFloatNumber } from '@/libs/data-handler';
export function setDoubleNum(num) {
  var result = num > 0 && num < 10 ? '0' + num : '' + num;
  return result;
}
export function getListItemNum(totalNum, rowPerPage, curPage) {
  var startNum = 0;
  var endNum = 0;
  startNum = (curPage - 1) * rowPerPage + 1;
  if (totalNum % rowPerPage === 0) {
    // 如果最后一页也有 rowPerPage项
    endNum = curPage * rowPerPage;
  } else {
    if (curPage === Math.ceil(totalNum / rowPerPage)) {
      // 刚好是最后一页的时候
      endNum = startNum - 1 + totalNum % rowPerPage;
    } else {
      endNum = curPage * rowPerPage;
    }
  }
  return {
    'startIndex': startNum,
    'totalItems': totalNum,
    'endNum': endNum
  };
}

/**
 *  这个方法用于将数字每三位加“,”号
 * @param str
 * @returns {string | *}
 */
export function formatNum(str) {
  if (!str) return 0; // 如果传了0、不传、或其他不为真的值，直接返回0
  str = str.toString();
  var newStr = '';
  var count = 0;
  if (str.indexOf('.') === -1) {
    // 如果没有小数点
    for (var i = str.length - 1; i >= 0; i--) {
      newStr = count % 3 === 0 && count !== 0 && str.charAt(i) != '-' ? str.charAt(i) + ',' + newStr : str.charAt(i) + newStr;
      count++;
    }
    str = newStr;
  } else {
    for (var _i = str.indexOf('.') - 1; _i >= 0; _i--) {
      newStr = count % 3 === 0 && count !== 0 && str.charAt(_i) != '-' ? str.charAt(_i) + ',' + newStr : str.charAt(_i) + newStr;
      count++;
    }
    str = newStr + str.substring(str.indexOf('.'));
  }
  return str;
}
export function formatMoney(str) {
  var num = Number(str).toFixed(2);
  return formatNum(num);
}

/**
 * 获取url中?后面的参数,原来的方法，部分位置已经改为通过router query 来获取
 * @param name
 * @returns {*}
 */
export function getQueryString(name, url) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  var hashVal = url ? url : window.location.href;
  if (hashVal.indexOf('?') !== -1) {
    var searchVal = hashVal.split('?')[1];
    var r = searchVal.match(reg);
    if (r != null) return decodeURI(r[2]); // unescape,改成decodeURI解决中文乱码问题
    return null;
  } else {
    return null;
  }
}
export function checkTableSortChange(data) {
  var field = null;
  var type = '';
  switch (data.order) {
    case 'ascending':
      type = 'asc';
      break;
    case 'descending':
      type = 'desc';
      break;
    default:
      type = null;
      break;
  }
  if (data.column) {
    if (data.column.sortBy) {
      field = data.column.sortBy;
    } else {
      field = data.prop;
    }
  }
  if (field) field = field.replace(field[0], field[0].toUpperCase());
  return {
    'field': field,
    'type': type
  };
}

/**
 * 自定义table展开行的操作，改为点击某一行的任意空位置即可展开，再点击收起
 * @param key列表的key值
 * @param ExpandArr：expand-row-keys的值，为数组
 * @returns {*} 返回一个数组，重新赋值给组建中的expand-row-keys
 */
export function customToggleExpandRow(key, ExpandArr) {
  Array.prototype.removeArrItem = function (val) {
    var index = this.indexOf(val);
    if (index > -1) this.splice(index, 1);
  };
  if (ExpandArr.indexOf(key) < 0) {
    var curArr = [];
    curArr.push(key);
    ExpandArr = curArr;
  } else {
    ExpandArr.removeArrItem(key);
  }
  return ExpandArr;
}

/**
 * 请求返回是否成功的判断
 * @param isSuccess：外层的success,布尔值
 * @param result: result
 * @param isResultSuccess：result里面的success，布尔值
 * return 布尔值
 */
export function checkRequestResult(isSuccess, result, isResultSuccess) {
  if (isSuccess) {
    if (result) return !!isResultSuccess;
    return true;
  } else {
    return false;
  }
}

/**
 *  自定义日期选择框选择不为空时，调用此方法进行格式化
 * @param val 组件值的回调
 * @param betweenDate：实例中的自定义日期变量
 * @param initDate：初始化日期的变量
 * @param days： 天数按钮的变量
 * @param daysVal：自定义日期后天数需要设置的值
 * @returns {{days: *, betweenDate: Array}}
 */
export function betweenDateFormat(val, betweenDate, initDate, days, daysVal) {
  betweenDate = [];
  initDate = [];
  val.forEach(function (item, index) {
    var dateItem = getDate(item);
    var year = dateItem.getFullYear();
    var month = dateItem.getMonth() + 1;
    var date = dateItem.getDate();
    var formatDate = year + '-' + setDoubleNum(month) + '-' + setDoubleNum(date);
    betweenDate.push(formatDate);
    initDate.push(formatDate);
    days = daysVal;
  });
  return {
    'days': days,
    'initDate': initDate,
    'betweenDate': betweenDate
  };
}
function isNull(object) {
  if (object == null || typeof object == "undefined") {
    return true;
  }
  return false;
}
/**
 * 根据日期字符串获取星期几
 * @param {dateString} 日期字符串（如：2016-12-29），为空时为用户电脑当前日期
 * @returns {String}
 */
export function getWeek(dateString) {
  var date;
  if (dateString.length < 10) {
    return null;
  }
  if (isNull(dateString)) {
    date = new Date();
  } else {
    var dateArray = dateString.split("-");
    // date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2]);
    date = new Date(dateString);
  }
  var week = "周" + "日一二三四五六".charAt(date.getDay());
  return week;
}
export function getWeekEn(dateString) {
  var date;
  var weekMap = {
    0: 'Sun',
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat'
  };
  if (dateString.length < 10) {
    return null;
  }
  if (isNull(dateString)) {
    date = new Date();
  } else {
    // let dateArray = dateString.split("-");
    // date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2]);
    date = new Date(dateString);
  }
  var week = weekMap[date.getDay()];
  return week;
}
/**
 *@author hjw
 *@desc days: 时间筛选days的值；serviceTime： 服务器时间， isTrend：（非必传,布尔值)是否是趋势图
 */
export function checkDaysType(daysVal, serviceTime, isTrend) {
  if (daysVal == 0) {
    var nowDate = getDate(serviceTime);
    var today = formatDate(nowDate);
    return {
      startDate: today,
      endDate: today
    };
  } else if (daysVal == 'weekToDate') {
    return getWeekToDate(serviceTime);
  } else if (daysVal == 'lastWeek') {
    var lastWStartDate = getLastWeekDate(7, serviceTime);
    var lastWEndDate = getLastWeekDate(1, serviceTime);
    return {
      startDate: lastWStartDate,
      endDate: lastWEndDate
    };
  } else if (daysVal == 'monthToDate') {
    return getMonthToDate(serviceTime);
  } else if (daysVal == 'lastMonth') {
    return getLastMothBetweenDate(serviceTime);
  } else {
    var dateObj = getLastNumberDaysDate(daysVal, serviceTime, isTrend);
    return {
      startDate: dateObj.startDate,
      endDate: dateObj.endDate
    };
  }
}
function getLastNumberDaysDate(num, serviceTime, isTrend) {
  var dayMSec = 24 * 3600 * 1000; // 一天的毫秒数
  var nowDate = getDate(serviceTime);
  var nowTimeStamp = nowDate.getTime();
  var startDateStamp = nowTimeStamp - num * dayMSec; // 获取到last num days 的开始日期时间戳
  // if (isTrend) {                                        // 如果是趋势图的话也显示当天的数据
  //   startDateStamp = startDateStamp + 1 * dayMSec
  // }
  if (num && num != 1) {
    startDateStamp = startDateStamp + 1 * dayMSec; // 上面注释内容是原来趋势图过去几天（比如7天）是取到当天，而列表从当天的前一天开始算，先改为都取到当天
  }
  var startDateObj = getDate(startDateStamp);
  var startDate = formatDate(startDateObj); // 获取到last num days 的开始日期
  var endDateStamp = null;
  // if (isTrend) {                                        // 如果是趋势图的话也显示当天的数据
  //   endDateStamp = nowTimeStamp
  // } else {
  //   endDateStamp = nowTimeStamp - 1 * dayMSec         // 因为last num days 不包括当天，这里减去一天的时间戳来获取昨天的时间戳
  // }
  if (num && num != 1) {
    endDateStamp = nowTimeStamp;
  } else {
    endDateStamp = nowTimeStamp - 1 * dayMSec;
  }
  var endDateObj = getDate(endDateStamp);
  var endDate = formatDate(endDateObj); // 获取到last num days 的结束日期
  return {
    startDate: startDate,
    endDate: endDate
  };
}

/**
 * 获取本周至今的起止日期
 * @param serviceTime
 * @returns {{startDate: string, endDate: string}}
 */
export function getWeekToDate(serviceTime) {
  var dayMSec = 24 * 3600 * 1000;
  var nowDate = getDate(serviceTime);
  var Day = nowDate.getDay();
  var startDateStamp = nowDate.getTime() - Day * dayMSec;
  var startDate = formatDate(getDate(startDateStamp));
  var endDate = formatDate(nowDate);
  return {
    startDate: startDate,
    endDate: endDate
  };
}

/**
 * 获取上个星期的起止日期
 * @param n
 * @param serviceTime
 * @returns {string}
 */
export function getLastWeekDate(n, serviceTime) {
  var now = getDate(serviceTime);
  var year = now.getFullYear();
  var month = now.getMonth() + 1;
  var date = now.getDate();
  var day = now.getDay();
  n = n + day;
  if (day) {
    // 这个判断是为了解决跨年的问题
    if (!(month > 1)) {
      year = year - 1;
      month = 12;
    }
  }
  now.setDate(now.getDate() - n);
  year = now.getFullYear();
  month = now.getMonth() + 1;
  date = now.getDate();
  var result = year + '-' + (month < 10 ? '0' + month : month) + '-' + (date < 10 ? '0' + date : date);
  return result;
}

/**
 * 获取本月的1号日期和今天的日期
 * @param serviceTime
 * @returns {{startDate: string, endDate: string}}
 */
export function getMonthToDate(serviceTime) {
  var nowDate = getDate(serviceTime);
  var nowMonthFirstDay = getDate([nowDate.getFullYear(), nowDate.getMonth() + 1, 1].join('-'));
  var startDate = formatDate(nowMonthFirstDay);
  var endDate = formatDate(nowDate);
  return {
    startDate: startDate,
    endDate: endDate
  };
}
export function getMothBetweenDate(serviceTime) {
  var dayMSec = 24 * 3600 * 1000;
  var today = getDate(serviceTime);
  var nowMonthFirstDay = getDate([today.getFullYear(), today.getMonth() + 1, 1].join('-')); // 获取本月第一天
  var nowMonthLastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  return {
    startDate: formatDate(nowMonthFirstDay),
    endDate: formatDate(nowMonthLastDay)
  };
}

/**
 * 获取上个月的起止日期
 * @returns {{lastMonthFirstDay: *, lastMonthLastDay: *}}
 */
export function getLastMothBetweenDate(serviceTime) {
  var dayMSec = 24 * 3600 * 1000;
  var today = getDate(serviceTime);
  var lastMonthFirstDay = null;
  if (today.getMonth() !== 0) {
    lastMonthFirstDay = getDate([today.getFullYear(), today.getMonth(), 1].join('-')); // 获取上一个月的第一天
  } else {
    lastMonthFirstDay = getDate([today.getFullYear() - 1, 12, 1].join('-'));
  }
  var nowMonthFirstDay = getDate([today.getFullYear(), today.getMonth() + 1, 1].join('-')); // 获取本月第一天
  var lastMonthLastDayMSec = nowMonthFirstDay.getTime() - 1 * dayMSec; // 获取上一个月的最后一天的毫秒值
  var lastMonthLastDay = getDate(lastMonthLastDayMSec);
  return {
    startDate: formatDate(lastMonthFirstDay),
    endDate: formatDate(lastMonthLastDay)
  };
}
/**
 * 获取下个月的起止日期
 * @returns {{lastMonthFirstDay: *, lastMonthLastDay: *}}
 */
export function getNextMothBetweenDate(serviceTime) {
  var dayMSec = 24 * 3600 * 1000;
  var today = getDate(serviceTime);
  var nextMonthFirstDay = null;
  var nextMonthLageDay = null;
  if (today.getMonth() !== 11) {
    nextMonthFirstDay = getDate([today.getFullYear(), today.getMonth() + 2, 1].join('-')); // 获取下一个月的第一天
  } else {
    nextMonthFirstDay = getDate([today.getFullYear() + 1, 1, 1].join('-'));
  }
  nextMonthLageDay = new Date(nextMonthFirstDay.getFullYear(), nextMonthFirstDay.getMonth() + 1, 0);
  return {
    startDate: formatDate(nextMonthFirstDay),
    endDate: formatDate(nextMonthLageDay)
  };
}

/**
 * 传入日期对象，返回yy-MM-DD格式的日期
 * @param newDate
 * @returns {string}
 */
export function formatDate(newDate) {
  var firstY = newDate.getFullYear();
  var firstM = newDate.getMonth() + 1;
  var firstD = newDate.getDate();
  firstM = firstM >= 10 ? firstM : '0' + firstM;
  firstD = firstD >= 10 ? firstD : '0' + firstD;
  return firstY + '-' + firstM + '-' + firstD;
}

/**
 *@author hjw
 *@desc 计算两个时间相差的天数
 */
export function calcDateRangeNum(startDate, endDate) {
  var dayMSec = 24 * 3600 * 1000;
  var newStart = startDate.replace(new RegExp(/-/gm), '/');
  var startStamp = getDate(newStart).getTime();
  var newEnd = endDate.replace(new RegExp(/-/gm), '/');
  var endStamp = getDate(newEnd).getTime() + dayMSec;
  var disDays = parseInt((endStamp - startStamp) / dayMSec);
  return disDays;
}
export function checkDateRangeValCommon(startDate, endDate, dateRange) {
  var calcVal = calcDateRangeNum(startDate, endDate);
  var range = null;
  // 原来根据 天数的不同切换 按天、周、月的纬度显示，现注释掉，默认按天
  // if (calcVal <= 30) {
  //   range = '1'
  // } else if (calcVal > 30 && calcVal <= 90) {
  //   range = '2'
  // } else {
  //   range = '3'
  // }
  // 默认都按天-------------------
  range = dateRange;
  if (dateRange && calcVal <= 14 && dateRange == 3) {
    // 14天的时候不能按月
    range = '1';
  }
  return {
    range: range,
    val: calcVal
  };
}

/**
 *@author hjw
 *@desc JSEncrypt: JSEncrypt的实例化对象, pwd：密码
 */
export function pwdEncryption(JSEncrypt, pwd) {
  // let jse = new JSEncrypt()  // 新建JSEncrypt对象
  var pkey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCM0agdDmHKc1nWBfNtB6bmUgSmRp7YWhXhL8HOc31xZR+XJhxzDn2FEJNeowealiNyxSidbzXz9X9jWqqq9oxsw3Gt30xYXK4KB86Wor5Xr26ZTRWDfggsDtaevqCJZ1CrLGkz3nzNrGuKf6e2kixE1O4d/7zAbl31nYjKTHkSSwIDAQAB';
  JSEncrypt.setPublicKey(pkey);
  var rsaPassWord = JSEncrypt.encrypt(pwd); // 加密
  return rsaPassWord;
}

/**
 * 新标签打开新页面
 * @param routerName: 路由名称
 * @param queryStr：query；string
 */
// export function openSpecificPage (routerName, queryStr) {
//   let host = window.location.host
//   let domain = netUrl.indexOf(host) >= 0 ? netUrl : `http://${host}`
//   let redirectUrl = domain + '/console/' + routerName
//   if (queryStr) redirectUrl += queryStr
//   window.open(redirectUrl, '_blank')
// }

/**
 * 格式化浮点数，解决js浮点数计算后精度问题
 * @param number
 * @param accuracy 小数点位数
 * @returns {number}
 */
export function formatNumber(number) {
  var accuracy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  return Number.parseFloat(number.toFixed(accuracy));
}

/**
 * 格式化新标签页打开带的query参数
 * @param json
 * @returns {string}
 */
export function parseRedirectQuery(json) {
  var tempArr = [];
  for (var i in json) {
    var key = encodeURIComponent(i);
    var value = encodeURIComponent(json[i]);
    tempArr.push(key + '=' + value);
  }
  var urlParamsStr = tempArr.join('&');
  return urlParamsStr;
}

/**
 * @aurhor hjw
 * @desc JSON数组去重
 * array: json 数组
 * key 要去重的key
 */
export function uniqueJsonArray(array, key) {
  var resultArr = [array[0]];
  array.forEach(function (item) {
    var repeat = false;
    resultArr.forEach(function (el) {
      if (item[key] && el[key] && item[key] === el[key]) repeat = true;
    });
    if (!repeat) resultArr.push(item);
  });
  return resultArr;
}

/**
 * @author hjw
 * 根据相同字段对数组进行分组
 * @param list
 * @param field
 * @returns {Array}
 */
export function groupArrBySameField(list, field) {
  // 先把field取出来放一个数组里，然后去重
  var fieldArr = list.map(function (item) {
    return item[field];
  });
  var fieldFilterArr = new Set(fieldArr);
  var resultArr = [];
  // 根据去重后的数组进行分组筛选
  fieldFilterArr.forEach(function (item) {
    var resultItem = list.filter(function (el) {
      return el[field] === item;
    });
    resultArr.push(resultItem);
  });
  return resultArr;
}

/**
 * @author zy
 * 格式化url中search格式
 * @returns {Object}
 */
export function parseUrlParams() {
  if (window.location.search.length <= 0) return false;
  var info = window.location.search.slice(1);
  var result = {};
  info.split('&').forEach(function (item) {
    if (item.split('=')[1] && item.split('=')[1] !== 'null' && item.split('=')[1] !== 'undefined') {
      result[decodeURIComponent(item.split('=')[0])] = decodeURIComponent(item.split('=')[1]);
    }
  });
  return result;
}

/**
 * @author zy
 * 获取多个数组相加之和
 * @returns {Array}
 */
export function getArrsSum() {
  var arg = arguments;
  if (arg.length === 0) return [];
  var result = [];
  for (var i = 0; i < arg.length; i++) {
    arg[i].forEach(function (e, i) {
      if (result[i] == null || result[i] == '') {
        result[i] = 0;
      }
      result[i] += e;
    });
  }
  return result;
}

/**
 * @author zy
 * 获取数组中最大值
 * @returns {Number}
 */

export function getArrayMax(arr) {
  if (arr.length === 0) return 0;
  return Math.max.apply(null, arr);
}
/**
 * 获取Date格式时间
 */
function getDate(date) {
  try {
    // Date、Number类型，直接转换后返回
    if (['Date', 'Number'].includes(getObjectType(date))) return new Date(date);
    // String类型，替换时区
    date = date.split('+')[0];
    var newDate = new Date(date);
    // 转换后newDate 为 Invalid date，尝试做格式转换
    if (Number.isNaN(newDate.getTime())) newDate = new Date(date.split('-').join('/'));
    return newDate;
  } catch (e) {
    // new Date失败，返回当前时间
    return new Date();
  }
}
// 传入文件名判断是否为图片
export function isImage(fileName) {
  var index = fileName.lastIndexOf(".");
  var suffix = fileName.substr(index + 1);
  var imgSuffix = ['JPG', 'JPEG', 'PNG', 'GIF', 'BMP', 'jpg', 'jpeg', 'png', 'gif', 'bmp'];
  return imgSuffix.includes(suffix);
}
export function isWordFile(fileName) {
  var index = fileName.lastIndexOf(".");
  var suffix = fileName.substr(index + 1);
  var imgSuffix = ['doc', 'docx'];
  return imgSuffix.includes(suffix);
}
export function isExcel(fileName) {
  var index = fileName.lastIndexOf(".");
  var suffix = fileName.substr(index + 1);
  var imgSuffix = ['xls', 'xlsx'];
  return imgSuffix.includes(suffix);
}

// 获取两个周期环比对比
export function getMomData(a, b) {
  // 可能返回值为String '0.00'
  var current = Number(a);
  var last = Number(b);
  if (current && last) {
    var percent = Number(((current - last) / last * 100).toFixed(2));
    return {
      percent: Math.abs(percent) + '%',
      rise: percent === 0 ? '' : percent > 0 ? 'top' : 'bottom'
    };
  }
  return {
    percent: '--'
  };
}
/**
 * 防抖函数
 * @param fn
 * @param wait
 * @returns {Function}
 */
export function debounce(fn, wait) {
  var timer = null;
  return function () {
    var _arguments = arguments,
      _this = this;
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      fn.apply(_this, _arguments);
    }, wait);
  };
}
export function checkMoneySymbolTypeBySite(curSite) {
  var moneySymbol = '';
  if (curSite === 'uk') {
    moneySymbol = '￡';
  } else if (curSite === 'ca') {
    moneySymbol = 'C$';
  } else if (curSite === 'mx') {
    moneySymbol = 'Mex.$';
  } else if (curSite === 'de' || curSite === 'fr' || curSite === 'it' || curSite === 'es' || curSite === 'nl') {
    moneySymbol = '€';
  } else if (curSite === 'au') {
    moneySymbol = 'A$';
  } else if (curSite === 'jp') {
    moneySymbol = '￥';
  } else if (curSite === 'se') {
    moneySymbol = 'kr';
  } else if (curSite === 'pl') {
    moneySymbol = 'zł';
  } else {
    moneySymbol = '$';
  }
  return moneySymbol;
}
export function getCurrencyCode(site) {
  var currencyCode;
  if (site === 'uk') {
    currencyCode = 'GBP';
  } else if (site === 'us' || !site) {
    currencyCode = 'USD';
  } else if (site === 'ca') {
    currencyCode = 'CAD';
  } else if (site === 'mx') {
    currencyCode = 'MXN';
  } else if (site === 'jp') {
    currencyCode = 'JPY';
  } else if (site === 'au') {
    currencyCode = 'AUD';
  } else if (site === 'se') {
    currencyCode = 'SEK';
  } else if (site === 'pl') {
    currencyCode = 'PLN';
  } else {
    currencyCode = 'EUR';
  }
  return currencyCode;
}
export function checkMoneySymbolTypeByCode(currencyCode) {
  var moneySymbol = '';
  if (currencyCode === 'GBP') {
    moneySymbol = '￡';
  } else if (currencyCode === 'USD') {
    moneySymbol = '$';
  } else if (currencyCode === 'CAD') {
    moneySymbol = 'C$';
  } else if (currencyCode === 'MXN') {
    moneySymbol = 'Mex.$';
  } else if (currencyCode === 'JPY') {
    moneySymbol = '￥';
  } else if (currencyCode === 'AUD') {
    moneySymbol = 'A$';
  } else if (currencyCode === 'EUR') {
    moneySymbol = '€';
  } else if (currencyCode === 'CNY') {
    moneySymbol = '￥';
  } else if (currencyCode === 'SEK') {
    moneySymbol = 'kr';
  } else if (currencyCode === 'PLN') {
    moneySymbol = 'zł';
  } else if (currencyCode === 'INR') {
    moneySymbol = '₹';
  } else if (currencyCode === 'AED') {
    moneySymbol = 'AED';
  } else if (currencyCode === 'SAR') {
    moneySymbol = 'ريال';
  } else if (currencyCode === 'HKD') {
    moneySymbol = 'HK$';
  } else if (currencyCode === 'BRL') {
    moneySymbol = 'R$';
  } else if (currencyCode === 'TRY') {
    moneySymbol = '₺';
  } else {
    moneySymbol = '$';
  }
  return moneySymbol;
}
export function getMdpBudget(value) {
  var result = '';
  if (value) {
    if (Number(value.toString().replace(/,/g, '')) >= 1) {
      result = Number(value.toString().replace(/,/g, '')) || '';
    }
  }
  return result;
}
export function getMdpBudgetText(value) {
  var result = '';
  if (value) {
    if (Number(value.toString().replace(/,/g, '')) >= 1) {
      result = formatFloatNumber(Number(value.toString().replace(/,/g, '')));
    }
  }
  return result;
}