import Mask from "../mask/Mask";
import Pop from "./Pop";

/**
 * 默认配置prop
 * Proxy 解决cancelText、confirmText中英文切换问题
 * @private
 */
function getDefaultConfig() {
  return {
    width: '462px',
    icon: 'warning',
    cancelText: '取消',
    confirmText: '确认',
    remindText: '不再提示',
    cancel: false,
    showClose: true,
    showRemind: false,
    title: '',
    showIcon: true,
    btnPosition: 'center',
    contentPosition: 'center'
  };
}
export default (function (Vue) {
  // 创建mask
  var MaskConstructor = Vue.extend(Mask);
  var mask = new MaskConstructor().$mount();
  mask.fixed = true;
  mask.zIndex = 3998;

  // 创建Pop
  var PopConstructor = Vue.extend(Pop);
  var pop = new PopConstructor().$mount();

  // 挂载
  var body = document.body;
  body.appendChild(mask.$el);
  body.appendChild(pop.$el);

  // method
  var $pop = function $pop(content, options) {};
  $pop.success = function (content, options) {
    return new Promise(function (resolve, reject) {
      // 设置属性
      Object.assign(pop, getDefaultConfig(), options, {
        icon: 'success',
        content: content
      });
      // 显示
      pop.visible = true;
      // 回调
      pop._callback = function (ok) {
        pop.visible = false;
        mask.visible = false;
        ok ? resolve('confirm') : reject(new Error('cancel'));
      };
    });
  };
  $pop.error = function (content, options) {
    return new Promise(function (resolve, reject) {
      // 设置属性
      Object.assign(pop, getDefaultConfig(), options, {
        content: content
      });
      // 显示
      pop.visible = true;
      // 回调
      pop._callback = function (ok) {
        pop.visible = false;
        mask.visible = false;
        ok ? resolve('confirm') : reject(new Error('cancel'));
      };
    });
  };
  $pop.confirm = function (content, desc, options) {
    return new Promise(function (resolve, reject) {
      // 设置属性
      Object.assign(pop, getDefaultConfig(), options, {
        content: content,
        desc: desc,
        cancel: true,
        remind: true
      });
      // 显示
      pop.visible = true;
      // 回调
      pop._callback = function (ok, resp) {
        pop.visible = false;
        mask.visible = false;
        ok ? resolve(resp) : reject(new Error('cancel'));
      };
    });
  };
  $pop.alert = function (content, desc, options) {
    return new Promise(function (resolve, reject) {
      // 设置属性
      Object.assign(pop, getDefaultConfig(), options, {
        content: content,
        desc: desc,
        cancel: false,
        remind: true
      });
      // 显示
      pop.visible = true;
      // 回调
      pop._callback = function (ok, resp) {
        pop.visible = false;
        mask.visible = false;
        ok ? resolve(resp) : reject(new Error('cancel'));
      };
    });
  };
  Vue.prototype.$pop = $pop;
});