import _toArray from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/toArray.js";
import _langs from "../config/lang"; // 语言配置
import { warn } from "../util/warn";

// 国际化
export default {
  computed: {
    // 语言内容
    langs: function langs() {
      var lang = this.lang;

      // 自动识别浏览器语言
      if (lang === 'auto') {
        lang = (navigator.language || navigator.userLanguage).substr(0, 2);
      }
      if (typeof lang === 'string') {
        lang = _langs[lang];
      }

      // 找不到语言配置，则使用英文
      if (!lang) lang = _langs['en'];
      return lang;
    }
  },
  methods: {
    // 获取国际化内容
    i18nText: function i18nText(text) {
      var _this$i18nParse = this.i18nParse(text),
        key = _this$i18nParse.key,
        params = _this$i18nParse.params;
      if (key) {
        var hasI18nProp = typeof this.i18n === 'function';

        // 未配置 i18n 方法则警告
        if (!this._hasI18nPropWarn) {
          warn(hasI18nProp, this.langs.msg.i18nProp);
          this._hasI18nPropWarn = true;
        }
        if (hasI18nProp) {
          return this.i18n(key, params);
        }
      }
      return text;
    },
    // 解析国际化
    i18nParse: function i18nParse(text) {
      var key;
      var params;

      // 获取国际化配置
      if (typeof text === 'string') {
        // 字符串方式配置：'i18n:custom.lang.key'
        var res = /^i18n:([^\s]+)$/.exec(text);
        if (res) {
          key = res[1];
          params = [];
        }
      } else if (Array.isArray(text)) {
        // 数组方式配置：['tab.i18n.key', 'param1', 'param2', ...]
        ;
        var _text = _toArray(text);
        key = _text[0];
        params = _text.slice(1);
      }
      return {
        key: key,
        params: params
      };
    }
  }
};