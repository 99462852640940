import "core-js/modules/es6.number.constructor";
import { scrollTo as _scrollTo, scrollIntoView as _scrollIntoView, getScrollbarWidth } from "../util";

// 滚动条
export default {
  name: 'TabScroll',
  props: {
    // 每次滚动距离
    space: {
      type: Number,
      default: 300
    }
  },
  data: function data() {
    return {
      isMobile: false,
      // 是否移动端

      scrollData: {
        clientWidth: 0,
        scrollWidth: 0,
        scrollLeft: 0
      },
      dragData: null
    };
  },
  computed: {
    // 是否拥有滚动条
    hasScroller: function hasScroller() {
      return !this.isMobile && !this.$tabs.onDragSort && this.scrollData.scrollWidth > this.scrollData.clientWidth;
    },
    // 滑块宽度
    thumbWidth: function thumbWidth() {
      if (!this.hasScroller) return;
      var _this$scrollData = this.scrollData,
        clientWidth = _this$scrollData.clientWidth,
        scrollWidth = _this$scrollData.scrollWidth;
      return clientWidth / scrollWidth * clientWidth;
    },
    // 滑块 left
    thumbLeft: function thumbLeft() {
      if (!this.hasScroller) return;

      // 拖拽滚动
      if (this.dragData) {
        return this.dragData.thumbLeft;
      }
      var _this$scrollData2 = this.scrollData,
        clientWidth = _this$scrollData2.clientWidth,
        scrollWidth = _this$scrollData2.scrollWidth,
        scrollLeft = _this$scrollData2.scrollLeft;
      return (clientWidth - this.thumbWidth) * (scrollLeft / (scrollWidth - clientWidth));
    }
  },
  mounted: function mounted() {
    this.update();
  },
  methods: {
    // 更新滚动数据
    update: function update() {
      var _this$$refs$container = this.$refs.container,
        clientWidth = _this$$refs$container.clientWidth,
        scrollWidth = _this$$refs$container.scrollWidth,
        scrollLeft = _this$$refs$container.scrollLeft;

      // 判断是否移动端
      // userAgent 中包含 mobile 字段，或者浏览器滚动条宽度为 0
      this.isMobile = /mobile/i.test(navigator.userAgent) || !getScrollbarWidth();
      Object.assign(this.scrollData, {
        clientWidth: clientWidth,
        scrollWidth: scrollWidth,
        scrollLeft: scrollLeft
      });
    },
    // 滚动到指定位置
    scrollTo: function scrollTo(left) {
      var smooth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      _scrollTo({
        wrap: this.$refs.container,
        left: left,
        smooth: smooth
      });
    },
    // 滚动到元素
    scrollIntoView: function scrollIntoView(el) {
      _scrollIntoView({
        el: el,
        wrap: this.$refs.container,
        inline: 'center'
      });
    },
    // 判断子节点是否完全在可视区域
    isInView: function isInView(el) {
      var container = this.$refs.container;
      var offsetLeft = el.offsetLeft;
      var scrollLeft = container.scrollLeft;
      if (offsetLeft < scrollLeft || offsetLeft + el.clientWidth > scrollLeft + container.clientWidth) {
        return false;
      }
      return true;
    },
    // 页签鼠标滚动
    onWheel: function onWheel(e) {
      var now = +new Date();
      var enable = now - (this.lastWheel || 0) > 100;
      if (!enable) return;
      this.lastWheel = now;
      var space = this.space;
      var isWheelUp = e.deltaY < 0;
      this.scrollTo(this.$refs.container.scrollLeft + (isWheelUp ? -space : space));
    },
    // 拖拽
    onDragStart: function onDragStart(e) {
      var thumbLeft = this.thumbLeft;
      this.dragData = {
        startPageX: e.pageX,
        startScrollLeft: this.$refs.container.scrollLeft,
        startThumbLeft: thumbLeft,
        thumbLeft: thumbLeft
      };
      document.addEventListener('mousemove', this.onDragMove);
      document.addEventListener('mouseup', this.onDragEnd);
    },
    // 拖拽移动
    onDragMove: function onDragMove(e) {
      var dragData = this.dragData,
        thumbWidth = this.thumbWidth;
      var _this$scrollData3 = this.scrollData,
        clientWidth = _this$scrollData3.clientWidth,
        scrollWidth = _this$scrollData3.scrollWidth;
      var thumbLeft = dragData.startThumbLeft + e.pageX - dragData.startPageX;
      var maxThumbLeft = clientWidth - thumbWidth;
      if (thumbLeft < 0) {
        thumbLeft = 0;
      } else if (thumbLeft > maxThumbLeft) {
        thumbLeft = maxThumbLeft;
      }

      // 更新滑块定位
      dragData.thumbLeft = thumbLeft;

      // 滚动
      this.scrollTo(thumbLeft * (scrollWidth - clientWidth) / (clientWidth - thumbWidth), false);
      e.preventDefault();
    },
    // 拖拽结束
    onDragEnd: function onDragEnd(e) {
      this.dragData = null;
      document.removeEventListener('mousemove', this.onDragMove);
      document.removeEventListener('mouseup', this.onDragEnd);
      e.preventDefault();
    }
  }
};