export default {
  us: require('@/assets/images/sites-icon/us@1x.png'),
  ca: require('@/assets/images/sites-icon/ca@1x.png'),
  mx: require('@/assets/images/sites-icon/mx@1x.png'),
  uk: require('@/assets/images/sites-icon/uk@1x.png'),
  de: require('@/assets/images/sites-icon/de@1x.png'),
  fr: require('@/assets/images/sites-icon/fr@1x.png'),
  it: require('@/assets/images/sites-icon/it@1x.png'),
  es: require('@/assets/images/sites-icon/es@1x.png'),
  in: require('@/assets/images/sites-icon/in@1x.jpg'),
  ae: require('@/assets/images/sites-icon/ae@1x.jpg'),
  sa: require('@/assets/images/sites-icon/sa@1x.png'),
  jp: require('@/assets/images/sites-icon/jp@1x.png'),
  au: require('@/assets/images/sites-icon/au@1x.jpg'),
  br: require('@/assets/images/sites-icon/br@1x.png'),
  nl: require('@/assets/images/sites-icon/nl@1x.png'),
  se: require('@/assets/images/sites-icon/se@1x.png'),
  be: require('@/assets/images/sites-icon/be@1x.png')
};