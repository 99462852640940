import "core-js/modules/es6.array.find";
import "core-js/modules/es6.function.name";
import _toConsumableArray from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
export default {
  name: 'advertiser-info',
  props: ['row', 'areaList', 'hideSite', 'noJump', 'noJumpId'],
  data: function data() {
    return {};
  },
  computed: {
    siteComputed: function siteComputed() {
      var _this = this;
      if (this.areaList) {
        var sites = this.areaList.reduce(function (pre, v) {
          pre = [].concat(_toConsumableArray(v.sites), _toConsumableArray(pre));
          return pre;
        }, []);
        return sites.find(function (v) {
          return v.id === _this.row.siteId;
        }).name;
      } else {
        return '';
      }
    }
  },
  methods: {
    getArea: function getArea(areaId) {
      return this.areaList && this.areaList.find(function (i) {
        return i.id === areaId;
      }) || {};
    }
  }
};