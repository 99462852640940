export default {
  path: "/dsp/convention-reminder",
  name: 'convention-reminder',
  meta: {
    cache: true,
    closable: true,
    auth: true,
    title: "广告命名规范提醒"
  },
  component: function component() {
    return import("@/pages/dsp/home/convention-reminder");
  }
};