import dayjs from 'dayjs';
var year = dayjs().format('YYYY');
// U代表通用字段
export default {
  locale: 'zh-CN',
  language: '简体中文',
  menu: {
    i18n: '多语言',
    home: '首页',
    salesOverview: '销售总览',
    accountReport: '账号报告',
    brandReport: 'brand报告',
    productLineReport: '产品线报告',
    ordersReport: 'orders报告',
    lineItemsReport: 'lineItems报告',
    creativeReport: 'creative报告',
    adAnalysis: '广告表现',
    brands: 'Brand表现',
    productLines: '产品线表现',
    asins: 'Asin表现',
    orders: 'Orders表现',
    lineItems: 'LineItems表现',
    creatives: '广告素材表现',
    placement: '展示位置',
    brandHalo: 'BH表现',
    ntb: '新老客户',
    ntbMenu: '新老客户',
    brandDetails: 'Brand详情',
    productDetails: '产品线详情',
    asinDetails: 'Asin详情',
    orderDetails: 'Order详情',
    lineItemDetails: 'LineItem详情',
    creativeDetails: '广告素材详情',
    associateOrders: '关联Orders',
    associateLineItems: '关联line Items'
  },
  system: {
    advertiser: '广告主',
    attributionAdvertiser: '归属广告主',
    dateRange: '日期范围',
    daily: '按天',
    weekly: '按周',
    monthly: '按月',
    last7days: '往前7天',
    last14days: '往前14天',
    last30days: '往前30天',
    last1weeks: '往前1周',
    last3weeks: '往前3周',
    last6weeks: '往前6周',
    last1months: '往前1月',
    last3months: '往前3月',
    last6months: '往前6月',
    all: '全部',
    moreDetails: '查看详情',
    moreMore: '查看更多',
    brand: '品牌',
    attributionBrand: '归属品牌',
    attributionOrder: '归属Order',
    productLine: '产品线',
    creatives: '广告素材',
    customField: '自定义字段',
    customColumn: '自定义列',
    custom: '自定义',
    PCTDCTBD: '请勾选列表展示的列，之后将按照勾选结果默认展示',
    CTDF: '恢复默认字段',
    seniorFilter: '高级筛选',
    confirm: '确认',
    cancel: '取消',
    empty: '清空',
    quickSearch: '搜索关键词',
    others: '其他',
    other: '其他',
    total: '总数',
    pleaseInput: '请输入',
    pleaseSelect: '请选择',
    pleaseInputOrders: '请输入Orders',
    pleaseInputChannel: '请输入推广渠道',
    pleaseInputProductLine: '请输入产品线',
    pleaseInputLineItem: '请输入LineItem',
    pleaseSelectPlacement: '请选择展示位置',
    pleaseInputCreatives: '请输入广告素材',
    pleaseInputAudience: '请输入受众人群',
    pleaseSelectPictureType: '请选择图片类型',
    search: '搜索',
    reset: '重置',
    action: '操作',
    report: '报告',
    placement: '展示位置',
    pc: 'PC端',
    mobile: '移动端',
    pcM: 'PC、移动端',
    searchKeyword: '搜索关键字',
    analysis: '统计维度',
    productData: '产品数据',
    brandHaloData: 'BH数据',
    viewForm: '查看表格',
    viewChart: '查看图表',
    switch: '切换',
    funnelStage: '漏斗阶段',
    PutTheCrowd: '投放人群',
    landingPage: '落地页',
    optimizationDirection: '优化方向',
    channel: "推广渠道",
    creativeSize: '素材大小',
    creativePictureType: '图片类型',
    audience: '受众人群',
    analysis1: '分析',
    noData: '暂无数据',
    site: '站点',
    conversionFunnel: '转化漏斗'
  },
  page: {
    login: {
      title: '登 录',
      userLogin: '用户登录',
      remember: '自动登录',
      forgot: '忘记密码',
      submit: '登录',
      other: '其它登录方式',
      signup: '注册账户',
      DSP: 'DSP数据系统',
      account: '账号',
      password: '密码',
      ETVC: '验证码',
      newCode: '看不清，换一张',
      tips: 'DSP通过亚马逊系统的及其学习算法实现人工+智能投放。<br />投放新广告需大约2周时间供机器学习。因此广告时间越长，机器学习算法更精准，广告效果更好。',
      PEYN: '请输入用户名',
      PEYP: '请输入密码',
      IVCPTA: '验证码错误，请重新输入',
      IUNOP: '用户名或密码不正确',
      copyRight: "Copyright \xA9 2019-".concat(year, " \u98DE\u672A\u4E91\u79D1\uFF08\u6DF1\u5733\uFF09\u6280\u672F\u6709\u9650\u516C\u53F8 \u7248\u6743\u6240\u6709 \u7CA4ICP\u590718104455\u53F7-1"),
      LIHE: '登录信息已过期',
      LIVA: '登陆信息校验失败',
      TLIIW: '登陆信息有误，请联系我们!',
      clickJumpToLogin: '点击确定跳转登录页面',
      brandRecognition: '品牌认知',
      productConsiderations: '产品考虑',
      brandRepurchase: '吸引复购',
      productPurchase: '产品购买',
      purchaseIntention: '购买意向',
      brandRecognitionDescription: '亚马逊独有电商人群 + 接入DSP的SSP站外，覆盖全网流量',
      productConsiderationsDescription1: '根据受众群体的画像更精准投放，通过机器学习不断优化人群选择',
      productConsiderationsDescription2: '算法跑越久越精准，广告效果越好',
      brandRepurchaseDescription: '精准定位品牌购买人群，培养品牌忠诚度',
      productPurchaseDescription: '精细化再营销，提高广告投放效率',
      purchaseIntentionDescription: '直接触达竞品ASIN人群，抢占高购买意向客户'
    },
    register: {
      title: '注册',
      submit: '注册',
      other: '使用已有账户登录'
    },
    exception: {
      e403: '抱歉，你无权访问该页面',
      e404: '抱歉，你访问的页面不存在',
      e500: '抱歉，服务器出错了',
      btn: '返回首页',
      noAdvertiser: '请联系客服人员，授权广告主~',
      subNoAdvertiser: '请联系父账号，前往【用户中心-子账号管理】，开通DSP数据系统权限~',
      TMIS: '修改成功!',
      TMISContent: '3S后跳转登陆页面'
    },
    i18n: {
      content: '你好，很高兴认识你！'
    },
    home: {
      cost: 'Cost 成本数据',
      awareness: 'Awareness 品牌认知',
      consideration: 'Consideration 熟虑阶段',
      conversion: 'Conversion 转化数据',
      salesAnalysis: '销售表现',
      offAmazon: '站外',
      inAmazon: '站内',
      ohterAmazon: '其他亚马逊产品 <br /> AMS,Lifetime SnSS等',
      brandHaloProducts: 'Brand Halo产品',
      promotedProductData: '广告产品',
      totalReturn: 'Total Return 广告回报',
      returnSource: 'Return Source 来源',
      performanceBreakDown: 'Performance BreakDown 细分',
      totalDspSaleAmount: 'Total Product Sales 总销售额',
      totalAdCost: 'Total Cost DSP总花费',
      totalRoas: 'Total ROAS 总ROAS',
      totalECPDPV: 'Total eCPDPV 总eCPDPV'
    },
    accountReport: {
      overview: '总览',
      brand: 'Brand',
      productLine: '产品线',
      order: 'Orders',
      lineItem: 'Line Items',
      creative: '广告素材'
    },
    bhTableHeader: {
      promotedProductData: '广告产品',
      bhProduct: 'BH产品',
      total: '总数据'
    },
    tbHeader: {
      brand: '品牌认知',
      cost: '成本数据',
      adProductData: '广告产品数据',
      totalAdData: '总广告数据',
      bhProductData: 'BH产品数据',
      consideration: 'Consideration 熟虑阶段',
      tb: '新、老客户',
      ntb: '新客户',
      otb: '老客户',
      totalTb: '总客户'
    }
  },
  fields: {
    reportDate: '日期',
    belongWeek: '日期',
    belongMonth: '日期',
    total: "总数据",
    action: '操作',
    asin: "Asin",
    atc: "ATC",
    atcr: "ATCR",
    atl: "ATL",
    brandAtc: "BH ATC",
    brandAtl: "BH ATL",
    brandDpv: "BH DPV",
    brandDpvr: "BH DPVR",
    brandDspSaleAmount: "BH销售额",
    brandDspSaleCount: "BH销量",
    brandECPDPV: "BH eCPDPV",
    brandName: "品牌",
    brands: "品牌",
    brandNewDspSaleAmount: "新客户BH销售额",
    brandNewDspSaleCount: "新客户BH销量",
    brandNewPurchases: "新客户BH订单量",
    brandNewRoas: "新客户BH ROAS",
    brandNewRpm: "新客户BH RPM",
    brandOldDspSaleAmount: "老客户BH销售额",
    brandOldDspSaleCount: "老客户BH销量",
    brandOldPurchases: "老客户BH订单量",
    brandOldRoas: "老客户BH ROAS",
    brandOldRpm: "老客户BH RPM",
    brandPrpv: "BH PRPV",
    brandPurchases: "BH订单量",
    brandSnss: "BH SnSS",
    channel: "推广渠道",
    clickCount: "点击量",
    cpm: "CPM",
    creative: "广告素材",
    ctr: "CTR",
    dpv: "DPV",
    dpvr: "DPVR",
    dspSaleAmount: "销售额",
    dspSaleCount: "销量",
    eCPDPV: "eCPDPV",
    impressions: "展示量",
    intervalEnd: "统计截止时间",
    intervalStart: "统计开始时间",
    lineItem: "广告组",
    newDspSaleAmount: "新客户销售额",
    newDspSaleCount: "新客户销量",
    newPurchases: "新客户订单量",
    newPurchasesRate: "新客户产品订单比例",
    newRoas: "新客户ROAS",
    newRpm: "新客户RPM",
    newToBrandPurchases: "新客户产品订单量",
    newToBrandPurchasesRate: "新客户产品订单比例",
    oldDspSaleAmount: "老客户销售额",
    oldDspSaleCount: "老客户销量",
    oldPurchases: "老客户订单量",
    oldPurchasesRate: "老客户产品订单比例",
    oldRoas: "老客户ROAS",
    oldRpm: "老客户RPM",
    order: "Orders",
    productLine: "产品线",
    products: "产品线",
    prpv: "PRPV",
    purchases: "订单量",
    pr: "订单率",
    roas: "ROAS",
    rpm: "RPM",
    salesRate: "销售率",
    segment: "受众人群",
    snss: "SnSS",
    totalAdCost: "DSP总花费",
    totalAtc: "总ATC",
    totalAtcr: "总ATCR",
    totalAtl: "总ATL",
    totalDpv: "总DPV",
    totalDpvr: "总DPVR",
    totalDspSaleAmount: "总销售额",
    totalDspSaleCount: "总销量",
    totalECPDPV: "总eCPDPV",
    totalNewDspSaleAmount: "新客户总销售额",
    totalNewDspSaleCount: "新客户总销量",
    totalNewPurchases: "新客户总订单量",
    totalNewRoas: "新客户总ROAS",
    totalNewRpm: "新客户总RPM",
    totalOldDspSaleAmount: "老客户总销售额",
    totalOldDspSaleCount: "老客户总销量",
    totalOldPurchases: "老客户总订单量",
    totalOldRoas: "老客户总ROAS",
    totalOldRpm: "老客户总RPM",
    totalPrpv: "总PRPV",
    totalPurchases: "总订单量",
    totalPr: "总订单率",
    totalRoas: "总ROAS",
    totalRpm: "总RPM",
    totalSalesRate: "总销售率",
    totalSnss: "总SnSS",
    tpFees: "3P Fees",
    creativeSize: '素材大小',
    creativePictureType: '图片类型',
    actionAsin: '操作',
    amazonLink: '产品链接',
    lineItemType: '展示位置',
    ntb: '新客户',
    otb: '老客户',
    promotedProductData: '广告产品',
    brandHaloData: 'BH产品',
    totalNtb: '总新客户',
    totalOtb: '总老客户',
    bhNtb: 'BH新客户',
    bhOtb: 'BH老客户',
    videoStart: '视频开始数',
    videoComplete: '视频完成数',
    vcr: 'VCR',
    eCPVC: 'eCPVC',
    purchasesU: "订单量",
    dspSaleCountU: "销量",
    // 通用字段
    dspSaleAmountU: "销售额",
    advertiserName: '广告主'
  },
  fieldsTip: {
    total: "总广告数据。即DSP带来的同品牌产品的数据。同品牌产品包括参加广告产品和同品牌非促销产品。总广告数据包括新客户数据和老客户数据",
    clickToAmazon: '点击打开亚马逊产品链接。',
    creativeSize: 'Creative size，素材图片尺寸的大小',
    "impressions": "impressions.DSP广告的展现量",
    "clickCount": "click-throughs.DSP带来的广告产品总点击量。",
    "ctr": "Click-through rate(CTR).广告点击率。CTR=点击量/展示量",
    "brandDpv": "Brand Halo detail page views (BH DPV).DSP广告带来的同品牌非广告产品的亚马逊详情页面被浏览次数。详情页浏览来源包括与DSP广告有关的各个途径进入详情页浏览次数，包括但不限于1.DSP广告曝光点击进入详情页浏览；2.通过DSP广告加入购物车后由购物车点击进入产品页面的浏览次数等",
    "brandDpvr": "Brand Halo Detail page view rate (BH DPVR），同品牌非广告产品的亚马逊详情页面被浏览次数占展示量占比。浏览率=浏览量/展示量",
    "brandAtc": "Brand Halo Add to Carts (BH ATC).通过DSP产生的同品牌非广告产品的加购物车的产品数量。",
    "brandPurchases": "Brand Halo purchases（BH purchases).DSP产生的同品牌非广告产品的订单量。",
    "brandDspSaleCount": "Brand halo units sold(BH units sold).DSP带来的同品牌非广告产品的销量。",
    "brandDspSaleAmount": "  Brand halo Product Sales(BH Product Sales).DSP带来的同品牌非广告产品的销售额。",
    "roas": "Return on advertising spend ( ROAS).广告产品的支出回报率。ROAS=销售额/DSP花费",
    "rpm": "Effective return per thousand impressions (RPM)，每千次展现带来的广告产品的销售额，RPM=销售额/（展示量/1000）",
    "brandAtl": "Brand Halo Add to List (BH ATL).DSP广告带来的同品牌非广告产品的收藏量。",
    "brandSnss": "Brand halo Subscribe & Save subscriptions(BH SnSS).通过DSP广告为同品牌非广告产品带来的亚马逊提供的订购省服务量。订阅订购省服务的订单量也包含在总订单量",
    "brandPrpv": "Brand Halo product review page views (BH PRPV).DSP广告带来的同品牌非广告产品评论页浏览量，包括评论总页和单独的评论页。",
    "brandNewPurchases": "Brand Halo new-to-brand purchases(BH NTB purchases).DSP产生的近一年的周期内首次购买同品牌非广告产品的的客户产生的订单量。",
    "brandNewDspSaleCount": "Brand halo New-to-brand units sold(BH NTB units sold).DSP带来的近一年的周期内首次购买同品牌非广告产品产生的销量。",
    "brandNewDspSaleAmount": "Brand halo new-to-brand product sales(BH NTB product sales).DSP带来的近一年的周期内首次购买同品牌非广告产品产生的销售额。",
    "brandNewRoas": "Brand Halo new-to-brand return on advertising spend (BH new-to-brand ROAS).新客户的同品牌非广告产品的支出回报率。新客户BH ROAS=新客户BH销售额/DSP总花费。",
    "brandNewRpm": "Brand halo new-to-brand effective return per thousand impressions (BH NTB RPM).每千次展现带来的新客户产生的同品牌非广告产品的销售额。新客户BH RPM=新客户BH销售额/（展示量/1000）。",
    "brandOldPurchases": "Brand Halo old-to-brand purchases(BH OTB purchases).DSP产生的近一年的周期内非首次购买同品牌非广告产品的的客户产生的订单量。",
    "brandOldDspSaleCount": "Brand halo old-to-brand units sold(BH OTB units sold).DSP带来的近一年的周期内非首次购买同品牌非广告产品产生的销量。",
    "brandOldDspSaleAmount": "Brand halo old-to-brand product sales(BH OTB product sales).DSP带来的近一年的周期内非首次购买同品牌非广告产品产生的销售额。",
    "brandOldRoas": "Brand Halo old-to-brand return on advertising spend (BH OTB ROAS).老客户的同品牌非广告产品的支出回报率。老客户BH ROAS=老客户BH销售额/DSP总花费。",
    "brandOldRpm": "Brand halo old-to-brand effective return per thousand impressions (BH OTB RPM).每千次展现带来的老客户产生的同品牌非广告产品的销售额。老客户BH RPM=老客户BH销售额/（展示量/1000）。",
    "dpv": "Detail page views (DPV).参加广告产品的亚马逊详情页面被浏览次数。包括与DSP广告有关的各个途径进入详情页浏览次数，包括但不限于1.DSP广告曝光点击进入详情页浏览；2.通过DSP广告加入购物车后由购物车点击进入产品页面的浏览次数等",
    "atc": "Add to Cart (ATC).产品加购量，通过DSP产生的广告产品的加购物车的产品数量",
    "atcr": "Add to Cart rate (ATCR).加购率，广告展现带来的加购物车转化率。加购率=加购量/展示量",
    "dpvr": "Detail page view rate (DPVR）由展示广告带来的详情页浏览量。浏览率=浏览量/展示量",
    "atl": "Add to List (ATL).产品收藏量，DSP广告带来的参加广告的收藏量",
    "prpv": "Product review page views (PRPV)。DSP广告带来的广告产品评论页浏览量，包括评论总页和单独的评论页",
    "snss": "Subscribe & Save subscriptions (SnSS)。通过DSP广告为广告产品带来的亚马逊提供的订购省服务量，订阅订购省服务的订单量也包含在总订单量",
    "eCPDPV": "Effective cost per detail page view。每次广告产品的产品详情页浏览的广告成本=DSP总花费/DPV",
    "totalECPDPV": "Total cost per detail page view。每次总详情页浏览的广告成本=DSP总花费/总DPV",
    "brandECPDPV": "Effective cost per detail page view。每次产品详情页浏览的广告成本=DSP总花费/DPV",
    "totalAdCost": "Total Cost.DSP广告的总花费",
    "tpFees": "Third party (3P) fees。使用第三方平台收取的CPM费用",
    "cpm": "Effective cost per thousand(CPM).平均每千次展示费用,DSP广告的计费方式。CPM=DSP花费/（展示量/1000）",
    "brandName": "Brand,亚马逊品牌",
    "brands": "Brand,亚马逊品牌",
    "channel": "supply source. 广告投放的渠道，在line item中设置。网站类型分为亚马逊站内和亚马逊站外；渠道类型分为web端和移动端",
    "creative": "广告创意图片",
    "segment": "Segment. 定向投放的人群分类。 对拥有某一特征，或某一具体行为的人群进行归类为特定受众人群，从而针对该人群精准地实现智能推荐广告",
    "newToBrandPurchases": "New-to-brand purchases.DSP带来的近一年的周期内首次购买广告产品的客户产生的订单量",
    "newToBrandPurchasesRate": "Percent of purchases new-to-brand.新客户产生的广告产品的订单量比例=新客户产品订单量/产品订单量",
    "newPurchases": "New-to-brand purchases.DSP带来的近一年的周期内首次购买广告产品的客户产生的订单量",
    "newPurchasesRate": "Percent of purchases new-to-brand.新客户产生的广告产品的订单量比例=新客户产品订单量/产品订单量",
    "newDspSaleCount": "New-to-brand units sold.DSP带来近一年的周期内首次购买参加广告产品的的客户产生的销量",
    "newDspSaleAmount": "New-to-brand product sales。DSP带来的近一年的周期内首次购买参加广告产品的的客户产生的销售额",
    "newRoas": "New-to-brand return on advertising spend （NTB ROAS).新客户产生的广告产品的支出回报率。新客户产品ROAS=新客户产品销售额/DSP总花费",
    "newRpm": "New-to-brand Effective return per thousand impressions (NTB RPM).新客户产品RPM=新客户产品销售额/（展示量/1000）",
    "oldPurchases": "Old-to-brand purchases.DSP带来的近一年的周期内非首次购买广告产品的客户产生的订单量",
    "oldPurchasesRate": "Old-to-brand purchases.DSP带来的近一年的周期内非首次购买广告产品的客户产生的订单量",
    "oldDspSaleCount": "Old-to-brand units sold.DSP带来近一年的周期内非首次购买参加广告产品的的客户产生的销量",
    "oldDspSaleAmount": "Old-to-brand product sales 。DSP带来的近一年的周期内非首次购买参加广告产品的的客户产生的销售额",
    "oldRoas": "Old-to-brand return on advertising spend （OTB ROAS).老客户产生的广告产品的支出回报率。老客户产品ROAS=老客户销售额/DSP总花费",
    "oldRpm": "Old-to-brand Effective return per thousand impressions (OTB RPM).老客户产品RPM=老客户产品销售额/（展示量/1000）",
    "purchases": "Purchases.DSP产生的广告产品订单量",
    "pr": "Purchase Rate. 订单率=订单量/展示量",
    "dspSaleCount": "Units sold.DSP带来的广告产品销量",
    "salesRate": "Sales Rate.销售率=销量/订单量",
    "dspSaleAmount": "sales.DSP带来的广告产品销售额",
    "Total": "总广告数据。即DSP带来的同品牌产品的数据。同品牌产品包括参加广告产品和同品牌非促销产品。总广告数据包括新客户数据和老客户数据",
    "NTB": "New to brand(NTB). 新客户指在近1年内第一次购买产品的客户",
    "老客户": "Old to brand(OTB).老客户指在近1年内超过一次购买产品的客户",
    "广告产品": "The promoted products. 指用户参加DSP广告的产品",
    "brandHalo": "同品牌非广告产品（Brand Halo产品，BH产品），指用户通过DSP广告最后转化到同品牌下除了参加广告产品外的其他产品",
    "intervalStart": "Interval start,运行并开始产生数据的日期",
    "intervalEnd": "intervalEnd,数据分析统计时间段内的结束日期，不代表状态为结束运行",
    "totalDpv": "Total detail page views (Total DPV).DSP广告带来的同品牌产品的亚马逊详情页面被浏览次数。同品牌产品包括参加广告产品和同品牌非促销产品。详情页浏览来源包括与DSP广告有关的各个途径进入详情页浏览次数，包括但不限于1.DSP广告曝光点击进入详情页浏览；2.通过DSP广告加入购物车后由购物车点击进入产品页面的浏览次数等  ",
    "totalDpvr": "Total Detail page view rate (Total DPVR），由展示广告带来的同品牌产品的亚马逊详情页面被浏览次数。浏览率=总DPV/展示量",
    "totalAtc": "Total Add to Carts (Total ATC).通过DSP产生的同品牌产品的加购物车的产品数量。同品牌产品包括参加广告产品和同品牌非促销产品。",
    "totalAtcr": "Total Add to Cart rate (ATCR).加购率，广告展现带来的总广告产品的加购物车转化率。总加购率=总加购量/展示量",
    "totalPurchases": "Total purchases.DSP产生的同品牌产品的订单量。同品牌产品包括参加广告产品和同品牌非促销产品。",
    "totalPr": "Total Purchase Rate. 总订单率=总订单量/展示量",
    "totalDspSaleCount": "Total units sold.DSP带来的同品牌产品的销量。同品牌产品包括参加广告产品和同品牌非促销产品。",
    "totalDspSaleAmount": "Total Sales.DSP带来的同品牌产品的销售额。同品牌产品包括参加广告产品和同品牌非促销产品。",
    "totalRoas": "Total return on advertising spend (Total ROAS).同品牌产品的总支出回报率。总ROAS=总销售额/DSP总花费。同品牌产品包括参加广告产品和同品牌非促销产品。",
    "totalRpm": " Total effective return per thousand impressions (Total RPM).每千次展现带来的同品牌产品的总销售额。总RPM=总销售额/（展示量/1000）。同品牌产品包括参加广告产品和同品牌非促销产品。",
    "totalAtl": "Total Add to List (Total ATL).DSP广告带来的同品牌产品的收藏量。同品牌产品包括参加广告产品和同品牌非促销产品。",
    "totalSnss": "Total Subscribe & Save subscriptions(Total SnSS).通过DSP广告为同品牌产品带来的亚马逊提供的订购省服务量。同品牌产品包括参加广告产品和同品牌非促销产品。订阅订购省服务的订单量也包含在总订单量。",
    "totalPrpv": "Total product review page views (Total PRPV).DSP广告带来的同品牌产品评论页浏览量，包括评论总页和单独的评论页。同品牌产品包括参加广告产品和同品牌非促销产品。",
    "totalNewPurchases": "Total new-to-brand purchases(Total NTB purchases).DSP产生的近一年的周期内首次购买同品牌产品的的客户产生的订单量。同品牌产品包括参加广告产品和同品牌非促销产品。同品牌产品包括参加广告产品和同品牌非促销产品。",
    "totalOldPurchases": "Total old-to-brand purchases(Total OTB purchases).DSP产生的近一年的周期内非首次购买同品牌产品的的客户产生的订单量。同品牌产品包括参加广告产品和同品牌非促销产品。",
    "totalNewDspSaleCount": "Total New-to-brand units sold(Total NTB units sold).DSP带来的近一年的周期内首次购买同品牌产品产生的销量。同品牌产品包括参加广告产品和同品牌非促销产品。",
    "totalOldDspSaleCount": "Total Old-to-brand units sold(Total OTB units sold).DSP带来的近一年的周期内非首次购买同品牌产品产生的销量。同品牌产品包括参加广告产品和同品牌非促销产品。",
    "totalNewDspSaleAmount": "Total new-to-brand product sales(Total NTB product sales).DSP带来的近一年的周期内首次购买同品牌产品产生的销售额。同品牌产品包括参加广告产品和同品牌非促销产品。",
    "totalOldDspSaleAmount": "Total old-to-brand product sales(Total OTB product sales).DSP带来的近一年的周期内非首次购买同品牌产品产生的销售额。同品牌产品包括参加广告产品和同品牌非促销产品。",
    "totalNewRoas": "Total new-to-brand return on advertising spend (Total new-to-brand ROAS).新客户的同品牌产品的支出回报率。新客户总ROAS=新客户总销售额/DSP总花费。同品牌产品包括参加广告产品和同品牌非促销产品。",
    "totalOldRoas": "Total old-to-brand return on advertising spend (Total OTB ROAS).老客户的同品牌产品的支出回报率。老客户总ROAS=老客户总销售额/DSP总花费。同品牌产品包括参加广告产品和同品牌非促销产品。",
    "totalNewRpm": "Total new-to-brand effective return per thousand impressions (Total new-to-brand RPM).每千次展现带来的新客户产生的同品牌产品的总销售额。新客户总RPM=新客户总销售额/（展示量/1000）。同品牌产品包括参加广告产品和同品牌非促销产品。）",
    "totalOldRpm": "Total old-to-brand effective return per thousand impressions (Total OTB RPM).每千次展现带来的老客户产生的同品牌产品的总销售额。老客户总RPM=老客户总销售额/（展示量/1000）。同品牌产品包括参加广告产品和同品牌非促销产品。",
    "totalSalesRate": "Total Sales Rate.总销售率=总销量/总订单量",
    ntb: '指用户参加DSP广告的产品',
    otb: '老客户指在近1年内超过一次购买产品的客户',
    promotedProductData: '指用户参加DSP广告的产品',
    brandHaloData: '同品牌非广告产品（Brand Halo产品，BH产品），指用户通过DSP广告最后转化到同品牌下除了参加广告产品外的其他产品',
    totalNtb: '指在近1年内第一次购买总产品【包含广告产品和同品牌非广告产品】的客户',
    totalOtb: '指在近1年内超过一次购买总产品【包含广告产品和同品牌非广告产品】的客户',
    bhNtb: '指在近1年内第一次购买BH产品【同品牌非广告产品】的客户',
    bhOtb: '指在近1年内超过一次购买BH产品【同品牌非广告产品】的客户',
    videoStart: 'Video start. 视频广告被开始播放的数量',
    videoComplete: 'Video complete. 视频广告被完整播放到结尾的数量',
    vcr: 'Video completion rate. 视频完整播放率。VCR = 视频完成数/视频开始数。',
    eCPVC: 'Effective cost per Video complete.每次视频完整播放的平均广告成本=DSP总花费/视频完成数',
    dpvU: 'Detail page views (DPV).亚马逊详情页面被浏览次数。包括与DSP广告有关的各个途径进入详情页浏览次数，包括但不限于1.DSP广告曝光点击进入详情页浏览；2.通过DSP广告加入购物车后由购物车点击进入产品页面的浏览次数等',
    atlU: 'Add to List .DSP广告带来的收藏量',
    atcU: 'Add to Cart (ATC).加购量通过DSP产生的加购物车的数量',
    "dspSaleAmountU": "sales.DSP带来的销售额",
    "dspSaleCountU": "Units sold.DSP带来的销量",
    "purchasesU": "Purchases.DSP产生的订单量"
  }
};