var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", [_vm._v("\n  " + _vm._s(_vm.tableTitle)), _vm.tableQuestion ? _c("fk-question", {
    attrs: {
      content: _vm.tableQuestion
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };