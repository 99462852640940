import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/es6.number.constructor";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import eChart from 'echarts';
var defaultYAxis = {
  //坐标轴刻度标签的相关设置。
  axisLabel: {
    color: '#8b93a6'
  },
  //坐标轴在 grid 区域中的分隔线。
  splitLine: {
    lineStyle: {
      type: 'dotted',
      color: '#e5e9f2'
    }
  },
  //坐标轴轴线相关设置。
  axisLine: {
    lineStyle: {
      color: '#fff'
    }
  }
};
export default {
  name: 'dsp-echart',
  props: {
    height: {
      type: [String, Number],
      default: 500
    },
    legendLeft: {
      type: String,
      default: '54'
    },
    legendIcon: {},
    legendData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    legendSelectedMode: {
      type: Boolean,
      default: true
    },
    legendFormatter: Function,
    tooltipFormatter: [String, Function],
    tooltipShow: {
      // 是否显示 tooltip
      type: Boolean,
      default: true
    },
    color: {
      type: Array,
      default: function _default() {
        return ['rgba(51,153,255,0.6)', 'rgba(255,72,134,0.6)', 'rgba(40, 187, 135, 0.6)', 'rgba(253, 188, 0, 0.6)', 'rgba(0, 38, 142, 0.5)', 'rgba(208, 2, 27, 0.5)', 'rgba(0, 207, 204, 0.6)', 'rgba(245, 161, 107, 0.6)', 'rgba(153, 153, 153, 0.6)', 'rgba(202, 120, 235, 0.6)'];
      }
    },
    xAxisData: Array,
    xAxisShow: {
      // 是否显示 x 轴
      type: Boolean,
      default: true
    },
    xAxisBoundaryGap: {
      type: Boolean,
      default: false
    },
    yAxis: null,
    series: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    group: String,
    // 图表联动
    grid: Object,
    graphic: [Object, Array],
    dataZoom: [Object, Array]
  },
  computed: {
    options: function options() {
      var options = {
        tooltip: {
          show: this.tooltipShow,
          formatter: this.tooltipFormatter
        },
        grid: _objectSpread({
          top: 30,
          left: 80,
          right: 80,
          bottom: 40
        }, this.grid),
        legend: {
          left: this.legendLeft,
          data: this.legendData,
          formatter: this.legendFormatter,
          selectedMode: this.legendSelectedMode
        },
        color: this.color,
        series: this.series.map(function (item) {
          if (item.smooth == undefined) {
            item.smooth = true;
          }
          if (!item.barMaxWidth) {
            item.barMaxWidth = 50;
          }
          return item;
        }),
        graphic: this.graphic,
        dataZoom: this.dataZoom
      };
      if (this.legendIcon) {
        options.legend.icon = this.legendIcon;
        if (this.legendIcon === 'rect') {
          options.legend.itemWidth = 12;
          options.legend.itemHeight = 12;
        }
      }
      //无数据
      if (options.series.length === 0 || options.series.every(function (serie) {
        return serie.data.length === 0;
      })) {
        options.title = {
          show: true,
          extStyle: {
            color: 'grey',
            fontSize: 20
          },
          text: '暂无数据',
          left: 'center',
          top: 'center'
        };
      }

      // 折线图、柱状图
      var xAxisData = this.xAxisData;
      if (xAxisData) {
        options.xAxis = {
          show: this.xAxisShow,
          axisLine: {
            lineStyle: {
              color: '#e5e9f2'
            }
          },
          axisLabel: {
            color: '#8b93a6'
          },
          boundaryGap: this.xAxisBoundaryGap,
          data: xAxisData
        };
        options.yAxis = this.getYAxis();
        options.tooltip.trigger = this.series.length && this.series[0].type !== 'pie' ? 'axis' : 'item';
      }
      return options;
    }
  },
  watch: {
    // options:{
    //     handler( newOptions ){ this._chart.setOption(newOptions) },
    //     immediate:true
    // }
  },
  mounted: function mounted() {
    var _this = this;
    var chart = eChart.init(this.$el);
    this._chart = chart;
    this._windowResize = function () {
      return chart.resize();
    };
    window.addEventListener('resize', this._windowResize);
    this.$watch('options', function (newOptions) {
      _this._chart.setOption(newOptions, true);
    }, {
      immediate: true
    });
    chart.on('click', function (params) {
      _this.$emit('chartClick', params);
    });
    // 添加联动组
    var group = this.group;
    if (!group) return false;
    chart.group = group;
    eChart.disconnect(group);
    eChart.connect(group);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this._windowResize);
    eChart.disconnect(this.group);
    this._chart.dispose();
    this._chart = null;
  },
  activated: function activated() {
    // 在keep-alive 刷新图表
    if (this._chart) {
      this._chart.resize();
    }
  },
  methods: {
    getYAxis: function getYAxis() {
      var yAxis = this.yAxis;
      return yAxis ? Array.isArray(yAxis) ? yAxis.map(function (i) {
        return _objectSpread(_objectSpread({}, defaultYAxis), i);
      }) : _objectSpread({}, yAxis) : _objectSpread({}, defaultYAxis);
    },
    showTip: function showTip(index) {
      this._chart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: index
      });
    },
    hideTip: function hideTip() {
      this._chart.dispatchAction({
        type: 'hideTip'
      });
    }
  }
};