export function checkMoneySymbolTypeByCode(currencyCode) {
  var moneySymbol = '';
  if (currencyCode === 'GBP') {
    moneySymbol = '￡';
  } else if (currencyCode === 'USD') {
    moneySymbol = '$';
  } else if (currencyCode === 'CAD') {
    moneySymbol = 'C$';
  } else if (currencyCode === 'MXN') {
    moneySymbol = 'Mex.$';
  } else if (currencyCode === 'JPY') {
    moneySymbol = '￥';
  } else if (currencyCode === 'AUD') {
    moneySymbol = 'A$';
  } else if (currencyCode === 'EUR') {
    moneySymbol = '€';
  } else if (currencyCode === 'CNY') {
    moneySymbol = '￥';
  } else if (currencyCode === 'SEK') {
    moneySymbol = 'kr';
  } else if (currencyCode === 'PLN') {
    moneySymbol = 'zł';
  } else if (currencyCode === 'INR') {
    moneySymbol = '₹';
  } else if (currencyCode === 'AED') {
    moneySymbol = 'AED';
  } else if (currencyCode === 'SAR') {
    moneySymbol = 'ريال';
  } else if (currencyCode === 'HKD') {
    moneySymbol = 'HK$';
  } else if (currencyCode === 'BRL') {
    moneySymbol = 'R$';
  } else {
    moneySymbol = '$';
  }
  return moneySymbol;
}