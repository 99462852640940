import Vue from 'vue';
import Loading from "../../components/loading/Loading";
export default {
  bind: function bind(el, _bind) {
    var Constructor = Vue.extend(Loading);
    var instance = new Constructor().$mount();
    instance.visible = _bind.value;
    // el.appendChild(instance.$el)
    el.insertBefore(instance.$el, el.firstChild);
    el._ht_loading_component = instance;
    if (_bind.modifiers.relative) el.style.position = 'relative';
  },
  update: function update(el, bind) {
    el._ht_loading_component.visible = bind.value;
  },
  unbind: function unbind(el) {
    el._ht_loading_component = undefined;
  }
};