import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
/**
 * 计算文本是否溢出
 * @param value
 * @param style
 * @returns {boolean}
 */
export function compute(value, style) {
  var div = _getComputeElement();
  div.innerHTML = value;
  Object.keys(style).forEach(function (key) {
    return div.style[key] = style[key];
  });
  return div.scrollWidth > div.offsetWidth || div.scrollHeight > div.offsetHeight;
}
var _getComputeElement = function getComputeElement() {
  var div = document.createElement('div');
  div.className = 'fk-ellipsis-compute';
  div.style.position = 'fixed';
  div.style.left = '0';
  div.style.top = '0';
  div.style.overflow = 'auto';
  div.style.visibility = 'hidden';
  div.style.wordBreak = 'break-word';
  div.style.zIndex = '-99px';
  document.body.appendChild(div);
  _getComputeElement = function getComputeElement() {
    return div;
  };
  return _getComputeElement.apply(this, arguments);
};