import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.find";
import { list } from "./list";
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    question: {
      default: undefined
    }
  },
  data: function data() {
    return {
      tableTitle: undefined,
      tableQuestion: undefined
    };
  },
  created: function created() {
    var title = this.title;
    var info = list.find(function (i) {
      return i.name === title;
    });
    this.tableTitle = info ? info.name : title;
    // 若传入false 则不显示
    if (this.question !== false) {
      this.tableQuestion = this.question ? this.question : info ? info.question : undefined;
    }
  }
};