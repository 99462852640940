var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bonus-order-ctn"
  }, [_c("span", {
    staticStyle: {
      "margin-right": "10px"
    }
  }, [_vm._v("bonus order")]), _c("el-switch", {
    attrs: {
      "active-color": "#0077EA"
    },
    on: {
      change: _vm.valueChange
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };