export default {
  path: '/dsp/financial/ar-manage',
  title: '应收管理',
  header: 'dsp',
  children: [{
    path: '/dsp/financial/monthly-bill',
    title: '月账单管理'
  }, {
    path: '/dsp/financial/collection-manage',
    title: '收款管理',
    children: [{
      path: '/dsp/financial/collection-manage/edit',
      hide: true
    }, {
      path: '/dsp/financial/collection-manage/detail',
      hide: true
    }]
  }, {
    path: '/dsp/financial/payment-manage',
    title: '回款管理',
    children: [{
      path: '/dsp/financial/payment-manage/detail',
      hide: true,
      title: '回款详情'
    }]
  }, {
    path: '/dsp/financial/payment-plan',
    title: '预付计划',
    children: [{
      path: '/dsp/financial/payment-plan/detail',
      hide: true,
      title: '预付计划详情'
    }]
  }]
};