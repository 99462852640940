import "core-js/modules/es6.number.constructor";
export default {
  name: 'fk-to-portrait-detail',
  props: {
    userId: Number,
    userName: [String, Number]
  },
  methods: {
    jumpTo: function jumpTo() {
      var env = process.env.NODE_ENV;
      var url;
      if (env == 'development') {
        url = 'http://' + location.hostname + ':8100/portrait/portraitdetail/' + this.userId;
      } else {
        url = 'http://4kadmin.4kmiles-tec.com/portrait/portraitdetail/' + this.userId;
      }
      window.open(url, '_blank');
    }
  }
};