import "core-js/modules/es6.function.name";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "custom-fields-wrap"
  }, [_c("div", {
    staticClass: "ivu-clearfix"
  }, [_c("Button", {
    staticClass: "ivu-fr",
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.showModal = true;
      }
    }
  }, [_vm._v("自定义字段")])], 1), _c("Modal", {
    staticClass: "customModal",
    attrs: {
      title: "自定义列",
      "mask-closable": false,
      loading: true,
      "footer-hide": true,
      transfer: "",
      width: 500
    },
    on: {
      "on-visible-change": _vm.visiableChange
    },
    model: {
      value: _vm.showModal,
      callback: function callback($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "tips"
  }, [_vm._v("请勾选列表展示的列，之后将按照勾选结果默认展示")]), _c("div", {
    staticClass: "defaultBtn",
    on: {
      click: _vm.setDefault
    }
  }, [_vm._v("勾选默认字段")]), _c("div", {
    staticClass: "list"
  }, _vm._l(_vm.dspFieldList, function (item) {
    return _c("Checkbox", {
      key: item.id,
      model: {
        value: item.enable,
        callback: function callback($$v) {
          _vm.$set(item, "enable", $$v);
        },
        expression: "item.enable"
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)]), _c("div", {
    staticClass: "btnBox"
  }, [_c("Button", {
    attrs: {
      type: "default",
      disabled: _vm.loading
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("取消")]), _c("Button", {
    staticStyle: {
      "margin-left": "15px"
    },
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.ok
    }
  }, [_vm._v("确认")])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };