import icons from "./sites-icon";
export default {
  name: 'dsp-site-icon',
  props: {
    site: {
      type: String,
      default: null
    },
    placement: {
      type: String,
      default: 'top'
    },
    theme: {
      type: String,
      default: 'light'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      icons: icons
    };
  },
  computed: {
    style: function style() {
      return this.site ? {
        backgroundImage: "url(".concat(icons[this.site.toLowerCase()], ")")
      } : null;
    }
  }
};