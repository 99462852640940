export default {
  updateEntity: function updateEntity(state, value) {
    state.entity = value;
  },
  updateAdvertiser: function updateAdvertiser(state, value) {
    state.advertiser = value;
  },
  updateBonusOrderState: function updateBonusOrderState(state, value) {
    state.bonusOrderState = value;
    state.bonusOrderStateIsMounted = true;
  }
};