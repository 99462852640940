import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.find";
import _toConsumableArray from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/web.dom.iterable";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { mapState } from 'vuex';
import menuSider from '@/menu/sider';
import { flattenSiderMenu } from '@/libs/system';
import iMenuHeadTitle from "../menu-head/title";
import { on, off } from 'view-design/src/utils/dom';
import { findComponentUpward, getStyle } from 'view-design/src/utils/assist';
import { throttle } from 'lodash';
export default {
  name: 'iHeaderBreadcrumb',
  components: {
    iMenuHeadTitle: iMenuHeadTitle
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('admin/layout', ['showBreadcrumbIcon', 'menuCollapse'])), mapState('admin/menu', ['openNames', 'activePath', 'header', 'headerName'])), {}, {
    siderMenuObject: function siderMenuObject() {
      var obj = {};
      this.allSiderMenu.forEach(function (item) {
        if ('path' in item) {
          obj[item.path] = item;
        }
      });
      return obj;
    },
    items: function items() {
      var _this = this;
      var items = _toConsumableArray(this.openNames);
      var newItems = [];
      items.forEach(function (i) {
        newItems.push(_this.siderMenuObject[i]);
      });
      return newItems;
    },
    // 第一级，默认是 menu/filterheader.js 中的第一项
    topItem: function topItem() {
      var _this2 = this;
      return this.header.find(function (item) {
        return item.name === _this2.headerName;
      });
    }
  }),
  data: function data() {
    return {
      // 得到所有侧边菜单，并转为平级，查询图标及显示对应内容
      allSiderMenu: flattenSiderMenu(menuSider, []),
      handleResize: function handleResize() {},
      isLimit: false,
      maxWidth: 450,
      breadcrumbWidth: 0
    };
  },
  methods: {
    handleCheckWidth: function handleCheckWidth() {
      var _this3 = this;
      var $header = findComponentUpward(this, 'Header');
      if ($header) {
        var headerWidth = parseInt(getStyle($header.$el, 'width'));
        this.$nextTick(function () {
          _this3.isLimit = headerWidth - _this3.maxWidth <= _this3.breadcrumbWidth;
        });
      }
    },
    handleGetWidth: function handleGetWidth() {
      var _this4 = this;
      this.isLimit = false;
      this.$nextTick(function () {
        var $breadcrumb = _this4.$refs.breadcrumb;
        if ($breadcrumb) {
          _this4.breadcrumbWidth = parseInt(getStyle($breadcrumb.$el, 'width'));
        }
      });
    }
  },
  watch: {
    topItem: {
      handler: function handler() {
        this.handleGetWidth();
        this.handleCheckWidth();
      },
      deep: true
    },
    items: {
      handler: function handler() {
        this.handleGetWidth();
        this.handleCheckWidth();
      },
      deep: true
    },
    activePath: {
      handler: function handler() {
        this.handleGetWidth();
        this.handleCheckWidth();
        // 改变BreadcrumbItem导航栏的值 因为编辑entityI以及编辑广告主页面根据操作状态来改变BreadcrumbItem导航栏，
        //因此从这2个页面跳转到别的页面也需要改变title
        // let obj = this.siderMenuObject[this.activePath];
        // if(obj.path!='/dsp/advertiser/edit'||obj.path!='/dsp/entity-manage/edit'){
        // 	let ele = document.querySelector('#breadcrumb-head-title>span');
        // 	ele.innerText = obj.title;
        // }
      },
      deep: true
    }
  },
  mounted: function mounted() {
    this.handleResize = throttle(this.handleCheckWidth, 100, {
      leading: false
    });
    on(window, 'resize', this.handleResize);
    this.handleGetWidth();
    this.handleCheckWidth();
  },
  beforeDestroy: function beforeDestroy() {
    off(window, 'resize', this.handleResize);
  }
};