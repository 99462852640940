import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.number.parse-int";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.split";
import _slicedToArray from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/slicedToArray.js";
import "core-js/modules/es6.string.starts-with";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
/**
 * 值是否为空
 * @param value
 * @returns {boolean}
 */
export function isEmpty(value) {
  return [undefined, null, ''].includes(value);
}

/**
 * 获取对象具体类型，例：Array、Function、Object、Symbol、FormData
 * @param obj
 * @returns {string}
 */
export function getObjectType(obj) {
  var type = Object.prototype.toString.call(obj);
  return type.slice(8, type.length - 1);
}

/**
 * 节流函数
 * @param fn
 * @param delay
 * @param interval
 * @returns {Function}
 */
export function throttle(fn, delay, interval) {
  var timer, start;
  interval || (interval = delay);
  return function () {
    var ctx = this,
      args = arguments,
      current = +new Date();
    timer && clearTimeout(timer);
    start || (start = current);
    if (current - start > interval) {
      fn.apply(ctx, args);
      start = current;
    } else {
      timer = setTimeout(function () {
        fn.apply(ctx, args);
      }, delay);
    }
  };
}

/**
 * 格式化数字，3位分割
 * @param number
 * @param len
 * @returns {string|*}
 */
export function formatNumber(number) {
  var len = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;
  if (isEmpty(number)) return number;
  var str = "".concat(number);
  // 处理负数
  var sign = str.startsWith('-') ? '-' : '';
  if (sign) str = str.substr(1);
  // 处理小数点
  var _str$split = str.split('.'),
    _str$split2 = _slicedToArray(_str$split, 2),
    int = _str$split2[0],
    float = _str$split2[1];
  // 整数部分，首位补0
  var n = len - int.length % len;
  if (n !== len) Array.from(new Array(n)).forEach(function () {
    return int = "0".concat(int);
  });
  var arr = [];
  Array.from(new Array(int.length / len)).forEach(function (i, d) {
    arr.push(int.slice(len * d, len * (d + 1)));
  });
  // 处理首位补0
  arr[0] = Number.parseInt(arr[0]);
  return "".concat(sign).concat(arr.join(',')).concat(float ? ".".concat(float) : '');
}

/**
 * 过滤参数中无效值
 * @param obj
 * @returns {{}}
 */
export function removeInvalidProp() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Object.keys(obj).reduce(function (resp, key) {
    var value = obj[key];
    if (!isEmpty(value)) resp[key] = value;
    return resp;
  }, {});
}

/**
 * 获取url格式的字符串
 * @param obj
 * @returns {string}
 */
export function getQueryString(obj) {
  var _obj = removeInvalidProp(obj);
  return '?' + Object.keys(_obj).map(function (key) {
    return "".concat(key, "=").concat(encodeURIComponent(_obj[key]));
  }).join('&');
}