import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.regexp.split";
/**
 * 多语言
 * */
import Languages from '@/i18n/locale';
import Setting from '@/setting';
import util from '@/libs/util';
import router from '@/router';
export default {
  namespaced: true,
  state: {
    locale: '',
    isEN: null
  },
  actions: {
    /**
     * @description 获取当前语言
     * */
    getLocale: function getLocale(_ref) {
      var state = _ref.state;
      var locale;

      // const db = util.db.get(pathInit({
      //     dbName: 'i18',
      //     path: '',
      //     defaultValue: {}
      // }));

      // const savedLocale = db.get(savedLocaleKey).value();
      var savedLocale = localStorage.getItem('i18n');
      console.log(savedLocale, 'savedLocale');
      // 先判断本地存储是否已有语言选择
      if (savedLocale) {
        locale = savedLocale;
      } else {
        // 判断是否开启自动识别语言
        if (Setting.i18n.auto) {
          // 如果自动识别的语言，本地没有该语言包，则设置为默认语言
          var navLang = navigator.language;
          if (navLang !== 'zh-CN') {
            navLang = 'en-US';
          }
          if (Languages[navLang]) {
            locale = navLang;
          } else {
            locale = Setting.i18n.default;
          }
        } else {
          locale = Setting.i18n.default;
        }

        // 将初次的语言保存在本地
        // db.set(savedLocaleKey, locale).write();
        localStorage.setItem('i18n', locale);
        // 存入cookies
        var cLocale = locale.split('-')[0];
        util.cookies.set('lang.language', cLocale);
      }
      if (locale === 'zh-CN') {
        state.isEN = true;
      } else {
        state.isEN = false;
      }
      state.locale = locale;
    },
    /**
     * @description 设置当前语言
     * */
    setLocale: function setLocale(_ref2, _ref3) {
      var state = _ref2.state;
      var _ref3$locale = _ref3.locale,
        locale = _ref3$locale === void 0 ? Setting.i18n.default : _ref3$locale,
        vm = _ref3.vm;
      return new Promise(function (resolve, reject) {
        // const db = util.db.get(pathInit({
        //   dbName: 'i18',
        //   path: '',
        //   defaultValue: {}
        // }));
        // // 将语言保存在本地
        // console.log(locale,'locale')
        // db.set(savedLocaleKey, locale).write();
        localStorage.setItem('i18n', locale);
        if (locale === 'zh-CN') {
          state.isEN = true;
        } else {
          state.isEN = false;
        }
        // 存入cookies
        var cLocale = locale.split('-')[0];
        util.cookies.set('lang.language', cLocale);
        // 设置当前语言
        state.locale = locale;
        // 设置 vue-i18n 的语言
        vm.$i18n.locale = locale;
        // 更新网页标题
        util.title({
          title: vm.$route.meta.title
        });
        // if(router.currentRoute.path === '/login'){
        //   window.location.reload();
        // }
        if (locale === 'en-US') {
          // console.log(router.currentRoute.path)
          if (!router.currentRoute.path.includes('/performance') && !router.currentRoute.path.includes('/overview')) {
            if (router.currentRoute.path === '/dashboard') {
              window.location.reload();
            } else {
              router.push({
                name: 'dashboard'
              });
            }
          } else {
            window.location.reload();
          }
        } else {
          window.location.reload();
        }
        // end
        resolve();
      });
    }
  }
};