import { prunePath } from "../util";

// 内置规则
export default {
  // 地址，params 不一致则独立缓存
  path: function path(route) {
    return route.path;
  },
  // 完整地址 (忽略 hash)，params 或 query 不一致则独立缓存
  fullpath: function fullpath(route) {
    return prunePath(route.fullPath);
  }
};