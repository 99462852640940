export default {
  path: "/dsp/home",
  name: 'home',
  meta: {
    cache: true,
    closable: false,
    auth: true,
    title: "首页"
  },
  component: function component() {
    return import("@/pages/dsp/home");
  }
};