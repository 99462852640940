// 页签刷新后还原
export default {
  computed: {
    // 刷新还原存储 key
    restoreKey: function restoreKey() {
      var restore = this.restore,
        basePath = this.basePath;
      if (!restore || typeof sessionStorage === 'undefined') return '';
      var key = "RouterTab:restore:".concat(basePath);
      typeof restore === 'string' && (key += ":".concat(restore));
      return key;
    }
  },
  mounted: function mounted() {
    // 页面重载前保存页签数据
    window.addEventListener('beforeunload', this.saveTabs);
  },
  destroyed: function destroyed() {
    window.removeEventListener('beforeunload', this.saveTabs);
  },
  watch: {
    // 监听 restoreKey 变化自动还原页签
    restoreKey: function restoreKey() {
      if (this.restoreWatch) {
        var activeTab = this.activeTab;
        this.initTabs();
        if (!this.activeTab) {
          this.items.push(activeTab);
        }
      }
    }
  },
  methods: {
    // 保存页签数据
    saveTabs: function saveTabs() {
      this.restoreKey && sessionStorage.setItem(this.restoreKey, JSON.stringify(this.items));
    },
    // 清除页签缓存数据
    clearTabsStore: function clearTabsStore() {
      this.restoreKey && sessionStorage.removeItem(this.restoreKey);
    },
    // 从缓存读取页签
    restoreTabs: function restoreTabs() {
      if (!this.restoreKey) return false;
      var tabs = sessionStorage.getItem(this.restoreKey);
      var hasStore = false;
      try {
        tabs = JSON.parse(tabs);
        if (Array.isArray(tabs) && tabs.length) {
          hasStore = true;
          this.presetTabs(tabs);
        }
      } catch (e) {}
      return hasStore;
    }
  }
};