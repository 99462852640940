import _typeof from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.function.name";
/* 销售表现下的 多交互-echart 公用配置 */
export default {
  props: {
    //自定义的legend 组件，只显示效果，不做交互
    legendList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    xAxisData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    series: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    group: {
      type: String,
      default: 'echartGroup'
    },
    xAxisBoundaryGap: {
      type: Boolean,
      default: true
    },
    yAxis: {
      type: [Array, Object]
    }
  },
  methods: {
    /******* echart ********/tooltipFormatter: function tooltipFormatter(params) {
      var _this = this;
      var html = params[0].name + '<br>';
      params.forEach(function (_ref) {
        var data = _ref.data,
          marker = _ref.marker,
          value = _ref.value,
          seriesName = _ref.seriesName;
        //提取数据单位（百分比，金钱，浮点数等）
        var unit = _typeof(data) === 'object' ? data.unit : null;
        var itemValue = unit == undefined || unit == null ? _this.$formatNumber(value) : unit == '' ? _this.$formatFloatNumber(value) //浮点数值
        : unit == '%' ? _this.$formatPercent(value) //百分比值
        : _this.$formatMoney(value, unit); //不是浮点数和百分比，就是金钱值

        html += "".concat(marker, " ").concat(seriesName, "\uFF1A").concat(itemValue, " <br />");
      });
      return html;
    } /****end echart ********/
  },
  computed: {
    //数值纵坐标
    defaultYAxis: function defaultYAxis() {
      return {
        type: 'value',
        axisLabel: {
          color: '#8b93a6',
          formatter: '{value}'
        }
      };
    },
    //百分比的纵坐标
    percentYAxis: function percentYAxis() {
      var _this2 = this;
      return {
        type: 'value',
        axisLabel: {
          color: '#8b93a6',
          formatter: function formatter(value) {
            return "".concat(_this2.$formatPercent(value));
          }
        }
      };
    },
    //金钱的纵坐标
    moneyYAxis: function moneyYAxis() {
      var _this3 = this;
      return {
        type: 'value',
        axisLabel: {
          color: '#8b93a6',
          formatter: function formatter(value) {
            return "".concat(_this3.unit).concat(_this3.$formatNumber(value));
          }
        } //纵坐标的金钱值不需要保留两位小数，formatMoney保留两位小数，这里不使用
        // axisLine: {
        //     lineStyle: {
        //         color: '#888'
        //     }
        // }
      };
    },
    //颜色
    colors: function colors() {
      return ['rgba(51,153,255,0.6)', 'rgba(255,72,134,0.6)', 'rgba(40, 187, 135, 0.6)', 'rgba(253, 188, 0, 0.6)', 'rgba(0, 38, 142, 0.5)', 'rgba(208, 2, 27, 0.5)', 'rgba(0, 207, 204, 0.6)', 'rgba(245, 161, 107, 0.6)', 'rgba(153, 153, 153, 0.6)', 'rgba(202, 120, 235, 0.6)'];
    }
  }
};