var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("Affix", {
    attrs: {
      "offset-top": _vm.offsetTop,
      "offset-bottom": _vm.offsetBottom
    },
    on: {
      "on-change": _vm.change
    }
  }, [_c("div", {
    style: _vm.style
  }, [_vm._t("default")], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };