var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "fk-date-picker-with-quick-ctn"
  }, [_c("el-input", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      type: "text"
    },
    model: {
      value: _vm.inputValue,
      callback: function callback($$v) {
        _vm.inputValue = $$v;
      },
      expression: "inputValue"
    }
  }), _c("el-date-picker", {
    staticClass: "fk-date-picker-with-quick-input",
    staticStyle: {
      width: "250px"
    },
    attrs: {
      "popper-class": "fk-date-picker-with-quick",
      size: "small",
      type: "daterange",
      align: "right",
      "unlink-panels": "",
      "value-format": "yyyy-MM-dd",
      "picker-options": _vm.pickerOptions
    },
    on: {
      change: _vm.dateChange
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };