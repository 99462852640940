import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import iMenuSideItem from "./menu-item";
import iMenuSideSubmenu from "./submenu";
import iMenuSideCollapse from "./menu-collapse";
import { mapState, mapGetters } from 'vuex';
export default {
  name: 'iMenuSide',
  components: {
    iMenuSideItem: iMenuSideItem,
    iMenuSideSubmenu: iMenuSideSubmenu,
    iMenuSideCollapse: iMenuSideCollapse
  },
  props: {
    hideLogo: {
      type: Boolean,
      default: false
    }
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('admin/layout', ['siderTheme', 'menuAccordion', 'menuCollapse'])), mapState('admin/menu', ['activePath', 'openNames'])), mapGetters('admin/menu', ['filterSider'])),
  watch: {
    '$route': {
      handler: function handler() {
        this.handleUpdateMenuState();
      },
      immediate: true
    },
    // 在展开/收起侧边菜单栏时，更新一次 menu 的状态
    menuCollapse: function menuCollapse() {
      this.handleUpdateMenuState();
    }
  },
  methods: {
    handleUpdateMenuState: function handleUpdateMenuState() {
      var _this = this;
      this.$nextTick(function () {
        if (_this.$refs.menu) {
          _this.$refs.menu.updateActiveName();
          if (_this.menuAccordion) _this.$refs.menu.updateOpened();
        }
      });
    }
  }
};