var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "router-tab__scroll",
    on: {
      wheel: function wheel($event) {
        $event.preventDefault();
        return _vm.onWheel.apply(null, arguments);
      },
      mouseenter: _vm.update
    }
  }, [_c("div", {
    ref: "container",
    staticClass: "router-tab__scroll-container",
    class: {
      "is-mobile": _vm.isMobile
    },
    on: {
      scroll: _vm.update
    }
  }, [_vm._t("default")], 2), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasScroller,
      expression: "hasScroller"
    }],
    ref: "bar",
    staticClass: "router-tab__scrollbar",
    class: {
      "is-dragging": _vm.dragData
    }
  }, [_c("div", {
    ref: "thumb",
    staticClass: "router-tab__scrollbar-thumb",
    style: {
      width: "".concat(_vm.thumbWidth, "px"),
      transform: "translateX(".concat(_vm.thumbLeft, "px")
    },
    on: {
      mousedown: function mousedown($event) {
        $event.preventDefault();
        return _vm.onDragStart.apply(null, arguments);
      }
    }
  })])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };