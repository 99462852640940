import "core-js/modules/es6.regexp.split";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import tableHeader from "../components/VxeTableComponents/header";
import stateAction from "../components/VxeTableComponents/stateAction";
import dateUtil from '@/assets/js/utils/date';
import XEUtils from 'xe-utils';
import { checkMoneySymbolTypeBySite } from '@/assets/js/utils/util';
import { checkMoneySymbolTypeByCode } from "../libs/common";
function renderHeader(h, renderOpts, params) {
  var props = renderOpts.props;
  var title = props.title,
    question = props.question,
    field = props.field;
  if (title) {
    // 默认表格title
    return [h(tableHeader, {
      props: {
        title: title,
        question: question
      },
      key: field
    })];
  }
}
export default function (table) {
  // vxe-table默认渲染配置
  table.renderer.add('VxeTableDefaultCellRender', {
    renderHeader: renderHeader,
    renderDefault: function renderDefault(h, renderOpts, params) {
      var row = params.row,
        column = params.column;
      var props = renderOpts.props;
      var contentType = props.contentType,
        field = props.field,
        showSymbol = props.showSymbol,
        showPercent = props.showPercent,
        fixedNumber = props.fixedNumber,
        methods = props.methods,
        line = props.line,
        format = props.format;
      if (!row) return '';
      var content = row[field];
      if (row.isTotal && !row.curSite) content = row[field + 'Usd'] || row[field];
      if (['Total', '--'].includes(content)) return content;
      var symbol = null;
      if (row.site) {
        symbol = checkMoneySymbolTypeBySite(row.site);
      } else if (row.orderCurrency) {
        symbol = checkMoneySymbolTypeByCode(row.orderCurrency);
      }
      if (!content && content !== 0 && field) {
        content = '--';
      } else {
        if (showSymbol && content) {
          content = symbol + XEUtils.commafy(XEUtils.toNumber(content), {
            digits: 2
          });
        } else if (showPercent && fixedNumber && content) {
          content = XEUtils.commafy(XEUtils.toNumber(content), {
            digits: fixedNumber
          }) + '%';
        } else if (showPercent && content) {
          content = XEUtils.commafy(XEUtils.toNumber(content), {
            digits: 4
          }) + '%';
        } else if (typeof content === 'number') {
          var xiaoShu = String(content).split('.')[1];
          if (xiaoShu && xiaoShu.length > 2) {
            content = XEUtils.commafy(XEUtils.toNumber(content), {
              digits: 2
            });
          } else {
            content = this.$formatNumber(content);
          }
        }
      }
      if (line) {
        content = h('fk-ellipsis', {
          props: {
            width: column.renderWidth - 20,
            line: line,
            content: content
          }
        });
      } else {
        content = h('span', content);
      }
      if (methods) {
        content = h('fk-link', {
          on: {
            click: function click() {
              methods(params);
            }
          }
        }, [content]);
      }
      if (contentType === 'stateAction') {
        return [h(stateAction, {
          props: {
            state: row.state
          },
          on: {
            stateChange: function stateChange(state) {
              methods && methods.stateChange && methods.stateChange(state, params);
            }
          }
        })];
      } else if (contentType === 'site') {
        if (!row.site) return '--';
        return [h('fk-site', {
          props: {
            site: row.site
          }
        })];
      } else if (contentType === 'time') {
        /*format针对contentType === 'time' 有format属性就取这个属性*/
        if (format) {
          return row[field] ? dateUtil.format(row[field], format) : '--';
        }
        return row[field] ? dateUtil.format(row[field]) : '--';
      }
      return [content];
    }
  });
}