import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import yieldRate from "@/router/pages/dsp/business-statistic/performance/yield-rate";
import ordersManage from "@/router/pages/dsp/business-statistic/performance/order-manage";
import advertiserStatistics from "@/router/pages/dsp/business-statistic/performance/advertiser-statistics";
import asin from "@/router/pages/dsp/business-statistic/advertisement/asin/index";

//业务统计
export default {
  path: '/dsp/business-statistic',
  header: 'dsp',
  title: '业务统计',
  children: [{
    path: '/dsp/financial/financial-manage',
    title: '财务统计'
  }, _objectSpread(_objectSpread({}, asin), {}, {
    title: 'Asin表现'
  }), {
    path: '/dsp/bonus-order',
    title: 'Bonus Order表现'
  }, _objectSpread(_objectSpread({}, advertiserStatistics), {}, {
    title: '广告主统计'
  }), _objectSpread(_objectSpread({}, yieldRate), {}, {
    title: '月达成率'
  }), _objectSpread(_objectSpread({}, ordersManage), {}, {
    title: '广告主订单'
  })]
};