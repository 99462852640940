var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("Input", {
    attrs: {
      type: "number"
    },
    on: {
      "on-blur": _vm.numBlur
    },
    model: {
      value: _vm.inputValue,
      callback: function callback($$v) {
        _vm.inputValue = typeof $$v === "string" ? $$v.trim() : $$v;
      },
      expression: "inputValue"
    }
  }, [_vm._t("prepend", null, {
    slot: "prepend"
  }), _vm._t("append", null, {
    slot: "append"
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };