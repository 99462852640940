import "core-js/modules/es6.number.constructor";
export default {
  name: 'fk-tooltip',
  props: {
    effect: {
      type: String,
      default: 'dark'
    },
    value: Boolean,
    content: String,
    manual: Boolean,
    offset: {
      type: Number,
      default: 0
    },
    placement: {
      type: String,
      default: 'top'
    },
    enterable: {
      type: Boolean,
      default: true
    },
    openDelay: {
      type: Number,
      default: 500
    },
    popperClass: {
      type: String
    }
  }
};