import "core-js/modules/es6.number.parse-int";
import "core-js/modules/es6.number.constructor";
import { compute } from '@/components/ellipsis/util';
export default {
  name: 'fk-ellipsis',
  props: {
    isLink: Boolean,
    content: {
      // 文本内容
      type: String,
      required: true
    },
    line: {
      // 需要省略的行数
      type: Number,
      default: 1
    },
    width: {
      // 文本内容宽度
      type: Number,
      default: 0
    },
    fontSize: {
      type: Number,
      default: 12
    },
    lineHeight: {
      // 行高，多行省略时必填
      type: Number,
      default: 16
    },
    placement: {
      // 同tooltip属性placement
      type: String,
      default: 'top'
    },
    delay: {
      // 同tooltip属性openDelay
      type: [String, Number],
      default: '500'
    },
    popperClass: String
  },
  render: function render(h) {
    var line = this.line;
    var content = this.content;
    var LISTENERS = this.$listeners;
    var style = {};
    if (this.width) {
      style.width = "".concat(this.width - 2, "px");
    }
    if (line === 1) {
      style.whiteSpace = 'nowrap';
      style.fontSize = "".concat(this.fontSize, "px");
      style.height = "".concat(this.lineHeight, "px");
      style.lineHeight = "".concat(this.lineHeight, "px");
    } else {
      style.fontSize = "".concat(this.fontSize, "px");
      style.height = "".concat(line * this.lineHeight, "px");
      style.lineHeight = "".concat(this.lineHeight, "px");
      style.whiteSpace = 'pre-wrap';
    }
    if (compute(content, style)) {
      return h('fk-tooltip', {
        props: {
          placement: this.placement,
          openDelay: Number.parseInt(this.delay),
          content: content,
          popperClass: this.popperClass
        }
      }, [this.getText(h, content, true, LISTENERS)]);
    }
    return this.getText(h, content, false, LISTENERS);
  },
  methods: {
    getText: function getText(h, content, overflow, LISTENERS) {
      var dom = this.isLink ? 'fk-link' : 'div';
      return h(dom, {
        class: this.getClass(overflow),
        style: this.getStyle(overflow),
        on: LISTENERS
      }, content);
    },
    getClass: function getClass(overflow) {
      var list = ['fk-ellipsis', 'fk-ellipsis-component'];
      if (!overflow) return list;
      list.push(this.line === 1 ? 'is-overflow-single' : 'is-overflow');
      return list;
    },
    getStyle: function getStyle(overflow) {
      if (!overflow) return {};
      var line = this.line;
      if (line === 1) return {
        width: this.width + 'px'
      };
      return {
        '-webkit-line-clamp': line,
        height: "".concat(line * this.lineHeight, "px"),
        lineHeight: "".concat(this.lineHeight, "px"),
        whiteSpace: 'pre-wrap'
      };
    }
  }
};