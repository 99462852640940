import home from "./home";
import upload from "./upload";
import applyManage from "./apply-manage";
import accountManage from "./account-manage";
import arManage from "./financial-manage/ar-manage";
import payableManage from "./financial-manage/payable-manage";
import exchangeRate from "./financial-manage/exchangeRate";
import businessStatistic from "./business-statistic";
import officeManage from "./office-manage";
import system from "./system";
import advertiseManage from "./advertise-manage";
import logManage from "./log-manage";
export default [home, advertiseManage, officeManage, accountManage, arManage, payableManage, exchangeRate, businessStatistic,
//业务管理
applyManage, logManage, upload, system];