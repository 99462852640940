import request from '@/plugins/request';

// POST 查询关联用户下拉框可选项
export var getDspUsers = function getDspUsers() {
  return request.get("/dsp/manager/selectable/user");
};

// 获取指定用户下可选广告主列表
// GET /dsp/mediaPlan/advertiser
export var getAdvertisers = function getAdvertisers(dspUserId) {
  var isSelected = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var siteId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  return request.post("/dsp/mediaPlan/advertiser", {
    dspUserId: dspUserId,
    isSelected: isSelected,
    siteId: siteId
  });
};

//获取货币单位
// ORDER_CURRENCY       ---->  订单货币单位
export var getCurrency = function getCurrency() {
  return request.get("/dsp/order/constant/ORDER_CURRENCY");
};

// 根据日期查询汇率比例 /dsp/payment/plan/exchangeRate
export var getExchangeRate = function getExchangeRate(belongDate, fromCurrency, toCurrency) {
  return request({
    url: "/dsp/payment/plan/exchangeRate",
    method: 'get',
    params: {
      belongDate: belongDate,
      fromCurrency: fromCurrency,
      toCurrency: toCurrency
    }
  });
};

// 获取国家 列表
export var getCountries = function getCountries() {
  return request.get("/dsp/manager/selectable/country");
};

// 根据页面获取BA，AM选项
export var getPrepareInfo = function getPrepareInfo(id) {
  return request({
    url: "/dsp/prepareInfo",
    method: 'get',
    headers: {
      moduleSubId: id
    }
  });
};

// 获取当前用户是否有该方案详情权限
export var isPermissionToJump = function isPermissionToJump(mediaPlanId) {
  return request.get("/dsp/authority/mediaPlan/".concat(mediaPlanId));
};

// 获取当前用户是否有entity详情权限 /dsp/manager/entity/permission/{id}
export var isEntityPermission = function isEntityPermission(id) {
  return request.post("/dsp/manager/entity/permission/".concat(id));
};

// 获取当前用户是否有该方案详情权限
export var isAdvertiserPermission = function isAdvertiserPermission(id) {
  return request.post("/dsp/manager/advertiser/permission/".concat(id));
};

// 搜索所有员工
export var searchWxUser = function searchWxUser(params) {
  return request.post("/system/search/wxUser", params);
};