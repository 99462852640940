var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a", {
    attrs: {
      href: "javascript:void(0);"
    },
    on: {
      click: _vm.jumpTo
    }
  }, [_vm._v("\n\t" + _vm._s(_vm.userName)), _vm._t("default")], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };