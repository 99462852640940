var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "router-tab"
  }, [_c("header", {
    ref: "header",
    staticClass: "router-tab__header"
  }, [_c("div", {
    staticClass: "router-tab__slot-start"
  }, [_vm._t("start")], 2), _c("tab-scroll", {
    ref: "scroll"
  }, [_c("transition-group", _vm._b({
    staticClass: "router-tab__nav",
    attrs: {
      tag: "ul"
    },
    on: {
      "after-enter": _vm.onTabTrans,
      "after-leave": _vm.onTabTrans
    }
  }, "transition-group", _vm.tabTrans, false), _vm._l(_vm.items, function (item, index) {
    return _c("tab-item", {
      key: item.id || item.to,
      ref: "tab",
      refInFor: true,
      attrs: {
        data: item,
        index: index
      },
      nativeOn: {
        contextmenu: function contextmenu($event) {
          $event.preventDefault();
          return function (e) {
            return _vm.showContextmenu(item.id, index, e);
          }.apply(null, arguments);
        }
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "router-tab__slot-end"
  }, [_vm._t("end")], 2)], 1), _c("div", {
    staticClass: "router-tab__container"
  }, [_c("router-alive", {
    attrs: {
      hideRouter: _vm.hideRouter,
      disRefreshComponents: _vm.disRefreshComponents,
      "page-class": "router-tab-page",
      "keep-alive": _vm.keepAlive,
      reuse: _vm.reuse,
      max: _vm.maxAlive,
      transition: _vm.pageTrans,
      "page-scroller": _vm.pageScroller
    },
    on: {
      ready: _vm.onAliveReady,
      change: _vm.onAliveChange
    }
  }), _c("transition-group", _vm._b({
    staticClass: "router-tab__iframes",
    attrs: {
      tag: "div"
    }
  }, "transition-group", _vm.pageTrans, false), _vm._l(_vm.iframes, function (url) {
    return _c("iframe", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: url === _vm.currentIframe,
        expression: "url === currentIframe"
      }],
      key: url,
      staticClass: "router-tab__iframe",
      attrs: {
        src: url,
        name: _vm.iframeNamePrefix + url,
        frameborder: "0",
        allowfullscreen: "true"
      },
      on: {
        load: function load($event) {
          return _vm.iframeLoaded(url);
        }
      }
    });
  }), 0)], 1), _c("transition", {
    attrs: {
      name: "router-tab-zoom"
    }
  }, [_vm.contextmenu !== false && _vm.contextData.index > -1 ? _c("tab-contextmenu", {
    attrs: {
      data: _vm.contextData,
      menu: _vm.contextMenu
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };