export default {
  path: '/dsp',
  name: 'dsp',
  meta: {
    auth: true,
    title: 'DSP AM系统'
  },
  redirect: {
    name: 'home'
  }
};