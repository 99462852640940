import request from '@/plugins/request';

// 获取首页提醒中心
export var getRemindCenter = function getRemindCenter() {
  return request.get("/dsp/workBench/remindCenter");
};

// 获取系统公告list
export var getSystemNoticeList = function getSystemNoticeList(params) {
  return request.get("/dsp/workBench/systemNotice/page", {
    params: params
  });
};

// 获取所有未读消息数量
export var getNotReadCount = function getNotReadCount() {
  return request.get("/dsp/workBench/systemNotice/notReadCount");
};

// 获取最新的未读公告
export var getNewNotice = function getNewNotice() {
  return request.get("/dsp/workBench/systemNotice/last");
};

// 清除所有系统消息
export var clearSystemNotReadCount = function clearSystemNotReadCount(filter) {
  return request.put("/dsp/workBench/systemNotice/clear", filter, {
    isMore: true
  });
};

// 获取广告主状态数据
export var getAdvertiserStatus = function getAdvertiserStatus(params) {
  return request.get("/dsp/workBench/advertiser/status", {
    params: params
  });
};

// 获取广告分析数据
export var getAdvertiserData = function getAdvertiserData(year, params) {
  return request.get("/dsp/workBench/ad/analyse/".concat(year), {
    params: params,
    isMore: true
  });
};

// 获取详情list 分成两个接口
export var getOrderDetail = function getOrderDetail(filter) {
  return request.post("/dsp/workBench/remindCenter/orderDetail", filter);
};
export var getlineItemDetail = function getlineItemDetail(filter) {
  return request.post("/dsp/workBench/remindCenter/lineItemDetail", filter);
};

// 排除
export var exclusion = function exclusion(filter) {
  return request.post("/dsp/workBench/remindCenter/exclusion", filter);
};

// 获取归属AM下拉框
export var getBranchAm = function getBranchAm() {
  return request.get("/dsp/workBench/branch/am");
};

// 每日广告花费
export var getDayDdvertiserCost = function getDayDdvertiserCost(params) {
  return request.post("/dsp/workBench/remindCenter/dayDdvertiserCost", params);
};

// reports首页查询所有Entity
export var getEntity = function getEntity(params) {
  return request.get("/dsp/manager/getEntity", params);
};

// reports首页根据entityId获取广告主列表
export var getAdvertiserList = function getAdvertiserList(params) {
  return request.get("/dsp/manager/getAdvertiserList", {
    params: params
  });
};