export default {
  path: '/dsp',
  title: '账号管理',
  header: 'dsp',
  children: [{
    path: '/dsp/entity-manage',
    title: 'Entity管理'
  }, {
    path: '/dsp/entity-manage/edit',
    title: '操作/编辑Entity',
    hide: true
  }, {
    path: '/dsp/entity-manage/detail',
    title: 'Entity详情',
    hide: true
  }, {
    path: '/dsp/advertiser',
    title: '广告主管理'
  }, {
    path: '/dsp/advertiser/edit',
    title: '操作/编辑广告主',
    hide: true
  }, {
    path: '/dsp/advertiser/detail',
    title: '广告主详情',
    hide: true
  }]
};