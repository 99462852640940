import datePickerWithQuick from "./datePickerWithQuick";
import multipleSelect from "./multipleSelect";
import tooltip from "./tooltip";
import timeGranularity from "./timeGranularity";
import vxeTable from "./VxeTable";
import pagination from "./pagination";
import question from "./question";
import ellipsis from "./ellipsis";
import link from "./fklink";
export default (function (Vue) {
  Vue.use(datePickerWithQuick);
  Vue.use(multipleSelect);
  Vue.use(tooltip);
  Vue.use(timeGranularity);
  Vue.use(vxeTable);
  Vue.use(pagination);
  Vue.use(question);
  Vue.use(ellipsis);
  Vue.use(link);
});