import "core-js/modules/es6.number.constructor";
import _toConsumableArray from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/web.dom.iterable";
/* 提取 带有 自定义 slot 的列头 */
var getSlotColumn = function getSlotColumn(array) {
  var temp = [];
  array.forEach(function (item) {
    if (item.slot) {
      temp.push(item);
    }
    if (item.children && item.children.length) {
      temp.push.apply(temp, _toConsumableArray(getSlotColumn(item.children)));
    }
  });
  return temp;
};
export default {
  name: 'dsp-table',
  props: {
    /********* 单位 **********/
    unit: {
      type: String,
      default: '$'
    },
    site: {
      type: String,
      default: ''
    },
    siteId: {
      type: Number,
      required: false
    },
    advertiser: {
      type: String,
      required: false
    },
    advertiserRemark: {
      type: String,
      required: false
    },
    advertiserId: {
      type: Number,
      required: false
    },
    /********* 货币单位 **********/

    /********* 数据 **********/
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    columns: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    /******end 数据 **********/

    /******** 配置项 *********/
    stripe: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    width: {
      type: [Number, String]
    },
    height: {
      type: [Number, String]
    },
    maxHeight: {
      type: [Number, String],
      default: 400
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabledHover: {
      type: Boolean,
      default: false
    },
    highlightRow: {
      type: Boolean,
      default: false
    },
    rowClassName: {
      type: String
    },
    size: {
      validate: function validate(v) {
        return ['large', 'small', 'default'].include(v);
      },
      default: 'default'
    },
    noDataText: {
      type: String
    },
    noFilteredDataText: {
      type: String
    }
    /*****end 配置项 *********/
  },
  computed: {
    templateColumns: function templateColumns() {
      return getSlotColumn(this.columns);
    }
  }
};