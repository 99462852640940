export default {
  path: '/dsp/financial/payable-manage',
  title: '应付管理',
  header: 'dsp',
  children: [{
    path: '/dsp/financial/amazon-bill',
    title: '亚马逊账单',
    children: [{
      path: '/dsp/financial/amazon-bill/detail',
      hide: true,
      title: '账单详情'
    }]
  }, {
    path: '/dsp/financial/paymoney-manage',
    title: '付款管理',
    children: [{
      path: '/dsp/financial/paymoney-manage/add-edit',
      hide: true,
      title: '付款新增/付款编辑'
    }, {
      path: '/dsp/financial/paymoney-manage/detail',
      hide: true,
      title: '付款详情'
    }]
  }]
};