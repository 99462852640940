export default {
  name: 'fk-pop',
  props: {
    width: String,
    content: String,
    desc: String,
    icon: String,
    // warning、success
    cancelText: String,
    confirmText: String,
    cancel: Boolean,
    showClose: Boolean,
    visible: Boolean,
    showRemind: Boolean,
    remindText: String,
    title: String,
    showIcon: Boolean,
    btnPosition: String,
    contentPosition: String
  },
  data: function data() {
    return {
      remind: true
    };
  },
  methods: {
    close: function close() {
      this._callback();
    },
    confirm: function confirm() {
      this._callback(true, {
        remind: this.remind
      });
    }
  }
};