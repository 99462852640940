import "core-js/modules/es6.number.constructor";
export default {
  name: 'fk-question',
  props: {
    content: String,
    placement: {
      type: String,
      default: 'top'
    },
    openDelay: {
      type: Number,
      default: 500
    }
  }
};