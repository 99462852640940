var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-tooltip", {
    attrs: {
      placement: _vm.placement,
      "open-delay": _vm.openDelay
    }
  }, [_c("div", {
    attrs: {
      slot: "content"
    },
    domProps: {
      innerHTML: _vm._s(_vm.content)
    },
    slot: "content"
  }), _c("i", {
    staticClass: "fk-question fk-question-component el-icon-question"
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };