import date from "../../assets/js/utils/date";
export default {
  name: "fk-time-granularity",
  props: ['selectValue'],
  model: {
    prop: 'selectValue',
    event: 'valueChange'
  },
  data: function data() {
    var _this = this;
    return {
      value: null,
      customizeTime: [],
      hoverStyle: false,
      selectDate: '',
      pickerOptions: {
        //选定时间限制在再90天
        disabledDate: function (time) {
          if (this.selectDate) {
            var deff = 89 * 24 * 60 * 60 * 1000;
            var nowDate = this.selectDate.getTime();
            return time.getTime() > nowDate + deff || time.getTime() < nowDate - deff;
          }
          return false;
        }.bind(this),
        onPick: function onPick(date) {
          // 如果只选择一个则保存至selectDate 否则selectDate 为空
          if (date.minDate && !date.maxDate) {
            _this.selectDate = date.minDate;
          } else {
            _this.selectDate = null;
          }
        }
      }
    };
  },
  created: function created() {
    this.value = this.selectValue;
  },
  methods: {
    setValue: function setValue(value, customizeDaysRange) {
      this.value = value;
      if (customizeDaysRange) {
        this.customizeTime = customizeDaysRange;
      }
    },
    blur: function blur() {
      if (this.value !== 6) {
        this.selectDate = null;
      }
    },
    timeGranularityChange: function timeGranularityChange(value) {
      if (value !== 6) {
        this.customizeTime = [];
        this.$emit('change', value);
      }
      this.$emit('valueChange', value);
    },
    customizeTimeChange: function customizeTimeChange(value) {
      if (value) {
        this.value = 6;
        var customizeDays = date.getBetweenDays(value[0], value[1]) + 1;
        this.$emit('change', 6, customizeDays, value);
      }
    }
  }
};