import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.array.find-index";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { mapState } from 'vuex';
export default {
  data: function data() {
    return {
      showAthority1: false,
      showAthority2: false
    };
  },
  computed: _objectSpread({}, mapState('admin/user', ['info'])),
  methods: {
    getAuthority: function getAuthority(id) {
      var authorityIdList = this.info.authorityIdList ? this.info.authorityIdList : [];
      var index = authorityIdList.findIndex(function (item) {
        return item == id;
      });
      if (index >= 0) {
        return true;
      } else {
        return false;
      }
    }
  }
};