var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("Layout", {
    staticClass: "i-layout"
  }, [!_vm.isMobile && !_vm.hideSider ? _c("Sider", {
    staticClass: "i-layout-sider",
    class: _vm.siderClasses,
    attrs: {
      width: _vm.menuSideWidth
    }
  }, [_c("i-menu-side", {
    attrs: {
      "hide-logo": _vm.isHeaderStick && _vm.headerFix && _vm.showHeader
    }
  })], 1) : _vm._e(), _c("Layout", {
    staticClass: "i-layout-inside",
    class: _vm.insideClasses
  }, [_c("transition", {
    attrs: {
      name: "fade-quick"
    }
  }, [_c("Header", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showHeader,
      expression: "showHeader"
    }, {
      name: "resize",
      rawName: "v-resize",
      value: _vm.handleHeaderWidthChange,
      expression: "handleHeaderWidthChange"
    }],
    staticClass: "i-layout-header",
    class: _vm.headerClasses,
    staticStyle: {
      height: "40px",
      "line-height": "40px"
    },
    style: _vm.headerStyle
  }, [_c("div", {
    staticClass: "system-notification-box"
  }, [_c("i", {
    staticClass: "iconfont iconxitonggonggao",
    on: {
      click: function click($event) {
        return _vm.$router.push({
          name: "home"
        });
      }
    }
  }), _c("div", {
    staticClass: "system-notification-content text-eli"
  }, [_vm._v("\n              系统公告：\n              "), _c("span", {
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: _vm.showSystemNotificationModel
    }
  }, [_vm._v(_vm._s(_vm.newNotice.title))])]), _vm.systemNotificationNotReadCount !== 0 ? _c("strong", {
    staticClass: "system-notification-title-tips"
  }, [_vm._v(_vm._s(_vm.systemNotificationNotReadCount))]) : _vm._e()]), _c("div", {
    staticClass: "i-layout-header-right flexbox",
    staticStyle: {
      height: "40px"
    }
  }, [_c("span", [_vm._v("欢迎您~")]), _vm.showSearch && _vm.isMobile || _vm.showSearch && (_vm.headerMenu || _vm.showBreadcrumb) ? _c("i-header-search") : _vm._e(), _vm.headerMenu && _vm.isMobile ? _c("i-menu-head") : _vm._e(), !_vm.isMobile && _vm.showLog ? _c("i-header-log") : _vm._e(), !_vm.isMobile && _vm.showFullscreen ? _c("i-header-fullscreen") : _vm._e(), _vm.showNotice ? _c("i-header-notice") : _vm._e(), _c("i-header-user"), _vm.enableSetting && !_vm.isMobile ? _c("i-header-setting") : _vm._e()], 1)])], 1), _c("Content", {
    staticClass: "i-layout-content",
    class: _vm.contentClasses
  }, [_c("div", [_c("transition", {
    on: {
      "after-enter": _vm.afterRouterChange
    }
  }, [_c("router-tab", {
    staticClass: "router-view",
    attrs: {
      tabs: _vm.titleTabs,
      "default-page": _vm.defaultPage,
      "page-transition": {
        name: "",
        mode: "out-in"
      },
      "tab-transition": {
        name: ""
      }
    },
    on: {
      onRefreshTab: _vm.onRefreshTab
    }
  })], 1)], 1)])], 1), _vm.isMobile && !_vm.hideSider ? _c("div", [_c("Drawer", {
    attrs: {
      placement: "left",
      closable: false,
      "class-name": _vm.drawerClasses
    },
    model: {
      value: _vm.showDrawer,
      callback: function callback($$v) {
        _vm.showDrawer = $$v;
      },
      expression: "showDrawer"
    }
  }, [_c("i-menu-side")], 1)], 1) : _vm._e(), _c("Modal", {
    attrs: {
      "footer-hide": true,
      width: 750
    },
    model: {
      value: _vm.systemNotificationModel,
      callback: function callback($$v) {
        _vm.systemNotificationModel = $$v;
      },
      expression: "systemNotificationModel"
    }
  }, [_c("div", {
    staticClass: "ivu-modal-header-inner",
    staticStyle: {
      width: "97%"
    },
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_vm._v("\n        " + _vm._s(_vm.newNotice.title) + "\n      ")]), _c("p", {
    staticClass: "fk-notice",
    domProps: {
      innerHTML: _vm._s(_vm.newNotice.noticeText)
    }
  })])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };