export default {
  path: '/dsp/report',
  title: '办公管理',
  header: 'dsp',
  children: [{
    path: '/dsp/reports',
    title: 'Reports'
  }, {
    path: '/dsp/report-manage',
    title: '报告管理',
    children: [{
      path: '/dsp/report-manage/detail',
      title: '添加报告',
      hide: true
    }]
  }, {
    path: '/dsp/metting-manage',
    title: '会议管理'
  }, {
    path: '/dsp/mdp-manage',
    title: 'MDP管理'
  }, {
    path: '/dsp/assets-manage',
    title: '素材管理'
  }
  // {
  //  path:'/dsp/question-manage',
  //  title:'问卷管理',
  //  children: [
  //    {
  //      path: '/dsp/question-manage/create',
  //      title: '问卷编辑',
  //      hide: true,
  //    }
  //  ]
  // },
  // {
  //  path:'/dsp/question-score',
  //  title:'问卷评分',
  // }
  ]
};