import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import request from '@/plugins/request';
import QS from 'qs';

// 获取站点
export var getSiteList = function getSiteList() {
  return request.get('/business/constant/site');
};

/**
 * 上传 图片/视频 post ok
 * @param img
 */
export var uploadFile = function uploadFile(formData) {
  var extraConfig = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var config = _objectSpread({
    method: 'post',
    url: "/tool/school/uploadImg",
    data: formData,
    timeout: 1000 * 60 * 30
  }, extraConfig);
  return request(config);
};