var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-tooltip", {
    attrs: {
      effect: _vm.effect,
      enterable: _vm.enterable,
      manual: _vm.manual,
      offset: _vm.offset,
      "open-delay": _vm.openDelay,
      placement: _vm.placement,
      "popper-class": _vm.popperClass,
      value: _vm.value
    },
    on: {
      input: function input(e) {
        return _vm.$emit("input", e);
      }
    }
  }, [_vm.$slots.content ? _vm._t("content", null, {
    slot: "content"
  }) : _c("div", {
    staticStyle: {
      "white-space": "pre-wrap"
    },
    attrs: {
      slot: "content"
    },
    domProps: {
      innerHTML: _vm._s(_vm.content)
    },
    slot: "content"
  }), _vm.$slots.default ? [_vm._t("default")] : _c("i", {
    staticClass: "fk-question el-icon-question"
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };