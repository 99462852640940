// 4k支持全部站点
export var FKSite = [{
  id: 3,
  siteName: 'US',
  siteCode: 'us',
  site: '美国站',
  siteChName: '美国',
  siteEnName: 'America',
  areaId: 1,
  marketPlaces: '北美大区'
}, {
  id: 4,
  siteName: 'CA',
  siteCode: 'ca',
  site: '加拿大站',
  siteChName: '加拿大',
  siteEnName: 'Canada',
  areaId: 1,
  marketPlaces: '北美大区'
}, {
  id: 5,
  siteName: 'MX',
  siteCode: 'mx',
  site: '墨西哥站',
  siteChName: '墨西哥',
  siteEnName: 'Mexico',
  areaId: 1,
  marketPlaces: '北美大区'
}, {
  id: 1,
  siteName: 'UK',
  siteCode: 'uk',
  site: '英国站',
  siteChName: '英国',
  siteEnName: 'Britain',
  areaId: 2,
  marketPlaces: '欧洲大区'
}, {
  id: 7,
  siteName: 'FR',
  siteCode: 'fr',
  site: '法国站',
  siteChName: '法国',
  siteEnName: 'France',
  areaId: 2,
  marketPlaces: '欧洲大区'
}, {
  id: 6,
  siteName: 'DE',
  siteCode: 'de',
  site: '德国站',
  siteChName: '德国',
  siteEnName: 'Germany',
  areaId: 2,
  marketPlaces: '欧洲大区'
}, {
  id: 2,
  siteName: 'IT',
  siteCode: 'it',
  site: '意大利站',
  siteChName: '意大利',
  siteEnName: 'Italy',
  areaId: 2,
  marketPlaces: '欧洲大区'
}, {
  id: 8,
  siteName: 'ES',
  siteCode: 'es',
  site: '西班牙站',
  siteChName: '西班牙',
  siteEnName: 'Spain',
  areaId: 2,
  marketPlaces: '欧洲大区'
}, {
  id: 16,
  siteName: 'NL',
  siteCode: 'nl',
  site: '荷兰站',
  siteChName: '荷兰',
  siteEnName: 'Netherlands',
  areaId: 2,
  marketPlaces: '欧洲大区'
}, {
  id: 17,
  siteName: 'SE',
  siteCode: 'se',
  site: '瑞典站',
  siteChName: '瑞典',
  siteEnName: 'Sweden',
  areaId: 2,
  marketPlaces: '欧洲大区'
}, {
  id: 18,
  siteName: 'PL',
  siteCode: 'pl',
  site: '波兰站',
  siteChName: '波兰',
  siteEnName: 'Poland',
  areaId: 2,
  marketPlaces: '欧洲大区'
}, {
  id: 14,
  siteName: 'JP',
  siteCode: 'jp',
  site: '日本站',
  siteChName: '日本',
  siteEnName: 'Japan',
  areaId: 3,
  marketPlaces: '亚太大区（日本站）'
}, {
  id: 15,
  siteName: 'AU',
  siteCode: 'au',
  site: '澳大利亚站',
  siteChName: '澳大利亚',
  siteEnName: 'Australia',
  areaId: 4,
  marketPlaces: '亚太大区（澳大利亚站）'
}];

// 获取区域列表
function getAreaList() {
  var list = [];
  FKSite.forEach(function (item) {
    if (!list.some(function (i) {
      return i.id === item.areaId;
    })) {
      list.push({
        id: item.areaId,
        areaName: item.marketPlaces
      });
    }
  });
  return list;
}

// 获取区域站点
function getAreaSites(areaId) {
  return FKSite.filter(function (i) {
    return i.areaId === areaId;
  }).map(function (item) {
    return {
      site: item.site,
      flag: require("../assets/images/siteFlag/".concat(item.siteCode, ".png")),
      areaId: item.areaId,
      siteChName: item.siteChName,
      siteEnName: item.siteEnName,
      siteCode: item.siteCode,
      createTime: null,
      updateTime: null,
      id: item.id
    };
  });
}
// 区域列表
export var areaList = getAreaList();
// 北美站点
export var northAmericaSite = getAreaSites(1);
// 欧洲站点
export var europeSite = getAreaSites(2);
// 亚太日本站点
export var asiaJapanSite = getAreaSites(3);
// 亚太澳大利亚站点
export var asiaAustraliaSite = getAreaSites(4);

// 授权相关信息
export var authInfo = FKSite.map(function (item) {
  return {
    site: item.site,
    flag: require("../assets/images/siteFlag/".concat(item.siteCode, ".png")),
    isDefaultSite: false,
    isAccAuth: false,
    isAccDisable: false,
    isAccCheck: false,
    isAdsAuth: false,
    isAdsDisable: true,
    isAdsCheck: false,
    subaccount: '',
    isMarketPlaceExpire: false,
    marketPlaces: item.marketPlaces,
    areaId: item.areaId,
    siteChName: item.siteChName,
    siteEnName: item.siteEnName,
    siteCode: item.siteCode,
    createTime: null,
    updateTime: null,
    id: item.id
  };
});

// 4k支持的eu站点
export var FKEUSite = ['uk', 'de', 'it', 'fr', 'es', 'nl', 'se', 'pl'];

// 不同广告在不同站点中的竞价最小值
export var siteBidMap = {
  0: {
    'us': 0.02,
    'ca': 0.02,
    'mx': 0.1,
    'uk': 0.02,
    'de': 0.02,
    'fr': 0.02,
    'es': 0.02,
    'it': 0.02,
    'nl': 0.02,
    'jp': 2,
    'au': 0.1,
    'se': 0.18,
    'pl': 0.04
  },
  1: {
    'us': 0.02,
    'ca': 0.02,
    'mx': 0.1,
    'uk': 0.02,
    'de': 0.02,
    'fr': 0.02,
    'es': 0.02,
    'it': 0.02,
    'nl': 0.02,
    'jp': 2,
    'au': 0.02,
    'se': null,
    'pl': null
  },
  2: {
    'us': 0.1,
    'ca': 0.1,
    'mx': 0.1,
    'uk': 0.1,
    'de': 0.1,
    'fr': 0.1,
    'es': 0.1,
    'it': 0.1,
    'nl': 0.1,
    'jp': 10,
    'au': 0.1,
    'se': 0.9,
    'pl': 0.2
  },
  3: {
    'us': 0.25,
    'ca': 0.15,
    'mx': 0.15,
    'uk': 0.15,
    'de': 0.15,
    'fr': null,
    'es': null,
    'it': null,
    'nl': null,
    'jp': null,
    'au': 0.15,
    'se': 1.3,
    'pl': 0.3
  },
  4: {
    'us': 1,
    'ca': .1,
    'mx': 5,
    'uk': 1,
    'de': 1,
    'fr': 1,
    'es': 1,
    'it': 1,
    'nl': 1,
    'jp': 100,
    'au': 1,
    'se': 1,
    'pl': null
  }
};

// 不同站点预算最小值
export var siteBudgetMap = {
  'au': 1.4,
  'jp': 100,
  'se': 9,
  'pl': 2
};