import "core-js/modules/es6.number.constructor";
export default {
  name: 'to-dsp-user',
  props: {
    id: {
      type: [String, Number]
    },
    name: {
      type: String
    },
    target: {
      type: String,
      default: "_blank"
    }
  },
  computed: {
    to: function to() {
      return {
        name: 'dsp-user-detail',
        params: {
          id: this.id
        }
      };
    }
  }
};