export default {
  props: {
    state: {
      type: String,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    hidePaused: {
      type: Boolean,
      default: false
    },
    hideArchived: {
      type: Boolean,
      default: false
    },
    disArchived: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleStateChange: function handleStateChange(state) {
      this.$emit('change', state);
      if (this.state === state) return;
      this.$emit('stateChange', state);
    }
  }
};