var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "fk-pagination-component fk-vertical-middle"
  }, [_c("div", {
    staticStyle: {
      position: "relative",
      flex: "1",
      overflow: "hidden"
    }
  }, [_vm._t("default")], 2), _vm.showDragMsg === "true" ? _c("div", {
    staticClass: "showDragMsg"
  }, [_vm._v(_vm._s(_vm.$t("filedTips.canDragMsg")))]) : _vm._e(), _vm.showTime !== "false" ? _c("div", {
    staticClass: "showTime"
  }, [_vm._v(_vm._s(_vm._f("showTimeHandle")(_vm.showTime)))]) : _vm._e(), _vm.showItemIndex ? _c("div", {
    staticStyle: {
      "padding-right": "10px"
    }
  }, [_vm.total > 0 ? [_vm._v("\n      " + _vm._s(_vm.page * _vm.size - _vm.size + 1) + " - " + _vm._s(Math.min(_vm.page * _vm.size, _vm.total)) + " of " + _vm._s(_vm.total) + "\n    ")] : [_vm._v("0")]], 2) : _vm._e(), _c("el-pagination", {
    attrs: {
      "current-page": _vm.page,
      "page-size": _vm.size,
      "page-sizes": _vm.sizes,
      layout: _vm.layout,
      total: _vm.total
    },
    on: {
      "current-change": _vm.pageChange,
      "size-change": _vm.pageSizeChange
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };