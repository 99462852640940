import "core-js/modules/es6.number.constructor";
export default {
  name: 'dsp-affix',
  props: {
    offsetTop: Number,
    offsetBottom: Number
  },
  data: function data() {
    return {
      isFixed: false
    };
  },
  computed: {
    style: function style() {
      var style = {
        background: "#fff",
        transition: ' .3s'
      };
      if (this.isFixed) {
        // style.boxShadow = "0 0 10px -5px #333";
        // style.padding = "10px";
      }
      return style;
    }
  },
  methods: {
    change: function change(v) {
      this.isFixed = v;
      this.$emit("on-change", v);
    }
  }
};