var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "el-dialog-radius",
    attrs: {
      visible: _vm.visible,
      width: _vm.width,
      title: _vm.title,
      "close-on-click-modal": false,
      "show-close": _vm.cancel
    },
    on: {
      "update:visible": _vm.close
    }
  }, [_c("div", {
    staticClass: "fk-pop-component",
    style: {
      "align-items": _vm.contentPosition === "center" ? "center" : "initial"
    }
  }, [_vm.showIcon ? _c("div", {
    staticClass: "fk-pop-image",
    class: [_vm.icon]
  }) : _vm._e(), _vm.content ? _c("div", {
    staticClass: "fk-pop-content",
    class: {
      "no-margin-top": !_vm.showIcon
    },
    style: {
      "text-align": _vm.contentPosition
    },
    domProps: {
      innerHTML: _vm._s(_vm.content)
    }
  }) : _vm._e(), _vm.desc ? _c("div", {
    staticClass: "fk-pop-desc",
    style: {
      "text-align": _vm.contentPosition
    },
    domProps: {
      innerHTML: _vm._s(_vm.desc)
    }
  }) : _vm._e(), _vm.showRemind ? _c("el-checkbox", {
    staticClass: "fk-pop-remind",
    model: {
      value: _vm.remind,
      callback: function callback($$v) {
        _vm.remind = $$v;
      },
      expression: "remind"
    }
  }, [_c("span", {
    staticStyle: {
      color: "#8B93A6"
    }
  }, [_vm._v(_vm._s(_vm.remindText))])]) : _vm._e()], 1), _c("div", {
    staticClass: "fk-pop-footer",
    style: {
      "text-align": _vm.btnPosition
    },
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_vm.cancel && _vm.cancelText ? _c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: _vm.close
    }
  }, [_vm._v(_vm._s(_vm.cancelText))]) : _vm._e(), _c("el-button", {
    attrs: {
      type: "primary",
      size: "small"
    },
    on: {
      click: _vm.confirm
    }
  }, [_vm._v(_vm._s(_vm.confirmText))])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };