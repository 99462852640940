import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.number.constructor";
import { debounce } from '@/assets/js/utils/util';
export default {
  name: 'fk-multiple-select',
  props: {
    resetEmpty: {
      type: Boolean,
      default: false
    },
    selectValue: {
      type: [Array, String, Number],
      default: function _default() {
        return [];
      }
    },
    selectList: {
      // 数组
      type: Array,
      default: function _default() {
        return [];
      }
    },
    placeholder: {
      // 占位内容
      type: String,
      default: '请选择'
    },
    label: {
      type: String,
      default: 'name'
    },
    value: {
      type: String,
      default: 'id'
    },
    itemKey: {
      type: String,
      default: 'id'
    },
    allLabel: {
      type: String,
      default: 'All'
    },
    allValue: {
      type: [String, Number, Object],
      default: ''
    },
    allowCreate: {
      type: Boolean,
      default: false
    },
    hideAll: {
      type: Boolean,
      default: false
    },
    collapseTags: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '200px'
    },
    allInfirst: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'selectValue',
    event: 'valueChange'
  },
  watch: {
    selectValue: function selectValue(val) {
      this.setValue(val);
    }
  },
  data: function data() {
    return {
      reserveKeyword: false,
      visible: false,
      emitValueHide: false,
      localMultiple: true,
      // 判断单选多选 默认单选
      localValue: null,
      // 本地存储选中的值
      cacheLocalMultiple: true,
      cache: null,
      // 缓存上一次选中结果，点取消要还原成当前
      debounceEmitChange: undefined,
      debounceEmitValueChange: undefined
    };
  },
  mounted: function mounted() {
    // 默认是单选的
    this.localValue = this.selectValue[0];
    this.debounceEmitChange = debounce(this.handleEmit('change'), 0);
    this.debounceEmitValueChange = debounce(this.handleEmit('valueChange'), 0);
  },
  methods: {
    setValue: function setValue(val) {
      var value = this.localMultiple ? val : val[0];
      this.localValue = value;
      if (!this.visible) {
        this.cache = value;
      }
    },
    visibleChange: function visibleChange(flag) {
      this.visible = flag;
      if (flag) {
        this.emitValueHide = false;
      } else {
        if (!this.emitValueHide) {
          this.localMultiple = this.cacheLocalMultiple;
          this.localValue = this.cache;
          this.debounceEmitValueChange(this.cache);
        }
      }
    },
    // 切换多选单选
    toggleMode: function toggleMode() {
      var _this = this;
      this.localMultiple = !this.localMultiple;
      if (this.localMultiple) {
        this.localValue = [''];
      } else {
        this.localValue = null;
      }
      this.debounceEmitValueChange([]);
      var node = this.$refs.multiSelect;
      var children = node.$children;
      for (var i = 0; i < children.length; i++) {
        if (children[i].getInput) {
          children[i].getInput().value = '';
        }
      }
      this.$nextTick(function () {
        if (_this.localMultiple) {
          _this.reserveKeyword = true;
        } else {
          _this.reserveKeyword = false;
        }
      });
    },
    // 取消
    cancel: function cancel() {
      if (this.resetEmpty) {
        this.localValue = [];
      } else {
        this.localValue = this.localMultiple ? [this.allValue] : this.allValue;
      }
      this.debounceEmitValueChange(this.localValue);
      if (this.resetEmpty) {
        this.emitValue();
      }
    },
    // 确定
    emitValue: function emitValue() {
      var _this2 = this;
      console.log('emitValue');
      this.emitValueHide = true;
      this.cache = this.localValue;
      this.cacheLocalMultiple = this.localMultiple;
      this.debounceEmitChange(this.localValue);
      setTimeout(function () {
        _this2.$refs.multiSelect.blur();
      }, 50);
    },
    selectChange: function selectChange(value) {
      // 多选
      if (this.localMultiple) {
        var result = value;
        var length = result.length;
        if (value.includes(this.allValue) && result[length - 1] === this.allValue) {
          result = [this.allValue];
        } else {
          var index = result.indexOf(this.allValue);
          if (index > -1) {
            result.splice(index, 1);
          }
        }
        this.localValue = result;
        this.debounceEmitValueChange(result);
      } else {
        // 单选
        this.emitValueHide = true;
        this.localValue = value;
        this.cache = value;
        this.cacheLocalMultiple = false;
        this.debounceEmitValueChange([value]);
        this.debounceEmitChange([value]);
      }
    },
    removeTag: function removeTag() {
      this.debounceEmitValueChange(this.localValue);
      this.debounceEmitChange(this.localValue);
    },
    clear: function clear(e) {
      var result = [];
      if (this.localMultiple) {
        if (!this.hideAll) {
          if (this.allValue === '') result = [];
        }
      } else {
        result = e;
      }
      this.debounceEmitChange(result);
      this.debounceEmitValueChange(result);
    },
    handleEmit: function handleEmit(type) {
      var _this3 = this;
      return function (params) {
        _this3.$emit(type, params);
      };
    }
  }
};