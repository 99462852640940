import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a", {
    staticClass: "fk-link fk-link-component",
    class: _defineProperty(_defineProperty({}, _vm.icon, _vm.icon), "inline-block", _vm.inlineBlock),
    on: {
      click: function click(e) {
        return _vm.$emit("click", e);
      }
    }
  }, [_vm._t("default")], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };