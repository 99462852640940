var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    staticClass: "fk-mask-component",
    style: _vm.getStyle
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };