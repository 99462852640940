import _toConsumableArray from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "/app/node_modules/core-js/modules/es6.array.iterator.js";
import "/app/node_modules/core-js/modules/es6.promise.js";
import "/app/node_modules/core-js/modules/es6.object.assign.js";
import "/app/node_modules/core-js/modules/es7.promise.finally.js";
// Vue
import Vue from 'vue';
import App from "./App";
import Clipboard from 'v-clipboard';

// 插件
import plugins from '@/plugins';

// store
import store from '@/store/index';

// storage
import storage from "./assets/js/storage";

// fieldMap
import { getTooltip, getFieldKey, getFieldType, getFieldName, fieldTypeIsCosts, fieldTypeIsRates, getFieldListByType, getFixedNumber } from "./fieldMap";

// 请求新接口的方法
import requestExternal from "./plugins/request/requestExternalInterface";

// iView 和 iView Pro
import iView from 'view-design';
import iViewPro from '@/libs/iview-pro/iview-pro.min.js';

// 引入vxe-table
import { vxeStyleFnc } from "./assets/js/resetVxeTableUi";
vxeStyleFnc(); // 初始化动态vxe样式
import 'xe-utils';
import VXETable from 'vxe-table';
import 'vxe-table/lib/style.css';
import VXETablePluginElement from 'vxe-table-plugin-element';
import 'vxe-table-plugin-element/dist/style.css';

// 菜单和路由
import router from "./router";
// import menuHeader from '@/menu/header';
import menuSider from '@/menu/sider';
import { frameInRoutes } from '@/router/routes';

// vue-router-tab
import RouterTab from "./openSourcePlugin/RouterTab";
Vue.use(RouterTab);

// 方法
import { getHeaderName, getMenuSider, getSiderSubmenu } from '@/libs/system';

// 内置组件
import iLink from '@/components/link';

// 使用样式，修改主题可以在 styles 目录下创建新的主题包并修改 iView 默认的 less 变量
// 参考 https://www.iviewui.com/docs/guide/theme
import "./styles/index.less";
import "./libs/iview-pro/iview-pro.css";
import components from "./components";
Vue.use(components);
Vue.use(plugins);
Vue.use(iView, {
  size: 'small'
});
Vue.use(iViewPro);
Vue.component('i-link', iLink);
Vue.use(VXETable);

// 自定义指令
import directive from "./directives";
Vue.use(directive);

//***** 数据处理方法 *****
import { nan2zero, fixed2, formatNumber, formatFloatNumber, formatMoney, formatPercent, ms2time, toDate, setTitle, exportAsExcel, formatM100illion, formatRate, formatRateInt, fixed4, formatMillion } from "@/libs/data-handler";
Vue.use(VXETable);
VXETable.use(VXETablePluginElement);
/***** @六月 全局过滤器 *****/
// 全局注册filter
Vue.filter("nan2zero", nan2zero);
Vue.filter("fixed2", fixed2);
Vue.filter("formatNumber", formatNumber);
Vue.filter("formatFloatNumber", formatFloatNumber);
Vue.filter("formatMoney", formatMoney);
Vue.filter("formatPercent", formatPercent);
Vue.filter("ms2time", ms2time);
Vue.filter("toDate", toDate);
Vue.filter("formatMillion", formatMillion);
Vue.filter('formatM100illion', formatM100illion);
Vue.filter('formatRate', formatRate);
Vue.filter('formatRateInt', formatRateInt);
Vue.filter('fixed4', fixed4);
/***** @六月 全局方法 *****/
Vue.prototype.$storage = storage;
Vue.prototype.$nan2zero = nan2zero;
Vue.prototype.$fixed2 = fixed2;
Vue.prototype.$formatRate = formatRate;
Vue.prototype.$formatRateInt = formatRateInt;
Vue.prototype.$formatNumber = formatNumber;
Vue.prototype.$formatFloatNumber = formatFloatNumber;
Vue.prototype.$formatMoney = formatMoney;
Vue.prototype.$formatPercent = formatPercent;
Vue.prototype.$setTitle = setTitle;
Vue.prototype.$toDate = toDate;
Vue.prototype.$exportAsExcel = exportAsExcel;
Vue.prototype.$requestExternal = requestExternal;
Vue.prototype.$getTooltip = getTooltip;
Vue.prototype.$getFieldKey = getFieldKey;
Vue.prototype.$getFieldType = getFieldType;
Vue.prototype.$getFieldName = getFieldName;
Vue.prototype.$fieldTypeIsCosts = fieldTypeIsCosts;
Vue.prototype.$fieldTypeIsRates = fieldTypeIsRates;
Vue.prototype.$getFieldListByType = getFieldListByType;
Vue.prototype.$fieldGetFixedNumber = getFixedNumber;
/***** @六月 全局方法 *****/
import { getWeekStartDate, getWeekEndDate } from "@/libs/date/week";
Vue.prototype.$getWeekStartDate = getWeekStartDate;
Vue.prototype.$getWeekEndDate = getWeekEndDate;

// 埋点
import SlsWebLogger from 'js-sls-logger';
var opts = {
  host: 'cn-shanghai.log.aliyuncs.com',
  project: 'user-activity',
  logstore: 'dspam-visit',
  time: 10,
  count: 20
};
Vue.prototype.logger = new SlsWebLogger(opts);

//*****@六月 全局组件******/
import fkComponents from "@/components/fk";
import dspComponents from "@/components/dsp";
Vue.use(fkComponents);
Vue.use(dspComponents);
Vue.use(Clipboard);
//*****end 全局组件******/

//***** 混用element ******/
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en';
Vue.use(ElementUI, {
  size: 'mini',
  locale: locale
});
//*****end 混用element ******/

import { cloneDeep } from 'lodash';
import pop from "./components/pop";
Vue.use(pop);

// icon
import "./assets/icon";
new Vue({
  store: store,
  router: router,
  render: function render(h) {
    return h(App);
  },
  created: function created() {
    // 加载用户登录的数据
    this.$store.dispatch('admin/account/load');
    // 处理路由 得到每一级的路由设置
    this.$store.commit('admin/page/init', frameInRoutes);
    // 设置顶栏菜单
    // this.$store.commit('admin/menu/setHeader', menuHeader);
    // 初始化全屏监听
    this.$store.dispatch('admin/layout/listenFullscreen');
  },
  watch: {
    // 监听路由 控制侧边栏显示 标记当前顶栏菜单（如需要）
    '$route': function $route(_ref, old) {
      var path = _ref.path;
      var userInfo = store.state.admin.user.info;
      var menuList = cloneDeep(userInfo.menuList);
      menuList = [menuSider[0]].concat(_toConsumableArray(menuList));
      var headerName = getHeaderName(path, menuSider);
      // 在 404 时，是没有 headerName 的
      if (headerName !== null) {
        this.$store.commit('admin/menu/setHeaderName', headerName);
      }
      // const filterMenuSider = getMenuSider(menuSider, headerName);
      this.$store.commit('admin/menu/setSider', menuList);
      this.$store.commit('admin/menu/setActivePath', path);
      var openNames = getSiderSubmenu(path, menuSider);
      this.$store.commit('admin/menu/setOpenNames', openNames);
    }
  }
}).$mount('#app');