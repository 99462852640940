var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("Table", {
    directives: [{
      name: "fixed-table-head",
      rawName: "v-fixed-table-head.fixed.drag",
      modifiers: {
        fixed: true,
        drag: true
      }
    }],
    attrs: {
      data: _vm.data,
      columns: _vm.columns,
      size: _vm.size,
      "max-height": _vm.maxHeight,
      stripe: _vm.stripe,
      border: _vm.border,
      showHeader: _vm.showHeader,
      width: _vm.width,
      height: _vm.height,
      maxHeight: _vm.maxHeight,
      loading: _vm.loading,
      disabledHover: _vm.disabledHover,
      highlightRow: _vm.highlightRow,
      rowClassName: _vm.rowClassName,
      noDataText: _vm.noDataText,
      noFilteredDataText: _vm.noFilteredDataText
    },
    on: {
      "on-expend": function onExpend($event) {
        return _vm.$emit("on-expend", $event);
      },
      "on-sort-change": function onSortChange($event) {
        return _vm.$emit("on-sort-change", $event);
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.templateColumns, function (c, i) {
      return {
        key: c.key,
        fn: function fn(_ref) {
          var row = _ref.row;
          return [c.key === "brandName" && !row.isTotal ? _c("span", [_vm._v(_vm._s(row.brandName))]) : c.key === "order" && row.orderId ? _c("span", [_vm._v(_vm._s(row.order))]) : c.key === "lineItem" && row.lineItemId ? _c("span", [_vm._v(_vm._s(row.lineItem))]) : c.key === "creative" && row.creativeId ? _c("div", [_vm._v("\n\t\t\t\t" + _vm._s(row.creative) + "\n            ")]) : c.key === "channel" && !row.isTotal ? _c("span", [_vm._v(_vm._s(row.channel))]) : c.key === "asin" && row.asinUrl ? _c("span", [_vm._v(_vm._s(row.asin))]) : _c("span", [_vm._v("\n                " + _vm._s(row[c.key] !== 0 && !row[c.key] ? "--" : c.unit === "PERCENT" ? _vm.$formatPercent(row[c.key]) : c.unit === "MONEY" ? _vm.$formatMoney(row[c.key], _vm.unit) : c.unit === "DOUBLE" ? _vm.$formatFloatNumber(row[c.key]) : c.unit === "INT" ? _vm.$formatNumber(row[c.key]) : row[c.key]) + "\n            ")])];
        }
      };
    })], null, true)
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };