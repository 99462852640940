import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.string.starts-with";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/es6.number.constructor";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { mapGetters } from "../util";

// 拖拽传输数据前缀
var TRANSFER_PREFIX = 'RouterTabDragSortIndex:';

// 排序拖拽数据
// 用以解决双核浏览器兼容模式下无法通过 dataTransfer.getData 获取数据
var dragSortData = null;

// 页签项
// @vue/component
export default {
  name: 'TabItem',
  inject: ['$tabs'],
  props: {
    // 页签原始数据，方便 slot 插槽自定义数据
    data: {
      type: Object,
      required: true
    },
    // 页签项索引
    index: Number
  },
  data: function data() {
    return {
      onDragSort: false,
      // 是否正在拖拽
      isDragOver: false // 是否拖拽经过
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters('data', ['id', 'to', 'icon', 'tabClass', 'target', 'href'])), {}, {
    // class
    classList: function classList() {
      return ['router-tab__item', this.tabClass, {
        'is-active': this.$tabs.activeTabId === this.id,
        'is-closable': this.closable,
        'is-contextmenu': this.$tabs.contextData.id === this.id,
        'is-drag-over': this.isDragOver && !this.onDragSort
      }];
    },
    // 国际化
    i18nText: function i18nText() {
      return this.$tabs.i18nText;
    },
    // 未命名页签
    untitled: function untitled() {
      return this.$tabs.langs.tab.untitled;
    },
    // 页签标题
    title: function title() {
      return this.i18nText(this.data.title) || this.untitled;
    },
    // 页签提示
    tips: function tips() {
      return this.i18nText(this.data.tips || this.data.title) || this.untitled;
    },
    // 是否可关闭
    closable: function closable() {
      var _this$$tabs = this.$tabs,
        keepLastTab = _this$$tabs.keepLastTab,
        items = _this$$tabs.items;
      return this.data.closable !== false && !(keepLastTab && items.length < 2);
    }
  }),
  methods: {
    // 插槽默认内容
    slotDefault: function slotDefault() {
      var _this = this;
      var h = this.$createElement;
      return [this.icon && h("i", {
        "class": ['router-tab__item-icon', this.icon]
      }), h("span", {
        "class": "router-tab__item-title",
        "attrs": {
          "title": this.tips
        }
      }, [this.title]), this.closable && h("i", {
        "class": "router-tab__item-close",
        "on": {
          "click": function click($event) {
            $event.preventDefault();
            $event.stopPropagation();
            return _this.close($event);
          }
        }
      })];
    },
    // 关闭当前页签
    close: function close() {
      this.$tabs.closeTab(this.id);
    },
    // 拖拽
    onDragStart: function onDragStart(e) {
      this.onDragSort = this.$tabs.onDragSort = true;
      e.dataTransfer.dropEffect = 'move';
      dragSortData = TRANSFER_PREFIX + this.index;
      e.dataTransfer.setData('text', dragSortData);
    },
    // 拖拽结束
    onDragEnd: function onDragEnd() {
      this.onDragSort = this.$tabs.onDragSort = false;
      dragSortData = null;
    },
    // 释放后排序
    onDrop: function onDrop(e) {
      var items = this.$tabs.items;
      var raw = e.dataTransfer.getData('text') || dragSortData;
      this.isDragOver = false;
      if (typeof raw !== 'string' || !raw.startsWith(TRANSFER_PREFIX)) return;
      var fromIndex = raw.replace(TRANSFER_PREFIX, '');
      var tab = items[fromIndex];
      items.splice(fromIndex, 1);
      items.splice(this.index, 0, tab);
    }
  },
  // 渲染组件
  // 使用 jsx render 模式替换 template，避免 Vue 2.5.22 版本不支持子组件使用父组件的 slot 导致出错。
  render: function render() {
    var _this2 = this;
    var h = arguments[0];
    var _this$$tabs$$scopedSl = this.$tabs.$scopedSlots.default,
      slot = _this$$tabs$$scopedSl === void 0 ? this.slotDefault : _this$$tabs$$scopedSl;
    return h("router-link", {
      "attrs": {
        "tag": "li",
        "to": this.to,
        "draggable": this.$tabs.dragsort
      },
      "class": this.classList,
      "nativeOn": {
        "dragstart": this.onDragStart,
        "dragend": this.onDragEnd,
        "dragover": function dragover($event) {
          $event.preventDefault();
          return function () {
            return _this2.isDragOver = true;
          }($event);
        },
        "dragleave": function dragleave($event) {
          $event.preventDefault();
          return function () {
            return _this2.isDragOver = false;
          }($event);
        },
        "drop": function drop($event) {
          $event.stopPropagation();
          $event.preventDefault();
          return _this2.onDrop($event);
        },
        "mouseup": function mouseup($event) {
          if ("button" in $event && $event.button !== 1) return null;
          return function () {
            return _this2.closable && _this2.close();
          }($event);
        }
      }
    }, [slot(this)]);
  }
};