import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.keys";
import _toConsumableArray from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.find";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import _typeof from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
import "core-js/modules/es6.number.is-nan";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.set";
import "core-js/modules/es6.number.parse-float";
import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/web.dom.iterable";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { getObjectType } from "../assets/js/utils";
// import i18n from '@/i18n/index';
import { FKEUSite, siteBidMap, siteBudgetMap } from "./siteCommon";

/**
 * url 拼接同名参数
 * @param url 链接
 * @param sameList 相同的参数列表
 * sameList:[{
 *   key:String,
 *   valueList:Array
 * }]
 * */

export function urlConnectParams(url, sameList) {
  var result = url;
  if (sameList && sameList[0]) {
    if (result.indexOf('?') >= 0) {
      // 有问号说明前面拼接过了
      result = url + '&';
    } else {
      result = url + '?';
    }
    sameList.forEach(function (item) {
      item.valueList.forEach(function (valueItem) {
        if (![undefined, null, ''].includes(valueItem)) {
          if (typeof valueItem === 'string') {
            valueItem = valueItem.replace(/\&/g, '%26');
            valueItem = valueItem.replace(/\+/g, '%2B');
          }
          result += "".concat(item.key, "=").concat(valueItem, "&");
        }
      });
    });
  }
  result = result.substr(0, result.length - 1);
  return result;
}
export function setDoubleNum(num) {
  var result = num > 0 && num < 10 ? '0' + num : '' + num;
  return result;
}
export function getListItemNum(totalNum, rowPerPage, curPage) {
  var startNum = 0;
  var endNum = 0;
  startNum = (curPage - 1) * rowPerPage + 1;
  if (totalNum % rowPerPage === 0) {
    // 如果最后一页也有 rowPerPage项
    endNum = curPage * rowPerPage;
  } else {
    if (curPage === Math.ceil(totalNum / rowPerPage)) {
      // 刚好是最后一页的时候
      endNum = startNum - 1 + totalNum % rowPerPage;
    } else {
      endNum = curPage * rowPerPage;
    }
  }
  return {
    'startIndex': startNum,
    'totalItems': totalNum,
    'endNum': endNum
  };
}

/**
 *  这个方法用于将数字每三位加“,”号
 * @param str
 * @returns {string | *}
 */
export function formatNum(str) {
  if (!str) return 0; // 如果传了0、不传、或其他不为真的值，直接返回0
  str = str.toString();
  var newStr = '';
  var count = 0;
  if (str.indexOf('.') === -1) {
    // 如果没有小数点
    for (var i = str.length - 1; i >= 0; i--) {
      newStr = count % 3 === 0 && count !== 0 && str.charAt(i) != '-' ? str.charAt(i) + ',' + newStr : str.charAt(i) + newStr;
      count++;
    }
    str = newStr;
  } else {
    for (var _i = str.indexOf('.') - 1; _i >= 0; _i--) {
      newStr = count % 3 === 0 && count !== 0 && str.charAt(_i) != '-' ? str.charAt(_i) + ',' + newStr : str.charAt(_i) + newStr;
      count++;
    }
    str = newStr + str.substring(str.indexOf('.'));
  }
  return str;
}
export function formatMoney(str) {
  var num = Number(str).toFixed(2);
  return formatNum(num);
}

/**
 * 获取url中?后面的参数,原来的方法，部分位置已经改为通过router query 来获取
 * @param name
 * @returns {*}
 */
export function getQueryString(name, url) {
  var hashVal = url ? url : window.location.href;
  var searchUrl = hashVal.split('?')[1];
  if (!searchUrl) return '';
  var obj = {};
  var searchArr = searchUrl.split("&");
  var arr = [];
  for (var k = 0; k < searchArr.length; k++) {
    if (searchArr[k].indexOf('=') !== -1) {
      arr.push(searchArr[k]);
    } else {
      arr[arr.length - 1] += '&' + searchArr[k];
    }
  }
  for (var i = 0; i < arr.length; i++) {
    var keyValue = arr[i].split("=");
    if (keyValue.length === 1) continue;
    var value = keyValue[1];
    if (!value) continue;
    obj[keyValue[0]] = decodeURIComponent(value);
  }
  return obj[name];
}
export function checkTableSortChange(data, firstCharacterNotToUpperCase) {
  // 有些字段的排序要颠倒过来，所以这里要特殊处理一下
  var reverse = ['ReturnAmountUsd', 'ActualReturnUsd', 'CostsUsd', 'AmzCostUsd'];
  var field = null;
  var type = '';
  switch (data.order) {
    case 'ascending':
      type = 'asc';
      break;
    case 'asc':
      type = 'asc';
      break;
    case 'descending':
      type = 'desc';
      break;
    case 'desc':
      type = 'desc';
      break;
    default:
      type = null;
      break;
  }
  if (data.column) {
    if (data.column.sortBy) {
      field = data.column.sortBy;
    } else if (data.property) {
      field = data.property;
    } else {
      field = data.prop;
    }
  }
  if (field) {
    if (!firstCharacterNotToUpperCase) {
      field = field.replace(field[0], field[0].toUpperCase());
    }
    if (reverse.includes(field)) {
      type = type === 'asc' ? 'desc' : 'asc';
    }
  }
  return {
    field: field,
    type: type
  };
}

/**
 * 自定义table展开行的操作，改为点击某一行的任意空位置即可展开，再点击收起
 * @param key列表的key值
 * @param ExpandArr：expand-row-keys的值，为数组
 * @returns {*} 返回一个数组，重新赋值给组建中的expand-row-keys
 */
export function customToggleExpandRow(key, ExpandArr) {
  Array.prototype.removeArrItem = function (val) {
    var index = this.indexOf(val);
    if (index > -1) this.splice(index, 1);
  };
  if (ExpandArr.indexOf(key) < 0) {
    var curArr = [];
    curArr.push(key);
    ExpandArr = curArr;
  } else {
    ExpandArr.removeArrItem(key);
  }
  return ExpandArr;
}

/**
 * 请求返回是否成功的判断
 * @param isSuccess：外层的success,布尔值
 * @param result: result
 * @param isResultSuccess：result里面的success，布尔值
 * return 布尔值
 */
export function checkRequestResult(isSuccess, result, isResultSuccess) {
  if (isSuccess) {
    if (result) return !!isResultSuccess;
    return true;
  } else {
    return false;
  }
}

/**
 *  自定义日期选择框选择不为空时，调用此方法进行格式化
 * @param val 组件值的回调
 * @param betweenDate：实例中的自定义日期变量
 * @param initDate：初始化日期的变量
 * @param days： 天数按钮的变量
 * @param daysVal：自定义日期后天数需要设置的值
 * @returns {{days: *, betweenDate: Array}}
 */
export function betweenDateFormat(val, betweenDate, initDate, days, daysVal) {
  betweenDate = [];
  initDate = [];
  val.forEach(function (item, index) {
    var dateItem = getDate(item);
    var year = dateItem.getFullYear();
    var month = dateItem.getMonth() + 1;
    var date = dateItem.getDate();
    var formatDate = year + '-' + setDoubleNum(month) + '-' + setDoubleNum(date);
    betweenDate.push(formatDate);
    initDate.push(formatDate);
    days = daysVal;
  });
  return {
    'days': days,
    'initDate': initDate,
    'betweenDate': betweenDate
  };
}
function isNull(object) {
  if (object == null || typeof object == "undefined") {
    return true;
  }
  return false;
}
/**
 * 根据日期字符串获取星期几
 * @param {dateString} 日期字符串（如：2016-12-29），为空时为用户电脑当前日期
 * @returns {String}
 */
export function getWeek(dateString) {
  var date;
  if (dateString.length < 10) {
    return null;
  }
  if (isNull(dateString)) {
    date = new Date();
  } else {
    var dateArray = dateString.split("-");
    date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2]);
  }
  // if (i18n.locale === 'en') {
  //     return ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'][date.getDay()]
  // } else {
  //     return "周" + "日一二三四五六".charAt(date.getDay());
  // }
  return "周" + "日一二三四五六".charAt(date.getDay());
}
/**
 *@author hjw
 *@desc days: 时间筛选days的值；serviceTime： 服务器时间， isTrend：（非必传,布尔值)是否是趋势图
 */
export function checkDaysType(daysVal, serviceTime, isTrend) {
  if (daysVal == 0) {
    var nowDate = getDate(serviceTime);
    var today = formatDate(nowDate);
    return {
      startDate: today,
      endDate: today
    };
  } else if (daysVal == 'weekToDate') {
    return getWeekToDate(serviceTime);
  } else if (daysVal == 'lastWeek') {
    var lastWStartDate = getLastWeekDate(7, serviceTime);
    var lastWEndDate = getLastWeekDate(1, serviceTime);
    return {
      startDate: lastWStartDate,
      endDate: lastWEndDate
    };
  } else if (daysVal == 'monthToDate') {
    return getMonthToDate(serviceTime);
  } else if (daysVal == 'lastMonth') {
    return getLastMothBetweenDate(serviceTime);
  } else {
    var dateObj = getLastNumberDaysDate(daysVal, serviceTime, isTrend);
    return {
      startDate: dateObj.startDate,
      endDate: dateObj.endDate
    };
  }
}
function getLastNumberDaysDate(num, serviceTime, isTrend) {
  var dayMSec = 24 * 3600 * 1000; // 一天的毫秒数
  var nowDate = getDate(serviceTime);
  var nowTimeStamp = nowDate.getTime();
  var startDateStamp = nowTimeStamp - num * dayMSec; // 获取到last num days 的开始日期时间戳
  // if (isTrend) {                                        // 如果是趋势图的话也显示当天的数据
  //   startDateStamp = startDateStamp + 1 * dayMSec
  // }
  if (num && num != 1) {
    startDateStamp = startDateStamp + 1 * dayMSec; // 上面注释内容是原来趋势图过去几天（比如7天）是取到当天，而列表从当天的前一天开始算，先改为都取到当天
  }
  var startDateObj = getDate(startDateStamp);
  var startDate = formatDate(startDateObj); // 获取到last num days 的开始日期
  var endDateStamp = null;
  // if (isTrend) {                                        // 如果是趋势图的话也显示当天的数据
  //   endDateStamp = nowTimeStamp
  // } else {
  //   endDateStamp = nowTimeStamp - 1 * dayMSec         // 因为last num days 不包括当天，这里减去一天的时间戳来获取昨天的时间戳
  // }
  if (num && num != 1) {
    endDateStamp = nowTimeStamp;
  } else {
    endDateStamp = nowTimeStamp - 1 * dayMSec;
  }
  var endDateObj = getDate(endDateStamp);
  var endDate = formatDate(endDateObj); // 获取到last num days 的结束日期
  return {
    startDate: startDate,
    endDate: endDate
  };
}

/**
 * 获取本周至今的起止日期
 * @param serviceTime
 * @returns {{startDate: string, endDate: string}}
 */
function getWeekToDate(serviceTime) {
  var dayMSec = 24 * 3600 * 1000;
  var nowDate = getDate(serviceTime);
  var Day = nowDate.getDay();
  var startDateStamp = nowDate.getTime() - Day * dayMSec;
  var startDate = formatDate(getDate(startDateStamp));
  var endDate = formatDate(nowDate);
  return {
    startDate: startDate,
    endDate: endDate
  };
}

/**
 * 获取上个星期的起止日期
 * @param n
 * @param serviceTime
 * @returns {string}
 */
function getLastWeekDate(n, serviceTime) {
  var now = getDate(serviceTime);
  var year = now.getFullYear();
  var month = now.getMonth() + 1;
  var date = now.getDate();
  var day = now.getDay();
  n = n + day;
  if (day) {
    // 这个判断是为了解决跨年的问题
    if (!(month > 1)) {
      year = year - 1;
      month = 12;
    }
  }
  now.setDate(now.getDate() - n);
  year = now.getFullYear();
  month = now.getMonth() + 1;
  date = now.getDate();
  var result = year + '-' + (month < 10 ? '0' + month : month) + '-' + (date < 10 ? '0' + date : date);
  return result;
}

/**
 * 获取本月的1号日期和今天的日期
 * @param serviceTime
 * @returns {{startDate: string, endDate: string}}
 */
function getMonthToDate(serviceTime) {
  var nowDate = getDate(serviceTime);
  var nowMonthFirstDay = getDate([nowDate.getFullYear(), nowDate.getMonth() + 1, 1].join('-'));
  var startDate = formatDate(nowMonthFirstDay);
  var endDate = formatDate(nowDate);
  return {
    startDate: startDate,
    endDate: endDate
  };
}

/**
 * 获取上个月的起止日期
 * @returns {{lastMonthFirstDay: *, lastMonthLastDay: *}}
 */
function getLastMothBetweenDate(serviceTime) {
  var dayMSec = 24 * 3600 * 1000;
  var today = getDate(serviceTime);
  var lastMonthFirstDay = null;
  if (today.getMonth() !== 0) {
    lastMonthFirstDay = getDate([today.getFullYear(), today.getMonth(), 1].join('-')); // 获取上一个月的第一天
  } else {
    lastMonthFirstDay = getDate([today.getFullYear() - 1, 12, 1].join('-'));
  }
  var nowMonthFirstDay = getDate([today.getFullYear(), today.getMonth() + 1, 1].join('-')); // 获取本月第一天
  var lastMonthLastDayMSec = nowMonthFirstDay.getTime() - 1 * dayMSec; // 获取上一个月的最后一天的毫秒值
  var lastMonthLastDay = getDate(lastMonthLastDayMSec);
  return {
    startDate: formatDate(lastMonthFirstDay),
    endDate: formatDate(lastMonthLastDay)
  };
}

/**
 * 传入日期对象，返回yy-MM-DD格式的日期
 * @param newDate
 * @returns {string}
 */
export function formatDate(newDate) {
  var firstY = newDate.getFullYear();
  var firstM = newDate.getMonth() + 1;
  var firstD = newDate.getDate();
  firstM = firstM >= 10 ? firstM : '0' + firstM;
  firstD = firstD >= 10 ? firstD : '0' + firstD;
  return firstY + '-' + firstM + '-' + firstD;
}

/**
 *@author hjw
 *@desc 计算两个时间相差的天数
 */
export function calcDateRangeNum(startDate, endDate) {
  var dayMSec = 24 * 3600 * 1000;
  var newStart = startDate.replace(new RegExp(/-/gm), '/');
  var startStamp = getDate(newStart).getTime();
  var newEnd = endDate.replace(new RegExp(/-/gm), '/');
  var endStamp = getDate(newEnd).getTime() + dayMSec;
  var disDays = parseInt((endStamp - startStamp) / dayMSec);
  return disDays;
}
export function checkDateRangeValCommon(startDate, endDate, dateRange) {
  var calcVal = calcDateRangeNum(startDate, endDate);
  var range = null;
  // 原来根据 天数的不同切换 按天、周、月的纬度显示，现注释掉，默认按天
  // if (calcVal <= 30) {
  //   range = '1'
  // } else if (calcVal > 30 && calcVal <= 90) {
  //   range = '2'
  // } else {
  //   range = '3'
  // }
  // 默认都按天-------------------
  range = dateRange;
  if (dateRange && calcVal <= 14 && dateRange == 3) {
    // 14天的时候不能按月
    range = '1';
  }
  return {
    range: range,
    val: calcVal
  };
}

/**
 *@author hjw
 *@desc JSEncrypt: JSEncrypt的实例化对象, pwd：密码
 */
export function pwdEncryption(JSEncrypt, pwd) {
  // let jse = new JSEncrypt()  // 新建JSEncrypt对象
  var pkey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCM0agdDmHKc1nWBfNtB6bmUgSmRp7YWhXhL8HOc31xZR+XJhxzDn2FEJNeowealiNyxSidbzXz9X9jWqqq9oxsw3Gt30xYXK4KB86Wor5Xr26ZTRWDfggsDtaevqCJZ1CrLGkz3nzNrGuKf6e2kixE1O4d/7zAbl31nYjKTHkSSwIDAQAB';
  JSEncrypt.setPublicKey(pkey);
  var rsaPassWord = JSEncrypt.encrypt(pwd); // 加密
  return rsaPassWord;
}
/**
 * 格式化浮点数，解决js浮点数计算后精度问题
 * @param number
 * @param accuracy 小数点位数
 * @returns {number}
 */
export function formatNumber(number) {
  var accuracy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  return Number.parseFloat(number.toFixed(accuracy));
}

/**
 * 格式化新标签页打开带的query参数
 * @param json
 * @returns {string}
 */
export function parseRedirectQuery(json) {
  var tempArr = [];
  for (var i in json) {
    var key = encodeURIComponent(i);
    var value = encodeURIComponent(json[i]);
    tempArr.push(key + '=' + value);
  }
  var urlParamsStr = tempArr.join('&');
  return urlParamsStr;
}

/**
 * @aurhor hjw
 * @desc JSON数组去重
 * array: json 数组
 * key 要去重的key
 */
export function uniqueJsonArray(array, key) {
  var resultArr = [array[0]];
  array.forEach(function (item) {
    var repeat = false;
    resultArr.forEach(function (el) {
      if (item[key] && el[key] && item[key] === el[key]) repeat = true;
    });
    if (!repeat) resultArr.push(item);
  });
  return resultArr;
}

/**
 * @author hjw
 * 根据相同字段对数组进行分组
 * @param list
 * @param field
 * @returns {Array}
 */
export function groupArrBySameField(list, field) {
  // 先把field取出来放一个数组里，然后去重
  var fieldArr = list.map(function (item) {
    return item[field];
  });
  var fieldFilterArr = new Set(fieldArr);
  var resultArr = [];
  // 根据去重后的数组进行分组筛选
  fieldFilterArr.forEach(function (item) {
    var resultItem = list.filter(function (el) {
      return el[field] === item;
    });
    resultArr.push(resultItem);
  });
  return resultArr;
}

/**
 * @author zy
 * 格式化url中search格式
 * @returns {Object}
 */
export function parseUrlParams(info) {
  var result = {};
  if (info) {
    info.split('&').forEach(function (item) {
      if (item.split('=')[1] && item.split('=')[1] !== 'null' && item.split('=')[1] !== 'undefined') {
        result[decodeURIComponent(item.split('=')[0])] = decodeURIComponent(item.split('=')[1]);
      }
    });
  }
  return result;
}

/**
 * @author zy
 * 获取多个数组相加之和
 * @returns {Array}
 */
export function getArrsSum() {
  var arg = arguments;
  if (arg.length === 0) return [];
  var result = [];
  for (var i = 0; i < arg.length; i++) {
    arg[i].forEach(function (e, i) {
      if (result[i] == null || result[i] == '') {
        result[i] = 0;
      }
      result[i] += e;
    });
  }
  return result;
}

/**
 * @author zy
 * 获取数组中最大值
 * @returns {Number}
 */

export function getArrayMax(arr) {
  if (arr.length === 0) return 0;
  return Math.max.apply(null, arr);
}
/**
 * 获取Date格式时间
 */
function getDate(date) {
  try {
    // Date、Number类型，直接转换后返回
    if (['Date', 'Number'].includes(getObjectType(date))) return new Date(date);
    // String类型，替换时区
    date = date.split('+')[0];
    var newDate = new Date(date);
    // 转换后newDate 为 Invalid date，尝试做格式转换
    if (Number.isNaN(newDate.getTime())) newDate = new Date(date.split('-').join('/'));
    return newDate;
  } catch (e) {
    // new Date失败，返回当前时间
    return new Date();
  }
}
// 传入文件名判断是否为图片
export function isImage(fileName) {
  var index = fileName.lastIndexOf(".");
  var suffix = fileName.substr(index + 1);
  var imgSuffix = ['JPG', 'JPEG', 'PNG', 'GIF', 'BMP', 'jpg', 'jpeg', 'png', 'gif', 'bmp'];
  return imgSuffix.includes(suffix);
}
export function isWordFile(fileName) {
  var index = fileName.lastIndexOf(".");
  var suffix = fileName.substr(index + 1);
  var imgSuffix = ['doc', 'docx'];
  return imgSuffix.includes(suffix);
}
export function isExcel(fileName) {
  var index = fileName.lastIndexOf(".");
  var suffix = fileName.substr(index + 1);
  var imgSuffix = ['xls', 'xlsx'];
  return imgSuffix.includes(suffix);
}

// 获取两个周期环比对比
export function getMomData(a, b) {
  // 可能返回值为String '0.00'
  var current = Number(a);
  var last = Number(b);
  if (current && last) {
    var percent = Number(((current - last) / last * 100).toFixed(2));
    return {
      percent: Math.abs(percent) + '%',
      rise: percent === 0 ? '' : percent > 0 ? 'top' : 'bottom'
    };
  }
  return {
    percent: '--'
  };
}

// 站点货币相关
export var EUSites = FKEUSite;
export function checkMoneySymbolTypeByCode(currencyCode) {
  var moneySymbol = '';
  if (currencyCode === 'GBP') {
    moneySymbol = '￡';
  } else if (currencyCode === 'USD') {
    moneySymbol = '$';
  } else if (currencyCode === 'CAD') {
    moneySymbol = 'C$';
  } else if (currencyCode === 'MXN') {
    moneySymbol = 'Mex.$';
  } else if (currencyCode === 'JPY') {
    moneySymbol = '￥';
  } else if (currencyCode === 'AUD') {
    moneySymbol = 'A$';
  } else if (currencyCode === 'EUR') {
    moneySymbol = '€';
  } else if (currencyCode === 'CNY') {
    moneySymbol = '￥';
  } else if (currencyCode === 'SEK') {
    moneySymbol = 'kr';
  } else if (currencyCode === 'PLN') {
    moneySymbol = 'zł';
  } else if (currencyCode === 'INR') {
    moneySymbol = '₹';
  } else if (currencyCode === 'AED') {
    moneySymbol = 'AED';
  } else if (currencyCode === 'SAR') {
    moneySymbol = 'ريال';
  } else if (currencyCode === 'HKD') {
    moneySymbol = 'HK$';
  } else if (currencyCode === 'BRL') {
    moneySymbol = 'R$';
  } else {
    moneySymbol = '$';
  }
  return moneySymbol;
}
export function checkMoneySymbolTypeBySite(curSite) {
  var moneySymbol = '';
  if (curSite === 'uk') {
    moneySymbol = '￡';
  } else if (curSite === 'ca') {
    moneySymbol = 'C$';
  } else if (curSite === 'mx') {
    moneySymbol = 'Mex.$';
  } else if (curSite === 'de' || curSite === 'fr' || curSite === 'it' || curSite === 'es' || curSite === 'nl') {
    moneySymbol = '€';
  } else if (curSite === 'au') {
    moneySymbol = 'A$';
  } else if (curSite === 'jp') {
    moneySymbol = '￥';
  } else if (curSite === 'se') {
    moneySymbol = 'kr';
  } else if (curSite === 'pl') {
    moneySymbol = 'zł';
  } else {
    moneySymbol = '$';
  }
  return moneySymbol;
}
export function getCurrencyCode(site) {
  var currencyCode;
  if (site === 'uk') {
    currencyCode = 'GBP';
  } else if (site === 'us' || !site) {
    currencyCode = 'USD';
  } else if (site === 'ca') {
    currencyCode = 'CAD';
  } else if (site === 'mx') {
    currencyCode = 'MXN';
  } else if (site === 'jp') {
    currencyCode = 'JPY';
  } else if (site === 'au') {
    currencyCode = 'AUD';
  } else if (site === 'se') {
    currencyCode = 'SEK';
  } else if (site === 'pl') {
    currencyCode = 'PLN';
  } else {
    currencyCode = 'EUR';
  }
  return currencyCode;
}

/**
 * 生成唯一uuid
 * @returns {string}
 */

export function generateUUID() {
  var d = new Date().getTime();
  if (window.performance && typeof window.performance.now === "function") {
    d += performance.now(); //use high-precision timer if available
  }
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == 'x' ? r : r & 0x3 | 0x8).toString(16);
  });
  return uuid;
}
export function deepClone(target) {
  // 定义一个变量
  var result;
  // 如果当前需要深拷贝的是一个对象的话
  if (_typeof(target) === 'object') {
    // 如果是一个数组的话
    if (Array.isArray(target)) {
      result = []; // 将result赋值为一个数组，并且执行遍历
      for (var i in target) {
        // 递归克隆数组中的每一项
        result.push(deepClone(target[i]));
      }
      // 判断如果当前的值是null的话；直接赋值为null
    } else if (target === null) {
      result = null;
      // 判断如果当前的值是一个RegExp对象的话，直接赋值
    } else if (target.constructor === RegExp) {
      result = target;
    } else {
      // 否则是普通对象，直接for in循环，递归赋值对象的所有值
      result = {};
      for (var _i2 in target) {
        result[_i2] = deepClone(target[_i2]);
      }
    }
    // 如果不是对象的话，就是基本数据类型，那么直接赋值
  } else {
    result = target;
  }
  // 返回最终结果
  return result;
}
export function getScrollbarWidth() {
  var odiv = document.createElement('div'),
    //创建一个div
    styles = {
      width: '100px',
      height: '100px',
      overflowY: 'scroll' //让他有滚动条
    },
    i,
    scrollbarWidth;
  for (i in styles) odiv.style[i] = styles[i];
  document.body.appendChild(odiv); //把div添加到body中
  scrollbarWidth = odiv.offsetWidth - odiv.clientWidth; //相减
  odiv.remove(); //移除创建的div
  return scrollbarWidth; //返回滚动条宽度
}
export function getMinBidNumber(row) {
  console.log(_objectSpread({}, row));
  var type = row.advertiseType;
  var site = row.site;
  var creativeType = row.creativeType;
  if (type == 2 && (creativeType === 4 || creativeType === 5)) {
    // sbv类型
    type = 3;
  }
  if (row.costType === 'vcpm' || row.costType === 'VCPM') {
    //sd vcpm类型
    type = 4;
  }
  if (!type && type !== 0 || !site || !siteBidMap[Number(type)]) return 0.02;
  return siteBidMap[Number(type)][site] || 0;
}
export function getMinBudgetNumber(site) {
  return siteBudgetMap[site] || 1;
}
export function computeNumberUnit(num) {
  var numStr = String(num);
  var decimalStr = null;
  if (numStr.indexOf('.') !== -1) {
    var strArr = numStr.split('.');
    numStr = strArr[0];
    decimalStr = strArr[1];
  }
  if (numStr.length > 6) {
    var decimal = numStr.slice(numStr.length - 6, numStr.length - 4);
    if (decimal[1] === '0') decimal = decimal[0];
    return "".concat(numStr.slice(0, numStr.length - 6)).concat(decimal != 0 ? '.' + decimal : '', "M");
  } else if (numStr.length > 3) {
    var _decimal = numStr.slice(numStr.length - 3, numStr.length - 1);
    if (_decimal[1] === '0') _decimal = _decimal[0];
    return "".concat(numStr.slice(0, numStr.length - 3)).concat(_decimal != 0 ? '.' + _decimal : '', "K");
  } else if (decimalStr) {
    return numStr + '.' + decimalStr;
  } else {
    return numStr;
  }
}
/**
 * 数组元素交换位置
 * @param {array} arr 数组
 * @param {number} index1 添加项目的位置
 * @param {number} index2 删除项目的位置
 * index1和index2分别是两个数组的索引值，即是两个要交换元素位置的索引值，如1，5就是数组中下标为1和5的两个元素交换位置
 */
export function swapArray(arr, index1, index2) {
  arr[index1] = arr.splice(index2, 1, arr[index1])[0];
  return arr;
}

/**
 * 从文本中获取指定文字
 * @param text
 * @returns {RegExp}
 */
export function getTextRegExp(text) {
  return new RegExp("(?<=(?:^|\\s|".concat(getSpecialRegStr(), "))(").concat(text, ")(?=(?:$|\\s|").concat(getSpecialRegStr(), "))"), 'gi');
}

/**
 * 特殊字符
 */
function getSpecialRegStr() {
  return '<>?,./:"|;\'\\!@#$%^&*()_-+={}[]'.split('').map(function (i) {
    return "\\".concat(i);
  }).join('|');
}
export var autoTargetNameList = [{
  name: '紧密匹配',
  matchType: 'queryHighRelMatches',
  tips: '我们会向使用与您的产品密切相关的搜索字词的购物者展示您的广告。 如果您的产品是“多普勒400支棉床单”，我们会在购物者使用“棉床单”和“400支床单”等搜索字词时展示广告。'
}, {
  name: '宽泛匹配',
  matchType: 'queryBroadRelMatches',
  tips: '我们会向使用与您的产品松散相关的搜索字词的购物者展示您的广告。 如果您的产品是“多普勒400支棉床单”，我们会在购物者使用“床单”和“大号棉床单”等搜索字词时展示广告。'
}, {
  name: '同类商品',
  matchType: 'asinSubstituteRelated',
  tips: '我们会向使用类似于您产品的产品详细信息页面的购物者展示您的广告。 如果您的产品是“多普勒400支棉床单”，我们会在包括“300支棉床单”和“400支大号床单”的详细信息页面上展示广告。'
}, {
  name: '关联商品',
  matchType: 'asinAccessoryRelated',
  tips: '我们会向查看与您的产品相辅相成的产品详细信息页面的购物者展示您的广告。 如果您的产品是“多普勒400支棉床单”，我们将在包括“大床被”和“羽绒枕”的详细信息页面上展示广告。'
}, {
  name: '类似商品浏览',
  matchType: 'similarProduct',
  tips: '如果买家浏览过与您的商品类似的商品的商品详情页，我们会向其展示您的广告。'
}, {
  name: '商品浏览',
  matchType: 'exactProduct',
  tips: '如果买家浏览过您推广的商品的商品详情页，我们会向其展示您的广告。'
}];
export function getTargetName(row) {
  if (row.targetingType === 'manual') {
    if (row.groupTargetingType === 2 || row.groupTargetingType === 3) {
      if (row.expressionStr && !row.asinSameAs) {
        return row.expressionStr;
      } else {
        return row.asinSameAs;
      }
    } else {
      return row.keywords;
    }
  } else if (row.tactic && row.tactic !== 'remarketing') {
    return '与广告商品相似';
  } else {
    var info = autoTargetNameList.find(function (i) {
      return i.matchType === row.matchType;
    });
    return info ? info.name : '';
  }
}
export function getExtensionTargetName(row, field) {
  if (row.asinSameAs) {
    return row.asinSameAs;
  } else if (row[field] === 'loose-match' || row[field] === '宽泛匹配') {
    return '宽泛匹配';
  } else if (row[field] === 'close-match' || row[field] === '紧密匹配') {
    return '紧密匹配';
  } else if (row[field] === 'substitutes' || row[field] === '同类商品') {
    return '同类商品';
  } else if (row[field] === 'complements' || row[field] === '关联商品') {
    return '关联商品';
  } else if (row[field] !== 'Total') {
    return row[field];
  } else {
    return '*';
  }
}

// 判断多选的时候是否需要显示账号站点这种列
export var checkShowColumn = function checkShowColumn(curSite) {
  // 数组为['']的时候不显示
  if (curSite && curSite.length && curSite.length === 1 && curSite[0]) {
    return false;
  }
  // 数组为['all']的时候不显示
  if (curSite && curSite.length && (curSite[0] + '').toLowerCase() === 'all') {
    return false;
  }
  return true;
};

// 编辑推荐
export var isEditorChoiceRouter = function isEditorChoiceRouter(to) {
  var pathname = location.pathname;
  if (to && to.path) {
    pathname = to.path;
  }
  return ['/console/editorChoice', '/console/editorChoiceIntroduction', '/console/editorChoiceTrans', '/console/editorChoiceDetails'].find(function (item) {
    return pathname === item;
  });
};

// 沃尔玛
export var isSkuNinjaRouter = function isSkuNinjaRouter(to) {
  var pathname = location.pathname;
  if (to && to.path) {
    pathname = to.path;
  }
  return [
    // '/console/introduceSkuNinja',
  ].find(function (item) {
    return pathname === item;
  });
};

// 4K学堂
export var isSchoolRouter = function isSchoolRouter(to) {
  var pathname = location.pathname;
  if (to && to.path) {
    pathname = to.path;
  }
  return ['/console/school', '/console/videoList', '/console/help', '/console/sharing', '/console/ArticleDetails', '/console/videoDetail'].find(function (item) {
    return pathname === item;
  });
};
export var isUserCenterRouter = function isUserCenterRouter(to) {
  var pathname = location.pathname;
  if (to && to.path) {
    pathname = to.path;
  }
  return ['/console/addSubaccount', '/console/editSubAccount', '/console/editSubAccountMule', '/console/editSubAccountMule', '/console/companyManagement', '/console/userCenter', '/console/documentManagement', '/console/subaccount', '/console/roleManagement', '/console/applyLog', '/console/addCompany', '/console/applyInvoice'].find(function (item) {
    return pathname === item;
  });
};
export var analysisRouterObj = {
  '/console/bigDataCtn': '1071',
  '/console/analysisPlugin': '1119',
  '/console/keyHotSearch': '1114',
  '/console/googleTrends': '1235',
  '/console/recommend': '1023',
  '/console/category': '1064',
  '/console/BigDataBrand': '1065',
  '/console/seller': '1066',
  '/console/categoryTrend': '1077',
  '/console/anchor': '1068',
  '/console/dataAnalyDetail': '',
  '/console/proDetails': '',
  '/console/categoryDetails': '',
  '/console/analysisDownloadReport': '1406'
};
export var isAnalysisRouter = function isAnalysisRouter(to) {
  var pathname = location.pathname;
  if (to && to.path) {
    pathname = to.path;
  }
  return _toConsumableArray(Object.keys(analysisRouterObj)).find(function (item) {
    return pathname === item;
  });
};
export var isDashboardRouter = function isDashboardRouter() {
  return !(isEditorChoiceRouter() || isSkuNinjaRouter() || isSchoolRouter() || isUserCenterRouter() || isAnalysisRouter());
};
export var colorList = ['rgba(51, 153, 255, 1)', 'rgba(255, 80, 80, 1)', 'rgba(39, 176, 116, 1)', 'rgba(173, 62, 214, 1)', 'rgba(0, 38, 142, 1)', 'rgba(208, 2, 27, 1)', 'rgba(0, 207, 204, 1)', 'rgba(245, 161, 107, 1)', 'rgba(164, 164, 164, 1)', 'rgba(173, 62, 214, 1)', 'rgba(239, 70, 103, 1)', 'rgba(120, 185, 255, 1)', 'rgba(57, 185, 129, 1)', 'rgba(158, 16, 179, 1)', 'rgba(10, 115, 5, 1)', 'rgba(188, 188, 66, 1)', 'rgba(0, 207, 204, 1)', 'rgba(48, 31, 191, 1)', 'rgba(56, 67, 123, 1)', 'rgba(253, 188, 0, 1)', 'rgba(66, 88, 214, 1)'];
export var colorList06 = ['rgba(51, 153, 255, 0.6)', 'rgba(255, 80, 80, 0.6)', 'rgba(39, 176, 116, 0.6)', 'rgba(173, 62, 214, 0.6)', 'rgba(0, 38, 142, 0.6)', 'rgba(208, 2, 27, 0.6)', 'rgba(0, 207, 204, 0.6)', 'rgba(245, 161, 107, 0.6)', 'rgba(164, 164, 164, 0.6)', 'rgba(173, 62, 214, 0.6)', 'rgba(239, 70, 103, 0.6)', 'rgba(120, 185, 255, 0.6)', 'rgba(57, 185, 129, 0.6)', 'rgba(158, 16, 179, 0.6)', 'rgba(10, 115, 5, 0.6)', 'rgba(188, 188, 66, 0.6)', 'rgba(0, 207, 204, 0.6)', 'rgba(48, 31, 191, 0.6)', 'rgba(56, 67, 123, 0.6)', 'rgba(253, 188, 0, 0.6)', 'rgba(66, 88, 214, 0.6)'];

// 处理请求数据
export var serializeData = function serializeData(obj) {
  var str = '?';
  Object.keys(obj).forEach(function (key) {
    if (obj[key] || obj[key] === 0 || obj[key] === false) {
      var val = obj[key];
      if (typeof val === 'string' && val.indexOf('+') !== -1) {
        val = encodeURIComponent(val);
      }
      str += key + '=' + val + '&';
    }
  });
  var reg = /&$/gi;
  str = str.replace(reg, ''); //清除最后一个&符号
  return str;
};
export var toPrecision = function toPrecision(val, precision) {
  if (!val) return '';
  var v = Number(val);
  var newVal = '';
  if (!isNaN(v)) {
    var pre = precision || 2;
    newVal = parseFloat(Math.round(v * Math.pow(10, pre)) / Math.pow(10, pre));
  }
  return newVal;
};

// 数组静态化
export var optimize = function optimize(array) {
  return array.reduce(function (acc, cur) {
    for (var _i3 = 0, _Object$keys = Object.keys(cur); _i3 < _Object$keys.length; _i3++) {
      var key = _Object$keys[_i3];
      if (key === 'value') continue;
      // 将不是 value 的属性都进行非响应式优化
      Object.defineProperty(cur, [key], {
        enumerable: false
      });
    }
    acc.push(cur);
    return acc;
  }, []);
};

// 根据名称和值 判断趋势图一些属性 是否需要翻转 最大最小值是多少
export var checkChartAttr = function checkChartAttr(name, name_arr) {
  // 是否需要翻转
  var inverse = ['Spend',
  // 广告花费
  'TotalFees', 'amzCost', 'Selling Fees', 'FBA Fees', 'Storage Fees', 'Total Cost', 'BSR', 'bsrCategoryLs'].includes(name);
  var result = {
    inverse: inverse
  };

  // 大小类目比较特殊，最小也是1
  if (['BSR', 'bsrCategoryLs'].includes(name)) {
    result.min = 1;
    result.max = null;
  } else {
    result.min = 0;
    result.max = null;
  }

  // 广告花费负数翻转 全部为0 设置max 让坐标轴在下面
  if (['Spend',
  // 广告花费
  'TotalFees', 'amzCost', 'Selling Fees', 'FBA Fees', 'Storage Fees', 'Total Cost'].includes(name)) {
    // 查找是否全部是0的
    var existMoreZero = name_arr.find(function (item) {
      if (_typeof(item) !== 'object') {
        return item;
      } else {
        return item && item.value;
      }
    });
    // 目地是让坐标轴在最下面
    if (!existMoreZero) {
      result.min = 0;
      result.max = -5;
    } else {
      result.min = null;
      result.max = 0;
    }
  }

  // 毛利 毛利率
  if (['Gross Profits', 'Gross Profit Margin'].includes(name)) {
    result.min = null;
    result.max = null;
  }
  return result;
};

/**
 * 查看广告活动是否同时有关键词投放与商品投放
 * @param advertiseType
 * @returns {boolean}
 */
export var checkCampaignTypeIsKwAndTarget = function checkCampaignTypeIsKwAndTarget(advertiseType) {
  return advertiseType == 0 || advertiseType == 2;
};

/**
 * 获取该站点属于哪个大区
 */
export var siteInRegion = {
  na: ['us', 'ca', 'mx', 'br'],
  eu: ['uk', 'de', 'it', 'fr', 'es', 'nl', 'se', 'tr', 'in', 'ae', 'sa'],
  fe: ['jp', 'au']
};
export var getRegion = function getRegion(site) {
  var regions = siteInRegion;
  var regionKeys = Object.keys(regions);
  for (var i = 0; i < regionKeys.length; i++) {
    if (regions[regionKeys[i]].includes(site)) {
      return regionKeys[i];
    }
  }
};