import Vue from 'vue';
import Vuex from 'vuex';
import mutations from "./mutations";
import actions from "./actions";
var state = {
  entity: null,
  advertiser: null,
  bonusOrderState: null,
  bonusOrderStateIsMounted: false
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};