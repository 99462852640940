export default {
  path: '/dsp/question-manage/answer/:recordId/:roleId/:userId',
  name: 'questionManageAnswer',
  meta: {
    auth: true,
    title: '问卷填写'
  },
  component: function component() {
    return import( /*webpackChunkName:'dsp-account-manage'*/'@/pages/dsp/office-manage/question-manage/detail/answer');
  }
};