import "core-js/modules/es6.string.fixed";
import "core-js/modules/es6.number.constructor";
export default {
  name: 'fk-mask',
  props: {
    visible: Boolean,
    fixed: Boolean,
    background: {
      type: String,
      default: 'rgba(0,0,0,0.5)'
    },
    zIndex: {
      type: Number,
      default: 1998
    }
  },
  computed: {
    getStyle: function getStyle() {
      return {
        position: this.fixed ? 'fixed' : 'absolute',
        background: this.background,
        zIndex: this.zIndex
      };
    }
  }
};