import dayjs from 'dayjs';
var year = dayjs().format('YYYY');
export default {
  locale: 'en-US',
  language: 'English',
  menu: {
    i18n: 'Internationalization',
    home: 'Home',
    salesOverview: 'Sales Overview',
    accountReport: 'Account Report',
    brandReport: 'Brand Report',
    productLineReport: 'ProductLine Report',
    ordersReport: 'Orders Report',
    lineItemsReport: 'LineItems Report',
    creativeReport: 'Creative Report',
    adAnalysis: 'Ad Analysis',
    brands: 'Brands',
    productLines: 'Product Lines',
    asins: 'Asins',
    orders: 'Orders',
    lineItems: 'LineItems',
    creatives: 'Creatives',
    placement: 'Placement',
    brandHalo: 'Brand Halo',
    ntb: 'NTB',
    ntbMenu: 'NTB',
    brandDetails: 'Details',
    productDetails: 'Details',
    asinDetails: 'Details',
    orderDetails: 'Details',
    lineItemDetails: 'Details',
    creativeDetails: 'Details',
    associateOrders: 'Associate Orders',
    associateLineItems: 'Associate line Items'
  },
  system: {
    advertiser: 'Advertiser',
    attributionAdvertiser: 'Attribution advertiser',
    dateRange: 'Date Range',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    last7days: 'Last 7 days',
    last14days: 'Last 14 days',
    last30days: 'Last 30 days',
    last1weeks: 'Last 1 week',
    last3weeks: 'Last 3 week',
    last6weeks: 'Last 6 week',
    last1months: 'Last 1 months',
    last3months: 'Last 3 months',
    last6months: 'Last 6 months',
    all: 'All',
    moreDetails: 'More Details',
    moreMore: 'More Details',
    brand: 'Brand',
    attributionBrand: 'Attribution Brand',
    attributionOrder: 'Attribution Order',
    productLine: 'Product Line',
    creatives: 'Creatives',
    customField: 'Custom field',
    customColumn: 'Custom column',
    custom: 'Custom',
    PCTDCTBD: 'Please check the default columns to be displayed',
    CTDF: 'Check the default field',
    seniorFilter: 'Senior Filter',
    confirm: 'Confirm',
    cancel: 'Cancel',
    empty: 'Empty',
    quickSearch: 'Quick Search',
    others: 'Others',
    other: 'Others',
    total: 'Total',
    pleaseInput: 'Please input',
    pleaseSelect: 'Please select',
    pleaseInputOrders: 'Please input Orders',
    pleaseInputChannel: 'Please input Channel',
    pleaseInputProductLine: 'Please input Product Line',
    pleaseInputLineItem: 'Please input LineItem',
    pleaseSelectPlacement: 'Please select Placement',
    pleaseInputCreatives: 'Please input Creatives',
    pleaseInputAudience: 'Please input Audience',
    pleaseSelectPictureType: 'Please select Placement PictureType',
    search: 'Search',
    reset: 'Reset',
    action: 'Action',
    report: 'report',
    placement: 'Placement',
    pc: 'PC',
    mobile: 'Mobile',
    pcM: 'PC、Mobile',
    searchKeyword: 'Quick Search',
    switch: 'Switch',
    analysis: 'Analysis',
    viewForm: 'View Form',
    viewChart: 'View Chart',
    productData: 'Product Data',
    brandHaloData: 'BH Data',
    funnelStage: 'Funnel stage',
    PutTheCrowd: 'Put the crowd',
    landingPage: 'Landing page',
    optimizationDirection: 'Optimization direction',
    channel: "Channel",
    creativeSize: 'Size',
    creativePictureType: 'Image Type',
    audience: 'Audience',
    analysis1: 'Analysis',
    noData: 'No data',
    site: 'Site',
    conversionFunnel: 'Conversion Funnel'
  },
  page: {
    login: {
      title: 'Login',
      userLogin: 'Login',
      remember: 'Remember me',
      forgot: 'Forgot your password?',
      submit: 'Login',
      other: 'Sign in with',
      signup: 'Sign up',
      DSP: 'DSP Data System',
      account: 'Account',
      password: 'Password',
      ETVC: 'Enter the Verification Code',
      newCode: 'New Code',
      tips: 'It will take approx. 2 weeks for the artificial intelligent DSP system to learn and analysis the effectiveness of its automatic advertising deployment. The longer the advertising time, the more accurate the algorithm and the better the advertising results.',
      PEYN: 'Please enter your Username',
      PEYP: 'Please enter your password',
      IVCPTA: 'Incorrect verification code, please try again',
      IUNOP: 'Incorrect User Name or password',
      copyRight: "Copyright \xA9 2019-".concat(year, " \u98DE\u672A\u4E91\u79D1\uFF08\u6DF1\u5733\uFF09\u6280\u672F\u6709\u9650\u516C\u53F8"),
      LIHE: 'Login information has expired',
      LIVA: 'Login information verification failed',
      TLIIW: 'The login information is wrong, please contact us!',
      clickJumpToLogin: 'Click OK to jump to the login page',
      brandRecognition: 'Brand Recognition',
      productConsiderations: 'Product Considerations',
      brandRepurchase: 'Brand Repurchase',
      productPurchase: 'Product Purchase',
      purchaseIntention: 'Purchase Intention',
      brandRecognitionDescription: "Amazon's unique e-commerce audience + external website with SSP embarked with DSP, covering the entire network traffic",
      productConsiderationsDescription1: 'More accurate placement of the audience’s portraits, and continuous optimization of the population selection through machine learning',
      productConsiderationsDescription2: 'The longer the algorithm runs, the more accurate it is, the better the advertising effect will be',
      brandRepurchaseDescription: 'Locate accurately brand buyer, build customers loyalty',
      productPurchaseDescription: 'Refined remarketing, highly efficient advertising placement',
      purchaseIntentionDescription: 'Directly seize the advertising space of competing Asin products to reach high-quality and high-intent groups'
    },
    register: {
      title: 'Register',
      submit: 'Register',
      other: 'Already have an account?'
    },
    exception: {
      e403: 'Sorry, you don\'t have access to this page.',
      e404: 'Sorry, the page you visited does not exist.',
      e500: 'Sorry, the server is reporting an error.',
      btn: 'Back to home',
      noAdvertiser: 'Please contact customer service staff to authorize advertisers~',
      subNoAdvertiser: 'Please contact your parent account，go to【用户中心 - 子账号管理】，and grant the DSP data system permission ~',
      TMIS: 'The modification is successful',
      TMISContent: 'Jump to the login page after 3S'
    },
    i18n: {
      content: 'Hello, nice to meet you!'
    },
    home: {
      cost: 'Cost',
      awareness: 'Awareness',
      consideration: 'Consideration',
      conversion: 'Conversion',
      salesAnalysis: 'Sales Analysis',
      offAmazon: 'Off Amazon',
      inAmazon: 'In  Amazon',
      ohterAmazon: 'Amazon exclusive：<br /> AMS, Lifetime Snss and so on.',
      brandHaloProducts: 'Brand Halo Products',
      promotedProductData: 'Promoted Data',
      totalReturn: 'Total Return',
      returnSource: 'Return Source',
      performanceBreakDown: 'Performance BreakDown',
      totalDspSaleAmount: 'Total Sales',
      totalAdCost: 'Total Cost',
      totalRoas: 'Total ROAS',
      totalECPDPV: 'Total eCPDPV'
    },
    accountReport: {
      overview: 'Overview',
      brand: 'Brand',
      productLine: 'Product Line',
      order: 'Orders',
      lineItem: 'Line Items',
      creative: 'Creatives'
    },
    bhTableHeader: {
      promotedProductData: 'Promoted Data',
      bhProduct: 'BH Product',
      total: 'Total'
    },
    tbHeader: {
      brand: 'Awareness',
      cost: 'Cost',
      adProductData: 'Advertising Product Data',
      totalAdData: 'Total Advertising Data',
      bhProductData: 'BH Product Data',
      consideration: 'Consideration',
      tb: 'Total',
      ntb: 'NTB',
      otb: 'OTB',
      totalTb: 'Total'
    }
  },
  fields: {
    reportDate: 'Date',
    belongWeek: 'Date',
    belongMonth: 'Date',
    action: 'Action',
    total: "Total",
    asin: "Asin",
    atc: "ATC",
    atcr: "ATCR",
    atl: "ATL",
    brandAtc: "BH ATC",
    brandAtl: "BH ATL",
    brandDpv: "BH DPV",
    brandDpvr: "BH DPVR",
    brandDspSaleAmount: "BH Sales",
    brandDspSaleCount: "BH Units Sold",
    brandECPDPV: "BH eCPDPV",
    brandHalo: "Brand halo(BH)",
    brandName: "Brand",
    brands: "Brand",
    brandNewDspSaleAmount: "BH NTB Sales",
    brandNewDspSaleCount: "BH NTB Units Sold",
    brandNewPurchases: "BH NTB Purchases",
    brandNewRoas: "NTB BH ROAS",
    brandNewRpm: "BH NTB RPM",
    brandOldDspSaleAmount: "BH OTB Sales",
    brandOldDspSaleCount: "BH OTB Units Sold",
    brandOldPurchases: "BH OTB Purchases",
    brandOldRoas: "BH OTB ROAS",
    brandOldRpm: "BH OTB RPM",
    brandPrpv: "BH PRPV",
    brandPurchases: "BH Purchases",
    brandSnss: "BH SnSS",
    channel: "Supply Source",
    clickCount: "Click-throughs",
    cpm: "CPM",
    creative: "Creatives",
    ctr: "CTR",
    dpv: "DPV",
    dpvr: "DPVR",
    dspSaleAmount: "Sales",
    dspSaleCount: "Units Sold",
    eCPDPV: "eCPDPV",
    impressions: "Impressions",
    intervalEnd: "Interval End",
    intervalStart: "Interval Start",
    lineItem: "Line Items",
    newDspSaleAmount: "NTB Sales",
    newDspSaleCount: "NTB Units Sold",
    newPurchases: "NTB Purchases",
    newPurchasesRate: "NTB PR",
    newRoas: "NTB ROAS",
    newRpm: "NTB RPM",
    newToBrandPurchases: "NTB Purchases",
    newToBrandPurchasesRate: "NTB PR",
    oldDspSaleAmount: "OTB Sales",
    oldDspSaleCount: "OTB Units Sold",
    oldPurchases: "OTB Purchases",
    oldPurchasesRate: "Purchases Rate",
    oldRoas: "OTB ROAS",
    oldRpm: "OTB RPM",
    order: "Order",
    productLine: "Product Line",
    products: "Product Line",
    prpv: "PRPV",
    purchases: "Purchases",
    pr: "PR",
    roas: "ROAS",
    rpm: "RPM",
    salesRate: "Sales Rate",
    segment: "Segment",
    snss: "SnSS",
    totalAdCost: "Total Cost",
    totalAtc: "Total ATC",
    totalAtcr: "Total ATCR",
    totalAtl: "Total ATL",
    totalDpv: "Total DPV",
    totalDpvr: "Total DPVR",
    totalDspSaleAmount: "Total Sales",
    totalDspSaleCount: "Total Units Sold",
    totalECPDPV: "Total eCPDPV",
    totalNewDspSaleAmount: "Total NTB Sales",
    totalNewDspSaleCount: "Total NTB Units Sold",
    totalNewPurchases: "Total NTB Purchases",
    totalNewRoas: "Total NTB ROAS",
    totalNewRpm: "Total NTB RPM",
    totalOldDspSaleAmount: "Total OTB Sales",
    totalOldDspSaleCount: "Total OTB Units Sold",
    totalOldPurchases: "Total OTB Purchases",
    totalOldRoas: "Total OTB ROAS",
    totalOldRpm: "Total OTB RPM",
    totalPrpv: "Total PRPV",
    totalPurchases: "Total Purchases",
    totalPr: "Total PR",
    totalRoas: "Total ROAS",
    totalRpm: "Total RPM",
    totalSalesRate: "Total Sales Rate",
    totalSnss: "Total SnSS",
    tpFees: "3P Fees",
    creativeSize: 'Size',
    creativePictureType: 'Image Type',
    actionAsin: 'Action',
    amazonLink: 'Amazon Link',
    lineItemType: 'Placement',
    ntb: 'NTB',
    otb: 'OTB',
    promotedProductData: 'Promoted Product Data',
    brandHaloData: 'Brand Halo(BH) Data',
    totalNtb: 'Total NTB',
    totalOtb: 'Total OTB',
    bhNtb: 'BH NTB',
    bhOtb: 'BH OTB',
    videoStart: 'Video start',
    videoComplete: 'Video complete',
    vcr: 'VCR',
    eCPVC: 'eCPVC',
    purchasesU: "Purchases",
    dspSaleCountU: "Units Sold",
    // 通用
    dspSaleAmountU: "Sales",
    advertiserName: 'Advertiser'
  },
  fieldsTip: {
    total: "This includes views for products from the promoted products and same brands as the products tracked in the order. (Total data = DSP data + data BH)(Total data = DSP data + data BH) （Total data = NTB data + OTB data )",
    clickToAmazon: 'Click the Open Amazon product link.',
    creativeSize: 'Size of Creatives Image',
    intervalStart: "The  interval start and end are determined by the  date range selected when requesting the data. It is based on the first and last days of delivery.ex. “Last 30 days” will use those start and end dates as the interval)",
    intervalEnd: 'The  interval start and end are determined by the  date range selected when requesting the data. It is based on the first and last days of delivery.ex. “Last 30 days” will use those start and end dates as the interval)',
    NTB: "first-time conversion within the brand over a one-year lookback window.Note: New-to-brand data is available from November 1st, 2018. Selecting a date range prior to November 1st will result in incomplete or inaccurate new-to-brand reporting. ",
    OTB: "first-time conversion without the brand over a one-year lookback window.Note: Old-to-brand data is available from November 1st, 2018. Selecting a date range prior to November 1st will result in incomplete or inaccurate old-to-brand reporting.  ",
    Promotedproduct: "The products that is running DSP ad campaigns.",
    Total: "This includes views for products from the promoted products and same brands as the products tracked in the order. (Total data = DSP data + data BH)(Total data = DSP data + data BH) （Total data = NTB data + OTB data )",
    atc: "Add to Cart (ATC).The number of times a promoted ASIN is added to a customer's cart.",
    atcr: "Add to Cart rate (ATCR).The number of Add to Cart conversions relative to the number of impressions. (ATCR = ATC / Impressions)",
    atl: "Add to List (ATL).The number of times a promoted ASIN is added to a customer's wish list, gift list or registry.",
    brandAtc: "Brand Halo Add to Carts (BH ATC).The number of times an ASIN is added to a customer's cart. This counts adds for products from the same brands as the products tracked in the order. (ATC BH=Total ATC -ATC)",
    brandAtl: "Brand Halo Add to List (BH ATL).The number of ad-attributed product review page views on Amazon. This includes views on either the Read All Reviews page or individual review pages. This counts views for products from the same brands as the products tracked in the order. (PRPV BH=Total PRPV-PRPV )",
    brandDpv: "  Brand Halo detail page views (BH DPV).The number of ad-attributed detail page views on Amazon. This includes views for = products from the same brands as the products tracked in the order. ( BH DPV =Total DPV-DPV)",
    brandDpvr: "The number of brand halo detail page view conversions relative to the number of ad impressions. This includes views for = products from the same brands as the products tracked in the order. (BH DVPR =BH DPV Impressions)",
    brandDspSaleAmount: "Brand halo Product Sales(BH Product Sales).The total sales (in local currency) of products from the same brand as products purchased by customers on Amazon after delivering an ad.( product sales BH=Total sales- product sales)",
    brandDspSaleCount: "Brand halo units sold(BH units sold).The total quantity of products from the same brand as products purchased by customers on Amazon after delivering an ad. A campaign can have multiple units sold in a single purchase event.(units sold BH=Total units sold- units sold )",
    brandECPDPV: "The average cost to acquire a detail page view conversion. (eCPDPV = Total Cost/DPV)",
    brandHalo: "Brand Haldata (BH data). This includes views for = products from the same brands as the products tracked in the order. ( BH data=Total data -Promoted products data)",
    brandName: "Brand in Amazon Platform",
    brands: "Brand in Amazon Platform",
    brandNewDspSaleAmount: "Brand halo new-to-brand product sales(BH NTB product sales).The total sales (in local currency) of brand halo products purchased for the first time over a one-year lookback window by customers on Amazon.(BH NTB product sales=Total NTB product sales - NTB product sales)",
    brandNewDspSaleCount: "Brand halo New-to-brand units sold(BH NTB units sold).The quantity of brand halo products purchased for the first time within the brand over a one-year lookback period after delivering an ad. A campaign can have multiple units sold in a single purchase event.（BH NTB units sold = Total NTB units sold - NTB units sold)",
    brandNewPurchases: "Brand Halo new-to-brand purchases(BH NTB purchases).The number of first-time purchases for brand halo products within the brand over a one-year lookback window. Purchases include Subscribe & Save subscriptions and video rentals.(BH NTB purchases= Total NTB purchases - NTB purchases)",
    brandNewRoas: "Brand Halo new-to-brand return on advertising spend (BH NTB ROAS).Ad-attributed new-to-brand purchases of brand halo products per local currency unit of ad spend (BH NTB ROAS = BH NTB sales Total cost).",
    brandNewRpm: "Brand halo new-to-brand effective return per thousand impressions (BH NTB RPM).The average new-to-brand revenue generated per thousand impressions. This includes new-to-brand revenue for products from the same brands as the products tracked in orders. (BH NTB RPM = BH NTB sales (Impressions 1000))    ",
    brandOldDspSaleAmount: "Brand halo old-to-brand product sales(BH OTB product sales).The total sales (in local currency) of brand halo products purchased for the not first time over a one-year lookback window by customers on Amazon.(BH OTB product sales=Total OTB product sales - OTB product sales)",
    brandOldDspSaleCount: "Brand halo Old-to-brand units sold(BH OTB units sold).The quantity of brand halo products purchased for the not first time within the brand over a one-year lookback period after delivering an ad. A campaign can have multiple units sold in a single purchase event.（BH OTB units sold = Total OTB units sold - OTB units sold)",
    brandOldPurchases: "Brand Halo old-to-brand purchases(BH OTB purchases).The number of not first-time purchases for brand halo products within the brand over a one-year lookback window. Purchases include Subscribe & Save subscriptions and video rentals.(BH OTB purchases= Total OTB purchases - OTB purchases)",
    brandOldRoas: "Brand Halo old-to-brand return on advertising spend (BH OTB ROAS).Ad-attributed new-to-brand purchases of brand halo products per local currency unit of ad spend (BH OTB ROAS = BH OTB sales Total cost).",
    brandOldRpm: "Brand halo old-to-brand effective return per thousand impressions (BH OTB RPM).The average old-to-brand revenue generated per thousand impressions. This includes new-to-brand revenue for products from the same brands as the products tracked in orders. (BH OTB RPM = BH OTB sales (Impressions 1000))",
    brandPrpv: "Brand Halo product review page views (BH PRPV).The number of ad-attributed product review page views on Amazon. This includes views on either the Read All Reviews page or individual review pages. This counts views for products from the same brands as the products tracked in the order. (PRPV BH=Total PRPV - PRPV)",
    brandPurchases: "Brand Halo purchases（BH purchases).The number of times any number of products are included in a single purchase event. Purchase events include Subscribe & Save subscriptions and video rentals. This counts purchases for products from the same brands as the products tracked in the order. (Purchases BH=Total purchases - Purchases )",
    brandSnss: "Brand halo Subscribe & Save subscriptions(BH SnSS).The number of Total new Subscribe & Save subscriptions attributed to a view or click on an ad. This includes subscriptions for products from the same brands as the products tracked in the order. ( SnSS BH=Total SnS -SnSS)",
    channel: "Supply Source of DSP ad. It is set in line items. This includes site type for on Amazon and off Amazon. This includes inventory type for web and mobile.",
    clickCount: "A click on an ad that directs the user to a destination outside of the creative.",
    cpm: "Effective cost per thousand (CPM).The total cost per thousand impressions.（CPM=Total Cost/(impression/1000)）",
    creative: "Creative Image",
    ctr: "Click-through rate.The number of click-throughs relative to the number of impressions.（CTR=Click-throughs/Impression）",
    dpv: "Detail page views (DPV).The number of views of the advertised product's detail pages on Amazon.",
    dpvr: "The number of detail page view conversions relative to the number of ad impressions. (DVPR = DPV / Impressions)",
    dspSaleAmount: "The total sales (in local currency) of products purchased by customers on Amazon after delivering an ad.",
    dspSaleCount: "The total quantity of products purchased by customers on Amazon after delivering an ad. A campaign can have multiple units sold in a single purchase event.",
    eCPDPV: "Effective cost per detail page view (eCPDPV).The average cost to acquire a detail page view conversion. (eCPDPV = Total cost / DPV)",
    impressions: "The number of times an ad was displayed.",
    newDspSaleAmount: "New-to-brand product sales.The total sales (in local currency) of products purchased for the first time over a one-year lookback window by customers on Amazon.",
    newDspSaleCount: "New-to-brand units sold.The quantity of products purchased for the first time within the brand over a one-year lookback period after delivering an ad. A campaign can have multiple units sold in a single purchase event.",
    newPurchases: "New-to-brand purchases.The number of first-time purchases for products within the brand over a one-year lookback window. Purchases include Subscribe & Save subscriptions and video rentals",
    newPurchasesRate: "Percent of purchases new-to-brand.The percent of purchases that were first-time purchases for products within the brand over a one-year lookback window. Purchases include Subscribe & Save subscriptions and video rentals.(Percent of purchases NTB=NTB purchases/purchases)",
    newRoas: "New-to-brand return on advertising spend （new-to-brand ROAS).Ad-attributed new-to-brand purchases per local currency unit of ad spend (NTB ROAS =NTB product sales/Total cost).",
    newRpm: "New-to-brand Effective return per thousand impressions (NTB RPM).The average revenue generated per thousand impressions by purchasing a product for the first time over a one-year lookback period. (NTB RPM = NTB Sales/(Impressions/1000)).",
    newToBrandPurchases: "New-to-brand purchases.The number of first-time purchases for products within the brand over a one-year lookback window. Purchases include Subscribe & Save subscriptions and video rentals.",
    newToBrandPurchasesRate: "Percent of purchases new-to-brand.The percent of purchases that were first-time purchases for products within the brand over a one-year lookback window. Purchases include Subscribe & Save subscriptions and video rentals.(Percent of purchases NTB=NTB purchases/purchases)",
    oldDspSaleAmount: "Old-to-brand product sales.The total sales (in local currency) of products purchased for the not first time over a one-year lookback window by customers on Amazon.(OTB product sales = product sales - NTB product sales)",
    oldDspSaleCount: "Old-to-brand units sold.The quantity of products purchased for the not first time within the brand over a one-year lookback period after delivering an ad. A campaign can have multiple units sold in a single purchase event.(OTB units sold = units sold - NTB units sold)",
    oldPurchases: "Old-to-brand purchases.The number of not first-time purchases for products within the brand over a one-year lookback window. Purchases include Subscribe & Save subscriptions and video rentals.(OTB purchases=purchases- NTB purchases)",
    oldPurchasesRate: "Old-to-brand purchases.The number of not first-time purchases for products within the brand over a one-year lookback window. Purchases include Subscribe & Save subscriptions and video rentals.(OTB purchases=purchases- NTB purchases)",
    oldRoas: "Old-to-brand return on advertising spend （OTB ROAS).Ad-attributed Old-to-brand purchases per local currency unit of ad spend (OTB ROAS =OTB product sales/Total cost).",
    oldRpm: "Old-to-brand Effective return per thousand impressions (OTB RPM).The average revenue generated per thousand impressions by purchasing a product for the not first time over a one-year lookback period. (OTB RPM = OTB Sales (Impressions/1000)).",
    prpv: "Product review page views (PRPV).The number of views of the product's customer review pages on Amazon. This includes views on either the Read All Reviews page or individual review pages.",
    purchases: "The number of times any amount of the products are included in a purchase event. Purchase events include video rentals and new Subscribe & Save subscriptions.",
    pr: "The number of purchases relative to the number of impressions. (Purchase rate =Purchases / Impressions)",
    roas: "Return on advertising spend (ROAS).Ad-attributed product sales per local currency unit of ad spend. (ROAS = sales / Total cost)",
    rpm: "Effective return per thousand impressions (RPM)，The average revenue generated per thousand impressions. (RPM = Sales (Impressions/1000))",
    salesRate: "Sales Rate=Purchases/Units sold",
    segment: "Audience group. By targeting a specific group which have similiar behaviors or characteristic as an Audience group, Dsp can promote ad to targeted visitor.",
    snss: "Subscribe & Save subscriptions (SnSS).The number of new Subscribe & Save subscriptions attributed to a view or click on an ad. This count does not include orders of the ASIN(s) from replenishment subscription orders. New subscriptions are also included in the Purchase count.",
    totalAdCost: "The total amount of money spent on running the campaign",
    totalAtc: "Total Add to Carts (Total ATC).The number of times an ASIN is added to a customer's cart. This counts adds for products as well as products from the same brands as the products tracked in the order. (Total ATC = ATC + ATC BH)",
    totalAtcr: "Total Add to Cart rate (ATCR).The number of Add to Cart conversions relative to the number of impressions. (Total ATCR = Total ATC / Impressions)",
    totalAtl: "Total Add to List (Total ATL).The number of ad-attributed product review page views on Amazon. This includes views on either the Read All Reviews page or individual review pages. This counts views for products as well as products from the same brands as the products tracked in the order. (Total PRPV = PRPV + PRPV BH)",
    totalDpv: "Total detail page views (Total DPV).The number of ad-attributed detail page views on Amazon. This includes views for products as well as products from the same brands as the products tracked in the order. (Total DPV = DPV + DPV BH)",
    totalDpvr: "The number of ad-attributed detail page view conversions relative to the number of ad impressions.This includes views for products as well as products from the same brands as the products tracked in the order. (Total DVPR = Total DPV Impressions)",
    totalDspSaleAmount: "The total sales (in local currency) of promoted products and products from the same brand as promoted products purchased by customers on Amazon after delivering an ad.(Total sales = product sales + product sales BH)",
    totalDspSaleCount: "The total quantity of promoted products and products from the same brand as promoted products purchased by customers on Amazon after delivering an ad. A campaign can have multiple units sold in a single purchase event.(Total units sold = units sold + units sold BH)",
    totalECPDPV: "The average cost to acquire a Total detail page view conversion. This includes the cost to acquire a Detail page view conversion for promoted products as well as products from the same brands as the products tracked in the order. (Total eCPDPV = Total cost / Total DPV)",
    totalNewDspSaleAmount: "Total new-to-brand product sales(Total NTB product sales).The total sales (in local currency) of and brand halo products purchased for the first time over a one-year lookback window by customers on Amazon.",
    totalNewDspSaleCount: "Total New-to-brand units sold(Total NTB units sold).The quantity of and brand halo products purchased for the first time within the brand over a oneindicatorTypes:[indicatorTypes.Total],-year lookback period after delivering an ad. A campaign can have mul tiple units sold in a single purchase event.",
    totalNewPurchases: "Total new-to-brand purchases(Total NTB purchases).The number of first-time purchases for or brand halo products within the brand over a one-year lookback window. Purchases include Subscribe & Save subscriptions and video rentals.",
    totalNewRoas: "Total new-to-brand return on advertising spend (Total new-to-brand ROAS).Ad-attributed new-to-brand purchases per local currency unit of ad spend (Total new-to-brand ROAS = Total new-to-brand sales Total cost). This includes purchases for both and brand halo products.",
    totalNewRpm: "Total new-to-brand effective return per thousand impressions (Total new-to-brand RPM).The average new-to-brand revenue generated per thousand impressions. This includes new-to-brand revenue for products tracked in orders and products from the same brands as the products tracked in orders. (Total new-to-brand RPM = Total new-to-brand sales/(Impressions/1000))",
    totalOldDspSaleAmount: "Total old-to-brand product sales(Total OTB product sales).The total sales (in local currency) of and brand halo products purchased for not the first time over a one-year lookback window by customers on Amazon.（Total OTB products sales = Total sales-Total NTB products sales)",
    totalOldDspSaleCount: "Total Old-to-brand units sold(Total OTB units sold).The quantity of and brand halo products purchased for not the first time within the brand over indicatorTypes:[indicatorTypes.Total],a one-year lookback period after delivering an ad. A campaign can have mul tiple units sold in a single purchase event.(Total OTB units sold = Total units sold -Total NTB units sold ）",
    totalOldPurchases: "Total old-to-brand purchases(Total OTB purchases).The number of first-time purchases for or brand halo products without the brand over a one-year lookback window. Purchases include Subscribe & Save subscriptions and video rentals.(Total OTB purchases = Total purchases - Total NTB purchases)",
    totalOldRoas: "  Total old-to-brand return on advertising spend (Total OTB ROAS).Ad-attributed old-to-brand purchases per local currency unit of ad spend (Total OTB ROAS = Total OTB sales Total cost). This is for brand halo products.",
    totalOldRpm: "Total old-to-brand effective return per thousand impressions (Total OTB RPM).The average old-to-brand revenue generated per thousand impressions. This includes old-to-brand revenue for products tracked in orders and products from the same brands as the products tracked in orders. (Total OTB RPM = Total OTB sales/(Impressions/1000))",
    totalPrpv: "Total product review page views (Total PRPV).The number of ad-attributed product review page views on Amazon. This includes views on either the Read All Reviews page or individual review pages. This counts views for products as well as products from the same brands as the products tracked in the order. (Total PRPV = PRPV + PRPV BH)",
    totalPurchases: "The number of times any number of products are included in a single purchase event. Purchase events include Subscribe & Save subscriptions and video rentals. This counts purchases for products as well as products from the same brands as the products tracked in the order. (Total purchases = Purchases + Purchases BH)",
    totalPr: "The number of purchases relative to the number of impressions. (Total Purchase rate = Total Purchases / Impressions)",
    totalRoas: "Total return on advertising spend (Total ROAS).Ad-attributed product sales per local currency unit of ad spend. Includes both sales of promoted products tracked in orders and products from the same brands as the products tracked (Total ROAS = Total Sales / Total cost)",
    totalRpm: " Total effective return per thousand impressions (Total RPM).The average revenue generated per thousand impressions. This includes revenue for promoted products tracked in orders and products from the same brands as the products tracked in orders. (Total RPM = Total sales / (Impressions / 1000))",
    totalSalesRate: "Total Sales Rate=Total Units sold/Total Purchases",
    totalSnss: "Total Subscribe & Save subscriptions(Total SnSS).The number of Total new Subscribe & Save subscriptions attributed to a view or click on an ad. This includes subscriptions for products as well as products from the same brands as the products tracked in the order. (Total SnS = SnSS+ SnSS BH)",
    tpFees: "Third party (3P) fees.The total CPM charges applied for using 3P data providers.",
    ntb: 'New-to brand（NTB）.first-time conversion within the brand over a one-year lookback window.Note: New-to-brand data is available from November 1st, 2018. Selecting a date range prior to November 1st will result in incomplete or inaccurate new-to-brand reporting.',
    otb: 'Old-to-brand (OTB).first-time conversion without the brand over a one-year lookback window.Note: Old-to-brand data is available from November 1st, 2018. Selecting a date range prior to November 1st will result in incomplete or inaccurate old-to-brand reporting.',
    promotedProductData: 'The promoted products that is running DSP ad campaigns.',
    brandHaloData: 'Brand Haldata (BH data). This includes views for = products from the same brands as the products tracked in the order. ( BH data=Total data -Promoted products data)',
    totalNtb: 'Total New-to brand（NTB）.first-time conversion within the brand over a one-year lookback window.This includes views for products from the promoted products and same brands as the products tracked in the order. ',
    totalOtb: 'Total Old-to brand（OTB）.first-time conversion without the brand over a one-year lookback window.This includes views for products from the promoted products and same brands as the products tracked in the order. ',
    bhNtb: 'Brand Halo New-to brand（NTB）.first-time conversion within the brand over a one-year lookback window.This includes views for = products from the same brands as the products tracked in the order. ',
    bhOtb: 'Brand Halo Old-to brand（OTB）.first-time conversion without the brand over a one-year lookback window.This includes views for = products from the same brands as the products tracked in the order. ',
    videoStart: 'The number of times a video ad was started.',
    videoComplete: 'The number of times a video ad played to completion. If rewind occurred, completion was calculated on the total percentage of unduplicated video viewed.',
    vcr: 'Video completion rate. The number of video completions relative to the number of video starts. (Video completion rate = Video complete / Video start)',
    eCPVC: 'Effective cost per Video complete.The average cost to acquire a Video complete conversion (eCPVC = Total cost / Video complete)',
    dpvU: "Detail page views (DPV).The number of views of the product's detail pages on Amazon.",
    atlU: "Add to List (ATL).The number of times product is added to a customer's wish list, gift list or registry.",
    atcU: "Add to Cart (ATC).The number of times the product is added to a customer's cart.",
    purchasesU: "The number of times any amount of the products are included in a purchase event. Purchase events include video rentals and new Subscribe & Save subscriptions.",
    dspSaleCountU: "The total quantity of products purchased by customers on Amazon after delivering an ad. A campaign can have multiple units sold in a single purchase event.",
    dspSaleAmountU: "The total sales (in local currency) of products purchased by customers on Amazon after delivering an ad."
  }
};