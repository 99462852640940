import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.find";
var tooltip = [{
  name: 'Brand',
  key: 'brand',
  type: 'GroupBy'
}, {
  name: 'Product line',
  key: 'productLine',
  type: 'GroupBy'
}, {
  name: 'Funnel',
  key: 'funnel',
  type: 'GroupBy'
}, {
  name: 'Audience',
  key: 'audience',
  type: 'GroupBy'
}, {
  name: 'Landing page',
  key: 'landingPage',
  type: 'GroupBy'
}, {
  name: 'Goal KPI',
  key: 'goalKPI',
  type: 'GroupBy'
}, {
  name: 'Creative',
  key: 'creative',
  type: 'GroupBy'
}, {
  name: 'Inventory',
  key: 'inventory',
  type: 'GroupBy'
}, {
  name: 'Device',
  key: 'device',
  type: 'GroupBy'
}, {
  "name": "Advertiser",
  "property": "string",
  "tooltip": "The name for the advertiser that will appear in AAP.",
  "type": "General",
  "key": "advertiserName"
}, {
  "name": "Interval",
  "property": "string",
  "tooltip": "The selected measure of time between data points, for example daily, weekly, biweekly, or monthly.",
  "type": "General",
  "key": "belongDate"
}, {
  "name": "Agency fee",
  "property": "string",
  "tooltip": "A percentage removed from the total budget to compensate the agency managing the media buy.",
  "fixedNumber": 2,
  "type": "Costs",
  "key": "agencyFee"
}, {
  "name": "Amazon DSP console fee",
  "property": "string",
  "tooltip": "The technology fee applied to the media supply costs.",
  "fixedNumber": 2,
  "type": "Costs",
  "key": "amazonPlatformFee"
}, {
  "name": "Amazon DSP audience fee",
  "property": "string",
  "tooltip": "CPM charge applied to impressions that leverage Amazon's behavioral targeting.",
  "fixedNumber": 2,
  "type": "Costs",
  "key": "amazonAudienceFee"
}, {
  "name": "3P fees",
  "property": "string",
  "tooltip": "The total CPM charges applied for using 3P data providers.",
  "fixedNumber": 2,
  "type": "Costs",
  "key": "x3PFees"
}, {
  "name": "Total cost",
  "property": "string",
  "tooltip": "The total amount of money spent on running the campaign not including 3P fees paid by the agency.",
  "fixedNumber": 2,
  "type": "Costs",
  "key": "totalCost"
}, {
  "name": "Supply cost",
  "property": "string",
  "tooltip": "The total amount of money spent on media supply.",
  "fixedNumber": 2,
  "type": "Costs",
  "key": "supplyCost"
}, {
  "name": "Impressions",
  "property": "number",
  "tooltip": "The number of times the ad was displayed, excluding general invalid traffic or more. A traffic quality filter removed the potentially fraudulent, non-human, and other illegitimate impressions.",
  "fixedNumber": 0,
  "type": "Traffic",
  "key": "impressions"
}, {
  "name": "Viewable impressions",
  "property": "string",
  "tooltip": "Based on MRC standard, the number of times an ad was viewed by customers. An ad is counted as 'viewable' when atleast 50% of the ad shows on screen for one second or longer for display ads and two seconds or longer for video ads. Viewability metrics are available from October 1, 2019. Selecting a date range prior to October 1, 2019 will result in incomplete or inaccurate metrics.",
  "fixedNumber": 0,
  "type": "Traffic",
  "key": "viewableImpressions"
}, {
  "name": "Measurable impressions",
  "property": "string",
  "tooltip": "Number of impressions that were measured for viewability. The viewability metrics are available from October 1, 2019. Selecting a date range prior to October 1, 2019 will result in incomplete or inaccurate metrics.",
  "fixedNumber": 0,
  "type": "Traffic",
  "key": "measurableImpressions"
}, {
  "name": "Measurable rate",
  "property": "string",
  "tooltip": "The viewability metrics are available from October 1, 2019. Selecting a date range prior to October 1, 2019 will result in incomplete or inaccurate metrics.　Measurable rate = Measurable impressions / total impressions. ",
  "fixedNumber": 4,
  "type": "Traffic, Rates",
  "key": "measurableRate"
}, {
  "name": "Viewability rate",
  "property": "string",
  "tooltip": "The viewability metrics are available from October 1, 2019. Selecting a date range prior to October 1, 2019 will result in incomplete or inaccurate metrics. Viewability rate = Viewable impressions / measurable impressions. ",
  "fixedNumber": 4,
  "type": "Traffic, Rates",
  "key": "viewabilityRate"
}, {
  "name": "Click-throughs",
  "property": "string",
  "tooltip": "The number of times the ad was clicked, excluding general invalid traffic or more. A traffic quality filter removed potentially fraudulent, non-human, and other illegitimate clicks.",
  "fixedNumber": 0,
  "type": "Traffic",
  "key": "clickThroughs"
}, {
  "name": "CTR",
  "property": "percentage",
  "tooltip": "The number of click-throughs relative to the number of impressions.",
  "fixedNumber": 4,
  "type": "Rates, Traffic",
  "key": "ctr"
}, {
  "name": "eCPM",
  "property": "number",
  "tooltip": "The total cost per thousand impressions.",
  "fixedNumber": 2,
  "type": "Costs, Traffic",
  "key": "eCPM"
}, {
  "name": "eCPC",
  "property": "number",
  "tooltip": "The amount of money paid per click-through.",
  "fixedNumber": 2,
  "type": "Costs, Traffic",
  "key": "eCPC"
}, {
  "name": "Off-Amazon product sales",
  "property": "money",
  "tooltip": "Sales (in local currency) for off-Amazon purchases, attributed to an ad view or click.",
  "fixedNumber": 2,
  "type": "Off-Amazon conversions",
  "key": "offAmazonProductSales"
}, {
  "name": "Off-Amazon units sold",
  "property": "string",
  "tooltip": "Units of product purchased off Amazon, attributed to an ad view or click. A single purchase event can include multiple sold units.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "offAmazonUnitsSold"
}, {
  "name": "Off-Amazon ROAS",
  "property": "string",
  "tooltip": "Return on advertising spend for products included in off-Amazon purchase events, measured as ad-attributed sales per local currency unit of ad spend. (Off-Amazon ROAS = Off-Amazon product sales / Total cost)",
  "fixedNumber": 2,
  "type": "Off-Amazon conversions",
  "key": "offAmazonROAS"
}, {
  "name": "Off-Amazon eRPM",
  "property": "money",
  "tooltip": "Effective (average) revenue for sales included in off-Amazon purchases generated per thousand impressions. (Off-Amazon eRPM = Off-Amazon sales / (Impressions / 1000))",
  "fixedNumber": 2,
  "type": "Off-Amazon conversions",
  "key": "offAmazonERPM"
}, {
  "name": "Combined purchases",
  "property": "string",
  "tooltip": "Number of purchase events on and off Amazon, attributed to an ad view or click. (Combined purchases = Off-Amazon purchases + Total purchases (Amazon))",
  "fixedNumber": 0,
  "type": "Combined conversions",
  "key": "combinedPurchases"
}, {
  "name": "Combined purchases views",
  "property": "string",
  "tooltip": "Number of purchase events on and off Amazon, attributed to an ad view. (Combined purchases views = Off-Amazon purchases views + Total purchases views (Amazon))",
  "fixedNumber": 0,
  "type": "Combined conversions",
  "key": "combinedPurchasesViews"
}, {
  "name": "Combined purchases clicks",
  "property": "string",
  "tooltip": "Number of purchase events on and off Amazon, attributed to an ad click. (Combined purchases clicks = Off-Amazon purchases clicks + Total purchases clicks (Amazon))",
  "fixedNumber": 0,
  "type": "Combined conversions",
  "key": "combinedPurchasesClicks"
}, {
  "name": "Combined purchase rate",
  "property": "percentage",
  "tooltip": "Rate of attributed purchase events on and off Amazon, relative to ad impressions. (Combined purchase rate = Combined purchases / Impressions)",
  "fixedNumber": 4,
  "type": "Rates Combined conversions",
  "key": "combinedPurchaseRate"
}, {
  "name": "Combined eCPP",
  "property": "money",
  "tooltip": "Effective (average) cost to acquire a purchase conversion on or off Amazon. (Combined eCPP = Total cost / Combined purchases)",
  "fixedNumber": 2,
  "type": "Combined conversions",
  "key": "combinedECPP"
}, {
  "name": "Combined product sales",
  "property": "money",
  "tooltip": "Sales (in local currency) for purchases on and off Amazon, attributed to an ad view or click.",
  "fixedNumber": 2,
  "type": "Combined conversions",
  "key": "combinedProductSales"
}, {
  "name": "Combined units sold",
  "property": "string",
  "tooltip": "Units of product sold on and off Amazon, attributed to an ad view or click. A single purchase event can include multiple sold units.",
  "fixedNumber": 0,
  "type": "Combined conversions",
  "key": "combinedUnitsSold"
}, {
  "name": "Combined ROAS",
  "property": "string",
  "tooltip": "Return on advertising spend for products sold on and off Amazon, measured as ad-attributed sales per local currency unit of ad spend. (Combined ROAS = Combined product sales / Total cost)",
  "fixedNumber": 2,
  "type": "Combined conversions",
  "key": "combinedROAS"
}, {
  "name": "Combined eRPM",
  "property": "money",
  "tooltip": "Effective (average) revenue for sales on and off Amazon generated per thousand impressions. (Combined eRPM = Combined Sales / (Impressions / 1000))",
  "fixedNumber": 2,
  "type": "Costs Combined conversions",
  "key": "combinedERPM"
}, {
  "name": "Off-Amazon conversions",
  "property": "string",
  "tooltip": "The number of conversions that occured off Amazon, attributed to an ad view or click. This includes all off-Amazon conversion types.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "offAmazonConversions"
}, {
  "name": "Off-Amazon views",
  "property": "string",
  "tooltip": "The number of conversions that occured off Amazon attributed to an ad view. This includes all off-Amazon conversion types.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "offAmazonViews"
}, {
  "name": "Off-Amazon clicks",
  "property": "string",
  "tooltip": "The number of conversions that occured off Amazon attributed to an ad click. This includes all off-Amazon conversion types.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "offAmazonClicks"
}, {
  "name": "Off-Amazon CVR",
  "property": "percentage",
  "tooltip": "The number of off-Amazon conversions relative to the number of ad impressions.This includes all off-Amazon conversion types. (Off-Amazon CVR = Off-Amazon conversions / Impressions)",
  "fixedNumber": 4,
  "type": "Off-Amazon conversions, Rates",
  "key": "offAmazonCVR"
}, {
  "name": "Off-Amazon CPA",
  "property": "string",
  "tooltip": "The average cost to acquire an off-Amazon conversion. This includes all off-Amazon conversion types. (Off-Amazon CPA = Total cost / Off-Amazon conversions)",
  "fixedNumber": 2,
  "type": "Off-Amazon conversions, Costs",
  "key": "offAmazonCPA"
}, {
  "name": "Marketing landing page",
  "property": "string",
  "tooltip": "The number of Marketing landing page pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "marketingLandingPage"
}, {
  "name": "Marketing landing page views",
  "property": "string",
  "tooltip": "The number of Marketing landing page pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "marketingLandingPageViews"
}, {
  "name": "Marketing landing page clicks",
  "property": "string",
  "tooltip": "The number of Marketing landing page pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "marketingLandingPageClicks"
}, {
  "name": "Marketing landing page CVR",
  "property": "percentage",
  "tooltip": "The number of Marketing landing page pixel conversions relative to the number of ad impressions. (Marketing landing page CVR = Marketing landing page / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "marketingLandingPageCVR"
}, {
  "name": "Marketing landing page CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Marketing landing page pixel conversion. (Landing page CPA = Total cost / Landing page)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "marketingLandingPageCPA"
}, {
  "name": "Subscribe",
  "property": "number",
  "tooltip": "The number of Subscribe conversions attributed to an ad view or click.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "subscribe"
}, {
  "name": "Subscribe views",
  "property": "string",
  "tooltip": "The number of Subscribe conversions attributed to ad views.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "subscribeViews"
}, {
  "name": "Subscribe clicks",
  "property": "string",
  "tooltip": "The number of Subscribe conversions attributed to ad clicks.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "subscribeClicks"
}, {
  "name": "Subscribe CVR",
  "property": "percentage",
  "tooltip": "The number of Subscribe conversions relative to the number of ad impressions. (Subscribe CVR = Subscribe / Impressions)",
  "fixedNumber": 4,
  "type": "E-commerce conversions, Rates",
  "key": "subscribeCVR"
}, {
  "name": "Subscribe CPA",
  "property": "string",
  "tooltip": "Average cost to acquire a Subscribe conversion off Amazon. (Total cost / Subscribe)",
  "fixedNumber": 2,
  "type": "E-commerce conversions, Costs",
  "key": "subscribeCPA"
}, {
  "name": "Sign up page",
  "property": "string",
  "tooltip": "The number of Sign-up page pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "signUpPage"
}, {
  "name": "Sign up page views",
  "property": "string",
  "tooltip": "The number of Sign up page pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "signUpPageViews"
}, {
  "name": "Sign up page clicks",
  "property": "string",
  "tooltip": "The number of Sign up page pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "signUpPageClicks"
}, {
  "name": "Sign up page CVR",
  "property": "percentage",
  "tooltip": "The number of Sign up page pixel conversions relative to the number of ad impressions. (Sign up page CVR = Sign up page / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "signUpPageCVR"
}, {
  "name": "Sign up page CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Sign up page pixel conversion. (Sign up page CPA = Total cost / Sign up page)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "signUpPageCPA"
}, {
  "name": "Application",
  "property": "number",
  "tooltip": "The number of Application conversions attributed to an ad view or click.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "application"
}, {
  "name": "Application views",
  "property": "string",
  "tooltip": "The number of Application conversions attributed to an ad view.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "applicationViews"
}, {
  "name": "Application clicks",
  "property": "string",
  "tooltip": "The number of Application conversions attributed to an ad click.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "applicationClicks"
}, {
  "name": "Application CVR",
  "property": "percentage",
  "tooltip": "The number of Application conversions relative to the number of ad impressions. (Application CVR = Application / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions",
  "key": "applicationCVR"
}, {
  "name": "Application CPA",
  "property": "string",
  "tooltip": "The average cost to acquire an Application conversion. (Application CPA = Total cost / Application)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions",
  "key": "applicationCPA"
}, {
  "name": "Game load",
  "property": "string",
  "tooltip": "The number of Game load pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "gameLoad"
}, {
  "name": "Game load views",
  "property": "string",
  "tooltip": "The number of Game load pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "gameLoadViews"
}, {
  "name": "Game load clicks",
  "property": "string",
  "tooltip": "The number of Game load pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "gameLoadClicks"
}, {
  "name": "Game load CVR",
  "property": "percentage",
  "tooltip": "The number of Game load pixel conversions relative to the number of ad impressions. (Game load CVR = Game load / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "gameLoadCVR"
}, {
  "name": "Game load CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Game load pixel conversion. (Game load CPA = Total cost / Game load)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "gameLoadCPA"
}, {
  "name": "Widget load",
  "property": "string",
  "tooltip": "The number of Widget load pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "widgetLoad"
}, {
  "name": "Widget load views",
  "property": "string",
  "tooltip": "The number of Widget load pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "widgetLoadViews"
}, {
  "name": "Widget load clicks",
  "property": "string",
  "tooltip": "The number of Widget load pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "widgetLoadClicks"
}, {
  "name": "Widget load CVR",
  "property": "string",
  "tooltip": "The number of Widget load pixel conversions relative to the number of ad impressions. (Widget load CVR = Widget load / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "widgetLoadCVR"
}, {
  "name": "Widget load CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Widget load pixel conversion. (Widget load CPA = Total cost / Widget load)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "widgetLoadCPA"
}, {
  "name": "Survey start",
  "property": "string",
  "tooltip": "The number of Survey start pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "surveyStart"
}, {
  "name": "Survey start views",
  "property": "string",
  "tooltip": "The number of Survey start pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "surveyStartViews"
}, {
  "name": "Survey start clicks",
  "property": "string",
  "tooltip": "The number of Survey start pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "surveyStartClicks"
}, {
  "name": "Survey start CVR",
  "property": "string",
  "tooltip": "The number of Survey start pixel conversions relative to the number of ad impressions. (Survey start CVR = Survey start / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "surveyStartCVR"
}, {
  "name": "Survey start CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Survey start pixel conversion. (Survey start CPA = Total cost / Survey start)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "surveyStartCPA"
}, {
  "name": "Survey finish",
  "property": "string",
  "tooltip": "The number of Survey finish pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "surveyFinish"
}, {
  "name": "Survey finish views",
  "property": "string",
  "tooltip": "The number of Survey finish pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "surveyFinishViews"
}, {
  "name": "Survey finish clicks",
  "property": "string",
  "tooltip": "The number of Survey finish pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "surveyFinishClicks"
}, {
  "name": "Survey finish CVR",
  "property": "string",
  "tooltip": "The number of Survey finish pixel conversions relative to the number of ad impressions. (Survey finish CVR = Survey end / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "surveyFinishCVR"
}, {
  "name": "Survey finish CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Survey finish pixel conversion. (Survey finish CPA = Total cost / Survey end)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "surveyFinishCPA"
}, {
  "name": "Banner interaction",
  "property": "string",
  "tooltip": "The number of Banner interaction pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "bannerInteraction"
}, {
  "name": "Banner interaction views",
  "property": "string",
  "tooltip": "The number of Banner interaction pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "bannerInteractionViews"
}, {
  "name": "Banner interaction clicks",
  "property": "string",
  "tooltip": "The number of Banner interaction pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "bannerInteractionClicks"
}, {
  "name": "Banner interaction CVR",
  "property": "string",
  "tooltip": "The number of Banner interaction pixel conversions relative to the number of ad impressions. (Banner interaction CVR = Banner interaction / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "bannerInteractionCVR"
}, {
  "name": "Banner interaction CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Banner interaction pixel conversions. (Banner interaction CPA = Total cost / Banner interaction)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "bannerInteractionCPA"
}, {
  "name": "Widget interaction",
  "property": "string",
  "tooltip": "The number of Widget interaction pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "widgetInteraction"
}, {
  "name": "Widget interaction views",
  "property": "string",
  "tooltip": "The number of Widget interaction pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "widgetInteractionViews"
}, {
  "name": "Widget interaction clicks",
  "property": "string",
  "tooltip": "The number of Widget interaction pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "widgetInteractionClicks"
}, {
  "name": "Widget interaction CVR",
  "property": "string",
  "tooltip": "The number of Widget interaction pixel conversions relative to the number of ad impressions. (Widget interaction CVR = Widget interaction / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "widgetInteractionCVR"
}, {
  "name": "Widget interaction CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Widget interaction pixel conversions. (Widget interaction CPA = Total cost / Widget interaction)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "widgetInteractionCPA"
}, {
  "name": "Game interaction",
  "property": "string",
  "tooltip": "The number of Game interaction pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "gameInteraction"
}, {
  "name": "Game interaction views",
  "property": "string",
  "tooltip": "The number of Game interaction pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "gameInteractionViews"
}, {
  "name": "Game interaction clicks",
  "property": "string",
  "tooltip": "The number of Game interaction pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "gameInteractionClicks"
}, {
  "name": "Game interaction CVR",
  "property": "string",
  "tooltip": "The number of Game interaction pixel conversions relative to the number of ad impressions. (Game interaction CVR = Game interaction / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "gameInteractionCVR"
}, {
  "name": "Game interaction CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Game interaction pixel conversions. (Game interaction CPA = Total cost / Game interaction)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "gameInteractionCPA"
}, {
  "name": "Email load",
  "property": "string",
  "tooltip": "The number of Email load pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "emailLoad"
}, {
  "name": "Email load views",
  "property": "string",
  "tooltip": "The number of Email load pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "emailLoadViews"
}, {
  "name": "Email load clicks",
  "property": "string",
  "tooltip": "The number of Email load pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "emailLoadClicks"
}, {
  "name": "Email load CVR",
  "property": "string",
  "tooltip": "The number of Email load pixel conversions relative to the number of ad impressions. (Email load CVR = Email load / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "emailLoadCVR"
}, {
  "name": "Email load CPA",
  "property": "string",
  "tooltip": "The average cost to acquire an Email load pixel conversion. (Email load CPA = Total cost / Email load)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "emailLoadCPA"
}, {
  "name": "Email interaction",
  "property": "string",
  "tooltip": "The number of Email interaction pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "emailInteraction"
}, {
  "name": "Email interaction views",
  "property": "string",
  "tooltip": "The number of Email interaction pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "emailInteractionViews"
}, {
  "name": "Email interaction clicks",
  "property": "string",
  "tooltip": "The number of Email interaction pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "emailInteractionClicks"
}, {
  "name": "Email interaction CVR",
  "property": "string",
  "tooltip": "The number of Email interaction pixel conversions relative to the number of ad impressions. (Email interaction CVR = Email interaction / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "emailInteractionCVR"
}, {
  "name": "Email interaction CPA",
  "property": "string",
  "tooltip": "The average cost to acquire an Email interaction pixel conversion. (Email interaction CPA = Total cost / Email interaction)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "emailInteractionCPA"
}, {
  "name": "Submit button",
  "property": "string",
  "tooltip": "The number of Submit button pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "submitButton"
}, {
  "name": "Submit button views",
  "property": "string",
  "tooltip": "The number of Submit button pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "submitButtonViews"
}, {
  "name": "Submit button clicks",
  "property": "string",
  "tooltip": "The number of Submit button pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "submitButtonClicks"
}, {
  "name": "Submit button CVR",
  "property": "string",
  "tooltip": "The number of Submit button pixel conversions relative to the number of ad impressions. (Submit button CVR = Submit button / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "submitButtonCVR"
}, {
  "name": "Submit button CPA",
  "property": "string",
  "tooltip": "The average cost to acquire Submit button pixel conversions. (Submit button CPA = Total cost / Submit button)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "submitButtonCPA"
}, {
  "name": "Purchase button",
  "property": "string",
  "tooltip": "The number of Purchase button pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "purchaseButton"
}, {
  "name": "Purchase button views",
  "property": "string",
  "tooltip": "The number of Purchase button pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "purchaseButtonViews"
}, {
  "name": "Purchase button clicks",
  "property": "string",
  "tooltip": "The number of Purchase button pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "purchaseButtonClicks"
}, {
  "name": "Purchase button CVR",
  "property": "string",
  "tooltip": "The number of Purchase button pixel conversions relative to the number of ad impressions. (Purchase button CVR = Purchase button / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "purchaseButtonCVR"
}, {
  "name": "Purchase button CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Purchase button pixel conversions. (Purchase button CPA = Total cost / Purchase button)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "purchaseButtonCPA"
}, {
  "name": "Click on redirect",
  "property": "string",
  "tooltip": "The number of Click on redirect pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "clickOnRedirect"
}, {
  "name": "Click on redirect views",
  "property": "string",
  "tooltip": "The number of Click on redirect pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "clickOnRedirectViews"
}, {
  "name": "Click on redirect clicks",
  "property": "string",
  "tooltip": "The number of Click on redirect pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "clickOnRedirectClicks"
}, {
  "name": "Click on redirect CVR",
  "property": "string",
  "tooltip": "The number of Click on redirect pixel conversions relative to the number of ad impressions. (Click on redirect CVR = Click on redirect / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "clickOnRedirectCVR"
}, {
  "name": "Click on redirect CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Click on redirect pixel conversion. (Click on redirect CPA = Total cost / Click on redirect)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "clickOnRedirectCPA"
}, {
  "name": "Drop down selection",
  "property": "string",
  "tooltip": "The number of Drop down selection pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "dropDownSelection"
}, {
  "name": "Drop down selection views",
  "property": "string",
  "tooltip": "The number of Drop down selection pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "dropDownSelectionViews"
}, {
  "name": "Drop down selection clicks",
  "property": "string",
  "tooltip": "The number of Drop down selection pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "dropDownSelectionClicks"
}, {
  "name": "Drop down selection CVR",
  "property": "string",
  "tooltip": "The number of Drop down selection pixel conversions relative to the number of ad impressions. (Drop down selection CVR = Drop down selection / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "dropDownSelectionCVR"
}, {
  "name": "Drop down selection CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Drop down selection pixel conversion. (Drop down selection CPA = Total cost / Drop down selection)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "dropDownSelectionCPA"
}, {
  "name": "Sign-up",
  "property": "string",
  "tooltip": "Number of Sign-up conversions occurring off Amazon, attributed to an ad view or click.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "signUp"
}, {
  "name": "Sign-up views",
  "property": "string",
  "tooltip": "Number of Sign-up conversions occurring off Amazon, attributed to an ad view.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "signUpViews"
}, {
  "name": "Sign-up clicks",
  "property": "string",
  "tooltip": "Number of Sign-up conversions occurring off Amazon, attributed to an ad click.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "signUpClicks"
}, {
  "name": "Sign-up CVR",
  "property": "string",
  "tooltip": "Number of Sign-up conversions occurring off Amazon relative to the number of ad impressions. (Sign-up CVR = Sign-up/ Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions",
  "key": "signUpCVR"
}, {
  "name": "Sign-up CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Sign-up conversion off Amazon. (Sign-up CPA = Total cost / Sign-up)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions",
  "key": "signUpCPA"
}, {
  "name": "Subscription button",
  "property": "string",
  "tooltip": "The number of Subscription button pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "subscriptionButton"
}, {
  "name": "Subscription button views",
  "property": "string",
  "tooltip": "The number of Subscription button pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "subscriptionButtonViews"
}, {
  "name": "Subscription button clicks",
  "property": "string",
  "tooltip": "The number of Subscription button pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "subscriptionButtonClicks"
}, {
  "name": "Subscription button CVR",
  "property": "string",
  "tooltip": "The number of Subscription button pixel conversions relative to the number of ad impressions. (Subscription button CVR = Subscription button / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "subscriptionButtonCVR"
}, {
  "name": "Subscription button CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Subscription button pixel conversion. (Subscribe CPA = Total cost / Subscribe)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "subscriptionButtonCPA"
}, {
  "name": "Success page",
  "property": "string",
  "tooltip": "The number of Success page pixel conversions",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "successPage"
}, {
  "name": "Success page views",
  "property": "string",
  "tooltip": "The number of Success page pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "successPageViews"
}, {
  "name": "Success page clicks",
  "property": "string",
  "tooltip": "The number of Success page pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "successPageClicks"
}, {
  "name": "Success page CVR",
  "property": "string",
  "tooltip": "The number of Success page pixel conversions relative to the number of ad impressions. (Success page CVR = Success page / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "successPageCVR"
}, {
  "name": "Success page CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Success page pixel conversions. (Success page CPA = Total cost / Success page)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "successPageCPA"
}, {
  "name": "Thank you page",
  "property": "string",
  "tooltip": "The number of Thank you page pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "thankYouPage"
}, {
  "name": "Thank you page views",
  "property": "string",
  "tooltip": "The number of Thank you page pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "thankYouPageViews"
}, {
  "name": "Thank you page clicks",
  "property": "string",
  "tooltip": "The number of Thank you page pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "thankYouPageClicks"
}, {
  "name": "Thank you page CVR",
  "property": "string",
  "tooltip": "The number of Thank you page pixel conversions relative to the number of ad impressions. (Thank you page CVR = Thank you page / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "thankYouPageCVR"
}, {
  "name": "Thank you page CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Thank you page pixel conversions. (Thank you page CPA = Total cost / Thank you page)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "thankYouPageCPA"
}, {
  "name": "Registration form",
  "property": "string",
  "tooltip": "The number of Registration form pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "registrationForm"
}, {
  "name": "Registration form views",
  "property": "string",
  "tooltip": "The number of Registration form pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "registrationFormViews"
}, {
  "name": "Registration form clicks",
  "property": "string",
  "tooltip": "The number of Registration form pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "registrationFormClicks"
}, {
  "name": "Registration form CVR",
  "property": "string",
  "tooltip": "The number of Registration form pixel conversions relative to the number of ad impressions. (Registration form CVR = Registration form / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "registrationFormCVR"
}, {
  "name": "Registration form CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Registration form pixel conversion. (Registration form CPA = Total cost / Registration confirm page)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "registrationFormCPA"
}, {
  "name": "Registration confirm page",
  "property": "string",
  "tooltip": "The number of Registration confirm page pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "registrationConfirmPage"
}, {
  "name": "Registration confirm page views",
  "property": "string",
  "tooltip": "The number of Registration confirm page pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "registrationConfirmPageViews"
}, {
  "name": "Registration confirm page clicks",
  "property": "string",
  "tooltip": "The number of Registration confirm page pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "registrationConfirmPageClicks"
}, {
  "name": "Registration confirm page CVR",
  "property": "string",
  "tooltip": "The number of Registration confirm page pixel conversions relative to the number of ad impressions. (Registration confirm page CVR = Registration confirm page / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "registrationConfirmPageCVR"
}, {
  "name": "Registration confirm page CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Registration confirm page pixel conversion. (Registration confirm page CPA = Total cost / Registration confirm page)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "registrationConfirmPageCPA"
}, {
  "name": "Store locator page",
  "property": "string",
  "tooltip": "The number of Store locator page pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "storeLocatorPage"
}, {
  "name": "Store locator page views",
  "property": "string",
  "tooltip": "The number of Store locator page pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "storeLocatorPageViews"
}, {
  "name": "Store locator page clicks",
  "property": "string",
  "tooltip": "The number of Store locator page pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "storeLocatorPageClicks"
}, {
  "name": "Store locator page CVR",
  "property": "string",
  "tooltip": "The number of Store locator page pixel conversions relative to the number of ad impressions. (Locate Store CVR = Locate Store / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "storeLocatorPageCVR"
}, {
  "name": "Store locator page CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Store locator page pixel conversion. (Store locator page CPA = Total cost / Store locator page)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "storeLocatorPageCPA"
}, {
  "name": "Mobile app first starts",
  "property": "string",
  "tooltip": "The number of times an app for the featured product was first started.",
  "fixedNumber": 0,
  "type": "E-commerce conversions, Off-Amazon conversions, App conversions",
  "key": "mobileAppFirstStarts"
}, {
  "name": "Mobile app first start views",
  "property": "string",
  "tooltip": "The number of Mobile app first start conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "E-commerce conversions, Off-Amazon conversions, App conversions",
  "key": "mobileAppFirstStartViews"
}, {
  "name": "Mobile app first start clicks",
  "property": "string",
  "tooltip": "The number of Mobile app first start conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "E-commerce conversions, Off-Amazon conversions, App conversions",
  "key": "mobileAppFirstStartClicks"
}, {
  "name": "Mobile app first start CVR",
  "property": "string",
  "tooltip": "The number of Mobile app first start conversions relative to the number of ad impressions. (Mobile app first start CVR = Mobile app first start / Impressions)",
  "fixedNumber": 4,
  "type": "E-commerce conversions, Off-Amazon conversions, App conversions, Rates",
  "key": "mobileAppFirstStartCVR"
}, {
  "name": "Mobile app first start CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Mobile app first start conversion. (Mobile app first start CPA = Total cost / Mobile app first starts)",
  "fixedNumber": 2,
  "type": "E-commerce conversions, Off-Amazon conversions, App conversions, Costs",
  "key": "mobileAppFirstStartsCPA"
}, {
  "name": "Brand store engagement 1",
  "property": "string",
  "tooltip": "The number of Brand store engagement 1 pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement1"
}, {
  "name": "Brand store engagement 1 views",
  "property": "string",
  "tooltip": "The number of Brand store engagement 1 pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement1Views"
}, {
  "name": "Brand store engagement 1 clicks",
  "property": "string",
  "tooltip": "The number of Brand store engagement 1 pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement1Clicks"
}, {
  "name": "Brand store engagement 1 CVR",
  "property": "string",
  "tooltip": "The number of Brand store engagement 1 pixel conversions relative to the number of ad impressions. (Brand store engagement 1 CVR = Brand store engagement 1 / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement1CVR"
}, {
  "name": "Brand store engagement 1 CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Brand store engagement 1 pixel conversions. (Brand store engagement 1 CPA = Total cost / Brand store engagement 1)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement1CPA"
}, {
  "name": "Brand store engagement 2",
  "property": "string",
  "tooltip": "The number of Brand store engagement 2 pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement2"
}, {
  "name": "Brand store engagement 2 views",
  "property": "string",
  "tooltip": "The number of Brand store engagement 2 pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement2Views"
}, {
  "name": "Brand store engagement 2 clicks",
  "property": "string",
  "tooltip": "The number of Brand store engagement 2 pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement2Clicks"
}, {
  "name": "Brand store engagement 2 CVR",
  "property": "string",
  "tooltip": "The number of Brand store engagement 2 pixel conversions relative to the number of ad impressions. (Brand store engagement 2 CVR = Brand store engagement 2 / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement2CVR"
}, {
  "name": "Brand store engagement 2 CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Brand store engagement 2 pixel conversions. (Brand store engagement 2 CPA = Total cost / Brand store engagement 2)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement2CPA"
}, {
  "name": "Brand store engagement 3",
  "property": "string",
  "tooltip": "The number of Brand store engagement 3 pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement3"
}, {
  "name": "Brand store engagement 3 views",
  "property": "string",
  "tooltip": "The number of Brand store engagement 3 pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement3Views"
}, {
  "name": "Brand store engagement 3 clicks",
  "property": "string",
  "tooltip": "The number of Brand store engagement 3 pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement3Clicks"
}, {
  "name": "Brand store engagement 3 CVR",
  "property": "string",
  "tooltip": "The number of Brand store engagement 3 pixel conversions relative to the number of ad impressions. (Brand store engagement 3 CVR = Brand store engagement 3 / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement3CVR"
}, {
  "name": "Brand store engagement 3 CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Brand store engagement 3 pixel conversion. (Brand store engagement 3 CPA = Total cost / Brand store engagement 3)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement3CPA"
}, {
  "name": "Brand store engagement 4",
  "property": "string",
  "tooltip": "The number of Brand store engagement 4 pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement4"
}, {
  "name": "Brand store engagement 4 views",
  "property": "string",
  "tooltip": "The number of Brand store engagement 4 pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement4Views"
}, {
  "name": "Brand store engagement 4 clicks",
  "property": "string",
  "tooltip": "The number of Brand store engagement 4 pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement4Clicks"
}, {
  "name": "Brand store engagement 4 CVR",
  "property": "string",
  "tooltip": "The number of Brand store engagement 4 pixel conversions relative to the number of ad impressions. (Brand store engagement 4 CVR = Brand store engagement 4 / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement4CVR"
}, {
  "name": "Brand store engagement 4 CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Brand store engagement 4 pixel conversions. (Brand store engagement 4 CPA = Total cost / Brand store engagement 4)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement4CPA"
}, {
  "name": "Brand store engagement 5",
  "property": "string",
  "tooltip": "The number of Brand store engagement 5 pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement5"
}, {
  "name": "Brand store engagement 5 views",
  "property": "string",
  "tooltip": "The number of Brand store engagement 5 pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement5Views"
}, {
  "name": "Brand store engagement 5 clicks",
  "property": "string",
  "tooltip": "The number of Brand store engagement 5 pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement5Clicks"
}, {
  "name": "Brand store engagement 5 CVR",
  "property": "string",
  "tooltip": "The number of Brand store engagement 5 pixel conversions relative to the number of ad impressions. (Brand store engagement 5 CVR = Brand store engagement 5 / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement5CVR"
}, {
  "name": "Brand store engagement 5 CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Brand store engagement 5 pixel conversions. (Brand store engagement 5 CPA = Total cost / Brand store engagement 5)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement5CPA"
}, {
  "name": "Brand store engagement 6",
  "property": "string",
  "tooltip": "The number of Brand store engagement 6 pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement6"
}, {
  "name": "Brand store engagement 6 views",
  "property": "string",
  "tooltip": "The number of Brand store engagement 6 pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement6Views"
}, {
  "name": "Brand store engagement 6 clicks",
  "property": "string",
  "tooltip": "The number of Brand store engagement 6 pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement6Clicks"
}, {
  "name": "Brand store engagement 6 CVR",
  "property": "string",
  "tooltip": "The number of Brand store engagement 6 pixel conversions relative to the number of ad impressions. (Brand store engagement 6 CVR = Brand store engagement 6 / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement6CVR"
}, {
  "name": "Brand store engagement 6 CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Brand store engagement 6 pixel conversion. (Brand store engagement 6 CPA = Total cost / Brand store engagement 6)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement6CPA"
}, {
  "name": "Brand store engagement 7",
  "property": "string",
  "tooltip": "The number of Brand store engagement 7 pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement7"
}, {
  "name": "Brand store engagement 7 views",
  "property": "string",
  "tooltip": "The number of Brand store engagement 7 pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement7Views"
}, {
  "name": "Brand store engagement 7 clicks",
  "property": "string",
  "tooltip": "The number of Brand store engagement 7 pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement7Clicks"
}, {
  "name": "Brand store engagement 7 CVR",
  "property": "string",
  "tooltip": "The number of Brand store engagement 7 pixel conversions relative to the number of ad impressions. (Brand store engagement 7 CVR = Brand store engagement 7 / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement7CVR"
}, {
  "name": "Brand store engagement 7 CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Brand store engagement 7 pixel conversion. (Brand store engagement 7 CPA = Total cost / Brand store engagement 7)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "brandStoreEngagement7CPA"
}, {
  "name": "ATSC",
  "property": "string",
  "tooltip": "The number of Add to shopping cart conversions attributed to an ad view or click.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "addedToShoppingCart"
}, {
  "name": "ATSC views",
  "property": "string",
  "tooltip": "The number of Add to shopping cart conversions attributed to an ad view.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "addedToShoppingCartViews"
}, {
  "name": "ATSC clicks",
  "property": "string",
  "tooltip": "The number of Add to shopping cart conversions attributed to an ad click.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "addedToShoppingCartClicks"
}, {
  "name": "ATSC CVR",
  "property": "string",
  "tooltip": "The number of Add to shopping cart conversions relative to the number of ad impressions. (ATSC CVR = ATSC / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions",
  "key": "addedToShoppingCartCVR"
}, {
  "name": "ATSC CPA",
  "property": "string",
  "tooltip": "The average cost to acquire an Add to shopping cart conversion. (ATSC CPA = Total Cost / ATSC)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions",
  "key": "addedToShoppingCartCPA"
}, {
  "name": "off-Amazon purchases",
  "property": "string",
  "tooltip": "Number of off-Amazon purchase events attributed to an ad view or click.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "offAmazonPurchases"
}, {
  "name": "Off-Amazon purchases views",
  "property": "string",
  "tooltip": "Number of off-Amazon purchase events attributed to an ad view.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "offAmazonPurchasesViews"
}, {
  "name": "Off-Amazon purchases clicks",
  "property": "string",
  "tooltip": "Number of off-Amazon purchase events attributed to an ad click.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "offAmazonPurchasesClicks"
}, {
  "name": "Off-Amazon purchase rate",
  "property": "string",
  "tooltip": "Rate of attributed off-Amazon purchase events relative to ad impressions. (Off-Amazon purchase rate = Off-Amazon purchases / Impressions)",
  "fixedNumber": 4,
  "type": "Off-Amazon conversions, Rates",
  "key": "offAmazonPurchaseRate"
}, {
  "name": "Off-Amazon eCPP",
  "property": "string",
  "tooltip": "Effective (average) cost to acquire an off-Amazon purchase event. (Off-Amazon eCPP = Total cost / Off-Amazon purchases)",
  "fixedNumber": 2,
  "type": "Off-Amazon conversions, Costs",
  "key": "offAmazonECPP"
}, {
  "name": "Homepage visit",
  "property": "string",
  "tooltip": "The number of Homepage visit pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "homepageVisit"
}, {
  "name": "Homepage visit views",
  "property": "string",
  "tooltip": "The number of Homepage visit pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "homepageVisitViews"
}, {
  "name": "Homepage visit clicks",
  "property": "string",
  "tooltip": "The number of Homepage visit pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "homepageVisitClicks"
}, {
  "name": "Homepage visit CVR",
  "property": "string",
  "tooltip": "The number of Homepage visit pixel conversions relative to the number of ad impressions. (Homepage visit CVR = Homepage visit / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "homepageVisitCVR"
}, {
  "name": "Homepage visit CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Homepage visit pixel conversion. (Homepage visit CPA = Total cost / Homepage visit)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "homepageVisitCPA"
}, {
  "name": "Video started",
  "property": "string",
  "tooltip": "The number of Video started pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Video, Pixel conversions",
  "key": "videoStarted"
}, {
  "name": "Video started views",
  "property": "string",
  "tooltip": "The number of Video started pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Video, Pixel conversions",
  "key": "videoStartedViews"
}, {
  "name": "Video started clicks",
  "property": "string",
  "tooltip": "The number of Video started pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Video, Pixel conversions",
  "key": "videoStartedClicks"
}, {
  "name": "Video started CVR",
  "property": "string",
  "tooltip": "The number of Video started pixel conversions relative to the number of ad impressions. (Video started CVR = Video started / Impressions)",
  "fixedNumber": 4,
  "type": "Off-Amazon conversions, Video, Rates, Pixel conversions",
  "key": "videoStartedCVR"
}, {
  "name": "Video started CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Video started pixel conversion. (Video started CPA = Total cost / Video started)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Video, Pixel conversions",
  "key": "videoStartedCPA"
}, {
  "name": "Video completed",
  "property": "string",
  "tooltip": "The number of Video completed pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Video, Pixel conversions",
  "key": "videoCompleted"
}, {
  "name": "Video completed views",
  "property": "string",
  "tooltip": "The number of Video completed pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Video, Pixel conversions",
  "key": "videoCompletedViews"
}, {
  "name": "Video end clicks",
  "property": "string",
  "tooltip": "The number of Video completed pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Video, Pixel conversions",
  "key": "videoEndClicks"
}, {
  "name": "Video completed CVR",
  "property": "string",
  "tooltip": "The number of Video completed pixel conversions relative to the number of ad impressions. (Video completed CVR = Video end / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Video, Pixel conversions",
  "key": "videoCompletedCVR"
}, {
  "name": "Video completed CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Video completed pixel conversion. (Video completed CPA = Total cost / Video completed)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Video, Pixel conversions",
  "key": "videoCompletedCPA"
}, {
  "name": "Message sent",
  "property": "string",
  "tooltip": "The number of Message sent pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "messageSent"
}, {
  "name": "Message sent views",
  "property": "string",
  "tooltip": "The number of Message sent pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "messageSentViews"
}, {
  "name": "Message sent clicks",
  "property": "string",
  "tooltip": "The number of Message sent pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "messageSentClicks"
}, {
  "name": "Message sent CVR",
  "property": "string",
  "tooltip": "The number of Message sent pixel conversions relative to the number of ad impressions. (Message sent CVR = Message sent / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "messageSentCVR"
}, {
  "name": "Message sent CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Message sent pixel conversion. (Message sent CPA = Total cost / Message send)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "messageSentCPA"
}, {
  "name": "Mashup click to page",
  "property": "string",
  "tooltip": "The number of Mashup click to page pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "mashupClickToPage"
}, {
  "name": "Mashup click to page views",
  "property": "string",
  "tooltip": "The number of Mashup click to page pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "mashupClickToPageViews"
}, {
  "name": "Mashup click to page clicks",
  "property": "string",
  "tooltip": "The number of Mashup click to page pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "mashupClickToPageClicks"
}, {
  "name": "Mashup click to page CVR",
  "property": "string",
  "tooltip": "The number of Mashup click to page pixel conversions relative to the number of ad impressions. (Mashup click to page CVR = Ad click / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "mashupClickToPageCVR"
}, {
  "name": "Mashup click to page CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Mashup click to page pixel conversion. (Ad click CPA = Total cost / Ad click)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "mashupClickToPageCPA"
}, {
  "name": "Mashup backup image",
  "property": "string",
  "tooltip": "The number of Mashup backup image click pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "mashupBackupImage"
}, {
  "name": "Mashup backup image views",
  "property": "string",
  "tooltip": "The number of Mashup backup image click pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "mashupBackupImageViews"
}, {
  "name": "Mashup backup image clicks",
  "property": "string",
  "tooltip": "The number of Mashup backup image click pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "mashupBackupImageClicks"
}, {
  "name": "Mashup backup image CVR",
  "property": "string",
  "tooltip": "The number of Mashup backup image click pixel conversions relative to the number of ad impressions. (Mashup backup image CVR = Mashup backup image / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "mashupBackupImageCVR"
}, {
  "name": "Mashup backup image CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Mashup backup image click pixel conversion. (Mashup backup image CPA = Total cost / Mashup backup image)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "mashupBackupImageCPA"
}, {
  "name": "Mashup ATC",
  "property": "string",
  "tooltip": "The number of Mashup Add to Cart click pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "mashupAddToCart"
}, {
  "name": "Mashup ATC views",
  "property": "string",
  "tooltip": "The number of Mashup Add to Cart click pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "mashupAddToCartViews"
}, {
  "name": "Mashup ATC clicks",
  "property": "string",
  "tooltip": "The number of Mashup Add to Cart click pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "mashupAddToCartClicks"
}, {
  "name": "Mashup Add to Cart click CVR",
  "property": "string",
  "tooltip": "The number of Mashup Add to Cart click pixel conversions relative to the number of ad impressions. (Mashup Add to Cart click CVR = Mashup Add to Cart click / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "mashupAddToCartClickCVR"
}, {
  "name": "Mashup ATC CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Mashup Add to Cart click pixel conversion. (Mashup Add to Cart click CPA = Total cost / Ad ATC)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "mashupAddToCartCPA"
}, {
  "name": "Mashup ATW",
  "property": "string",
  "tooltip": "The number of Mashup Add to Wishlist click conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "mashupAddToWishlist"
}, {
  "name": "Mashup ATW views",
  "property": "string",
  "tooltip": "The number of Mashup Add to Wishlist click pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "mashupAddToWishlistViews"
}, {
  "name": "Mashup ATW clicks",
  "property": "string",
  "tooltip": "The number of Mashup Add to Wishlist click pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "mashupAddToWishlistClicks"
}, {
  "name": "Mashup ATW CVR",
  "property": "string",
  "tooltip": "The number of Mashup Add to Wishlist click pixel conversions relative to the number of ad impressions. (Mashup Add to Wishlist click CVR = Mashup Add to Wishlist click / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "mashupAddToWishlistCVR"
}, {
  "name": "Mashup ATW CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Mashup Add to Wishlist click conversion. (Mashup Add to Wishlist click CPA = Total cost / Mashup Add to Wishlist click)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "mashupAddToWishlistCPA"
}, {
  "name": "Mashup SnS click",
  "property": "string",
  "tooltip": "The number of Mashup Subscribe and Save click pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "mashupSubscribeAndSave"
}, {
  "name": "Mashup SnS click views",
  "property": "string",
  "tooltip": "The number of Mashup Subscribe and Save click pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "mashupSubscribeAndSaveClickViews"
}, {
  "name": "Mashup SnS click clicks",
  "property": "string",
  "tooltip": "The number of Mashup Subscribe and Save click pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "mashupSubscribeAndSaveClick"
}, {
  "name": "Mashup SnS click CVR",
  "property": "string",
  "tooltip": "The number of Mashup Subscribe and Save click pixel conversions relative to the number of ad impressions. (Mashup SnS click CVR = Ad SnS / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "mashupSubscribeAndSaveCVR"
}, {
  "name": "Mashup SnS click CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Mashup Subscribe and Save click pixel conversion. (Mashup SnS click CPA = Total cost / Ad SnS)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "mashupSubscribeAndSaveCPA"
}, {
  "name": "Mashup CC click",
  "property": "string",
  "tooltip": "The number of Mashup Clip Coupon click pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "mashupClipCouponClick"
}, {
  "name": "Mashup CC click views",
  "property": "string",
  "tooltip": "The number of Mashup Clip Coupon click pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "mashupClipCouponClickViews"
}, {
  "name": "Mashup CC click clicks",
  "property": "string",
  "tooltip": "The number of Mashup Clip Coupon click pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "mashupClipCouponClickClicks"
}, {
  "name": "Mashup CC click CVR",
  "property": "string",
  "tooltip": "The number of Mashup Clip Coupon click pixel conversions relative to the number of ad impressions. (Mashup CC click CVR = Coupon / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "mashupClipCouponClickCVR"
}, {
  "name": "Mashup CC click CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Mashup Clip Coupon click pixel conversion. (Mashup CC click CPA = Total cost / Coupon)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "mashupClipCouponClickCPA"
}, {
  "name": "Mashup SN click",
  "property": "string",
  "tooltip": "The number of Mashup Shop Now click pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "mashupShopNowClick"
}, {
  "name": "Mashup SN click views",
  "property": "string",
  "tooltip": "The number of Mashup Shop Now click pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "mashupShopNowClickViews"
}, {
  "name": "Mashup SN click clicks",
  "property": "string",
  "tooltip": "The number of Mashup Shop Now click pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "mashupShopNowClickClicks"
}, {
  "name": "Mashup SN click CVR",
  "property": "string",
  "tooltip": "The number of Mashup Shop Now click pixel conversions relative to the number of ad impressions. (Mashup SN click CVR = Mashup SN click / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "mashupShopNowClickCVR"
}, {
  "name": "Mashup SN click CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Mashup Shop Now click pixel conversion. (Mashup SN click CPA = Total cost / Mashup SN click)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "mashupShopNowClickCPA"
}, {
  "name": "Referral",
  "property": "number",
  "tooltip": "The number of Referral pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "referral"
}, {
  "name": "Referral views",
  "property": "string",
  "tooltip": "The number of Referral pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "referralViews"
}, {
  "name": "Referral clicks",
  "property": "string",
  "tooltip": "The number of Referral pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "referralClicks"
}, {
  "name": "Referral CVR",
  "property": "string",
  "tooltip": "The number of Referral pixel conversions relative to the number of ad impressions. (Referral CVR = Referral / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "referralCVR"
}, {
  "name": "Referral CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Referral pixel conversion. (Referral CPA = Total cost / Referral)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "referralCPA"
}, {
  "name": "Accept",
  "property": "number",
  "tooltip": "The number of Accept pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "accept"
}, {
  "name": "Accept views",
  "property": "string",
  "tooltip": "The number of Accept pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "acceptViews"
}, {
  "name": "Accept clicks",
  "property": "string",
  "tooltip": "The number of Accept pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "acceptClicks"
}, {
  "name": "Accept CVR",
  "property": "string",
  "tooltip": "The number of Accept pixel conversions relative to the number of ad impressions. (Accept CVR = Accept / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "acceptCVR"
}, {
  "name": "Accept CPA",
  "property": "string",
  "tooltip": "The average cost to acquire an Accept pixel conversion. (Accept CPA = Total cost / Accept)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "acceptCPA"
}, {
  "name": "Decline",
  "property": "number",
  "tooltip": "The number of Decline pixel conversions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "decline"
}, {
  "name": "Decline views",
  "property": "string",
  "tooltip": "The number of Decline pixel conversions attributed to ad impressions.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "declineViews"
}, {
  "name": "Decline clicks",
  "property": "string",
  "tooltip": "The number of Decline pixel conversions attributed to ad click-throughs.",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions, Pixel conversions",
  "key": "declineClicks"
}, {
  "name": "Decline CVR",
  "property": "string",
  "tooltip": "The number of Decline pixel conversions relative to the number of ad impressions. (Decline CVR = Decline / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions, Pixel conversions",
  "key": "declineCVR"
}, {
  "name": "Decline CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Decline pixel conversion. (Decline CPA = Total cost / Decline)",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions, Pixel conversions",
  "key": "declineCPA"
}, {
  "name": "DPV",
  "property": "number",
  "tooltip": "Number of detail page views for promoted products, attributed to an ad view or click. Use Total DPV to see all conversions for the brands' products.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "dpv"
}, {
  "name": "DPV views",
  "property": "string",
  "tooltip": "Number of detail page views for promoted products, attributed to an ad view. Use Total DPV views to see all conversions for the brands' products.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "dpvViews"
}, {
  "name": "DPV clicks",
  "property": "string",
  "tooltip": "Number of detail page views for promoted products, attributed to an ad click. Use Total DPV clicks to see all conversions for the brands' products.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "dpvClicks"
}, {
  "name": "DPVR",
  "property": "percentage",
  "tooltip": "Detail page view rate for promoted products relative to the number of ad impressions. (DPVR = Detail page views / Impressions) Use Total DPVR to see all conversions for the brands' products.",
  "fixedNumber": 4,
  "type": "Rates, E-commerce conversions",
  "key": "dpvr"
}, {
  "name": "eCPDPV",
  "property": "number",
  "tooltip": "Effective (average) cost to acquire a detail page view for a promoted product. (eCPDPV = Total cost / DPV) Use Total eCPDPV to see all conversions for the brands' products.",
  "fixedNumber": 2,
  "type": "Costs, E-commerce conversions",
  "key": "eCPDPV"
}, {
  "name": "PRPV",
  "property": "number",
  "tooltip": "Number of times shoppers visited the product review page for a promoted product, attributed to an ad view or click. Use Total PRPV to see all conversions for the brands' products.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "pRPV"
}, {
  "name": "PRPV views",
  "property": "string",
  "tooltip": "Number of times shoppers visited the product review page for a promoted product, attributed to an ad view. Use Total PRPV views to see all conversions for the brands' products.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "pRPVViews"
}, {
  "name": "PRPV clicks",
  "property": "string",
  "tooltip": "Number of times shoppers visited the product review page for a promoted product, attributed to an ad click. Use Total PRPV clicks to see all conversions for the brands' products.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "pRPVClicks"
}, {
  "name": "PRPVR",
  "property": "number",
  "tooltip": "Rate of product review page visits for promoted products, relative to the number of ad impressions. (PRPVR = PRPV / Impressions) Use Total PRPVR to see all conversions for the brands' products.",
  "fixedNumber": 4,
  "type": "Rates, E-commerce conversions",
  "key": "pRPVr"
}, {
  "name": "eCPPRPV",
  "property": "number",
  "tooltip": "Effective (average) cost to acquire a product review page conversion for a promoted product. (eCPPRPV = Total cost / PRPV) Use Total eCPPRPV to see all conversions for the brands' products.",
  "fixedNumber": 2,
  "type": "Costs, E-commerce conversions",
  "key": "eCPPRPV"
}, {
  "name": "ATL",
  "property": "number",
  "tooltip": "Number of times shoppers added a promoted product to a wish list, gift list, or registry, attributed to an ad view or click. Use Total ATL to see all conversions for the brands' products.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "atl"
}, {
  "name": "ATL views",
  "property": "string",
  "tooltip": "Number of times shoppers added a promoted product to a wish list, gift list, or registry, attributed to an ad view. Use Total ATL views to see all conversions for the brands' products.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "atlViews"
}, {
  "name": "ATL clicks",
  "property": "string",
  "tooltip": "Number of times shoppers added a promoted product to a wish list, gift list, or registry, attributed to an ad click. Use Total ATL clicks to see all conversions for the brands' products.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "atlClicks"
}, {
  "name": "ATLR",
  "property": "percentage",
  "tooltip": "Rate of Add to List conversions for promoted products relative to the number of impressions. (ATLR = ATL / Impressions) Use Total ATLR to see all conversions for the brands' products.",
  "fixedNumber": 4,
  "type": "Rates, E-commerce conversions",
  "key": "atlr"
}, {
  "name": "eCPATL",
  "property": "number",
  "tooltip": "Effective (average) cost to acquire an Add to List conversion for a promoted product. (eCPATL = Total cost / ATL) Use Total eCPATL to see all conversions for the brands' products.",
  "fixedNumber": 2,
  "type": "Costs, E-commerce conversions",
  "key": "eCPAtl"
}, {
  "name": "ATC",
  "property": "number",
  "tooltip": "Number of times shoppers added a promoted product to their cart, attributed to an ad view or click. Use Total ATC to see all conversions for the brands' products.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "atc"
}, {
  "name": "ATC views",
  "property": "string",
  "tooltip": "Number of times shoppers added a promoted product to their cart, attributed to an ad view. Use Total ATC views to see all conversions for the brands' products.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "atcViews"
}, {
  "name": "ATC clicks",
  "property": "string",
  "tooltip": "Number of times shoppers added a promoted product to their cart, attributed to an ad click. Use Total ATC clicks to see all conversions for the brands' products.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "atcClicks"
}, {
  "name": "ATCR",
  "property": "percentage",
  "tooltip": "Rate of Add to Cart conversions relative to the number of impressions. (ATCR = ATC / Impressions) Use Total ATCR to see all conversions for the brands' products.",
  "fixedNumber": 4,
  "type": "Rates, E-commerce conversions",
  "key": "atcr"
}, {
  "name": "eCPATC",
  "property": "number",
  "tooltip": "Effective (average) cost to acquire an Add to Cart conversion for a promoted product. (eCPATC = Total cost / ATC) Use Total eCPATC to see all conversions for the brands' products.",
  "fixedNumber": 2,
  "type": "Costs, E-commerce conversions",
  "key": "eCPAtc"
}, {
  "name": "Purchases",
  "property": "number",
  "tooltip": "Number of times any quantity of a promoted product was included in a purchase event, attributed to an ad view or click. Purchase events include new Subscribe & Save subscriptions and video rentals. Use Total purchases to see all conversions for the brands' products.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "purchases"
}, {
  "name": "Purchases views",
  "property": "string",
  "tooltip": "Number of times any quantity of a promoted product was included in a purchase event, attributed to an ad view. Purchase events include new Subscribe & Save subscriptions and video rentals. Use Total purchases views to see all conversions for the brands' products.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "purchasesViews"
}, {
  "name": "Purchases clicks",
  "property": "string",
  "tooltip": "Number of times any quantity of a promoted product was included in a purchase event, attributed to an ad click. Purchase events include new Subscribe & Save subscriptions and video rentals. Use Total purchases clicks to see all conversions for the brands' products.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "purchasesClicks"
}, {
  "name": "Purchases rate",
  "property": "string",
  "tooltip": "Rate of ad-attributed purchase events for promoted products, relative to ad impressions. (Purchase rate = Purchases / Impressions) Use Total purchase rate to see all conversions for the brands' products.",
  "fixedNumber": 4,
  "type": "Rates, E-commerce conversions",
  "key": "purchaseRate"
}, {
  "name": "eCPP",
  "property": "number",
  "tooltip": "Effective (average) cost to acquire a purchase conversion for a promoted product. (eCPP = Total cost / Purchases) Use Total eCPP to see all conversions for the brands' products.",
  "fixedNumber": 2,
  "type": "Costs, E-commerce conversions",
  "key": "eCPP"
}, {
  "name": "New-to-brand purchases",
  "property": "string",
  "tooltip": "Number of new-to-brand purchases for promoted products, attributed to an ad view or click. Shoppers are \"new to brand\" if they have not purchased from the brand in the last 365 days. Use Total new-to-brand purchases to see all conversions for the brands' products.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "newToBrandPurchases"
}, {
  "name": "New-to-brand purchases views",
  "property": "string",
  "tooltip": "Number of new-to-brand purchases for promoted products, attributed to an ad view. Shoppers are \"new to brand\" if they have not purchased from the brand in the last 365 days. Use Total new-to-brand purchases views to see all conversions for the brands' products.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "newToBrandPurchasesViews"
}, {
  "name": "New-to-brand purchases clicks",
  "property": "string",
  "tooltip": "Number of new-to-brand purchases for promoted products, attributed to an ad click. Shoppers are \"new to brand\" if they have not purchased from the brand in the last 365 days. Use Total new-to-brand purchases clicks to see all conversions for the brands' products.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "newToBrandPurchasesClicks"
}, {
  "name": "New-to-brand purchase rate",
  "property": "string",
  "tooltip": "Rate of new-to-brand purchase conversions for promoted products relative to the number of ad impressions. (New-to-brand purchase rate = New-to-brand purchases / Impressions) Use Total new-to-brand purchase rate to see all conversions for the brands' products.",
  "fixedNumber": 4,
  "type": "Rates, E-commerce conversions",
  "key": "newToBrandPurchaseRate"
}, {
  "name": "New-to-brand eCPP",
  "property": "string",
  "tooltip": "Effective (average) cost to acquire a new-to-brand purchase conversion for a promoted product. (New-to-brand eCPP = Total cost / New-to-brand purchases) Use Total new-to-brand eCPP to see all conversions for the brands' products.",
  "fixedNumber": 2,
  "type": "Costs, E-commerce conversions",
  "key": "newToBrandECPP"
}, {
  "name": "Percent of purchases new-to-brand",
  "property": "percentage",
  "tooltip": "Percent of ad-attributed purchases for promoted products that were new to brand. Use Total percent of purchases new-to-brand to see all conversions for the brands' products.",
  "fixedNumber": 2,
  "type": "E-commerce conversions",
  "key": "percentOfPurchasesNewToBrand"
}, {
  "name": "SnSS",
  "property": "string",
  "tooltip": "Number of new Subscribe & Save subscriptions for promoted products, attributed to an ad view or click. This does not include replenishment subscription orders. Use Total SnSS to see all conversions for the brands' products.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "newSubscribeAndSave"
}, {
  "name": "SnSS views",
  "property": "string",
  "tooltip": "Number of new Subscribe & Save subscriptions for promoted products, attributed to an ad view. This does not include replenishment subscription orders. Use Total SnSS views to see all conversions for the brands' products.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "newSubscribeAndSaveViews"
}, {
  "name": "SnSS clicks",
  "property": "string",
  "tooltip": "Number of new Subscribe & Save subscriptions for promoted products, attributed to an ad click. This does not include replenishment subscription orders. Use Total SnSS clicks to see all conversions for the brands' products.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "newSubscribeAndSaveClicks"
}, {
  "name": "SnSSR",
  "property": "string",
  "tooltip": "Rate of ad-attributed Subscribe & Save conversions for promoted products relative to ad impressions. (SnSSR = SnSS / Impressions) Use Total SnSSR to see all conversions for the brands' products.",
  "fixedNumber": 4,
  "type": "Rates, E-commerce conversions",
  "key": "newSubscribeAndSaveRate"
}, {
  "name": "eCPSnSS",
  "property": "string",
  "tooltip": "Effective (average) cost to acquire a Subscribe & Save subscription for a promoted product. (eCPSnSS = Total cost / SnSS) Use Total eCPSnSS to see all conversions for the brands' products.",
  "fixedNumber": 2,
  "type": "Costs, E-commerce conversions",
  "key": "eCPnewSubscribeAndSave"
}, {
  "name": "Video start",
  "property": "string",
  "tooltip": "The number of times a video ad was started.",
  "fixedNumber": 0,
  "type": "Video",
  "key": "videoStart"
}, {
  "name": "Video first quartile",
  "property": "string",
  "tooltip": "The number of times at least 25% of a video ad played. If rewind occurred, percent complete was calculated on the total percentage of unduplicated video viewed.",
  "fixedNumber": 0,
  "type": "Video",
  "key": "videoFirstQuartile"
}, {
  "name": "Video midpoint",
  "property": "string",
  "tooltip": "The number of times at least 50% of a video ad played. If rewind occurred, percent complete was calculated on the total percentage of unduplicated video viewed.",
  "fixedNumber": 0,
  "type": "Video",
  "key": "videoMidpoint"
}, {
  "name": "Video third quartile",
  "property": "string",
  "tooltip": "The number of times at least 75% of a video ad played. If rewind occurred, percent complete was calculated on the total percentage of unduplicated video viewed.",
  "fixedNumber": 0,
  "type": "Video",
  "key": "videoThirdQuartile"
}, {
  "name": "Video complete",
  "property": "string",
  "tooltip": "The number of times a video ad played to completion. If rewind occurred, completion was calculated on the total percentage of unduplicated video viewed.",
  "fixedNumber": 0,
  "type": "Video",
  "key": "videoComplete"
}, {
  "name": "Video completion rate",
  "property": "string",
  "tooltip": "The number of video completions relative to the number of video starts. (Video completion rate = Video complete / Video start)",
  "fixedNumber": 2,
  "type": "Rates, Video",
  "key": "videoCompletionRate"
}, {
  "name": "eCPVC",
  "property": "money",
  "tooltip": "The average cost to acquire a Video complete conversion (eCPVC = Total cost / Video complete)",
  "fixedNumber": 2,
  "type": "Costs, Video",
  "key": "ecpvc"
}, {
  "name": "Video pause",
  "property": "string",
  "tooltip": "The number of times a user paused the video ad.",
  "fixedNumber": 0,
  "type": "Video",
  "key": "videoPause"
}, {
  "name": "Video resume",
  "property": "string",
  "tooltip": "The number of times a user unpaused the video ad.",
  "fixedNumber": 0,
  "type": "Video",
  "key": "videoResume"
}, {
  "name": "Video mute",
  "property": "string",
  "tooltip": "The number of times a user muted the video ad.",
  "fixedNumber": 0,
  "type": "Video",
  "key": "videoMute"
}, {
  "name": "Video unmute",
  "property": "string",
  "tooltip": "The number of times a user unmuted the video ad.",
  "fixedNumber": 0,
  "type": "Video",
  "key": "videoUnmute"
}, {
  "name": "Units sold",
  "property": "string",
  "tooltip": "Units of promoted products purchased, attributed to an ad view or click. A single purchase event can include multiple sold units. Use Total units sold to see all conversions for the brands' products.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "unitsSold"
}, {
  "name": "Product sales",
  "property": "money",
  "tooltip": "Sales (in local currency) of promoted products, attributed to an ad view or click. Use Total product sales to see all conversions for the brands' products.",
  "fixedNumber": 2,
  "type": "E-commerce conversions",
  "key": "sales"
}, {
  "name": "ROAS",
  "property": "number",
  "tooltip": "Return on advertising spend for promoted products, measured as ad-attributed sales for promoted products per local currency unit of ad spend. (ROAS = Product sales / Total cost) Use Total ROAS to see all conversions for the brands' products.",
  "fixedNumber": 2,
  "type": "E-commerce conversions",
  "key": "ROAS"
}, {
  "name": "eRPM",
  "property": "number",
  "tooltip": "Effective (average) revenue for promoted products generated per thousand impressions. (eRPM = Sales / (Impressions / 1000)) Use Total eRPM to see all conversions for the brands' products.",
  "fixedNumber": 2,
  "type": "Traffic, Costs, E-commerce conversions",
  "key": "eRPM"
}, {
  "name": "New-to-brand units sold",
  "property": "string",
  "tooltip": "Units of promoted products purchased by shoppers who were new-to-brand, attributed to an ad view or click. A single new-to-brand purchase event can include multiple sold units. Use Total new-to-brand units sold to see all conversions for the brands' products.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "newToBrandUnitsSold"
}, {
  "name": "New-to-brand product sales",
  "property": "money",
  "tooltip": "Sales (in local currency) of promoted products to new-to-brand shoppers, attributed to an ad view or click. Use Total new-to-brand product sales to see all conversions for the brands' products.",
  "fixedNumber": 2,
  "type": "E-commerce conversions",
  "key": "newToBrandProductSales"
}, {
  "name": "New-to-brand ROAS",
  "property": "string",
  "tooltip": "Return on advertising spend for promoted new-to-brand purchases, measured as ad-attributed new-to-brand sales for promoted products per local currency unit of ad spend. (New-to-brand ROAS = New to brand product sales / Total cost) Use Total new-to-brand ROAS to see all conversions for the brands' products.",
  "fixedNumber": 2,
  "type": "E-commerce conversions",
  "key": "newToBrandROAS"
}, {
  "name": "New-to-brand eRPM",
  "property": "string",
  "tooltip": "Effective (average) revenue generated per thousand impressions from promoted products purchased by new-to-brand shoppers. (New-to-brand eRPM = NTB Sales / (Impressions / 1000)) Use Total new-to-brand eRPM to see all conversions for the brands' products.",
  "fixedNumber": 2,
  "type": "Costs, E-commerce conversions",
  "key": "newToBrandERPM"
}, {
  "name": "Total units sold",
  "property": "string",
  "tooltip": "Units of the brands' products purchased, attributed to an ad view or click. A single purchase event can include multiple sold units.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "totalUnitsSold"
}, {
  "name": "Total product sales",
  "property": "money",
  "tooltip": "Sales (in local currency) of the brands’ products, attributed to an ad view or click.",
  "fixedNumber": 2,
  "type": "E-commerce conversions",
  "key": "totalSales"
}, {
  "name": "Total ROAS",
  "property": "string",
  "tooltip": "Return on ad spend, measured as ad-attributed sales for the brands’ products per local currency unit of ad spend. (Total ROAS = Total product sales / Total cost)",
  "fixedNumber": 2,
  "type": "E-commerce conversions",
  "key": "totalROAS"
}, {
  "name": "Total eRPM",
  "property": "string",
  "tooltip": "Effective (average) revenue for the brands’ products generated per thousand impressions. (Total eRPM = Sales / (Impressions / 1000))",
  "fixedNumber": 2,
  "type": "Costs, E-commerce conversions",
  "key": "totalERPM"
}, {
  "name": "Total SnSS",
  "property": "string",
  "tooltip": "Number of new Subscribe & Save subscriptions for the brands’ products, attributed to an ad view or click. This does not include replenishment subscription orders.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "totalSubscribeAndSaveSubscriptions"
}, {
  "name": "Total SnSS views",
  "property": "string",
  "tooltip": "Number of new Subscribe & Save subscriptions for the brands’ products, attributed to an ad view. This does not include replenishment subscription orders.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "totalSubscribeAndSaveSubscriptionViews"
}, {
  "name": "Total SnSS clicks",
  "property": "string",
  "tooltip": "Number of new Subscribe & Save subscriptions for the brands’ products, attributed to an ad click. This does not include replenishment subscription orders.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "totalSubscribeAndSaveSubscriptionClicks"
}, {
  "name": "Total SnSS rate",
  "property": "string",
  "tooltip": "Rate of ad-attributed Subscribe & Save conversions for the brands’ products relative to ad impressions. (Total SnSSR = Total SnSS / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, E-commerce conversions",
  "key": "totalSubscribeAndSaveSubscriptionCVR"
}, {
  "name": "Total eCPSnSS",
  "property": "string",
  "tooltip": "Effective (average) cost to acquire a Subscribe & Save subscription for the brands' products. (Total eCPSnSS = Total cost / Total SnSS)",
  "fixedNumber": 2,
  "type": "Costs, E-commerce conversions",
  "key": "totalSubscribeAndSaveSubscriptionCPA"
}, {
  "name": "Total DPV",
  "property": "string",
  "tooltip": "Number of detail page views for all the brands’ products, attributed to an ad view or click.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "totalDetailPageViews"
}, {
  "name": "Total DPV views",
  "property": "string",
  "tooltip": "Number of detail page views for all the brands' products, attributed to an ad view.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "totalDetailPageViewViews"
}, {
  "name": "Total DPV clicks",
  "property": "string",
  "tooltip": "Number of detail page views for all the brands’ products, attributed to an ad click.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "totalDetailPageClicks"
}, {
  "name": "Total DPVR",
  "property": "string",
  "tooltip": "Detail page view rate for the brands’ products, relative to the number of ad impressions. (Total DPV / Impressions = Total DPVR)",
  "fixedNumber": 4,
  "type": "Rates, E-commerce conversions",
  "key": "totalDetailPageViewsCVR"
}, {
  "name": "Total eCPDPV",
  "property": "string",
  "tooltip": "Effective (average) cost to acquire a detail page view for a product in your brand. (Total eCPDPV = Total cost / Total DPV)",
  "fixedNumber": 2,
  "type": "Costs, E-commerce conversions",
  "key": "totalDetaiPageViewCPA"
}, {
  "name": "Total PRPV",
  "property": "string",
  "tooltip": "Number of times shoppers visited a product review page for the brands' products, attributed to an ad view or click.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "totalPRPV"
}, {
  "name": "Total PRPV views",
  "property": "string",
  "tooltip": "Number of times shoppers visited a product review page for the brands' products, attributed to an ad view.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "totalPRPVViews"
}, {
  "name": "Total PRPV clicks",
  "property": "string",
  "tooltip": "Number of times shoppers visited a product review page for the brands' products, attributed to an ad click.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "totalPRPVClicks"
}, {
  "name": "Total PRPVR",
  "property": "string",
  "tooltip": "Rate of product review page visits for the brands' products, relative to the number of ad impressions. (Total ATCR = Total ATC / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, E-commerce conversions",
  "key": "totalPRPVr"
}, {
  "name": "Total eCPPRPV",
  "property": "string",
  "tooltip": "Effective (average) cost to acquire a product review page conversion for the brands' products. (Total eCPPRPV = Total cost / Total PRPV)",
  "fixedNumber": 2,
  "type": "Costs, E-commerce conversions",
  "key": "totalECPPRPV"
}, {
  "name": "Total ATL",
  "property": "string",
  "tooltip": "Number of times shoppers added the brands' products to a wish list, gift list, or registry, attributed to an ad view or click.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "totalAddToList"
}, {
  "name": "Total ATL views",
  "property": "string",
  "tooltip": "Number of times shoppers added the brands' products to a wish list, gift list or registry, attributed to an ad view.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "totalAddToListViews"
}, {
  "name": "Total ATL clicks",
  "property": "string",
  "tooltip": "Number of times shoppers added the brands' products to a wish list, gift list, or registry, attributed to an ad click.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "totalAddToListClicks"
}, {
  "name": "Total ATLR",
  "property": "string",
  "tooltip": "Rate of Add to List conversions for the brands' products relative to the number of impressions. (Total  ATLR =Total  ATL / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, E-commerce conversions",
  "key": "totalAddToListCVR"
}, {
  "name": "Total eCPATL",
  "property": "string",
  "tooltip": "Effective (average) cost to acquire an Add to List conversion for the brands' products. (Total eCPATL = Total cost / Total ATL)",
  "fixedNumber": 2,
  "type": "Costs, E-commerce conversions",
  "key": "totalAddToListCPA"
}, {
  "name": "Total ATC",
  "property": "string",
  "tooltip": "Number of times shoppers added the brands' products to their cart, attributed to an ad view or click.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "totalAddToCart"
}, {
  "name": "Total ATC views",
  "property": "string",
  "tooltip": "Number of times shoppers added the brands' products to their cart, attributed to an ad view.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "totalAddToCartViews"
}, {
  "name": "Total ATC clicks",
  "property": "string",
  "tooltip": "Number of times shoppers added the brands' products to their cart, attributed to an ad click.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "totalAddToCartClicks"
}, {
  "name": "Total ATCR",
  "property": "string",
  "tooltip": "Rate of Add to Cart conversions for the brands' products relative to the number of ad impressions. (Total ATCR = Total ATC / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, E-commerce conversions",
  "key": "totalAddToCartCVR"
}, {
  "name": "Total eCPATC",
  "property": "string",
  "tooltip": "Effective (average) cost to acquire an Add to Cart conversion for the brands' products. (Total eCPATC = Total cost / Total ATC)",
  "fixedNumber": 2,
  "type": "Costs, E-commerce conversions",
  "key": "totalAddToCartCPA"
}, {
  "name": "Total purchases",
  "property": "string",
  "tooltip": "Number of times any quantity of a brand product was included in a purchase event, attributed to an ad view or click. Purchase events include new Subscribe & Save subscriptions and video rentals.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "totalPurchases"
}, {
  "name": "Total purchases views",
  "property": "string",
  "tooltip": "Number of times any quantity of a brand product was included in a purchase event, attributed to an ad view. Purchase events include new Subscribe & Save subscriptions and video rentals.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "totalPurchasesViews"
}, {
  "name": "Total purchases clicks",
  "property": "string",
  "tooltip": "Number of times any quantity of a brand product was included in a purchase event, attributed to an ad click. Purchase events include new Subscribe & Save subscriptions and video rentals.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "totalPurchasesClicks"
}, {
  "name": "Total purchase rate",
  "property": "string",
  "tooltip": "Rate of ad-attributed purchase events for the brands' products relative to ad impressions. (Total purchase rate = Total purchases / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, E-commerce conversions",
  "key": "totalPurchaseRate"
}, {
  "name": "Total eCPP",
  "property": "string",
  "tooltip": "Effective (average) cost to acquire a purchase conversion for the brands' products. (Total eCPP = Total cost / Total purchases)",
  "fixedNumber": 2,
  "type": "Costs, E-commerce conversions",
  "key": "totalECPP"
}, {
  "name": "Total new-to-brand purchases",
  "property": "string",
  "tooltip": "Number of new-to-brand purchases for the brands’ products, attributed to an ad view or click. Shoppers are \"new to brand\" if they have not purchased from the brand in the last 365 days.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "totalNewToBrandPurchases"
}, {
  "name": "Total new-to-brand purchases views",
  "property": "string",
  "tooltip": "Number of new-to-brand purchases for the brands’ products, attributed to an ad view. Shoppers are \"new to brand\" if they have not purchased from the brand in the last 365 days.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "totalNewToBrandPurchasesViews"
}, {
  "name": "Total new-to-brand purchases clicks",
  "property": "string",
  "tooltip": "Number of new-to-brand purchases for the brands’ products, attributed to an ad click. Shoppers are \"new to brand\" if they have not purchased from the brand in the last 365 days.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "totalNewToBrandPurchasesClicks"
}, {
  "name": "Total new-to-brand purchase rate",
  "property": "string",
  "tooltip": "Rate of new-to-brand purchase conversions for the brands' products relative to the number of ad impressions. (Total new-to-brand purchase rate = Total new-to-brand purchases / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, E-commerce conversions",
  "key": "totalNewToBrandPurchaseRate"
}, {
  "name": "Total new-to-brand eCPP",
  "property": "string",
  "tooltip": "Effective (average) cost to acquire a new-to-brand purchase conversion for the brands' products. (Total new-to-brand eCPP = Total cost / Total new-to-brand purchases)",
  "fixedNumber": 2,
  "type": "Costs, E-commerce conversions",
  "key": "totalNewToBrandECPP"
}, {
  "name": "Total percent of purchases new-to-brand",
  "property": "string",
  "tooltip": "Percent of purchase conversions for the brands’ products that were new to brand.",
  "fixedNumber": 2,
  "type": "Rates, E-commerce conversions",
  "key": "totalPercentOfPurchasesNewToBrand"
}, {
  "name": "Total new-to-brand units sold",
  "property": "string",
  "tooltip": "Units of the brands' products purchased by shoppers who were new-to-brand, attributed to an ad view or click. A single new-to-brand purchase event can include multiple sold units.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "totalNewToBrandUnitsSold"
}, {
  "name": "Total new-to-brand product sales",
  "property": "money",
  "tooltip": "Sales (in local currency) of the brands’ products purchased by new-to-brand shoppers, attributed to an ad view or click. Shoppers are \"new to brand\" if they have not purchased from the brand in the last 365 days.",
  "fixedNumber": 2,
  "type": "E-commerce conversions",
  "key": "totalNewToBrandProductSales"
}, {
  "name": "Total new-to-brand ROAS",
  "property": "string",
  "tooltip": "Return on advertising spend for new-to-brand purchases, measured as ad-attributed new-to-brand sales for the brands' products per local currency unit of ad spend. (Total new-to-brand ROAS = Total new to brand product sales / Total cost)",
  "fixedNumber": 2,
  "type": "E-commerce conversions",
  "key": "totalNewToBrandROAS"
}, {
  "name": "Total new-to-brand eRPM",
  "property": "string",
  "tooltip": "Effective (average) revenue generated per thousand impressions from the brands’ products purchased by new-to-brand shoppers. (Total new-to-brand eRPM = Total new-to-brand Sales / (Impressions / 1000))",
  "fixedNumber": 2,
  "type": "Costs, E-commerce conversions",
  "key": "totalNewToBrandERPM"
}, {
  "name": "Branded searches",
  "property": "string",
  "tooltip": "Number of brand search for promoted products, attributed to an ad view or click.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "brandSearch"
}, {
  "name": "Branded search views",
  "property": "string",
  "tooltip": "Number of brand search for promoted products, attributed to an ad view.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "brandSearchViews"
}, {
  "name": "Branded search clicks",
  "property": "string",
  "tooltip": "Number of brand search for promoted products, attributed to an ad click.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "brandSearchClicks"
}, {
  "name": "Branded search rate",
  "property": "string",
  "tooltip": "Brand search rate for promoted products relative to the number of ad impressions. (Branded search rate = Branded search / Impressions)",
  "fixedNumber": 4,
  "type": "Rates, E-commerce conversions",
  "key": "brandSearchRate"
}, {
  "name": "Cost per branded search",
  "property": "string",
  "tooltip": "Effective (average) cost to acquire a brand search for a promoted product. (Cost per branded search = Total cost / Branded search) ",
  "fixedNumber": 2,
  "type": "Costs, E-commerce conversions",
  "key": "brandSearchCPA"
}, {
  "name": "Invalid Impressions",
  "property": "string",
  "tooltip": "The number of impressions removed by a traffic quality filter. This includes potentially fraudulent, non-human, and other illegitimate traffic.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "invalidImpressions"
}, {
  "name": "Invalid Click-Throughs",
  "property": "string",
  "tooltip": "Clicks that were removed by the traffic quality filter. This includes potentially fraudulent, non-human, and other illegitimate traffic.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "invalidClickThroughs"
}, {
  "name": "Invalid Impression Rate",
  "property": "string",
  "tooltip": "The percentage of gross impressions that were removed by the traffic quality filter. (Invalid impression rate = invalid impressions / gross impressions)",
  "fixedNumber": 4,
  "type": "Rates, E-commerce conversions",
  "key": "invalidImpressionRate"
}, {
  "name": "Invalid Click-Throughs Rate",
  "property": "string",
  "tooltip": "The percentage of gross click-thoroughs that were removed by the traffic quality filter. (Invalid click-throughs rate = invalid click-throughs / gross click-throughs)",
  "fixedNumber": 2,
  "type": "Rates, E-commerce conversions",
  "key": "invalidClickThroughsRate"
}, {
  "name": "Gross Impressions",
  "property": "string",
  "tooltip": "The total number of times the ad was displayed. This includes valid and invalid impressions such as potentially fraudulent, non-human, and other illegitimate impressions.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "grossImpressions"
}, {
  "name": "Gross Click-Throughs",
  "property": "string",
  "tooltip": "The total number of times the ad was clicked. This includes valid, potentially fraudulent, non-human, and other illegitimate clicks.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "grossClickThroughs"
}, {
  "name": "ATW",
  "property": "string",
  "tooltip": "Number of times shoppers added a promoted product to their watchlist, attributed to an ad view or click.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "addToWatchlist"
}, {
  "name": "ATW views",
  "property": "string",
  "tooltip": "Number of times shoppers added a promoted product to their watchlist, attributed to an ad view.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "addToWatchlistViews"
}, {
  "name": "ATW clicks",
  "property": "string",
  "tooltip": "Number of times shoppers added a promoted product to their watchlist, attributed to an ad click.",
  "fixedNumber": 0,
  "type": "E-commerce conversions",
  "key": "addToWatchlistClicks"
}, {
  "name": "ATWR",
  "property": "string",
  "tooltip": "Rate of Add to Watchlist conversions relative to the number of impressions. (ATWLR = ATWL / Impressions).",
  "fixedNumber": 4,
  "type": "E-commerce conversions, Rates",
  "key": "addToWatchlistCVR"
}, {
  "name": "eCPATW",
  "property": "string",
  "tooltip": "Effective (average) cost to acquire an Add to Watchlist conversion for a promoted product. (eCPATWL = Total cost / ATWL).",
  "fixedNumber": 2,
  "type": "E-commerce conversions, Costs",
  "key": "addToWatchlistCPA"
}, {
  "name": "Order",
  "property": "string",
  "tooltip": "A name for the order that will appear in AAP. You can click the order name to see its line items",
  "type": "General",
  "key": "orderName"
}, {
  "name": "Budget",
  "property": "string",
  "tooltip": "The budget set for the order.",
  "type": "General",
  "key": "orderBudget"
}, {
  "name": "Line item",
  "property": "string",
  "tooltip": "The ad format, media, budget, flight, targeting, and fee settings used to distribute ads. You can click the line item name to see all creatives associated with it.",
  "type": "General",
  "key": "lineItemName"
}, {
  "name": "Budget",
  "property": "string",
  "tooltip": "The budget set for the line item.",
  "type": "General",
  "key": "lineItemBudget"
}, {
  "name": "Start",
  "property": "string",
  "tooltip": "The first date for the line item's flight.",
  "type": "General",
  "key": "lineItemStartDate"
}, {
  "name": "End",
  "property": "string",
  "tooltip": "The last date for the line item's flight.",
  "type": "General",
  "key": "lineItemEndDate"
}, {
  "name": "Creative",
  "property": "string",
  "tooltip": "The creative asset used to populate an ad slot.",
  "type": "General",
  "key": "creativeName"
}, {
  "name": "Placement",
  "property": "string",
  "tooltip": "The name of the placement the campaign was run.",
  "type": "General",
  "key": "placementName"
}, {
  "name": "Placement size",
  "property": "string",
  "tooltip": "The dimensions of the placement in pixels.",
  "type": "General",
  "key": "placementSize"
}, {
  "name": "Marketplace",
  "property": "number",
  "tooltip": "Defines the language used for AdChoices and which Amazon site it links to.",
  "type": "General",
  "key": "marketplace"
}, {
  "name": "Start",
  "property": "string",
  "tooltip": "The first date for the order's flight.",
  "type": "General",
  "key": "orderStartDate"
}, {
  "name": "End",
  "property": "string",
  "tooltip": "The last date for the order's flight.",
  "type": "General",
  "key": "orderEndDate"
}, {
  "name": "Line item type",
  "property": "string",
  "tooltip": "The classification of the line item (for example standard display, mobile app, third party video, etc.).",
  "type": "General",
  "key": "lineitemtype"
}, {
  "name": "Creative size（后台Size字段）",
  "property": "string",
  "tooltip": "The dimensions of the creative in pixels.",
  "type": "General",
  "key": "creativeSize"
}, {
  "name": "Entity",
  "property": "string",
  "tooltip": "投放广告的主体机构。接口获取不到，暂时决定通过CRM系统获取。",
  "type": "General",
  "key": "--"
}, {
  "name": "Entity ID",
  "property": "string",
  "tooltip": "投放广告的主体机构id，通过接口获取，在页面上并不显示",
  "type": "General",
  "key": "entityId"
}, {
  "name": "Advertiser ID",
  "property": "string",
  "tooltip": "广告主的id",
  "type": "General",
  "key": "advertiserId"
}, {
  "name": "Advertiser country",
  "property": "string",
  "tooltip": "广告主所在国家、站点",
  "type": "General",
  "key": "advertiserCountry"
}, {
  "name": "Advertiser timezone",
  "property": "string",
  "tooltip": "广告主所在国家的时区",
  "type": "General",
  "key": "advertiserTimezone"
}, {
  "name": "Brand",
  "property": "string",
  "tooltip": "用户产品所属的品牌，与SaaS系统内的品牌定义相同。只是这里的品牌数据由系统根据DSP数据解析得到，而不是通过接口直接获取。",
  "type": "General",
  "key": "brandName"
}, {
  "name": "Product line",
  "property": "string",
  "tooltip": "用户产品所属的产品线，由用户自行定义，在DSP服务过程中告知Flywheel。Flywheel在DSP广告order、line item等数据的命名中体现。系统在获取数据后进行拆解得到。",
  "type": "General",
  "key": "oProductLine"
}, {
  "name": "Funnel",
  "property": "string",
  "tooltip": "DSP广告转化策略阶段，一般属于AW－CS－RT－LY中的一个。Flywheel在DSP广告order、line item等数据的命名中体现。系统在获取数据后进行拆解得到。",
  "type": "General",
  "key": "oFunnel"
}, {
  "name": "Audience",
  "property": "string",
  "tooltip": "DSP广告的投放人群，比如过去14天浏览过某个商品的人。人群在投放计划中确认并制定，Flywheel在创建DSP广告order、line item等数据的命名中体现。系统在获取数据后进行拆解得到。",
  "type": "General",
  "key": "oAudience"
}, {
  "name": "Landing page",
  "property": "string",
  "tooltip": "DSP广告投放后的点击行为落地页，落地页在投放计划中确认并制定，Flywheel在创建DSP广告order、line item等数据的命名中体现。系统在获取数据后进行拆解得到。",
  "type": "General",
  "key": "oLandingPage"
}, {
  "name": "Goal KPI",
  "property": "string",
  "tooltip": "DSP广告的投放目标，配置广告时选择，类型固定，比如PURCHASE、CTR等，Flywheel在DSP广告order等数据的命名中体现。系统在获取数据后进行拆解得到。",
  "type": "General",
  "key": "oGoal"
}, {
  "name": "Inventory",
  "property": "string",
  "tooltip": "DSP广告的投放渠道，一般分为INSIDE、OUTSIDE两种类型，指将广告投放到亚马逊站内或站外。Inventory也成为site，在投放计划中确认并制定，Flywheel在创建DSP广告order、line item等数据的命名中体现。系统在获取数据后进行拆解得到。",
  "type": "General",
  "key": "lInventory"
}, {
  "name": "Device",
  "property": "string",
  "tooltip": "DSP广告投放的设备，一般分为PC端、MOB端两种类型，指广告投放在个人电脑或手机上，Flywheel在创建DSP广告order、line item等数据的命名中体现。系统在获取数据后进行拆解得到。",
  "type": "General",
  "key": "lDevice"
}, {
  "name": "Creative",
  "property": "string",
  "tooltip": "DSP广告的创意素材，比如图片、视频等内容，一般由亚马逊系统自动生成，也可以由卖家或投放机构提供自定义的图片、视频。",
  "type": "General",
  "key": "creativeName"
}, {
  "name": "Total fee",
  "property": "string",
  "fixedNumber": 2,
  "type": "General",
  "key": "totalFee"
}, {
  "name": "3P fee - automotive",
  "property": "money",
  "fixedNumber": 2,
  "type": "3p",
  "key": "x3pFeeAutomotive"
}, {
  "name": "3P fee - automotive (absorbed)",
  "property": "money",
  "fixedNumber": 2,
  "type": "3p",
  "key": "x3pFeeAutomotiveAbsorbed"
}, {
  "name": "3P fee - ComScore",
  "property": "money",
  "fixedNumber": 2,
  "type": "3p",
  "key": "x3pFeeComScore"
}, {
  "name": "3P fee - ComScore (absorbed)",
  "property": "money",
  "fixedNumber": 2,
  "type": "3p",
  "key": "x3pFeeComScoreAbsorbed"
}, {
  "name": "3P fee - CPM 1",
  "property": "money",
  "fixedNumber": 2,
  "type": "3p",
  "key": "x3pFeeCPM1"
}, {
  "name": "3P fee - CPM 1 (absorbed)",
  "property": "money",
  "fixedNumber": 2,
  "type": "3p",
  "key": "x3pFeeCPM1Absorbed"
}, {
  "name": "3P fee - CPM 2",
  "property": "money",
  "fixedNumber": 2,
  "type": "3p",
  "key": "x3pFeeCPM2"
}, {
  "name": "3P fee - CPM 2 (absorbed)",
  "property": "money",
  "fixedNumber": 2,
  "type": "3p",
  "key": "x3pFeeCPM2Absorbed"
}, {
  "name": "3P fee - CPM 3",
  "property": "money",
  "fixedNumber": 2,
  "type": "3p",
  "key": "x3pFeeCPM3"
}, {
  "name": "3P fee - CPM 3 (absorbed)",
  "property": "money",
  "fixedNumber": 2,
  "type": "3p",
  "key": "x3pFeeCPM3Absorbed"
}, {
  "name": "3P fee - DoubleClick Campaign Manager",
  "property": "money",
  "fixedNumber": 2,
  "type": "3p",
  "key": "x3pFeeDoubleclickCampaignManager"
}, {
  "name": "3P fee - Doubleclick Campaign Manager (absorbed)",
  "property": "money",
  "fixedNumber": 2,
  "type": "3p",
  "key": "x3pFeeDoubleclickCampaignManagerAbsorbed"
}, {
  "name": "3P fee - DoubleVerify",
  "property": "money",
  "fixedNumber": 2,
  "type": "3p",
  "key": "x3pFeeDoubleVerify"
}, {
  "name": "3P fee - DoubleVerify (absorbed)",
  "property": "money",
  "fixedNumber": 2,
  "type": "3p",
  "key": "x3pFeeDoubleVerifyAbsorbed"
}, {
  "name": "3P fee - Integral Ad Science",
  "property": "money",
  "fixedNumber": 2,
  "type": "3p",
  "key": "x3pFeeIntegralAdScience"
}, {
  "name": "3P fee - Integral Ad Science (absorbed)",
  "property": "money",
  "fixedNumber": 2,
  "type": "3p",
  "key": "x3pFeeIntegralAdScienceAbsorbed"
}, {
  "name": "Downloaded video plays",
  "property": "string",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "downloadedVideoPlays"
}, {
  "name": "Downloaded video plays views",
  "property": "string",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "downloadedVideoPlaysViews"
}, {
  "name": "Downloaded video plays clicks",
  "property": "string",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "downloadedVideoPlaysClicks"
}, {
  "name": "Downloaded video plays rate",
  "property": "string",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions",
  "key": "downloadedVideoPlayRate"
}, {
  "name": "eCPDVP",
  "property": "number",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions",
  "key": "eCPDVP"
}, {
  "name": "Play trailers",
  "property": "string",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "playTrailers"
}, {
  "name": "Play trailers views",
  "property": "string",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "playTrailersViews"
}, {
  "name": "Play trailers clicks",
  "property": "string",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "playerTrailersClicks"
}, {
  "name": "Play trailers rate",
  "property": "string",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions",
  "key": "playTrailerRate"
}, {
  "name": "eCPPT",
  "property": "number",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions",
  "key": "eCPPT"
}, {
  "name": "Rentals",
  "property": "number",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "rentals"
}, {
  "name": "Rentals views",
  "property": "string",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "rentalsViews"
}, {
  "name": "Rentals clicks",
  "property": "string",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "rentalsClicks"
}, {
  "name": "Rentals rate",
  "property": "string",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions",
  "key": "rentalRate"
}, {
  "name": "eCPR",
  "property": "money",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions",
  "key": "ecpr"
}, {
  "name": "Video downloads",
  "property": "string",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "videoDownloads"
}, {
  "name": "Video downloads views",
  "property": "string",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "videoDownloadsViews"
}, {
  "name": "Video downloads clicks",
  "property": "string",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "videoDownloadsClicks"
}, {
  "name": "Video downloads rate",
  "property": "string",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions",
  "key": "videoDownloadRate"
}, {
  "name": "eCPVD",
  "property": "money",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions",
  "key": "ecpvd"
}, {
  "name": "Video streams",
  "property": "string",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "videoStreams"
}, {
  "name": "Video streams views",
  "property": "string",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "videoStreamsViews"
}, {
  "name": "Video streams clicks",
  "property": "string",
  "fixedNumber": 0,
  "type": "Off-Amazon conversions",
  "key": "videoStreamsClicks"
}, {
  "name": "Video streams rate",
  "property": "string",
  "fixedNumber": 4,
  "type": "Rates, Off-Amazon conversions",
  "key": "videoStreamsRate"
}, {
  "name": "eCPVS",
  "property": "money",
  "fixedNumber": 2,
  "type": "Costs, Off-Amazon conversions",
  "key": "ecpvs"
}, {
  "name": "DPV BH",
  "property": "string",
  "type": "E-commerce conversions",
  "key": "brandHaloDetailPage"
}, {
  "name": "DPV views BH",
  "property": "string",
  "type": "E-commerce conversions",
  "key": "brandHaloDetailPageViews"
}, {
  "name": "DPV clicks BH",
  "property": "string",
  "type": "E-commerce conversions",
  "key": "brandHaloDetailPageClicks"
}, {
  "name": "PRPV BH",
  "property": "string",
  "type": "E-commerce conversions",
  "key": "brandHaloProductReviewPage"
}, {
  "name": "PRPV views BH",
  "property": "string",
  "type": "E-commerce conversions",
  "key": "brandHaloProductReviewPageViews"
}, {
  "name": "PRPV clicks BH",
  "property": "string",
  "type": "E-commerce conversions",
  "key": "brandHaloProductReviewPageClicks"
}, {
  "name": "ATL BH",
  "property": "string",
  "type": "E-commerce conversions",
  "key": "brandHaloAddToList"
}, {
  "name": "ATL views BH",
  "property": "string",
  "type": "E-commerce conversions",
  "key": "brandHaloAddToListViews"
}, {
  "name": "ATL clicks BH",
  "property": "string",
  "type": "E-commerce conversions",
  "key": "brandHaloAddToListClicks"
}, {
  "name": "ATC BH",
  "property": "string",
  "type": "E-commerce conversions",
  "key": "brandHaloAddToCart"
}, {
  "name": "ATC views BH",
  "property": "string",
  "type": "E-commerce conversions",
  "key": "brandHaloAddToCartViews"
}, {
  "name": "ATC clicks BH",
  "property": "string",
  "type": "E-commerce conversions",
  "key": "brandHaloAddToCartClicks"
}, {
  "name": "Purchases BH",
  "property": "string",
  "type": "E-commerce conversions",
  "key": "brandHaloPurchases"
}, {
  "name": "Purchases views BH",
  "property": "string",
  "type": "E-commerce conversions",
  "key": "brandHaloPurchasesViews"
}, {
  "name": "Purchases clicks BH",
  "property": "string",
  "type": "E-commerce conversions",
  "key": "brandHaloPurchasesClicks"
}, {
  "name": "New-to-brand purchases BH",
  "property": "string",
  "type": "E-commerce conversions",
  "key": "brandHaloNewToBrandPurchases"
}, {
  "name": "New-to-brand purchases views BH",
  "property": "string",
  "type": "E-commerce conversions",
  "key": "brandHaloNewToBrandPurchasesViews"
}, {
  "name": "New-to-brand purchases clicks BH",
  "property": "string",
  "type": "E-commerce conversions",
  "key": "brandHaloNewToBrandPurchasesClicks"
}, {
  "name": "Percent of purchases new-to-brand BH",
  "property": "string",
  "type": "Rates, E-commerce conversions",
  "key": "brandHaloPercentOfPurchasesNewToBrand"
}, {
  "name": "SnSS BH",
  "property": "string",
  "type": "E-commerce conversions",
  "key": "brandHaloNewSubscribeAndSave"
}, {
  "name": "SnSS views BH",
  "property": "string",
  "type": "E-commerce conversions",
  "key": "brandHaloNewSubscribeAndSaveViews"
}, {
  "name": "SnSS clicks BH",
  "property": "string",
  "type": "E-commerce conversions",
  "key": "brandHaloNewSubscribeAndSaveClicks"
}, {
  "name": "Total units sold BH",
  "property": "string",
  "type": "E-commerce conversions",
  "key": "brandHaloTotalUnitsSold"
}, {
  "name": "Total Sales BH",
  "property": "money",
  "type": "E-commerce conversions",
  "key": "brandHaloTotalSales"
}, {
  "name": "New-to-brand product sales BH USD",
  "property": "string",
  "type": "E-commerce conversions",
  "key": "brandHaloTotalNewToBrandSales"
}, {
  "name": "New-to-brand units sold BH",
  "property": "string",
  "type": "E-commerce conversions",
  "key": "brandHaloTotalNewToBrandUnitsSold"
}, {
  "name": "Order ID",
  "property": "string",
  "type": "General",
  "key": "orderId"
}, {
  "name": "Order currency",
  "property": "string",
  "type": "General",
  "key": "orderCurrency"
}, {
  "name": "Line item ID",
  "property": "string",
  "type": "General",
  "key": "lineItemId"
}, {
  "name": "Creative ID",
  "property": "string",
  "type": "General",
  "key": "creativeID"
}, {
  "name": "Date",
  "property": "number",
  "tooltip": "请求接口/报告的日期？",
  "type": "General",
  "key": "date"
}, {
  "name": "Line item external ID",
  "property": "string",
  "type": "General",
  "key": "lineItemExternalId"
}, {
  "name": "Deal type",
  "property": "string",
  "type": "General",
  "key": "dealType"
}, {
  "property": "string",
  "type": "General",
  "key": "targetingMethod"
}, {
  "property": "string",
  "type": "General",
  "key": "segmentClassCode"
}, {
  "property": "string",
  "type": "General",
  "key": "segmentSource"
}, {
  "property": "string",
  "type": "General",
  "key": "segmentType"
}, {
  "property": "string",
  "type": "General",
  "key": "segmentMarketplaceID"
}, {
  "property": "string",
  "type": "General",
  "key": "reportGranularity"
}, {
  "property": "string",
  "type": "General",
  "key": "amazonStandardId"
}, {
  "name": "Order external ID",
  "property": "string",
  "type": "General",
  "key": "orderExternalId"
}, {
  "property": "string",
  "type": "General",
  "key": "creativeAdId"
}, {
  "name": "Creative type",
  "property": "string",
  "type": "General",
  "key": "creativeType"
}, {
  "name": "ASIN conversion type",
  "property": "string",
  "type": "General",
  "key": "asinConversionType"
}, {
  "name": "Parent ASIN",
  "property": "string",
  "type": "General",
  "key": "parentASIN"
}, {
  "name": "Featured ASIN",
  "property": "string",
  "type": "General",
  "key": "featuredASIN"
}, {
  "name": "Product name",
  "property": "string",
  "type": "General",
  "key": "productName"
}, {
  "name": "Product group",
  "property": "string",
  "type": "General",
  "key": "productGroup"
}, {
  "name": "Product category",
  "property": "string",
  "type": "General",
  "key": "productCategory"
}, {
  "name": "Product subcategory",
  "property": "string",
  "type": "General",
  "key": "productSubcategory"
}, {
  "name": "Units sold BH",
  "property": "string",
  "type": "E-commerce conversions"
}, {
  "name": "PO number",
  "property": "string",
  "tooltip": "External ID and PO number are the same.",
  "type": "General"
}, {
  "name": "Type",
  "property": "string",
  "tooltip": "The ad format visually displayed.",
  "type": "General"
}, {
  "name": "Approval",
  "property": "string",
  "tooltip": "Waiting for line item: We haven't started to review this creative because it hasn't been added to a line item."
}, {
  "name": "Approved: This creative will deliver when the line item does.",
  "property": "string",
  "tooltip": "General"
}, {
  "name": "Active",
  "property": "string",
  "tooltip": "Use this to activate and deactivate line item-creative associations. Live line items won’t stop running unless all their associated creatives are deactivated.",
  "type": "General"
}, {
  "name": "Comments",
  "property": "string",
  "tooltip": "Comments are only visible inside of tool. Use this field to track variations in targeting, optimization notes, etc ...",
  "type": "General"
}, {
  "name": "Line items",
  "property": "string",
  "tooltip": "The number of active and inactive line items the creative is associated to.",
  "type": "General"
}, {
  "name": "Created",
  "property": "string",
  "tooltip": "The date of creative creation.",
  "type": "General"
}, {
  "name": "Cornerstone CFID",
  "property": "string",
  "tooltip": "A unique identifier for the creative.",
  "type": "General"
}, {
  "name": "Third party ad ID",
  "property": "string",
  "tooltip": "The unique identifier for the creative generated by the third party ad server.",
  "type": "General"
}, {
  "name": "Status explanation",
  "property": "string",
  "tooltip": "Creatives aren't reviewed until they are attached to an eligible line item. View the explanation for unapproved creatives next to the status.",
  "type": "General"
}, {
  "name": "Thumbnail",
  "property": "string",
  "tooltip": "A preview of the creative or creative size.",
  "type": "General"
}, {
  "name": "Last modified",
  "property": "string",
  "tooltip": "The date the creative was last modified.",
  "type": "General"
}, {
  "name": "Supply source",
  "property": "string",
  "tooltip": "The inventory the campaign ran on, for example real-time bidding exchanges or Amazon-owned sites.",
  "type": "General"
}, {
  "name": "Site",
  "property": "string",
  "tooltip": "The site or group of sites the campaign ran on.",
  "type": "General"
}, {
  "name": "Weight",
  "property": "string",
  "tooltip": "Determines how frequently creatives of the same size are shown (ex. a creative with 90% weight is shown 90% of the time that ad size is served).",
  "type": "General"
}, {
  "name": "Base bid",
  "property": "string",
  "tooltip": "Also known as media bid. This is the ideal amount you want to pay per thousand impressions for ad inventory. This price isn’t guaranteed, but Amazon optimizes its bidding strategy to try to meet this goal.",
  "type": "General"
}, {
  "name": "Maximum average CPM",
  "property": "string",
  "tooltip": "The maximum cost-per-thousand impressions bid for media supply.",
  "type": "General"
}, {
  "name": "Supply CPM",
  "property": "string",
  "tooltip": "The average cost per thousand impressions for supply.",
  "type": "General"
}, {
  "name": "Delivery rate",
  "property": "string",
  "tooltip": "The rate at which the order or line item is spending money relative to its budget and date range.",
  "type": "Traffic"
}, {
  "name": "Budget cap",
  "property": "string",
  "tooltip": "You can limit how much is spent per day and/or month to more carefully control budget spending. Notes: 1) This refers to calendar day or month, so applying this mid-day or month will assume the cap is for the remainder of that day or month. 2) Setting budget cap will affect pacing and may lead to over- or under-delivery depending on how high or low it’s set. You can set budget caps on both orders and line items. 3) The combined line item spend will never exceed the order’s budget cap, regardless of line item budget settings.",
  "type": "General"
}, {
  "name": "Frequency",
  "property": "string",
  "tooltip": "A set limit on the number of times an advertisement can be served to a unique user during a specific time period.",
  "type": "General"
}, {
  "name": "External ID",
  "property": "string",
  "tooltip": "If you're using an ID to track this creative elsewhere, you can enter it here. This ID will be available in all dashboards and downloadable reports.",
  "type": "General"
}, {
  "name": "Proposal line item ID",
  "property": "string",
  "tooltip": "Proposal line item ID",
  "type": "General"
}, {
  "name": "Off-Amazon ROAS (reconciled)",
  "property": "string",
  "tooltip": "Return on advertising spend for products included in off-Amazon purchase events, after reconciliation, measured as ad-attributed sales per local currency unit of ad spend. (Off-Amazon product sales / Total cost reconciled)",
  "type": "Off-Amazon conversions"
}, {
  "name": "Pixel convs.",
  "property": "string",
  "tooltip": "The number of pixel conversion events.",
  "type": "Rates, Pixel conversions"
}, {
  "name": "Pixel CVR",
  "property": "string",
  "tooltip": "The number of pixel conversion events relative to the number of impressions across all pixels.",
  "type": "Rates, Pixel conversions"
}, {
  "name": "Avg. impression frequency",
  "property": "string",
  "tooltip": "The average number of exposures per unique user for an ad.",
  "type": "Traffic"
}, {
  "name": "Combined ROAS (reconciled)",
  "property": "string",
  "tooltip": "Return on advertising spend for products sold on and off Amazon, after reconciliation, measured as attributed sales per local currency unit of ad spend. (Combined product sales / Total cost reconciled)",
  "type": "Combined conversions"
}, {
  "name": "Other",
  "property": "string",
  "tooltip": "The number of Other conversions.",
  "type": "Off-Amazon conversions"
}, {
  "name": "Other views",
  "property": "string",
  "tooltip": "The number of Other conversions attributed to an ad view.",
  "type": "Off-Amazon conversions"
}, {
  "name": "Other clicks",
  "property": "string",
  "tooltip": "The number of Other conversions attributed to an ad click.",
  "type": "Off-Amazon conversions"
}, {
  "name": "Other CVR",
  "property": "string",
  "tooltip": "The number of Other conversions relative to the number of ad impressions. (Other CVR = Other / Impressions)",
  "type": "Off-Amazon conversions, Rates"
}, {
  "name": "Other CPA",
  "property": "string",
  "tooltip": "The average cost to acquire an Other conversion. (Other CPA = Total cost / Other)",
  "type": "Off-Amazon conversions, Costs"
}, {
  "name": "Page view",
  "property": "string",
  "tooltip": "The number of Page view conversions.",
  "type": "Off-Amazon conversions"
}, {
  "name": "Page view views",
  "property": "string",
  "tooltip": "The number of Page view conversions attributed to an ad view.",
  "type": "Off-Amazon conversions"
}, {
  "name": "Page view clicks",
  "property": "string",
  "tooltip": "The number of Page view conversions attributed to an ad click.",
  "type": "Off-Amazon conversions"
}, {
  "name": "Page view CVR",
  "property": "string",
  "tooltip": "The number of Page view conversions relative to the number of ad impressions. (Page view CVR = Page view / Impressions)",
  "type": "Off-Amazon conversions, Rates"
}, {
  "name": "Page view CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Page view conversion. (Page view CPA = Total cost / Page view)",
  "type": "Off-Amazon conversions, Costs"
}, {
  "name": "Search",
  "property": "string",
  "tooltip": "The number of Search conversions.",
  "type": "Off-Amazon conversions"
}, {
  "name": "Search views",
  "property": "string",
  "tooltip": "The number of Search conversions attributed to an ad view.",
  "type": "Off-Amazon conversions"
}, {
  "name": "Search clicks",
  "property": "string",
  "tooltip": "The number of Search conversions attributed to an ad click.",
  "type": "Off-Amazon conversions"
}, {
  "name": "Search CVR",
  "property": "string",
  "tooltip": "The number of Search conversions relative to the number of ad impressions. (Search CVR = Search / Impressions)",
  "type": "Off-Amazon conversions, Rates"
}, {
  "name": "Search CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Search conversion. (Search CPA = Total cost / Search)",
  "type": "Off-Amazon conversions, Costs"
}, {
  "name": "Contact",
  "property": "string",
  "tooltip": "The number of Contact conversions.",
  "type": "Off-Amazon conversions"
}, {
  "name": "Contact views",
  "property": "string",
  "tooltip": "The number of Contact conversions attributed to an ad view.",
  "type": "Off-Amazon conversions"
}, {
  "name": "Contact clicks",
  "property": "string",
  "tooltip": "The number of Contact conversions attributed to an ad click.",
  "type": "Off-Amazon conversions"
}, {
  "name": "Contact CVR",
  "property": "string",
  "tooltip": "The number of Contact conversions relative to the number of ad impressions. (Contact CVR = Contact / Impressions)",
  "type": "Off-Amazon conversions, Rates"
}, {
  "name": "Contact CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Contact conversion. (Contact CPA = Total cost / Contact)",
  "type": "Off-Amazon conversions, Costs"
}, {
  "name": "Checkout",
  "property": "string",
  "tooltip": "The number of Checkout conversions.",
  "type": "Off-Amazon conversions"
}, {
  "name": "Checkout views",
  "property": "string",
  "tooltip": "The number of Checkout conversions attributed to an ad view.",
  "type": "Off-Amazon conversions"
}, {
  "name": "Checkout clicks",
  "property": "string",
  "tooltip": "The number of Checkout conversions attributed to an ad click.",
  "type": "Off-Amazon conversions"
}, {
  "name": "Checkout CVR",
  "property": "string",
  "tooltip": "The number of Checkout conversions relative to the number of ad impressions. (Checkout CVR = Checkout / Impressions)",
  "type": "Off-Amazon conversions, Rates"
}, {
  "name": "Checkout CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Checkout conversion. (Checkout CPA = Total cost / Checkout)",
  "type": "Off-Amazon conversions, Costs"
}, {
  "name": "Lead",
  "property": "string",
  "tooltip": "The number of Lead conversions.",
  "type": "Off-Amazon conversions"
}, {
  "name": "Lead views",
  "property": "string",
  "tooltip": "The number of Lead conversions attributed to an ad view.",
  "type": "Off-Amazon conversions"
}, {
  "name": "Lead clicks",
  "property": "string",
  "tooltip": "The number of Lead conversions attributed to an ad click.",
  "type": "Off-Amazon conversions"
}, {
  "name": "Lead CVR",
  "property": "string",
  "tooltip": "The number of Lead conversions relative to the number of ad impressions. (Lead CVR = Lead / Impressions)",
  "type": "Off-Amazon conversions, Rates"
}, {
  "name": "Lead CPA",
  "property": "string",
  "tooltip": "The average cost to acquire a Lead conversion. (Lead CPA = Total cost / Lead)",
  "type": "Off-Amazon conversions, Costs"
}, {
  "name": "Subscription sign-ups",
  "property": "string",
  "tooltip": "The number of free trial and paid subscriptions for sponsored products associated with a click or view on your ad.",
  "type": "E-commerce conversions"
}, {
  "name": "Subscription sign-up views",
  "property": "string",
  "tooltip": "The number of free trial and paid subscriptions for sponsored products associated with ad impressions.",
  "type": "E-commerce conversions"
}, {
  "name": "Subscription sign-up clicks",
  "property": "string",
  "tooltip": "The number of free trial and paid subscriptions for sponsored products associated with a click on your ad.",
  "type": "E-commerce conversions"
}, {
  "name": "Subscription sign-up rate",
  "property": "string",
  "tooltip": "The ratio of how often customers signed up for a free trial or paid subscription for sponsored products when you ad was displayed. This is calculated as free trials and paid subscriptions divided by impressions.",
  "type": "Rates, E-commerce conversions"
}, {
  "name": "Cost per subscription",
  "property": "string",
  "tooltip": "The cost to acquire a free trial or paid subscription for sponsored products. This is calculated as total cost divided by subscription sign ups.",
  "type": "Costs, E-commerce conversions"
}, {
  "name": "Free trial subscription sign-ups",
  "property": "string",
  "tooltip": "The number of free trial subscriptions for sponsored products associated with a click or view on your ad.",
  "type": "E-commerce conversions"
}, {
  "name": "Free trial subscription sign-up views",
  "property": "string",
  "tooltip": "The number of free trial subscriptions for sponsored products associated with ad impressions.",
  "type": "E-commerce conversions"
}, {
  "name": "Free trial subscription sign-up clicks",
  "property": "string",
  "tooltip": "The number of free trial subscriptions for sponsored products associated with a click on your ad.",
  "type": "E-commerce conversions"
}, {
  "name": "Free trial subscription sign-up rate",
  "property": "string",
  "tooltip": "The ratio of how often customers signed up for a free trial for sponsored products when you ad was displayed. This is calculated as free trials divided by impressions.",
  "type": "Rates, E-commerce conversions"
}, {
  "name": "Cost per free trial subscription",
  "property": "string",
  "tooltip": "The cost to acquire a free trial subscription for sponsored products. This is calculated as total cost divided by free trial subscription sign ups.",
  "type": "Costs, E-commerce conversions"
}, {
  "name": "Paid subscription sign-ups",
  "property": "string",
  "tooltip": "The number of paid subscriptions for sponsored products associated with a click or view on your ad.",
  "type": "E-commerce conversions"
}, {
  "name": "Paid subscription sign-up views",
  "property": "string",
  "tooltip": "The number of paid subscriptions for sponsored products associated with ad impressions.",
  "type": "E-commerce conversions"
}, {
  "name": "Paid subscription sign-up clicks",
  "property": "string",
  "tooltip": "The number of paid subscriptions for sponsored products associated with a click on your ad.",
  "type": "E-commerce conversions"
}, {
  "name": "Paid subscription sign-up rate",
  "property": "string",
  "tooltip": "The ratio of how often customers signed up for a paid subscription for sponsored products when you ad was displayed. This is calculated as paid subscriptions divided by impressions.",
  "type": "Rates, E-commerce conversions"
}, {
  "name": "Cost per paid subscription",
  "property": "string",
  "tooltip": "The cost to acquire a paid subscription for sponsored products. This is calculated as total cost divided by paid subscription sign ups.",
  "type": "Costs, E-commerce conversions"
}, {
  "name": "Alexa skill enable",
  "property": "string",
  "tooltip": "The number of views of the advertised product's Alexa skill enable.",
  "type": "App conversions"
}, {
  "name": "Alexa skill enable views",
  "property": "string",
  "tooltip": "The number of alexa skill enable conversions attributed to ad impressions.",
  "type": "App conversions"
}, {
  "name": "Alexa skill enable clicks",
  "property": "string",
  "tooltip": "The number of alexa skill enable conversions attributed to ad click-throughs.",
  "type": "App conversions"
}, {
  "name": "Alexa skill enable rate",
  "property": "string",
  "tooltip": "The number of Alexa skill enable conversions relative to the number of ad impressions. (Alexa skill enable rate = Alexa skill enable / Impressions)",
  "type": "App conversions, Rates"
}, {
  "name": "eCP Alexa skill enable",
  "property": "string",
  "tooltip": "The average cost to acquire an Alexa skill Enable conversion on a Kindle E Ink device. (eCP Alexa skill enable = Total cost / Alexa skill enable)",
  "type": "App conversions, Costs"
}, {
  "name": "Audio view",
  "property": "string",
  "tooltip": "Tracks the number of times that some playback of the audio ad has occurred.",
  "type": "Audio"
}, {
  "name": "Audio start",
  "property": "string",
  "tooltip": "Tracks the number of times audio ad starts playing.",
  "type": "Audio"
}, {
  "name": "Audio first quartile",
  "property": "string",
  "tooltip": "Tracks the number of times the audio ad plays to 25% of its length.",
  "type": "Audio"
}, {
  "name": "Audio midpoint",
  "property": "string",
  "tooltip": "Tracks the number of times the audio ad plays to 50% of its length.",
  "type": "Audio"
}, {
  "name": "Audio third quartile",
  "property": "string",
  "tooltip": "Tracks the number of times the audio ad plays to 75% of its length.",
  "type": "Audio"
}, {
  "name": "Audio complete",
  "property": "string",
  "tooltip": "Tracks the number of times the audio ad plays to the end.",
  "type": "Audio"
}, {
  "name": "Audio pause",
  "property": "string",
  "tooltip": "The number of times a user pauses the audio ad.",
  "type": "Audio"
}, {
  "name": "Audio resume",
  "property": "string",
  "tooltip": "Tracks the number of times a user resumes playback of the audio ad.",
  "type": "Audio"
}, {
  "name": "Audio rewind",
  "property": "string",
  "tooltip": "Tracks the number of times a user rewinds the audio ad.",
  "type": "Audio"
}, {
  "name": "Audio skip",
  "property": "string",
  "tooltip": "Tracks the number of times a user skips the audio ad.",
  "type": "Audio"
}, {
  "name": "Audio mute",
  "property": "string",
  "tooltip": "Tracks the number of times a user mutes the audio ad.",
  "type": "Audio"
}, {
  "name": "Audio unmute",
  "property": "string",
  "tooltip": "Tracks the number of times a user skips the audio ad.",
  "type": "Audio"
}, {
  "name": "Audio progress",
  "property": "string",
  "tooltip": "Tracks an optimal audio ad time marker agreed upon with the publisher.",
  "type": "Audio"
}, {
  "name": "Audio companion",
  "property": "string",
  "tooltip": "Tracks the number of times that the audio ad companion banner was displayed.",
  "type": "Audio"
}, {
  "name": "Audio click",
  "property": "string",
  "tooltip": "Tracks the number of times that the audio ad companion banner was clicked.",
  "type": "Audio"
}, {
  "name": "Audio completion rate",
  "property": "string",
  "tooltip": "The number of audio completions relative to the number of audio starts. (audio completion rate = audio complete / audio start)",
  "type": "Rates, Audio"
}, {
  "name": "eCPAC",
  "property": "string",
  "tooltip": "The average cost to acquire an audio complete conversion. (eCPVC = Total cost / audio complete)",
  "type": "Costs, Audio"
}, {
  "name": "On-target impressions",
  "property": "string",
  "tooltip": "The number of times an advertisement was displayed within the advertiser’s target demographic segment, as measured by Nielsen Digital Ad Ratings.",
  "type": "3p, Traffic"
}, {
  "name": "On-target total cost",
  "property": "string",
  "tooltip": "The total amount of money spent on the campaign when delivering on-target impressions.",
  "type": "3p, Costs, Traffic"
}, {
  "name": "On-target CPM",
  "property": "string",
  "tooltip": "The total cost per thousand on-target impressions.",
  "type": "3p, Costs, Traffic"
}, {
  "name": "Target demographic",
  "property": "string",
  "tooltip": "The Nielsen demographic segment the advertiser is transacting on.",
  "type": "3p, Traffic"
}, {
  "name": "App subscription sign-ups",
  "property": "string",
  "tooltip": "The number of free trial and paid app subscriptions associated with a click or view on your ad.",
  "type": "E-commerce conversions"
}, {
  "name": "App subscription sign-up views",
  "property": "string",
  "tooltip": "The number of free trial and paid app subscriptions associated with ad impressions.",
  "type": "E-commerce conversions"
}, {
  "name": "App subscription sign-up clicks",
  "property": "string",
  "tooltip": "The number of free trial and paid app subscriptions associated with a click on your ad.",
  "type": "E-commerce conversions"
}, {
  "name": "App subscription sign-up rate",
  "property": "string",
  "tooltip": "The ratio of how often customers signed up for a free trial or paid app subscription when your ad was displayed. This is calculated as app subscription sign-ups divided by impressions.",
  "type": "Rates, E-commerce conversions"
}, {
  "name": "Cost per app subscription",
  "property": "string",
  "tooltip": "The cost to acquire free trial and paid app subscriptions. This is calculated as total cost divided by app subscription sign-ups.",
  "type": "Costs, E-commerce conversions"
}, {
  "name": "Free trial app subscription sign-ups",
  "property": "string",
  "tooltip": "The number of free trial app subscriptions associated with a click or view on your ad.",
  "type": "E-commerce conversions"
}, {
  "name": "Free trial app subscription sign-up views",
  "property": "string",
  "tooltip": "The number of free trial app subscriptions associated with ad impressions.",
  "type": "E-commerce conversions"
}, {
  "name": "Free trial app subscription sign-up clicks",
  "property": "string",
  "tooltip": "The number of free trial app subscriptions associated with a click on your ad.",
  "type": "E-commerce conversions"
}, {
  "name": "Free trial app subscription sign-up rate",
  "property": "string",
  "tooltip": "The ratio of how often customers signed up for a free trial subscription when your ad was displayed. This is calculated as free trial app subscription sign-ups divided by impressions.",
  "type": "Rates, E-commerce conversions"
}, {
  "name": "Cost per free trial app subscription",
  "property": "string",
  "tooltip": "The cost to acquire free trial app subscriptions. This is calculated as total cost divided by free trial app subscription sign-ups.",
  "type": "Costs, E-commerce conversions"
}, {
  "name": "Paid app subscription sign-ups",
  "property": "string",
  "tooltip": "The number of paid app subscriptions associated with a click or view on your ad.",
  "type": "E-commerce conversions"
}, {
  "name": "Paid app subscription sign-ups views",
  "property": "string",
  "tooltip": "The number of paid app subscriptions associated with ad impressions.",
  "type": "E-commerce conversions"
}, {
  "name": "Paid app subscription sign-ups clicks",
  "property": "string",
  "tooltip": "The number of paid app subscriptions associated with a click on your ad.",
  "type": "E-commerce conversions"
}, {
  "name": "Paid app subscription sign-up rate",
  "property": "string",
  "tooltip": "The ratio of how often customers signed up for a paid app subscription when your ad was displayed. This is calculated as paid app subscription sign-ups divided by impressions.",
  "type": "Rates, E-commerce conversions"
}, {
  "name": "Cost per paid app subscription",
  "property": "string",
  "tooltip": "The cost to acquire paid app subscriptions. This is calculated as total cost divided by paid app subscription sign-ups.",
  "type": "Costs, E-commerce conversions"
}, {
  "name": "Appstore opens",
  "property": "string",
  "tooltip": "The number of first-time and recurring app opens attributed to a click or view on an ad.",
  "type": "App conversions"
}, {
  "name": "Appstore opens views",
  "property": "string",
  "tooltip": "The number of first-time and recurring app opens attributed to ad views.",
  "type": "App conversions"
}, {
  "name": "Appstore opens clicks",
  "property": "string",
  "tooltip": "The number of first-time and recurring app opens attributed to a click on an ad.",
  "type": "App conversions"
}, {
  "name": "Appstore open rate",
  "property": "string",
  "tooltip": "The ratio of app opens to ad impressions. This is calculated as first-time and recurring app opens divided by impressions.",
  "type": "Rates, App conversions"
}, {
  "name": "Cost per appstore opens",
  "property": "string",
  "tooltip": "The cost to acquire app opens. This is calculated as total cost divided by first-time and recurring app opens.",
  "type": "Costs, App conversions"
}, {
  "name": "Appstore first opens",
  "property": "string",
  "tooltip": "The number of first-time app opens attributed to a click or view on an ad.",
  "type": "App conversions"
}, {
  "name": "Appstore first-open views",
  "property": "string",
  "tooltip": "The number of first-time app opens attributed to ad views.",
  "type": "App conversions"
}, {
  "name": "Appstore first-open clicks",
  "property": "string",
  "tooltip": "The number of first-time app opens attributed to a click on an ad.",
  "type": "App conversions"
}, {
  "name": "Appstore first-open rate",
  "property": "string",
  "tooltip": "The ratio of how often customers opened the app for the first time when an ad was displayed. This is calculated as first app opens divided by impressions.",
  "type": "Rates, App conversions"
}, {
  "name": "Cost per appstore first open",
  "property": "string",
  "tooltip": "The cost to acquire a first-time app open. This is calculated as total cost divided by first app opens.",
  "type": "Costs, App conversions"
}, {
  "name": "Appstore first-session hours",
  "property": "string",
  "tooltip": "The number of hours spent using the app during first-time app open sessions attributed to a click or view on ad ad.",
  "type": "App conversions"
}, {
  "name": "Appstore first-session hours clicks",
  "property": "string",
  "tooltip": "The number of hours spent using the app during first-time app open sessions attributed to a click on an ad.",
  "type": "App conversions"
}, {
  "name": "Appstore first-session hours views",
  "property": "string",
  "tooltip": "The number of hours spent using the app during first-time app open sessions attributed to ad views.",
  "type": "App conversions"
}, {
  "name": "Appstore usage hours",
  "property": "string",
  "tooltip": "The number of hours spent using the app during first-time and recurring app open sessions attributed to a click or view on ad ad.",
  "type": "App conversions"
}, {
  "name": "Appstore usage hours clicks",
  "property": "string",
  "tooltip": "The number of hours spent using the app during first-time and recurring app open sessions attributed to a click on an ad.",
  "type": "App conversions"
}, {
  "name": "Appstore usage hours views",
  "property": "string",
  "tooltip": "The number of hours spent using the app during first-time and recurring app open sessions attributed to ad views.",
  "type": "App conversions"
}, {
  "name": "Total subscription sign-ups",
  "property": "string",
  "tooltip": "The number of free trial and paid subscriptions for sponsored and related products associated with a click or view on your ad. This includes subscription sign-ups and app subscription sign-ups.",
  "type": "E-commerce conversions"
}, {
  "name": "Total subscription sign-up views",
  "property": "string",
  "tooltip": "The number of free trial and paid subscriptions for sponsored and related products associated with ad impressions. This includes subscription sign-ups and app subscription sign-ups.",
  "type": "E-commerce conversions"
}, {
  "name": "Total subscription sign-up clicks",
  "property": "string",
  "tooltip": "The number of free trial and paid subscriptions for sponsored and related products associated with a click on your ad. This includes subscription sign-ups and app subscription sign-ups.",
  "type": "E-commerce conversions"
}, {
  "name": "Total subscription sign-up rate",
  "property": "string",
  "tooltip": "The ratio of how often customers signed up for a free trial or paid subscription for sponsored and related products products when your ad was displayed. This includes subscription sign-ups and app subscription sign-ups. This is calculated as total subscription sign-ups sponsored & related products divided by impressions.",
  "type": "Rates, E-commerce conversions"
}, {
  "name": "Total cost per subscription",
  "property": "string",
  "tooltip": "The cost to acquire free trial and paid subscriptions for sponsored and related products . This includes subscription sign-ups and app subscription sign-ups. This is calculated as total cost divided by subscription sign-ups sponsored & related products.",
  "type": "Costs, E-commerce conversions"
}, {
  "name": "Total free trial subscription sign-ups",
  "property": "string",
  "tooltip": "The number of free trial subscriptions for sponsored and related products associated with a click or view on your ad. This includes free trial subscription sign-ups and free trial app subscription sign-ups.",
  "type": "E-commerce conversions"
}, {
  "name": "Total free trial subscription sign-up views",
  "property": "string",
  "tooltip": "The number of free trial subscriptions for sponsored and related products associated with ad impressions. This includes free trial subscription sign-ups and free trial app subscription sign-ups.",
  "type": "E-commerce conversions"
}, {
  "name": "Total free trial subscription sign-up clicks",
  "property": "string",
  "tooltip": "The number of free trial subscriptions for sponsored and related products associated with a click on your ad. This includes free trial subscription sign-ups and free trial app subscription sign-ups.",
  "type": "E-commerce conversions"
}, {
  "name": "Total free trial subscription sign-up rate",
  "property": "string",
  "tooltip": "The ratio of how often customers signed up for a free trial subscription for sponsored and related products products when your ad was displayed. This includes free trial subscription sign-ups and free trial app subscription sign-ups. This is calculated as total subscription free trial sign-ups sponsored & related products divided by impressions.",
  "type": "Rates, E-commerce conversions"
}, {
  "name": "Total cost per free trial subscription",
  "property": "string",
  "tooltip": "The cost to acquire free trial subscriptions for sponsored and related products . This includes free trial subscription sign-ups and free trial app subscription sign-ups. This is calculated as total cost divided by free trial subscription sign-ups sponsored & related products.",
  "type": "Costs, E-commerce conversions"
}, {
  "name": "Total paid app subscription sign-ups",
  "property": "string",
  "tooltip": "The number of paid subscriptions for sponsored and related products associated with a click or view on your ad. This includes paid subscription sign-ups and paid app subscription sign-ups.",
  "type": "E-commerce conversions"
}, {
  "name": "Total paid app subscription sign-ups views",
  "property": "string",
  "tooltip": "The number of paid subscriptions for sponsored and related products associated with ad impressions. This includes paid subscription sign-ups and paid app subscription sign-ups.",
  "type": "E-commerce conversions"
}, {
  "name": "Total paid app subscription sign-ups clicks",
  "property": "string",
  "tooltip": "The number of paid subscriptions for sponsored and related products associated with a click on your ad. This includes paid subscription sign-ups and paid app subscription sign-ups.",
  "type": "E-commerce conversions"
}, {
  "name": "Total paid app subscription sign-up rate",
  "property": "string",
  "tooltip": "The ratio of how often customers signed up for a paid subscription for sponsored and related products products when your ad was displayed. This includes paid subscription sign-ups and app subscription sign-ups. This is calculated as total paid subscription sign-ups sponsored & related products divided by impressions.",
  "type": "Rates, E-commerce conversions"
}, {
  "name": "Total cost per paid subscription",
  "property": "string",
  "tooltip": "The cost to acquire paid subscriptions for sponsored and related products . This includes paid subscription sign-ups and paid app subscription sign-ups. This is calculated as total cost divided by paid subscription sign-ups sponsored & related products.",
  "type": "Costs, E-commerce conversions"
}];
export function getTooltip(name) {
  var result = tooltip.find(function (item) {
    return item.name === name || item.key === name;
  });
  if (result) {
    if (!result.tooltip) {
      return null;
    }
    return result.tooltip;
  } else {
    return null;
  }
}
export function getFieldName(key) {
  var result = tooltip.find(function (item) {
    return item.key === key;
  });
  if (result) {
    return result.name;
  } else {
    console.log(key + '没有这个字段');
    return '';
  }
}
export function getFieldKey(name) {
  var result = tooltip.find(function (item) {
    return item.name === name;
  });
  if (result) {
    return result.key;
  } else {
    console.log(name + '没有这个字段');
    return '';
  }
}
export function getFieldType(name) {
  var result = tooltip.find(function (item) {
    return item.name === name || item.key === name;
  });
  if (result) {
    return result.type;
  } else {
    console.log(name + '没有这个字段');
    return '';
  }
}
export function fieldTypeIsCosts(name) {
  var result = tooltip.find(function (item) {
    return item.name === name || item.key === name;
  });
  if (result) {
    return result.property === 'money' || result.type.includes('Costs');
  } else {
    console.log(name + '没有这个字段');
    return false;
  }
}
export function fieldTypeIsRates(name) {
  var result = tooltip.find(function (item) {
    return item.name === name || item.key === name;
  });
  if (result) {
    return result.property === 'percentage' || result.type.includes('Rates');
  } else {
    console.log(name + '没有这个字段');
    return false;
  }
}
export function getFieldListByType(type) {
  var result = [];
  if (type && type !== 'all') {
    tooltip.forEach(function (item) {
      if (item.type.includes(type)) {
        result.push(item);
      }
    });
  } else {
    result = [].concat(tooltip);
  }
  return result;
}
export function getFixedNumber(name) {
  var result = tooltip.find(function (item) {
    return item.name === name || item.key === name;
  });
  if (result) {
    return result.fixedNumber || false;
  } else {
    console.log(name + '没有这个字段');
    return false;
  }
}