var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-select", {
    ref: "multiSelect",
    class: {
      "no-collapseTags": !_vm.collapseTags
    },
    style: {
      width: _vm.width
    },
    attrs: {
      clearable: "",
      "popper-class": _vm.hideAll ? "select-option-pending multi-select-all" : "select-option-pending multi-select-all scrollbarPaddingTop",
      filterable: "",
      multiple: _vm.localMultiple,
      "collapse-tags": _vm.collapseTags,
      "default-first-option": "",
      value: _vm.localValue,
      "reserve-keyword": _vm.reserveKeyword,
      "allow-create": _vm.allowCreate,
      placeholder: _vm.placeholder,
      size: "small",
      disabled: _vm.disabled,
      "no-match-text": "No data, please change your input and try again."
    },
    on: {
      "visible-change": _vm.visibleChange,
      clear: _vm.removeTag,
      "remove-tag": _vm.removeTag,
      change: _vm.selectChange
    }
  }, [!_vm.hideAll ? _c("el-option", {
    staticClass: "all-option",
    attrs: {
      label: _vm.allLabel,
      value: _vm.allValue
    }
  }) : _vm._e(), _vm._l(_vm.selectList, function (item) {
    return _c("el-option", {
      key: item[_vm.itemKey] ? item[_vm.itemKey] : item,
      attrs: {
        label: item[_vm.label] ? item[_vm.label] : item,
        value: item[_vm.value] || item[_vm.value] === 0 ? item[_vm.value] : item
      }
    }, [_c("span", {
      staticClass: "label-name"
    }, [_c("fk-tooltip", {
      attrs: {
        content: item[_vm.label] ? item[_vm.label] : item,
        popperClass: "pointerEvents"
      }
    }, [_c("span", [_vm._v(_vm._s(item[_vm.label] ? item[_vm.label] : item))])])], 1)]);
  }), _c("div", {
    staticClass: "multiple-select-btn-ctn"
  }, [_c("div", {
    staticClass: "Multiple-btn"
  }, [_c("el-button", {
    staticStyle: {
      display: "none"
    },
    attrs: {
      size: "mini",
      type: "primary",
      plain: ""
    },
    on: {
      click: _vm.toggleMode
    }
  }, [_vm._v("\n          " + _vm._s(_vm.localMultiple ? "单选" : "多选") + "\n        ")])], 1), _c("div", [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("Reset")]), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.emitValue
    }
  }, [_vm._v("Apply")])], 1)])], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };