export var tdFixedHeight = 64;
export var tdNormalHeight = 40;
export function vxeStyleFnc() {
  var cssStyle = ".vxe-fixed-table-height td{height: ".concat(tdFixedHeight, "px!important;}\n  .vxe-normal-table-height td{height: ").concat(tdNormalHeight, "px!important;}");
  var style = document.createElement('style');
  style.type = 'text/css';
  style.innerHTML = cssStyle;
  document.getElementsByTagName('HEAD').item(0).appendChild(style);
  style.appendChild(document.createTextNode(""));

  // 将 <style> 元素加到页面中
  document.head.appendChild(style);
}