import "core-js/modules/es6.regexp.split";
import _slicedToArray from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/slicedToArray.js";
import "core-js/modules/es7.object.entries";
import "core-js/modules/web.dom.iterable";
import _typeof from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
// 空对象和数组
export var emptyObj = Object.create(null);
export var emptyArray = [];

// 从数组删除项
export function remove(arr, item) {
  if (arr.length) {
    var index = arr.indexOf(item);
    if (index > -1) {
      return arr.splice(index, 1);
    }
  }
}

/**
 * 滚动到指定位置
 * @export
 * @param {Element} wrap 滚动区域
 * @param {number} [left=0]
 * @param {number} [top=0]
 */
export function scrollTo(_ref) {
  var wrap = _ref.wrap,
    _ref$left = _ref.left,
    left = _ref$left === void 0 ? 0 : _ref$left,
    _ref$top = _ref.top,
    top = _ref$top === void 0 ? 0 : _ref$top,
    _ref$smooth = _ref.smooth,
    smooth = _ref$smooth === void 0 ? true : _ref$smooth;
  if (!wrap) return;
  if (wrap.scrollTo) {
    wrap.scrollTo({
      left: left,
      top: top,
      behavior: smooth ? 'smooth' : 'auto'
    });
  } else {
    wrap.scrollLeft = left;
    wrap.scrollTop = top;
  }
}

/**
 * 指定元素滚动到可视区域
 * @export
 * @param {Element} el 目标元素
 * @param {Element} wrap 滚动区域
 * @param {String} block 垂直方向的对齐，可选：'start', 'center', 'end', 或 'nearest'
 * @param {String} inline 水平方向的对齐，可选值同上
 */
export function scrollIntoView(_ref2) {
  var el = _ref2.el,
    wrap = _ref2.wrap,
    _ref2$block = _ref2.block,
    block = _ref2$block === void 0 ? 'start' : _ref2$block,
    _ref2$inline = _ref2.inline,
    inline = _ref2$inline === void 0 ? 'nearest' : _ref2$inline;
  if (!el || !wrap) return;
  if (el.scrollIntoView) {
    el.scrollIntoView({
      behavior: 'smooth',
      block: block,
      inline: inline
    });
  } else {
    var offsetLeft = el.offsetLeft,
      offsetTop = el.offsetTop;
    var left, top;
    if (block === 'center') {
      top = offsetTop + (el.clientHeight - wrap.clientHeight) / 2;
    } else {
      top = offsetTop;
    }
    if (inline === 'center') {
      left = offsetLeft + (el.clientWidth - wrap.clientWidth) / 2;
    } else {
      left = offsetLeft;
    }
    scrollTo({
      wrap: wrap,
      left: left,
      top: top
    });
  }
}

// 获取滚动条宽度
export var getScrollbarWidth = function () {
  var width = null;
  return function () {
    if (width !== null) return width;
    var div = document.createElement('div');
    div.style.cssText = 'width: 100px; height: 100px;overflow-y: scroll';
    document.body.appendChild(div);
    width = div.offsetWidth - div.clientWidth;
    div.parentElement.removeChild(div);
    return width;
  };
}();

/**
 * 提取计算属性
 * @export
 * @param {String} origin 来源属性
 * @param {Array|Object} props 需要提取的计算属性
 * @param {String} context 来源选项为 function 时的入参
 * @returns {Object}
 */
export function mapGetters(origin, props, context) {
  var map = {};
  var each = function each(prop, option) {
    if (option === null || _typeof(option) !== 'object') {
      option = {
        default: option
      };
    }
    var _option = option,
      def = _option.default,
      alias = _option.alias;
    map[alias || prop] = function () {
      var val = this[origin][prop];
      if (context && typeof val === 'function') {
        // 函数返回
        return val(this[context]);
      } else if (def !== undefined && val === undefined) {
        // 默认值
        if (typeof def === 'function') {
          return def.bind(this)();
        }
        return def;
      }
      return val;
    };
  };
  if (Array.isArray(props)) {
    props.forEach(function (prop) {
      return each(prop);
    });
  } else {
    Object.entries(props).forEach(function (_ref3) {
      var _ref4 = _slicedToArray(_ref3, 2),
        prop = _ref4[0],
        def = _ref4[1];
      return each(prop, def);
    });
  }
  return map;
}

// 去除路径中的 hash
export var prunePath = function prunePath(path) {
  return path.split('#')[0];
};

// 解析过渡配置
export function getTransOpt(trans) {
  return typeof trans === 'string' ? {
    name: trans
  } : trans;
}

// 获取组件 id
export function getCtorId(vm) {
  return vm.$vnode.componentOptions.Ctor.cid;
}