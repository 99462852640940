import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { mapGetters } from "../util";
export default {
  name: 'ContextmenuItem',
  inject: ['$tabs'],
  props: {
    // 菜单数据
    data: {
      type: Object,
      required: true
    }
  },
  computed: _objectSpread({
    // 参数
    context: function context() {
      var $tabs = this.$tabs,
        $menu = this.$parent;
      var target = $menu.target,
        data = $menu.data;
      return {
        $tabs: $tabs,
        $menu: $menu,
        target: target,
        data: data
      };
    }
  }, mapGetters('data', {
    id: '',
    // 菜单标题
    title: function title() {
      return this.$tabs.langs.contextmenu[this.id];
    },
    icon: '',
    tips: '',
    class: {
      default: '',
      alias: 'menuClass'
    },
    visible: true,
    // 是否显示
    enable: true // 是否启用
  }, 'context'))
};