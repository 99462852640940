import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es7.string.pad-start";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.number.is-nan";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.replace";
import { getObjectType } from "./index";
import { checkDaysType, formatDate } from "./util";
// import hour from '../../../pages/smartAdvert/addAdvertRuleTemplate/assets/hour'

// 默认的格式
var _ft = 'yyyy-MM-dd hh:mm:ss';
var hourTime = 60 * 60 * 1000;
// 一天的毫秒数
var dayTime = 24 * hourTime;

/**
 * 格式化日期
 */
function format(date) {
  var ft = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _ft;
  if (!date) return;
  date = getDate(date);
  var o = {
    '(M+)': "".concat(date.getMonth() + 1),
    // 月
    '((D|d)+)': "".concat(date.getDate()),
    // 日
    '((H|h)+)': "".concat(date.getHours()),
    // 小时
    '(m+)': "".concat(date.getMinutes()),
    // 分
    '(s+)': "".concat(date.getSeconds()) // 秒
  };

  // 年份是4位，做特殊处理
  if (/(([Yy])+)/.test(ft)) {
    ft = ft.replace(RegExp.$1, "".concat(date.getFullYear()).substr(4 - RegExp.$1.length));
  }
  Object.keys(o).forEach(function (key) {
    if (new RegExp(key).test(ft)) {
      var value = o[key];
      ft = ft.replace(RegExp.$1, RegExp.$1.length === 1 ? value : "00".concat(value).substr(value.length));
    }
  });
  return ft;
}

/**
 * 获取Date格式时间
 */
function getDate(date) {
  try {
    // Date、Number类型，直接转换后返回
    if (['Date', 'Number'].includes(getObjectType(date))) return new Date(date);
    // String类型，替换时区
    date = date.split('+')[0];
    //做格式转换 否则在new Date时会导致加或减时区时间
    // 例如new Date('2020-02-02') 会得到Sun Feb 02 2020 08:00:00 GMT+0800 (中国标准时间) 因为是东八区  所以加了8个小时 而使用'/'则不会
    // 而若直接使用2020/02/02 00:00:00 则会无法解析时间
    if (!date.split(' ')[1] && !date.split('T')[1] && !date.includes('/')) {
      date = date.slice(0, 10) + 'T00:00:00';
    }
    var newDate = new Date(date);
    // 转换后newDate 为 Invalid date，尝试做格式转换
    if (Number.isNaN(newDate.getTime())) newDate = new Date(date.split('-').join('/'));
    return newDate;
  } catch (e) {
    // new Date失败，返回当前时间
    return new Date();
  }
}

/**
 * 获取currentDate的前days天
 */
export function getBefore(days, currentDate, ft) {
  var now = currentDate ? getDate(currentDate).getTime() : Date.now();
  return format(now - days * dayTime, ft);
}

/**
 * 获取startTime和endTime之间的days
 */
export function getBetweenDays(startTime, endTime) {
  var _startTime = getDate(startTime).getTime();
  var _endTime = getDate(endTime).getTime();
  return Math.floor((_endTime - _startTime) / dayTime);
}
export function getMonthBetween(start, end) {
  var result = [];
  var s = start.split("-");
  var e = end.split("-");
  var min = new Date();
  var max = new Date();
  min.setFullYear(s[0], s[1], 1);
  max.setFullYear(e[0], e[1], 1);
  var curr = min;
  while (curr <= max) {
    var month = curr.getMonth();
    var str = (month == 0 ? curr.getFullYear() - 1 : curr.getFullYear()) + "-" + (month == 0 ? 12 : month < 10 ? '0' + month : month);
    var _s = curr.getFullYear() + "-12";
    if (str == _s) {
      str = curr.getFullYear() + "-12";
    }
    result.push(str);
    curr.setMonth(month + 1);
  }
  return result;
}

/**
 * 获取startTime和endTime之间的date，例：[2019-10-11, 2019-10-12]
 */
export function getBetweenDate(startTime, endTime, ft) {
  var sTime = getDate(startTime).getTime();
  var eTime = getDate(endTime).getTime();
  var days = Math.floor((eTime - sTime) / dayTime);
  var list = [];
  while (days !== 0) {
    list.push(format(eTime - days * 24 * 60 * 60 * 1000, ft));
    days--;
  }
  list.push(format(eTime, ft));
  return list;
}

/**
 * 获取当前时间
 */
export function getCurrent(ft) {
  return format(new Date(), ft);
}

/**
 * 获取date时间的第n个周日
 */
function getSunday(date, n) {
  var _date = getDate(date);
  var week = 7;
  var start = (n - 1) * week + 1;
  _date.setDate(start);
  if (_date.getDay() !== 0) {
    _date.setDate(week - _date.getDay() + start);
  }
  return _date;
}

/**
 * 获取date时间的最后一个周日
 */
function getLastSunday(date) {
  var _date = getDate(date);
  _date.setMonth(_date.getMonth() + 1);
  _date.setDate(1);
  _date = new Date(_date.getTime() - dayTime);
  var start = _date.getDate();
  _date.setDate(start);
  _date.setDate(start - _date.getDay());
  return _date;
}

/**
 * + hours小时
 */
function add(date, hours) {
  return getDate(getDate(date).getTime() + hours * hourTime);
}

/**
 * + 把秒转化为时分秒
 */
function formatSeconds(endTime) {
  var secondTime = parseInt(endTime); //将传入的秒的值转化为Number
  var min = 0; // 初始化分
  var h = 0; // 初始化小时
  var result = '';
  if (secondTime > 60) {
    //如果秒数大于60，将秒数转换成整数
    min = parseInt(secondTime / 60); //获取分钟，除以60取整数，得到整数分钟
    secondTime = parseInt(secondTime % 60); //获取秒数，秒数取佘，得到整数秒数
    if (min > 60) {
      //如果分钟大于60，将分钟转换成小时
      h = parseInt(min / 60); //获取小时，获取分钟除以60，得到整数小时
      min = parseInt(min % 60); //获取小时后取佘的分，获取分钟除以60取佘的分
    }
  }
  result = "".concat(h > 0 ? h.toString().padStart(2, '0') + ':' : '').concat(min.toString().padStart(2, '0'), ":").concat(secondTime.toString().padStart(2, '0'));
  return result;
}

/**
 * 获取上x个月1号
 */
function getLastMonth(time, n) {
  var date = getDate(time);
  var month = date.getMonth();
  month = month - n;
  date.setMonth(month);
  date.setDate(1);
  return format(date, 'yyyy-MM-dd');
}
function getOldMonth(date, n) {
  var result = [];
  for (var i = 0; i < n; i++) {
    var d = new Date();
    d.setDate(1);
    d.setMonth(d.getMonth() - i);
    var year = d.getFullYear();
    var m = d.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    var nowDate = format(d, 'yyyy-MM-dd');
    var obj = {
      label: "".concat(year, "\u5E74").concat(m, "\u6708"),
      value: nowDate
    };
    result.push(obj);
  }
  return result;
}
;

/**
 *
 * @param startDate 当前周期开始时间
 * @param cycle 周期天数
 * @returns {null|string[]}
 */
function getLastCycle(startDate, cycle) {
  if ([7, 14, 30, 90].includes(Number(cycle))) {
    var date = getBefore(1, startDate);
    var result = checkDaysType(cycle, date, true);
    return [result.startDate, result.endDate];
  }
  return null;
}
var monthMap = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec'
};
/**
 *
 * @param date 日期字符串  2023-01-04
 */
export function date2EnNoYear(date) {
  var _date = date.split(' ')[0];
  var dateArr = _date.split('-');
  return monthMap[dateArr[1]] + ' ' + dateArr[2];
}
export function date2EnWithYear(date) {
  var dateArr = format(date, 'yyyy-MM-dd').split('-');
  return monthMap[dateArr[1]] + ' ' + dateArr[2] + ', ' + dateArr[0];
}
export function time2En(time) {
  var arr = time.split(':');
  var timeEn = '';
  if (Number(arr[0]) > 12) {
    if (Number(arr[0]) < 22) {
      timeEn = "0".concat(Number(arr[0]) - 12, ":").concat(arr[1], " pm");
    } else {
      timeEn = "".concat(Number(arr[0]) - 12, ":").concat(arr[1], " pm");
    }
  } else {
    timeEn = "".concat(arr[0], ":").concat(arr[1], " am");
  }
  return timeEn;
}
export function convertCSTToEST(time) {
  // Convert CST to UTC by subtracting 1 hour
  var cstTime = new Date(time);
  var utcTime = new Date(cstTime.getTime() - 1 * 60 * 60 * 1000);

  // Convert UTC to EST by adding 5 hours
  var estTime = new Date(utcTime.getTime() + 5 * 60 * 60 * 1000);
  estTime = format(estTime);
  return estTime;
}
export function convertUTF8ToCST(time) {
  var beijingTime = new Date(time);
  var cstTime = beijingTime;
  cstTime = format(cstTime);
  return cstTime;
}
export function convertUTF8ToEST(time) {
  var beijingTime = new Date(time);
  var estTime = new Date(beijingTime.getTime() + 60 * 60 * 1000);
  estTime = format(estTime);
  return estTime;
}

/**
 *
 * @param dateRange 日期字符串数组  ['2022-01-23','2023-01-04']
 */
export function dateRange2En(dateRange) {
  if (dateRange && dateRange[0]) {
    var startDate = dateRange[0].split(' ')[0];
    var endDate = dateRange[1].split(' ')[0];
    var startDateArr = startDate.split('-');
    var endDateArr = endDate.split('-');
    if (startDate === endDate) {
      return date2EnWithYear(startDate);
    }
    if (startDateArr[0] === endDateArr[0]) {
      return date2EnNoYear(startDate) + ' - ' + date2EnWithYear(endDate);
    } else {
      return date2EnWithYear(startDate) + ' - ' + date2EnWithYear(endDate);
    }
  }
}
export function getToday() {
  var date = new Date();
  var seperator = '-';
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = '0' + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = '0' + strDate;
  }
  var currentdate = year + seperator + month + seperator + strDate;
  return currentdate;
}
/**
 *
 * @param monthString 2023-06
 */
export function getMonthLastDay(monthString) {
  var year = monthString.split('-')[0];
  var month = Number(monthString.split('-')[1]);
  var day = new Date(year, month, 0).getDate();
  return monthString + '-' + day;
}

/**
 * @params date 日期字符串数 2022-01-23   difference 差几个月
 * @return yyyy-mm  传入日期的相差多少个月的月份
 */
export function getMonth(date, difference) {
  var _date = new Date(date);
  var year = _date.getFullYear();
  var month = _date.getMonth() + 1;
  if (difference + month > 12) {
    var newYear = year + Math.floor((difference + month) / 12);
    var newMonth = (difference + month) % 12;
    return "".concat(newYear, "-").concat(newMonth);
  } else if (difference + month < 0) {
    var _newYear = year - Math.ceil(Math.abs(difference + month) / 12);
    var _newMonth = 12 + (difference + month) % 12;
    return "".concat(_newYear, "-").concat(_newMonth);
  } else {
    return "".concat(year, "-").concat(difference + month);
  }
}
/**
 * @params date 月份 2022-01
 * @return Number
 */
export function getDaysInMonth(yearMonth) {
  var year = yearMonth.split('-')[0];
  var month = Number(yearMonth.split('-')[1]);
  // 月份是从 0 开始的，所以要减去 1
  var date = new Date(year, month - 1, 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return lastDay.getDate();
}
export default {
  date2EnNoYear: date2EnNoYear,
  date2EnWithYear: date2EnWithYear,
  dateRange2En: dateRange2En,
  getDate: getDate,
  format: format,
  getMonth: getMonth,
  getBefore: getBefore,
  getBetweenDays: getBetweenDays,
  getMonthBetween: getMonthBetween,
  getBetweenDate: getBetweenDate,
  getCurrent: getCurrent,
  getSunday: getSunday,
  getLastSunday: getLastSunday,
  add: add,
  formatSeconds: formatSeconds,
  getLastMonth: getLastMonth,
  getOldMonth: getOldMonth,
  getLastCycle: getLastCycle,
  getToday: getToday,
  getMonthLastDay: getMonthLastDay
};