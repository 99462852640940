var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.visible ? _c("a", {
    staticClass: "router-tab__contextmenu-item",
    class: _vm.menuClass,
    attrs: {
      "data-action": _vm.id,
      disabled: !_vm.enable,
      title: _vm.tips
    },
    on: {
      click: function click($event) {
        _vm.enable && _vm.data.handler(_vm.context);
      }
    }
  }, [_vm.icon ? _c("i", {
    staticClass: "router-tab__contextmenu-icon",
    class: _vm.icon
  }) : _vm._e(), _vm._v("\n  " + _vm._s(_vm.title) + "\n")]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };