import "core-js/modules/es6.string.fixed";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    staticClass: "fk-loading-component",
    class: {
      fixed: _vm.fixed
    }
  }, [_c("img", {
    staticClass: "fk-loading-image",
    attrs: {
      src: require("../../assets/images/logo_loading.gif"),
      alt: ""
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };