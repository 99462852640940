import _slicedToArray from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/slicedToArray.js";
import "core-js/modules/web.dom.iterable";
// 引入目录下语言配置
var context = require.context('./', false, /^((?!index).)*\.js$/);

// 语言配置
export default context.keys().reduce(function (map, path) {
  var _exec = /\.\/(.*).js/g.exec(path),
    _exec2 = _slicedToArray(_exec, 2),
    key = _exec2[1];
  map[key] = context(path).default;
  return map;
}, {});