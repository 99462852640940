// wiki中广告中心需要展示小问号字段
export var list = [
// 一级页面-广告管理
{
  name: '状态',
  question: '广告状态为亚马逊接口实时返回，展示广告活动当前的状态信息。' + '状态反映对广告活动所做的更改，以及广告活动的广告是否已根据亚马逊广告政策获得了批准。'
}, {
  name: '竞价策略',
  question: '请根据广告活动目标选择您希望为广告竞价的方式。 您可以随时从广告活动设置页面更改您的竞价策略。'
}, {
  name: '原始预算',
  question: '原始预算是备用字段，针对分时预算规则生效。通过以下方式修改预算，会自动同步为原始预算：\n' + '1. 您在Flywheel系统手动修改预算，或分时预算规则的预算总值；\n' + '2. 通过“自动预算”、广告计划、自动化模型修改。'
}, {
  name: '匹配类型',
  question: '匹配类型是关键词投放的一部分，若您在Flywheel修改匹配类型，我们会暂停原来的匹配类型对应的关键词投放，再新建一条修改后匹配类型对应的投放。'
}, {
  name: '只降低',
  question: '当您的广告不太可能带来销售时，我们将实时降低您的竞价。 在 2019 年 01 月之前创建的任何广告活动都会使用此设置。'
}, {
  name: '提高和降低',
  question: '当您的广告很有可能带来销售时，我们将实时提高您的竞价（最高可达 100%），并在您的广告不太可能带来销售时降低您的竞价。'
}, {
  name: '固定竞价',
  question: '我们将使用您的确切竞价和您设置的任何手动调整，而不会根据售出可能性对您的竞价进行更改。'
}, {
  name: '平均预算内活跃时间',
  question: 'Avg. Time In Budget，您的广告活动处于预算范围内的时间百分比。\n' + '注意：“平均预算内活跃时间”指标仅适用于商品推广，对应数据通过亚马逊接口获取，可能存在部分偏差。'
}, {
  name: '预计错过的展示',
  question: 'Estimated Missed Impressions，广告活动未超出预算的情况下，预计获得的额外曝光量。\n' + '该指标取决于多种因素，可能包括预计错过的点击量和历史点击率 (CTR)。只是估计值，并非实际曝光量保证。部分广告活动可能没有足够的数据计算估计值。\n' + '注意：“预计错过的展示”指标仅适用于商品推广，对应数据通过亚马逊接口获取，可能存在部分偏差。'
}, {
  name: '预计错过的点击',
  question: 'Estimated Missed Clicks，广告活动未超出预算的情况下，预计获得的额外点击量。\n' + '该指标取决于多种因素，可能包括未耗尽预算的类似广告活动的历史绩效。只是估计值，并非实际点击量保证。部分广告活动可能没有足够的数据计算估计值。\n' + '注意：“预计错过的点击”指标仅适用于商品推广，对应数据通过亚马逊接口获取，可能存在部分偏差。'
}, {
  name: '预计错过的销售额',
  question: 'Estimated Missed Sales，广告活动未超出预算的情况下，预计带来的额外销量。\n' + '该指标取决于多种因素，可能包括预计错过的点击量和历史销量。只是估计值，并非实际销量保证。部分广告活动可能没有足够的数据计算估计值。\n' + '注意：“预计错过的销售额”指标仅适用于商品推广，对应数据通过亚马逊接口获取，可能存在部分偏差。'
}, {
  name: 'ACoB',
  question: 'Advertising Cost of Budget，广告花费占预算的比例。\n' + 'ACoB值仅从北京时间2019年10月25日开始计算。'
}, {
  name: 'SPC',
  question: 'sales per click，每次点击带来的平均广告销售额。\n' + 'SPC = 广告销售额 / 点击量'
}, {
  name: 'CPO',
  question: 'cost per order，指每个广告订单的平均花费。\n' + 'CPO =广告花费 / 广告订单量'
}, {
  name: '广告SKU订单量',
  question: '广告SKU订单量是指买家在点击广告后购买广告SKU的订单数量。'
}, {
  name: '其他SKU订单量',
  question: '其他SKU订单量是指买家在点击广告后购买其他非广告SKU的订单数量。'
}, {
  name: '广告SKU销售额',
  question: '广告SKU销售额是在运行广告活动期间的指定时间范围内，因广告被点击而向买家售出的广告推广商品的价值总额。'
}, {
  name: '其他SKU销售额',
  question: '其他SKU销售额是在运行广告活动期间的指定时间范围内，因广告被点击而向买家售出的非广告推广商品的价值总额。'
}, {
  name: '总销售额',
  question: 'FBA产品，销售额 = 销量 *  价格（含税） \n' + 'FBM产品，销售额 = 销量 * 价格（含税） + 买家运费\n' + '1. 当产品有VAT税费时，销售额为通过含税价格计算得出的金额。\n' + '2. 针对pending订单，销售额为根据含税价格计算的预估金额，可能与实际金额存在误差；当订单全部发货后，将更新实际销售额。'
}, {
  name: 'ROAS',
  question: 'ROAS = 广告销售额 / 广告花费'
}, {
  name: 'ACoAS',
  question: 'Advertising Cost of All Sales，ACoAS = 广告花费 / 总销售额 * 100%'
}, {
  name: 'ASoAS',
  question: 'Advertising Sales of All Sales，ASoAS = 广告销售额 / 总销售额 * 100%'
}, {
  name: '转化率',
  question: '转化率 = 广告订单量 / 点击量 * 100%'
}, {
  name: '新买家订单数',
  question: '在过去一年内首次订购品牌旗下商品的订单数量。\n' + '注意： “品牌新买家”指标仅适用于品牌推广和展示型推广， 2018 年11月1日开始适用于品牌推广，“品牌新买家”指标自对应开始日期进行计算；2021年3月开始适用于展示型推广，对应“品牌新买家”指标自6月开始进行计算。\n' + '品牌推广发布了多个“品牌新买家”指标，帮助您监测首次在亚马逊网站上购买您的品牌商品的买家的订单数量和销售额。借助这些“品牌新买家”指标，您可以更好地衡量和优化正在投放的广告活动，以及规划未来的营销策略，从而提升买家获取率和品牌忠诚度。\n' + '我们通过查看买家在过去 12 个月的购买历史记录，来确定其订单是否来自品牌新买家。如果买家在过去 12 个月内未购买过您的品牌，则订单将被视为“品牌新买家”订单。'
}, {
  name: '新买家订单比',
  question: '“品牌新买家”订单数量占订单总数的百分比。\n' + '注意： “品牌新买家”指标仅适用于品牌推广和展示型推广， 2018 年11月1日开始适用于品牌推广，“品牌新买家”指标自对应开始日期进行计算；2021年3月开始适用于展示型推广，对应“品牌新买家”指标自6月开始进行计算。\n' + '品牌推广发布了多个“品牌新买家”指标，帮助您监测首次在亚马逊网站上购买您的品牌商品的买家的订单数量和销售额。借助这些“品牌新买家”指标，您可以更好地衡量和优化正在投放的广告活动，以及规划未来的营销策略，从而提升买家获取率和品牌忠诚度。\n' + '我们通过查看买家在过去 12 个月的购买历史记录，来确定其订单是否来自品牌新买家。如果买家在过去 12 个月内未购买过您的品牌，则订单将被视为“品牌新买家”订单。'
}, {
  name: '新买家销量',
  question: '“品牌新买家”订单的总销售额（以当地货币为单位）。\n' + '注意： “品牌新买家”指标仅适用于品牌推广和展示型推广， 2018 年11月1日开始适用于品牌推广，“品牌新买家”指标自对应开始日期进行计算；2021年3月开始适用于展示型推广，对应“品牌新买家”指标自6月开始进行计算。\n' + '品牌推广发布了多个“品牌新买家”指标，帮助您监测首次在亚马逊网站上购买您的品牌商品的买家的订单数量和销售额。借助这些“品牌新买家”指标，您可以更好地衡量和优化正在投放的广告活动，以及规划未来的营销策略，从而提升买家获取率和品牌忠诚度。\n' + '我们通过查看买家在过去 12 个月的购买历史记录，来确定其订单是否来自品牌新买家。如果买家在过去 12 个月内未购买过您的品牌，则订单将被视为“品牌新买家”订单。'
}, {
  name: '新买家销量比',
  question: '“品牌新买家”销售额占总销售额的百分比（以当地货币为单位）。\n' + '注意： “品牌新买家”指标仅适用于品牌推广和展示型推广， 2018 年11月1日开始适用于品牌推广，“品牌新买家”指标自对应开始日期进行计算；2021年3月开始适用于展示型推广，对应“品牌新买家”指标自6月开始进行计算。\n' + '品牌推广发布了多个“品牌新买家”指标，帮助您监测首次在亚马逊网站上购买您的品牌商品的买家的订单数量和销售额。借助这些“品牌新买家”指标，您可以更好地衡量和优化正在投放的广告活动，以及规划未来的营销策略，从而提升买家获取率和品牌忠诚度。\n' + '我们通过查看买家在过去 12 个月的购买历史记录，来确定其订单是否来自品牌新买家。如果买家在过去 12 个月内未购买过您的品牌，则订单将被视为“品牌新买家”订单。'
}, {
  name: '竞价调整',
  question: '通过输入相对默认竞价提高的百分比，按广告位应用不同竞价。 这些调整将应用于广告活动中的所有竞价。 您的竞价可以进一步更改，具体取决于您的竞价策略。'
}, {
  name: '原始竞价',
  question: '原始竞价是备用字段，针对分时调价规则生效。通过以下方式修改竞价，会自动同步为原始竞价：\n' + '1. 您在Flywheel系统手动修改竞价；\n' + '2. 通过“自动竞价”、“建议竞价自动同步”、自动化模型修改。'
},
// 二级页面-广告管理-广告自动化tab页
{
  name: '自动化标签',
  question: '您在“广告自动化”tab页创建标签后，符合标签条件范围的投放或搜索词会自动显示标签名称。\n' + '在“广告自动化”tab页可以集中查看当前广告活动所有的自动化标签。'
},
// 二级页面-广告管理-出单ASIN tab页
{
  name: '出单ASIN',
  question: '顾客点击您的广告后实际购买的ASIN'
}, {
  name: '出单ASIN所在广告活动数',
  question: '出单ASIN所在的非归档状态的广告活动数量'
}, {
  name: '出单ASIN订单量',
  question: '出单ASIN（SKU）对应的订单量，当出单ASIN（SKU）不是广告ASIN（SKU）时，对应订单量即其他SKU订单量；当出单ASIN（SKU）是广告ASIN（SKU）时，对应订单量即广告SKU订单量'
}, {
  name: '出单ASIN销量',
  question: '出单ASIN（SKU）对应的销量，当出单ASIN（SKU）不是广告ASIN（SKU）时，对应销量即其他SKU销量；当出单ASIN（SKU）是广告ASIN（SKU）时，对应销量即广告SKU销量'
}, {
  name: '出单ASIN销售额',
  question: '出单ASIN（SKU）对应的销售额，当出单ASIN（SKU）不是广告ASIN（SKU）时，对应销售额即其他SKU销售额；当出单ASIN（SKU）是广告ASIN（SKU）时，对应销售额即广告SKU销售额'
}, {
  name: '订单量占比',
  question: '订单量占比，即出单ASIN订单量占广告订单量的比例'
}, {
  name: '销售额占比',
  question: '销售额占比，即出单ASIN销售额占广告销售额的比例'
},
// 一级页面-自动化模板
{
  name: '广告活动数量',
  question: '指应用了对应自动化模板的有效广告活动数量。\n' + '对应广告活动数量不统计已归档的广告活动；不统计自动化模板所在自动化规则或标签全部归档的广告活动。'
}, {
  name: '费用类型',
  question: '用户支付广告费用的方式，费用类型包括：CPC、VCPM'
}, {
  name: 'VCPM',
  question: 'VCPM=广告花费 / 可见展示量 * 1000（以千次为单位）。您为每 1,000 次可见展示量支付的平均金额。\n' + '此指标仅适用于成本类型为 VCPM 的广告活动，其中广告花费、可见展示量均为VCPM类型广告活动的广告花费、可见展示量。'
}, {
  name: 'DPV',
  question: '商品详情页浏览量，指亚马逊上由广告产生的商品详情页浏览量。此指标仅适用于SD广告。'
}, {
  name: '可见展示量',
  question: '符合 MRC 可见度标准的展示量（广告内容的50%，至少被展示1 秒）。此指标仅适用于SBV广告、VCPM类型的SD广告。'
}, {
  name: 'VTR',
  question: 'View through rate，VTR = 可见展示量 / 曝光量 * 100%。此指标仅适用于SBV广告。'
}, {
  name: 'vCTR',
  question: 'Clicks through rate for views，vCTR = 点击量 / 可见展示量 * 100%。此指标仅适用于SBV广告。'
}, {
  name: 'CPV',
  question: 'Cost per view，指每次浏览的平均花费。\n' + 'CPV = 广告花费 / 可见展示量。此指标仅适用于SBV广告。'
}, {
  name: '5秒展示量',
  question: '5秒展示量，指用户观看完整视频时的展示量或观看 5 秒的展示量（以时间较短者为准）。此指标仅适用于SBV广告。'
}, {
  name: '5秒展示率',
  question: '5秒展示率，指用户观看完整视频时的展示量百分比或观看 5 秒的展示量百分比（以时间较短者为准）。\n' + '5秒展示率 = 5秒展示量 / 曝光量 * 100%，此指标仅适用于SBV广告。'
}, {
  name: '视频播放1/4次数',
  question: '视频播放1/4次数，指视频播放至 25% 时的展示量。此指标仅适用于SBV广告。'
}, {
  name: '视频播放1/2次数',
  question: '视频播放1/2次数，指视频播放至 50% 时的展示量。此指标仅适用于SBV广告。'
}, {
  name: '视频播放3/4次数',
  question: '视频播放3/4次数，指视频播放至 75% 时的展示量。此指标仅适用于SBV广告。'
}, {
  name: '视频完整播放次数',
  question: '视频完整播放次数，指视频播放至 100% 时的展示量。此指标仅适用于SBV广告。'
}, {
  name: '取消静音次数',
  question: '取消静音次数，指用户取消视频静音时的展示量。此指标仅适用于SBV广告。'
}, {
  name: '广告位',
  question: '通过输入相对默认竞价提高的百分比，按广告位应用不同竞价。 这些调整将应用于广告活动中的所有竞价。'
}, {
  name: '展示次数',
  question: '广告展示的次数。 搜索词中的所有展示次数都是针对具有点击量的商品，因此这个总计值可能与您广告组的总计值不同。'
}];