import _slicedToArray from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/slicedToArray.js";
import "core-js/modules/es7.object.entries";
import "core-js/modules/web.dom.iterable";
// 菜单数据
var menuMap = {
  // 刷新
  refresh: {
    handler: function handler(_ref) {
      var data = _ref.data,
        $tabs = _ref.$tabs;
      $tabs.refreshTab(data.id);
    }
  },
  // 刷新全部
  refreshAll: {
    handler: function handler(_ref2) {
      var $tabs = _ref2.$tabs;
      $tabs.refreshAll();
    }
  },
  // 关闭
  close: {
    enable: function enable(_ref3) {
      var target = _ref3.target;
      return target.closable;
    },
    handler: function handler(_ref4) {
      var data = _ref4.data,
        $tabs = _ref4.$tabs;
      $tabs.closeTab(data.id);
    }
  },
  // 关闭左侧
  closeLefts: {
    enable: function enable(_ref5) {
      var $menu = _ref5.$menu;
      return $menu.lefts.length;
    },
    handler: function handler(_ref6) {
      var $menu = _ref6.$menu;
      $menu.closeMulti($menu.lefts);
    }
  },
  // 关闭右侧
  closeRights: {
    enable: function enable(_ref7) {
      var $menu = _ref7.$menu;
      return $menu.rights.length;
    },
    handler: function handler(_ref8) {
      var $menu = _ref8.$menu;
      $menu.closeMulti($menu.rights);
    }
  },
  // 关闭其他
  closeOthers: {
    enable: function enable(_ref9) {
      var $menu = _ref9.$menu;
      return $menu.others.length;
    },
    handler: function handler(_ref10) {
      var $menu = _ref10.$menu;
      $menu.closeMulti($menu.others);
    }
  }
};

// 遍历填充 id
Object.entries(menuMap).forEach(function (_ref11) {
  var _ref12 = _slicedToArray(_ref11, 2),
    id = _ref12[0],
    item = _ref12[1];
  return item.id = id;
});
export default menuMap;

// 默认菜单
export var defaultMenu = ['refresh', 'refreshAll', 'close', 'closeLefts', 'closeRights', 'closeOthers'];